import React, {Component} from 'react';
import ReactPagination from "../ReactPagination/ReactPagination";
import {Image, Row, Modal, Button} from "react-bootstrap";
import './Promotions.css';
import {NavLink} from 'react-router-dom';

const {urlImage/*, urlBase*/} = require('../../utils');

const PromotionInfo = (props) => {
  // const {
  //   serviceCategory, plans, serviceCategories, onChangeServiceCategory, selectedProvider, selectService, selectedService,
  //   deselectService, country, paymentMethod, changePaymentMethod, onSubmitPay, currentUser, makingPayment, errorsPayment,
  //   paymentStatus, showAddPhone, openShowAddPhone, closeShowAddPhone, onSubmitAddPhone,
  //   phones, onSubmitDeletePhone, totalPrice, totalFee, totalToPay, onResetPayment, ...rest
  // } = props;
  // const formId = 'form-submit-payment';
  
  const {
    selectedPromotion, openModalPromotion, onDeselectPromotion
  } = props;
  return (
    <Modal
      // {...rest}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-app promotion-modal-container"
      show={openModalPromotion === true}
      onHide={onDeselectPromotion}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="app-header">
          <div className="row promotion-modal-title">
            <span> {selectedPromotion.title} </span>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
          <div className="row">
            <div className="col-4">
              <span className="font-weight-bold font-s-15">Valid from:</span>
            </div>
            <div className="col-8">
              <span>{selectedPromotion.startUtc} </span>
            </div >
          </div>

          <div className="row">
            <div className="col-4">
              <span className="font-weight-bold font-s-15">Valid to:</span>
            </div>
            <div className="col-8">
              <span>{selectedPromotion.endUtc} </span>
            </div >
          </div>

          <div className="row">
            <div className="col-4">
              <span className="font-weight-bold font-s-15">Minimun Send Amount:</span>
            </div>
            <div className="col-8">
              <span>{selectedPromotion.minimumSendAmount} USD </span>
            </div >
          </div>
          <div className="row">
            <div className="col-4">
              <span className="font-weight-bold font-s-15">Promotion Type:</span>
            </div>
            <div className="col-8">
              <span>{selectedPromotion.promotionType} </span>
            </div >
          </div>
          <div className="modal-content-custom-divider"></div>

          <div className="row">
            <div className="col-12">
              <p>{selectedPromotion.description} </p>
            </div >
          </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onDeselectPromotion} className="btn btn-outline-dark btn-form-app">Cancel</Button>
          <NavLink to="/pay" className="tn-link pay-now-link" onClick={props.onClickLogout}>
                  {/* <FontAwesomeIcon icon="pencil-alt" size="1x" className="icon-md color-dark-gray"/>Logout */}
                Pay Now
          </NavLink>
      </Modal.Footer>
    </Modal>
  );
};

const Promotion = (props) => (  
  <div className="">
    {/* <Row className="image-container-promotion-box">
      <Image src={`${urlBase}/${props.providerImage}`} className="fp-image"/>
    </Row> */}
    <Row className="image-container-promotion-box fp-container">
      <Image src={`${urlImage}/${props.flag}.png`} className="fp-image"/>
    </Row>
    <Row className="promotion-title-container">
      <p className="promotion-title-box display-only-peace-text">{props.title}</p>
    </Row>
  </div>
);

class Promotions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      promotions: props.promotions,
      currentPage: 1,
      currentStartElement: 0,
      currentEndElement: 3,
      promotionsToDisplay: [],
      openModal: false
    };

  }

  openModal = ev => {
    let openModal = true;
    this.setState({ openModal });
  }

  componentDidMount(){

  }

  render() {
     
    const { onPaginate, currentPage, totalPagesPromotions, promotions, onSelectPromotion, selectedPromotion, openModalPromotion, onDeselectPromotion } = this.props;
    let promotionsToDisplay = promotions.map((promotion, index) => {
      return  <div key={index} className={`promotion-product-container promotion-product-container-${index}`} onClick={() => onSelectPromotion(promotion) }>
                <Promotion  image={promotion.image} 
                            title={promotion.title} 
                            description={promotion.description}
                            url={promotion.url}
                            flag={promotion.flag}
                            providerImage={promotion.providerImageUrl}/>
              </div>;
    });
    return <div className="bg-gray content text-center promotions-super-container">
      <h1 className="section-title featured-promotions-header">Featured Promotions</h1>
       <Row className="empty-space-there"></Row>
      <Row>
        {promotionsToDisplay}
        { openModalPromotion ? 
         <PromotionInfo onSelectPromotion={onSelectPromotion}
                        selectedPromotion={selectedPromotion}
                        onDeselectPromotion={onDeselectPromotion} 
                        openModalPromotion={openModalPromotion}/> : 
         null}
      </Row>
      <Row className="empty-space-there-2"></Row>
      <Row className="promotions-pagination-container">
          <ReactPagination active={currentPage} nroPages={totalPagesPromotions} onPaginate={onPaginate}/>
          <div id="react-paginate"></div>
      </Row>
  
    </div>
  }
}

export default Promotions;