import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
  PAY_PAGE_LOADED,
  PAY_PAGE_UNLOADED,
  UPDATE_FIELD_PAY,
  PAGINATE_PAY,
  PAGINATE_PROMOTION,
  PAY_PAGE_LOAD_SERVICES,
  PAY_PAGE_LOAD_SERVICES_MASK,
  PAY_PAGE_UNLOAD_SERVICES,
  UPDATE_FIELD_PAY_SERVICE,
  PAY_SELECT_SERVICE,
  PAY_DESELECT_SERVICE,
  UPDATE_FIELD_FORM_PAY_SERVICE,
  MAKE_PAYMENT,
  PAY_AMOUNT_TO_RECEIVE,
  PAY_ADD_PHONE,
  PAY_DELETE_PHONE,
  PAY_RESET_PAYMENT,
  LOAD_USER,
  PROMOTION_LOAD_INFO,
  PROMOTION_UNLOAD_INFO,
  RESIZE_WINDOWS
} from '../../constants/actionTypes';

import './Pay.css';

// import ControlledCarousel from "./ControlledCarousel";
import Promotions from "./Promotions";
import PlanPayment from "./PlanPayment";
import agent from "../../agent";
import Services from "./Services";
import Buy from "./Buy";

const {paymentMethods} = require('../../utils');

const mapStateToProps = state => ({
  ...state.pay,
  ...state.common
});

const mapDispatchToProps = dispatch => ({
  onLoad: (payload) =>
    dispatch({type: PAY_PAGE_LOADED, payload}),
  onResize: (screenWidth) => 
    dispatch({type: RESIZE_WINDOWS, screenWidth}),
  onUnload: () =>
    dispatch({type: PAY_PAGE_UNLOADED}),
  onChangeCountry: value =>
    dispatch({type: UPDATE_FIELD_PAY, key: 'country', value}),
  onChangeCategory: value =>
    dispatch({type: UPDATE_FIELD_PAY, key: 'category', value}),
  onChangeProvider: value =>
    dispatch({type: UPDATE_FIELD_PAY, key: 'provider', value}),
  onPaginate: page =>
    dispatch({type: PAGINATE_PAY, page}),
  onPaginatePromotion: page =>
    dispatch({type: PAGINATE_PROMOTION, payload: {page: page, totalPerPage: 4}}),
  onSelectProvider: id => {
    dispatch({type: PAY_PAGE_LOAD_SERVICES_MASK, id});
    dispatch({type: PAY_PAGE_LOAD_SERVICES, payload: agent.Provider.getById(id)});
  },
  onSelectPromotion: promotion => {
    dispatch({type: PROMOTION_LOAD_INFO, payload: {promotion: promotion}});
  },
  onDeselectPromotion: () => {
    dispatch({type: PROMOTION_UNLOAD_INFO});
  },
  onCloseServices: () =>
    dispatch({type: PAY_PAGE_UNLOAD_SERVICES}),
  onChangeServiceCategory: value =>
    dispatch({type: UPDATE_FIELD_PAY_SERVICE, key: 'serviceCategory', value}),
  onSelectService: id => {
    dispatch({type: PAY_SELECT_SERVICE, id});
    dispatch({type: LOAD_USER, payload: agent.Auth.current()});
  },
  onDeselectService: () =>
    dispatch({type: PAY_DESELECT_SERVICE}),
  onChangePaymentMethod: value =>
    dispatch({type: UPDATE_FIELD_FORM_PAY_SERVICE, key: 'paymentMethod', value}),
  onSubmitPay: (paymentId, currency, totalAmount, planName, planKey, operatorCode, countryCode, type, transferType, notificationEmail, paymentMethod, nauta, recharges, currentUser) => {
    if (recharges.length < 1 || recharges.length > 5) {
      dispatch({type: MAKE_PAYMENT, payload: {body: {message: "You must add between 1 and 5 elements"}}, error: true});
    } else if (
      (paymentMethod === paymentMethods.BALANCE && currentUser.balance < totalAmount) ||
      (paymentMethod === paymentMethods.DEBIT_CREDIT && currentUser.credit < totalAmount)
    ) {
      dispatch({type: MAKE_PAYMENT, payload: {body: {message: "Insufficient funds"}}, error: true});
    } else {
      const payload = {
        paymentId,
        currency,
        totalAmount,
        planName,
        planKey,
        operatorCode,
        countryCode,
        type,
        transferType,
        notificationEmail,
        paymentMethod,
        nauta,
        recharges
      };
      dispatch({
        type: MAKE_PAYMENT,
        payload: agent.Payment.make(payload),
        error: null
      });
    }
  },
  onToggleShowAddPhone: value =>
    dispatch({type: UPDATE_FIELD_PAY, key: 'showAddPhone', value}),
  onSubmitAddPhone: (amount, phone, fee, planName, operatorCode, countryCode, phoneNumber, transferType, planKey) => {
    const id = Date.now();
    const payload = {
      amount, planName, operatorCode, countryCode, phoneNumber, transferType, planKey
    };
    dispatch({type: PAY_ADD_PHONE, id, amount, phone, fee});
    dispatch({type: PAY_AMOUNT_TO_RECEIVE, id, payload: agent.Payment.receive(payload)});
  },
  onSubmitDeletePhone: (index) =>
    dispatch({type: PAY_DELETE_PHONE, index}),
  onResetPayment: () =>
    dispatch({type: PAY_RESET_PAYMENT}),
});

class Home extends Component {

  constructor(props) {
    super(props);
    this.state = {
      screenWidth: null
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({screenWidth: window.innerWidth});
  }

  componentWillMount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
    this.props.onLoad(Promise.all([
      agent.Country.getAll(),
      agent.Provider.categories(),
      agent.Provider.getAll(),
      agent.Promotions.getAll()
    ]));
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }

  resize() {
    let width = window.innerWidth;
    this.props.onResize(width);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
    this.props.onUnload();
  }

  render() {
    const {
      inProgress, countries, categories, providers, country, category, provider, filteredProviders, currentPage, totalPages,
      showServices, serviceCategory, serviceCategories, plans, loadingServices, errors, selectedProvider, selectedService,
      paymentMethod, currentUser, makingPayment, errorsPayment, errorsServices, paymentStatus, providerMask,
      onChangeCountry, onChangeCategory, onChangeProvider, onPaginate, onSelectProvider, onCloseServices, onChangeServiceCategory,
      onSelectService, onDeselectService, onChangePaymentMethod, onSubmitPay, onToggleShowAddPhone, showAddPhone,
      onSubmitAddPhone, phones, onSubmitDeletePhone, totalPagesPromotions, onPaginatePromotion, promotionsPaginated, currentPagePromotions,
      totalPrice, totalFee, totalToPay, onResetPayment, onSelectPromotion, selectedPromotion, openModalPromotion, onDeselectPromotion
    } = this.props;

    return (
      <div className="pay-super-parent-container">
        {/* <ControlledCarousel/> */}
        {promotionsPaginated ?
          <Promotions
            promotions={promotionsPaginated}
            onPaginate={onPaginatePromotion}
            currentPage={currentPagePromotions}
            onSelectPromotion={onSelectPromotion}
            totalPagesPromotions={totalPagesPromotions}
            openModalPromotion={openModalPromotion}
            onDeselectPromotion={onDeselectPromotion}
            selectedPromotion={selectedPromotion}/>
          : null}
        <PlanPayment
          inProgress={inProgress}
          countries={countries}
          categories={categories}
          providers={providers}
          country={country}
          category={category}
          provider={provider}
          filteredProviders={filteredProviders}
          currentPage={currentPage}
          totalPages={totalPages}
          onChangeCountry={onChangeCountry}
          onChangeCategory={onChangeCategory}
          onChangeProvider={onChangeProvider}
          onPaginate={onPaginate}
          onSelectProvider={onSelectProvider}
          showServices={showServices}
          onCloseServices={onCloseServices}
          serviceCategory={serviceCategory}
          onChangeServiceCategory={onChangeServiceCategory}
          serviceCategories={serviceCategories}
          plans={plans}
          loadingServices={loadingServices}
          errors={errors}
          selectedProvider={selectedProvider}
          onSelectService={onSelectService}
          selectedService={selectedService}
          onDeselectService={onDeselectService}
          paymentMethod={paymentMethod}
          onChangePaymentMethod={onChangePaymentMethod}
          onSubmitPay={onSubmitPay}
          currentUser={currentUser}
          makingPayment={makingPayment}
          errorsPayment={errorsPayment}
          errorsServices={errorsServices}
          paymentStatus={paymentStatus}
          providerMask={providerMask}
          onToggleShowAddPhone={onToggleShowAddPhone}
          showAddPhone={showAddPhone}
          onSubmitAddPhone={onSubmitAddPhone}
          phones={phones}
          onSubmitDeletePhone={onSubmitDeletePhone}
          totalPrice={totalPrice}
          totalFee={totalFee}
          totalToPay={totalToPay}
          onResetPayment={onResetPayment}
          home={false}
        />
        <Services/>
        <Buy/>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);