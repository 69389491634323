import React from 'react';
import {Button, Col, Form, Modal, Row, Spinner} from "react-bootstrap";
import {Formik} from "formik";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as Yup from "yup";

const {roles} = require('../../utils');

const AddUser = (props) => {
  const {
    submitAddUser, inProgress, context, ...rest
  } = props;
  const formAddUserId = 'formAddUserId';
  return (
    <Modal
      {...rest}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-app"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="app-header">
          {`Add ${context === "resellers" ? 'Reseller' : 'User'}`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <Formik
          initialValues={{
            email: '',
            userName: '',
            firstName: '',
            lastName: '',
            phoneNumber: '',
            password: '',
            passwordConfirm: '',
            resellerPromoCode: '',
            isReseller: context === "resellers",
            rolesToAssign: ['User']
          }}
          onSubmit={(values) => submitAddUser(
            values.email,
            values.userName,
            values.firstName,
            values.lastName,
            values.phoneNumber,
            values.password,
            values.resellerPromoCode,
            values.isReseller,
            values.rolesToAssign
          )}
          validationSchema={Yup.object().shape({
            email: Yup.string().required('Required').email('Invalid email'),
            userName: Yup.string().required('Required'),
            firstName: Yup.string().required('Required'),
            lastName: Yup.string().required('Required'),
            phoneNumber: Yup.string().required('Required'),
            password: Yup.string().required('Required').min(6, 'At least 6 characters').matches(/\d/, 'Must contain at least 1 number').matches(/[*@!#%$&()^~{}+-/]+/, 'Must contain at least 1 special character'),
            passwordConfirm: Yup.string().oneOf([Yup.ref('password'), null], "Passwords must match").required('Required'),
          })}
        >
          {props => {
            const {
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue
            } = props;
            return (
              <Form id={formAddUserId} onSubmit={handleSubmit}>
                <Row className="text-left">
                  <Col md={6} sm={12}>
                    <Form.Group>
                      <Form.Label
                        className="font-weight-bold">First Name
                      </Form.Label>
                      <Form.Control
                        className="form-input border-radius-4"
                        type="text"
                        placeholder="First Name"
                        id="firstName"
                        value={values.firstName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.firstName && touched.firstName && (
                        <div className="error-message">{errors.firstName}</div>
                      )}
                    </Form.Group>
                    <Form.Group>
                      <Form.Label
                        className="font-weight-bold">Last Name
                      </Form.Label>
                      <Form.Control
                        className="form-input border-radius-4"
                        type="text"
                        placeholder="Last Name"
                        id="lastName"
                        value={values.lastName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.lastName && touched.lastName && (
                        <div className="error-message">{errors.lastName}</div>
                      )}
                    </Form.Group>
                    <Form.Group>
                      <Form.Label
                        className="font-weight-bold">User Name
                      </Form.Label>
                      <Form.Control
                        className="form-input border-radius-4"
                        type="text"
                        placeholder="User Name"
                        id="userName"
                        value={values.userName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.userName && touched.userName && (
                        <div className="error-message">{errors.userName}</div>
                      )}
                    </Form.Group>
                    <Form.Group>
                      <Form.Label
                        className="font-weight-bold">Email
                      </Form.Label>
                      <Form.Control
                        className="form-input border-radius-4"
                        type="text"
                        placeholder="Email"
                        id="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.email && touched.email && (
                        <div className="error-message">{errors.email}</div>
                      )}
                    </Form.Group>
                    <Form.Group>
                      <Form.Label
                        className="font-weight-bold">Phone Number
                      </Form.Label>
                      <Form.Control
                        className="form-input border-radius-4"
                        type="text"
                        placeholder="Phone Number"
                        id="phoneNumber"
                        value={values.phoneNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.phoneNumber && touched.phoneNumber && (
                        <div className="error-message">{errors.phoneNumber}</div>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={6} sm={12}>
                    <Form.Group>
                      <Form.Label
                        className="font-weight-bold">Password
                      </Form.Label>
                      <Form.Control
                        className="form-input border-radius-4"
                        type="password"
                        placeholder="Password"
                        id="password"
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.password && touched.password && (
                        <div className="error-message">{errors.password}</div>
                      )}
                    </Form.Group>
                    <Form.Group>
                      <Form.Label
                        className="font-weight-bold">Confirm Password
                      </Form.Label>
                      <Form.Control
                        className="form-input border-radius-4"
                        type="password"
                        placeholder="Confirm Password"
                        id="passwordConfirm"
                        value={values.passwordConfirm}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.passwordConfirm && touched.passwordConfirm && (
                        <div className="error-message">{errors.passwordConfirm}</div>
                      )}
                    </Form.Group>
                    <Form.Group>
                      <Form.Label
                        className="font-weight-bold">Reseller Promo Code
                      </Form.Label>
                      <Form.Control
                        className="form-input border-radius-4"
                        type="text"
                        placeholder="Promo Code"
                        id="resellerPromoCode"
                        value={values.resellerPromoCode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.resellerPromoCode && touched.resellerPromoCode && (
                        <div className="error-message">{errors.resellerPromoCode}</div>
                      )}
                    </Form.Group>
                    <Form.Label className="font-weight-bold">Is Reseller?</Form.Label>
                    <Form.Group className="mb-4">
                      <Form.Control
                        as="select"
                        id="isReseller"
                        className="form-input border-radius-4"
                        value={values.isReseller}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option key={0} value="false">No</option>
                        <option key={1} value="true">Yes</option>
                      </Form.Control>
                      {errors.isReseller && touched.isReseller && (
                        <div className="error-message">{errors.isReseller}</div>
                      )}
                    </Form.Group>
                    <Form.Group>
                      <Form.Label
                        className="font-weight-bold">Roles
                      </Form.Label>
                      <div className="w-100">
                        {
                          roles.map((item, i) => (
                            <Form.Check
                              key={i}
                              inline label={item}
                              type="checkbox"
                              id={`role${item}`}
                              checked={values.rolesToAssign ? values.rolesToAssign.includes(item) : false}
                              onChange={e => {
                                if (e.target.checked) {
                                  const rolesToAssign = values.rolesToAssign;
                                  rolesToAssign.push(item);
                                  setFieldValue('rolesToAssign', rolesToAssign);
                                  if (item === 'Merchant')
                                    setFieldValue('isReseller', 'true');
                                } else {
                                  const rolesToAssign = values.rolesToAssign;
                                  const idx = rolesToAssign.indexOf(item);
                                  setFieldValue('rolesToAssign', rolesToAssign.filter((item, i) => i !== idx));
                                }
                              }}
                              className="mb-2"/>
                          ))
                        }
                      </div>
                      {errors.rolesToAssign && (
                        <div className="error-message">{errors.rolesToAssign}</div>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn btn-form-app btn-option border-radius-4" type="submit" form={formAddUserId}
                disabled={inProgress === true}>
          {
            inProgress ?
              <Spinner
                animation="grow"
                variant="secondary"
                style={{marginRight: '5px', marginBottom: '4px'}}
                size="sm"
              />
              : ''
          }
          Accept
          <FontAwesomeIcon icon="arrow-right" className="icon-right"/>
        </Button>
        <Button onClick={props.onHide}
                className="btn btn-outline-dark btn-form-app border-radius-4">Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddUser;