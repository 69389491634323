import React, {Component} from 'react';
import {Col, Row, Form, Button} from "react-bootstrap";
import ReactTable from "react-table";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Formik} from "formik";
import TableInfo from '../TableInfo';

class ResellerInfo extends Component {
  componentWillMount() {
    this.props.loadResellerInfo();
    this.props.loadCommissions();
    this.props.loadDingProducts();
  }

  render() {
    const {
      referralCode, resellerInfo, filteredCommissions, onUpdateFilterResellerInfo, submitSearchResellerInfo
    } = this.props;
    return (
      <Row className="reseller-info-super-container">
        <Col sm={12}>
          <div className="pl-5-desktop">
            <h4 className="mb-4">Reseller Info</h4>
            <Row sm={12}>
              <Col>
                <Form.Label className="font-weight-bold">Your referral code:</Form.Label>&nbsp;
                <Form.Label>{referralCode && referralCode !== null ? referralCode : ''}</Form.Label>
              </Col>
            </Row>
            <Row sm={12} style={{marginBottom: '1.5rem'}}>
              <Col>
                <Form.Label className="font-weight-bold">Last month commission:</Form.Label>&nbsp;
                <Form.Label>{resellerInfo && resellerInfo !== null ? resellerInfo : '0.00'}</Form.Label>
              </Col>
            </Row>
            <Formik
              initialValues={{productName: ''}}
              onSubmit={() => submitSearchResellerInfo()}
            >
              {props => {
                const {
                  touched,
                  errors,
                  handleSubmit,
                  values,
                  handleChange,
                  handleBlur
                } = props;
                return (
                  <Form className="mt-4" onSubmit={handleSubmit}>
                    <div className="px-4 py-4 mb-4" style={{border: '1px solid rgba(0,0,0,.1)'}}>
                      <Row className="text-left">
                        <Col sm={12} md={6}>
                          <Form.Group className="mb-4">
                            <Form.Label className="font-weight-bold">Product</Form.Label>
                            <Form.Control
                              className="form-input border-radius-4"
                              type="text"
                              id="productName"
                              value={values.productName}
                              onChange={(ev) => {
                                handleChange(ev);
                                onUpdateFilterResellerInfo(ev.target.value);
                              }}
                              onBlur={handleBlur}
                            />
                            {errors.productName && touched.productName && (
                              <div className="error-message">{errors.productName}</div>
                            )}
                          </Form.Group>
                        </Col>
                        <Col sm={12} md={6}>
                          <Form.Group className="mb-3">
                            <Button
                              className="btn btn-black btn-inline border-radius-4 search-button-transactions"
                              type="submit"
                            >
                              <FontAwesomeIcon icon="search" size="sm" className="o-btn-icon"/>
                              Search
                            </Button>
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                    <ReactTable
                      columns={[
                        {
                          Header: "Product Name",
                          accessor: "productName",
                        },
                        {
                          Header: "Min Value",
                          id: "minValue",
                          accessor: d => parseFloat(d.minValue).toFixed(2),
                        },
                        {
                          Header: "Max Value",
                          id: "maxValue",
                          accessor: d => parseFloat(d.maxValue).toFixed(2),
                        },
                        {
                          Header: "Commission (%)",
                          id: "commission",
                          accessor: d => parseFloat(d.commission).toFixed(2),
                        }
                      ]}
                      defaultSorted={[
                        {
                          id: "productName",
                          desc: false
                        }
                      ]}
                      data={filteredCommissions}
                      className="-striped -highlight"
                      defaultPageSize={10}
                      pageSizeOptions={[5, 10, 20]}
                      minRows={0}
                      NoDataComponent={() => null}
                    >
                      {(state, makeTable) => {
                        return (
                          <div>
                            {makeTable()}
                            <TableInfo
                              totalRows={state.sortedData.length}
                              page={state.page}
                              pageSize={state.pageSize}
                            />
                          </div>
                        )
                      }}
                    </ReactTable>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </Col>
      </Row>
    );
  }
}

export default ResellerInfo;