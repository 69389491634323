import {
  ASYNC_START,
  ADMIN_PAGE_UNLOADED,
  RESET_ACTION,
  ADMIN_CHECK_PIN_NUMBER,
  ADMIN_LOAD_TAB_USERS,
  ADMIN_LOAD_DING_PRODUCT_RATE,
  ADMIN_LOAD_USERS,
  UPDATE_FIELD_ADMIN,
  ADMIN_ADD_USER,
  ADMIN_DELETE_USER,
  ADMIN_EDIT_USER,
  ADMIN_CHANGE_USER_STATE,
  ADMIN_RESET_DEBT_USER,
  ADMIN_SHOW_DELETE_USER,
  ADMIN_SHOW_CHANGE_USER_STATE,
  ADMIN_SHOW_EDIT_USER,
  ADMIN_SEARCH_RESELLER_CONFIGURATION,
  ADMIN_UPDATE_COMMISSION_CONFIGURATION,
  ADMIN_SET_COMMISSION_CONFIGURATION,
  ADMIN_SHOW_USER_RESET_DEBT,
  ADMIN_SET_PIN_NUMBER,
  ADMIN_LOAD_TAB_COUNTRIES,
  ADMIN_SHOW_DELETE_COUNTRY,
  ADMIN_SHOW_EDIT_COUNTRY,
  ADMIN_SEARCH_COUNTRY,
  ADMIN_ADD_COUNTRY,
  ADMIN_DELETE_COUNTRY,
  ADMIN_UPDATE_COUNTRY,
  ADMIN_LOAD_TAB_TRANSACTIONS,
  ADMIN_LOAD_TRANSACTIONS,
  ADMIN_SHOW_TRANSACTION_DETAIL,
  ADMIN_LOAD_TAB_TRANSFERENCES,
  ADMIN_LOAD_TRANSFERENCES,
  ADMIN_LOAD_TAB_PAYMENT_NOTIFICATIONS,
  ADMIN_LOAD_PAYMENT_NOTIFICATIONS,
  ADMIN_SHOW_PAYMENT_NOTIFICATION_DETAIL,
  ADMIN_LOAD_TAB_LOT_PAYMENTS,
  ADMIN_LOAD_LOT_PAYMENTS,
  ADMIN_SHOW_LOT_PAYMENT_DETAIL,
  ADMIN_LOAD_TAB_SUMMARY,
  ADMIN_LOAD_TRANSACTIONS_SUMMARY,
  ADMIN_LOAD_SIM_ACTIVATIONS_SUMMARY,
  ADMIN_SHOW_SIM_ACTIVATION_SUMMARY_DETAIL,
  ADMIN_LOAD_TAB_NAUTA_RECHARGES,
  ADMIN_LOAD_NAUTA_RECHARGES,
  ADMIN_SHOW_NAUTA_RECHARGE_DETAIL,
  ADMIN_LOAD_TAB_RESELLERS,
  ADMIN_SEARCH_RESELLER,
  ADMIN_LOAD_TAB_RESELLER_COMMISSION,
  ADMIN_SEARCH_RESELLER_COMMISSION,
  ADMIN_UPDATE_COMMISSION,
  ADMIN_UPDATE_MASTER_COMMISSION,
  ADMIN_SET_COMMISSION,
  ADMIN_LOAD_PLANS,
  ADMIN_ADD_RESELLER_COMMISSION,
  ADMIN_SHOW_DELETE_RESELLER_COMMISSION,
  ADMIN_DELETE_RESELLER_COMMISSION,
  ADMIN_LOAD_TAB_PROVIDERS,
  ADMIN_SEARCH_PROVIDER,
  ADMIN_ADD_PROVIDER,
  ADMIN_SHOW_EDIT_PROVIDER,
  ADMIN_FILL_PROVIDERS,
  ADMIN_SHOW_DELETE_PROVIDER,
  ADMIN_DELETE_PROVIDER,
  ADMIN_UPDATE_PROVIDER,
  ADMIN_LOAD_TAB_PAYMENT_METHODS,
  ADMIN_SEARCH_PAYMENT_METHOD,
  ADMIN_ADD_PAYMENT_METHOD,
  ADMIN_SHOW_EDIT_PAYMENT_METHOD,
  ADMIN_SHOW_DELETE_PAYMENT_METHOD,
  ADMIN_DELETE_PAYMENT_METHOD,
  ADMIN_UPDATE_PAYMENT_METHOD,
  ADMIN_LOAD_TAB_OPERATOR_CATEGORIES,
  ADMIN_SEARCH_OPERATOR_CATEGORY,
  ADMIN_ADD_OPERATOR_CATEGORY,
  ADMIN_SHOW_EDIT_OPERATOR_CATEGORY,
  ADMIN_SHOW_DELETE_OPERATOR_CATEGORY,
  ADMIN_DELETE_OPERATOR_CATEGORY,
  ADMIN_UPDATE_OPERATOR_CATEGORY,
  ADMIN_LOAD_TAB_PAYMENT_APIS,
  ADMIN_SEARCH_PAYMENT_API,
  ADMIN_ADD_PAYMENT_API,
  ADMIN_SHOW_EDIT_PAYMENT_API,
  ADMIN_SHOW_DELETE_PAYMENT_API,
  ADMIN_DELETE_PAYMENT_API,
  ADMIN_UPDATE_PAYMENT_API,
} from '../constants/actionTypes';

export default (state = {}, action) => {
  switch (action.type) {
    case ADMIN_PAGE_UNLOADED:
      return {};

    case ADMIN_CHECK_PIN_NUMBER:
      return {
        ...state,
        inProgress: false,
        errors: action.error ? action.payload.message : null,
      };

    case UPDATE_FIELD_ADMIN:
      return {...state, [action.key]: action.value};

    case ADMIN_LOAD_TAB_USERS:
      return {
        ...state,
        currentTab: 'users',
        currentEditUserTab: 'data',
        showAddUser: false,
        showEditUser: false,
        showChangeUserState: false,
        showDeleteUser: false,
        showResetDebtUser: false,
        selectedUser: null,
        usersState: {
          page: 0,
          pageSize: 20,
          pages: 0,
          data: [],
          filter: null,
          total: 0
        }
      };

    case ADMIN_LOAD_USERS:
      if (action.error) {
        return {
          ...state,
          errors: action.payload.message,
          inProgress: false,
          usersState: {
            page: 0,
            pageSize: 20,
            pages: 0,
            data: [],
            filter: null,
            total: 0
          },
        };
      } else {
        const pagination = JSON.parse(action.payload.header['x-pagination']);
        return {
          ...state,
          errors: action.error ? action.payload.message : null,
          inProgress: false,
          usersState: {
            page: pagination.currentPage - 1,
            pageSize: pagination.pageSize,
            pages: pagination.totalPages,
            data: action.payload.body,
            filter: action.usersState.filter,
            total: pagination.totalCount,
            sorted: action.usersState.sorted,
          },
        };
      }

    case ADMIN_ADD_USER:
      return {
        ...state,
        inProgress: false,
        errors: action.error ? action.payload.message : null,
        showAddUser: action.error,
        lastAction: action.error ? null : ADMIN_ADD_USER
      };

    case ADMIN_DELETE_USER:
      return {
        ...state,
        inProgress: false,
        errors: action.error ? action.payload.message : null,
        showDeleteUser: action.error,
        lastAction: action.error ? null : ADMIN_DELETE_USER
      };

    case ADMIN_SET_PIN_NUMBER:
      return {
        ...state,
        inProgress: false,
        errors: action.error ? action.payload.message : null,
        showEditUser: action.error,
        lastAction: action.error ? null : ADMIN_SET_PIN_NUMBER
      };

    case ADMIN_EDIT_USER:
      return {
        ...state,
        inProgress: false,
        errors: action.error ? action.payload.message : null,
        showEditUser: action.error,
        lastAction: action.error ? null : ADMIN_EDIT_USER
      };
    case ADMIN_CHANGE_USER_STATE:
      return {
        ...state,
        inProgress: false,
        errors: action.error ? action.payload.message : null,
        showChangeUserState: action.error,
        lastAction: action.error ? null : ADMIN_CHANGE_USER_STATE
      };
    case ADMIN_RESET_DEBT_USER:
      const selectedUser = state.selectedUser;
      if (!action.error)
        selectedUser.debt = 0;
      return {
        ...state,
        inProgress: false,
        errors: action.error ? action.payload.message : null,
        showResetDebtUser: false,
        selectedUser: action.error ? state.selectedUser : selectedUser,
        lastAction: action.error ? null : ADMIN_RESET_DEBT_USER
      };
    case ADMIN_SHOW_CHANGE_USER_STATE:
      return {
        ...state,
        showChangeUserState: action.show,
        selectedUser: action.value,
        currentEditUserTab: 'data',
      };
    case ADMIN_SHOW_EDIT_USER:
      return {
        ...state,
        showEditUser: action.show,
        selectedUser: action.value,
        currentEditUserTab: 'data',
        isSelectedUserAdmin: action.value && action.value.roles ? action.value.roles.includes('Admin') : false,
        isSelectedUserReseller: !!(action.value && action.value.isreseller),
        resellerConfiguration: [],
        filteredResellerConfiguration: [],
        resellerConfigurationFilterPlan: ''
      };

    case ADMIN_LOAD_DING_PRODUCT_RATE:
      const resellerCommission = Array.isArray(action.payload[0].body) && action.payload[0].body.length ? action.payload[0].body : [];
      const dingProductRate = Array.isArray(action.payload[1].body) && action.payload[0].body.length ? action.payload[1].body : [];
      const resellerConfiguration = resellerCommission.map(item => {
        item.discount = 0;
        const userRate = dingProductRate.find(elem => elem.dingProductId === item.dingProductId);
        if (typeof userRate !== 'undefined') {
          item.discount = userRate.commission;
        }
        return item;
      });
      return {
        ...state,
        resellerCommission,
        dingProductRate,
        resellerConfiguration,
        filteredResellerConfiguration: resellerConfiguration,
      };

    case ADMIN_SEARCH_RESELLER_CONFIGURATION:
      return {
        ...state,
        filteredResellerConfiguration: state.resellerConfiguration && state.resellerConfiguration.length > 0 ? state.resellerConfiguration.filter(item => item.dingProductName.toLowerCase().includes(state.resellerConfigurationFilterPlan.toLowerCase())) : []
      };

    case ADMIN_UPDATE_COMMISSION_CONFIGURATION:
      return {
        ...state,
        resellerConfiguration: state.resellerConfiguration.map(item => {
          if (item.id === action.id)
            item.discount = action.value;
          return item;
        })
      };

    case ADMIN_SET_COMMISSION_CONFIGURATION:
      return {
        ...state,
        inProgress: false,
        errors: action.error ? action.payload ? action.payload.message : 'Something were wrong, please contact de Administrator' : null,
        showEditUser: action.error,
        lastAction: action.error ? null : ADMIN_SET_COMMISSION_CONFIGURATION
      };

    case ADMIN_SHOW_DELETE_USER:
      return {
        ...state,
        showDeleteUser: action.show,
        selectedUser: action.value
      };

    case ADMIN_SHOW_USER_RESET_DEBT:
      return {
        ...state,
        showResetDebtUser: action.show,
      };

    case ADMIN_LOAD_TAB_COUNTRIES:
      return {
        ...state,
        showAddCountry: false,
        showEditCountry: false,
        showDeleteCountry: false,
        selectedCountry: null,
        errors: action.error ? action.payload.message : null,
        countries: !action.error ? action.payload.body : [],
        filteredCountries: !action.error ? action.payload.body : [],
        countriesFilterCountry: '',
        regions: [
          "Africa",
          "Asia",
          "Central America",
          "Europe",
          "Antarctica",
          "European Union",
          "Middle East",
          "North America",
          "Oceania",
          "South America",
          "The Caribbean",
        ],
      };

    case ADMIN_SEARCH_COUNTRY:
      return {
        ...state,
        filteredCountries: state.countries && state.countries.length > 0 ? state.countries.filter(item => item.longCountryName.toLowerCase().includes(state.countriesFilterCountry.toLowerCase())) : []
      };

    case ADMIN_ADD_COUNTRY:
      return {
        ...state,
        inProgress: false,
        errors: action.error ? action.payload.message : null,
        showAddCountry: action.error,
        lastAction: action.error ? null : ADMIN_ADD_COUNTRY
      };

    case ADMIN_UPDATE_COUNTRY:
      return {
        ...state,
        inProgress: false,
        errors: action.error ? action.payload.message : null,
        showEditCountry: action.error,
        lastAction: action.error ? null : ADMIN_UPDATE_COUNTRY
      };

    case ADMIN_DELETE_COUNTRY:
      return {
        ...state,
        inProgress: false,
        errors: action.error ? action.payload.message : null,
        showDeleteCountry: action.error,
        lastAction: action.error ? null : ADMIN_DELETE_COUNTRY
      };

    case ADMIN_SHOW_DELETE_COUNTRY:
      return {
        ...state,
        showDeleteCountry: action.show,
        selectedCountry: action.value
      };

    case ADMIN_SHOW_EDIT_COUNTRY:
      return {
        ...state,
        showEditCountry: action.show,
        selectedCountry: action.value
      };

    case ADMIN_LOAD_TAB_TRANSACTIONS:
      return {
        ...state,
        showTransactionDetail: false,
        selectedTransaction: null,
        transactionsState: {
          page: 0,
          pageSize: 20,
          pages: 0,
          data: [],
          filter: null,
          total: 0
        }
      };

    case ADMIN_LOAD_TRANSACTIONS:
      if (action.error) {
        return {
          ...state,
          errors: action.payload.message,
          inProgress: false,
          transactionsState: {
            page: 0,
            pageSize: 20,
            pages: 0,
            data: [],
            filter: null,
            total: 0
          },
        };
      } else {
        const pagination = JSON.parse(action.payload.header['x-pagination']);
        return {
          ...state,
          errors: action.error ? action.payload.message : null,
          inProgress: false,
          transactionsState: {
            page: pagination.currentPage - 1,
            pageSize: pagination.pageSize,
            pages: pagination.totalPages,
            data: action.payload.body,
            filter: action.transactionsState.filter,
            total: pagination.totalCount
          },
        };
      }

    case ADMIN_SHOW_TRANSACTION_DETAIL:
      return {
        ...state,
        showTransactionDetail: action.show,
        selectedTransaction: action.value
      };

    case ADMIN_LOAD_TAB_TRANSFERENCES:
      return {
        ...state,
        transferencesState: {
          page: 0,
          pageSize: 20,
          pages: 0,
          data: [],
          filter: null,
          total: 0
        }
      };

    case ADMIN_LOAD_TRANSFERENCES:
      if (action.error) {
        return {
          ...state,
          errors: action.payload.message,
          inProgress: false,
          transferencesState: {
            page: 0,
            pageSize: 20,
            pages: 0,
            data: [],
            filter: null,
            total: 0
          },
        };
      } else {
        const pagination = JSON.parse(action.payload.header['x-pagination']);
        return {
          ...state,
          errors: action.error ? action.payload.message : null,
          inProgress: false,
          transferencesState: {
            page: pagination.currentPage - 1,
            pageSize: pagination.pageSize,
            pages: pagination.totalPages,
            data: action.payload.body,
            filter: action.transferencesState.filter,
            total: pagination.totalCount
          },
        };
      }

    case ADMIN_LOAD_TAB_PAYMENT_NOTIFICATIONS:
      return {
        ...state,
        showTransactionDetail: false,
        selectedTransaction: null,
        paymentNotificationsState: {
          page: 0,
          pageSize: 20,
          pages: 0,
          data: [],
          filter: null,
          total: 0
        }
      };

    case ADMIN_LOAD_PAYMENT_NOTIFICATIONS:
      if (action.error) {
        return {
          ...state,
          errors: action.payload.message,
          inProgress: false,
          paymentNotificationsState: {
            page: 0,
            pageSize: 20,
            pages: 0,
            data: [],
            filter: null,
            total: 0
          },
        };
      } else {
        const pagination = JSON.parse(action.payload.header['x-pagination']);
        return {
          ...state,
          errors: action.error ? action.payload.message : null,
          inProgress: false,
          paymentNotificationsState: {
            page: pagination.currentPage - 1,
            pageSize: pagination.pageSize,
            pages: pagination.totalPages,
            data: action.payload.body,
            filter: action.paymentNotificationsState.filter,
            total: pagination.totalCount
          },
        };
      }

    case ADMIN_SHOW_PAYMENT_NOTIFICATION_DETAIL:
      return {
        ...state,
        showPaymentNotificationDetail: action.show,
        selectedPaymentNotification: action.value
      };

    case ADMIN_LOAD_TAB_LOT_PAYMENTS:
      return {
        ...state,
        showLotPaymentDetail: false,
        selectedLotPayment: null,
        lotPaymentsState: {
          page: 0,
          pageSize: 20,
          pages: 0,
          data: [],
          filter: null,
          total: 0
        }
      };

    case ADMIN_LOAD_LOT_PAYMENTS:
      if (action.error) {
        return {
          ...state,
          errors: action.payload.message,
          inProgress: false,
          lotPaymentsState: {
            page: 0,
            pageSize: 20,
            pages: 0,
            data: [],
            filter: null,
            total: 0
          },
        };
      } else {
        const pagination = JSON.parse(action.payload.header['x-pagination']);
        return {
          ...state,
          errors: action.error ? action.payload.message : null,
          inProgress: false,
          lotPaymentsState: {
            page: pagination.currentPage - 1,
            pageSize: pagination.pageSize,
            pages: pagination.totalPages,
            data: action.payload.body,
            filter: action.lotPaymentsState.filter,
            total: pagination.totalCount
          },
        };
      }

    case ADMIN_SHOW_LOT_PAYMENT_DETAIL:
      return {
        ...state,
        showLotPaymentDetail: action.show,
        selectedLotPayment: action.value
      };

    case ADMIN_LOAD_TAB_SUMMARY:
      return {
        ...state,
        inProgressSecondary: false,
        transactionsSummaryTotalColumns: null,
        showSimActivationSummaryDetail: false,
        transactionsSummaryState: {
          page: 0,
          pageSize: 20,
          pages: 0,
          data: [],
          filter: null,
          total: 0
        },
        simActivationsSummaryState: {
          page: 0,
          pageSize: 20,
          pages: 0,
          data: [],
          filter: null,
          total: 0
        },
      };

    case ADMIN_LOAD_TRANSACTIONS_SUMMARY:
      if (action.error) {
        return {
          ...state,
          errors: action.payload.message,
          inProgress: false,
          transactionsSummaryTotalColumns: null,
          transactionsSummaryState: {
            page: 0,
            pageSize: 20,
            pages: 0,
            data: [],
            filter: null,
            total: 0
          },
        };
      } else {
        const pagination = JSON.parse(action.payload.header['x-pagination']);
        return {
          ...state,
          errors: action.error ? action.payload.message : null,
          inProgress: false,
          transactionsSummaryTotalColumns: action.payload.body.totalColumns,
          transactionsSummaryState: {
            page: pagination.currentPage - 1,
            pageSize: pagination.pageSize,
            pages: pagination.totalPages,
            data: action.payload.body.userList,
            filter: action.transactionsSummaryState.filter,
            total: pagination.totalCount
          },
        };
      }

    case ADMIN_LOAD_SIM_ACTIVATIONS_SUMMARY:
      if (action.error) {
        return {
          ...state,
          errorsSecondary: action.payload.message,
          inProgressSecondary: false,
          showSimActivationSummaryDetail: false,
          simActivationsSummaryState: {
            page: 0,
            pageSize: 20,
            pages: 0,
            data: [],
            filter: null,
            total: 0
          },
        };
      } else {
        const pagination = JSON.parse(action.payload.header['x-pagination']);
        return {
          ...state,
          errorsSecondary: action.error ? action.payload.message : null,
          inProgressSecondary: false,
          simActivationsSummaryState: {
            page: pagination.currentPage - 1,
            pageSize: pagination.pageSize,
            pages: pagination.totalPages,
            data: action.payload.body.simActivationList,
            filter: action.simActivationsSummaryState.filter,
            total: pagination.totalCount
          },
        };
      }

    case ADMIN_SHOW_SIM_ACTIVATION_SUMMARY_DETAIL:
      return {
        ...state,
        showSimActivationSummaryDetail: action.show,
        selectedSimActivationSummary: action.value
      };

    case RESET_ACTION:
      return {
        ...state,
        errors: null,
        lastAction: null
      };

    case ADMIN_LOAD_TAB_NAUTA_RECHARGES:
      return {
        ...state,
        showNautaRechargeDetail: false,
        selectedNautaRecharge: null,
        nautaRechargesState: {
          page: 0,
          pageSize: 20,
          pages: 0,
          data: [],
          filter: null,
          total: 0
        }
      };

    case ADMIN_LOAD_NAUTA_RECHARGES:
      if (action.error) {
        return {
          ...state,
          errors: action.payload.message,
          inProgress: false,
          nautaRechargesState: {
            page: 0,
            pageSize: 20,
            pages: 0,
            data: [],
            filter: null,
            total: 0
          },
        };
      } else {
        const pagination = JSON.parse(action.payload.header['x-pagination']);
        return {
          ...state,
          errors: action.error ? action.payload.message : null,
          inProgress: false,
          nautaRechargesState: {
            page: pagination.currentPage - 1,
            pageSize: pagination.pageSize,
            pages: pagination.totalPages,
            data: action.payload.body,
            filter: action.nautaRechargesState.filter,
            total: pagination.totalCount
          },
        };
      }

    case ADMIN_SHOW_NAUTA_RECHARGE_DETAIL:
      return {
        ...state,
        showNautaRechargeDetail: action.show,
        selectedNautaRecharge: action.value
      };

    case ADMIN_LOAD_TAB_RESELLERS:
      let resellers = !action.error ? action.payload.body : [];
      for (let i = 0; i < resellers.length; i++) {
        resellers[i].superUserName = null;
        for (let j = 0; j < resellers.length; j++) {
          if (resellers[i].superuserid === resellers[j].id) {
            resellers[i].superUserName = resellers[j].username;
          }
        }
      }
      return {
        ...state,
        errors: action.error ? action.payload.message : null,
        resellers,
        filteredResellers: resellers,
        resellersFilterReseller: '',
      };

    case ADMIN_SEARCH_RESELLER:
      return {
        ...state,
        filteredResellers: state.resellers && state.resellers.length > 0 ? state.resellers.filter(item => item.username.toLowerCase().includes(state.resellersFilterReseller.toLowerCase())) : []
      };

    case ADMIN_LOAD_TAB_RESELLER_COMMISSION:
      return {
        ...state,
        showAddResellerCommission: false,
        showDeleteResellerCommission: false,
        selectedResellerCommission: null,
        selectedResellerCommissionId: null,
        errors: action.error ? action.payload.message : null,
        resellerCommission: !action.error ? action.payload.body : [],
        filteredResellerCommission: !action.error ? action.payload.body : [],
        resellerCommissionFilterPlan: '',
        inProgressSave: false,
      };

    case ADMIN_SEARCH_RESELLER_COMMISSION:
      return {
        ...state,
        filteredResellerCommission: state.resellerCommission && state.resellerCommission.length > 0 ? state.resellerCommission.filter(item => item.dingProductName.toLowerCase().includes(state.resellerCommissionFilterPlan.toLowerCase())) : []
      };

    case ADMIN_UPDATE_COMMISSION:
      return {
        ...state,
        resellerCommission: state.resellerCommission.map(item => {
          if (item.id === action.id)
            item.commission = action.value;
          return item;
        })
      };

    case ADMIN_UPDATE_MASTER_COMMISSION:
      return {
        ...state,
        resellerCommission: state.resellerCommission.map(item => {
          if (item.id === action.id)
            item.masterCommission = action.value;
          return item;
        })
      };

    case ADMIN_SET_COMMISSION:
      return {
        ...state,
        inProgressSave: false,
        errors: action.error ? action.payload.message : null,
        selectedResellerCommissionId: null,
        lastAction: action.error ? null : ADMIN_SET_COMMISSION
      };

    case ADMIN_LOAD_PLANS:
      return {
        ...state,
        plans: action.error ? [] : action.payload.body
      };

    case ADMIN_ADD_RESELLER_COMMISSION:
      return {
        ...state,
        inProgress: false,
        errors: action.error ? action.payload.message : null,
        showAddResellerCommission: action.error,
        lastAction: action.error ? null : ADMIN_ADD_RESELLER_COMMISSION
      };

    case ADMIN_SHOW_DELETE_RESELLER_COMMISSION:
      return {
        ...state,
        showDeleteResellerCommission: action.show,
        selectedResellerCommission: action.value
      };

    case ADMIN_DELETE_RESELLER_COMMISSION:
      return {
        ...state,
        inProgress: false,
        errors: action.error ? action.payload.message : null,
        showDeleteResellerCommission: action.error,
        lastAction: action.error ? null : ADMIN_DELETE_RESELLER_COMMISSION
      };

    case ADMIN_LOAD_TAB_PROVIDERS:
      const countries = Array.isArray(action.payload[0].body) && action.payload[0].body.length ? action.payload[0].body : [];
      const categories = Array.isArray(action.payload[1].body) && action.payload[0].body.length ? action.payload[1].body/*.filter(c => c.description !== null)*/ : [];
      const providers = Array.isArray(action.payload[2].body) && action.payload[2].body.length ? action.payload[2].body.map(item => {
        const foundCategory = categories.find(c => c.id === item.operatorCategoryId);
        item.category = foundCategory ? foundCategory.description : null;
        const foundCountry = countries.find(c => c.id === item.countryId);
        item.country = foundCountry ? foundCountry.countryName : null;
        return item;
      }) : [];
      return {
        ...state,
        showAddProvider: false,
        showEditProvider: false,
        showDeleteProvider: false,
        selectedProvider: null,
        errors: action.error ? action.payload.message : null,
        countries,
        providers,
        categories,
        filteredProviders: providers,
        providersFilterProvider: '',
      };

    case ADMIN_SEARCH_PROVIDER:
      return {
        ...state,
        filteredProviders: state.providers && state.providers.length > 0 ? state.providers.filter(item => item.name.toLowerCase().includes(state.providersFilterProvider.toLowerCase())) : []
      };

    case ADMIN_ADD_PROVIDER:
      return {
        ...state,
        inProgress: false,
        errors: action.error ? action.payload.message : null,
        showAddProvider: action.error,
        lastAction: action.error ? null : ADMIN_ADD_PROVIDER
      };

    case ADMIN_SHOW_EDIT_PROVIDER:
      return {
        ...state,
        showEditProvider: action.show,
        selectedProvider: action.value
      };

    case ADMIN_FILL_PROVIDERS:
      return {
        ...state,
        providers: state.providers.map(item => {
          const foundCategory = state.categories.find(c => c.id === item.operatorCategoryId);
          item.category = foundCategory ? foundCategory.description : null;
          const foundCountry = state.countries.find(c => c.id === item.countryId);
          item.country = foundCountry ? foundCountry.countryName : null;
          return item;
        })
      };

    case ADMIN_SHOW_DELETE_PROVIDER:
      return {
        ...state,
        showDeleteProvider: action.show,
        selectedProvider: action.value
      };

    case ADMIN_DELETE_PROVIDER:
      return {
        ...state,
        inProgress: false,
        errors: action.error ? action.payload.message : null,
        showDeleteProvider: action.error,
        lastAction: action.error ? null : ADMIN_DELETE_PROVIDER
      };

    case ADMIN_UPDATE_PROVIDER:
      return {
        ...state,
        inProgress: false,
        errors: action.error ? action.payload.message : null,
        showEditProvider: action.error,
        lastAction: action.error ? null : ADMIN_UPDATE_PROVIDER
      };

    case ADMIN_LOAD_TAB_PAYMENT_METHODS:
      return {
        ...state,
        showAddPaymentMethod: false,
        showEditPaymentMethod: false,
        showDeletePaymentMethod: false,
        selectedPaymentMethod: null,
        errors: action.error ? action.payload.message : null,
        paymentMethods: !action.error ? action.payload.body : [],
        filteredPaymentMethods: !action.error ? action.payload.body : [],
        paymentMethodsFilterMethod: '',
      };

    case ADMIN_SEARCH_PAYMENT_METHOD:
      return {
        ...state,
        filteredPaymentMethods: state.paymentMethods && state.paymentMethods.length > 0 ? state.paymentMethods.filter(item => item.name.toLowerCase().includes(state.paymentMethodsFilterMethod.toLowerCase())) : []
      };

    case ADMIN_ADD_PAYMENT_METHOD:
      return {
        ...state,
        inProgress: false,
        errors: action.error ? action.payload.message : null,
        showAddPaymentMethod: action.error,
        lastAction: action.error ? null : ADMIN_ADD_PAYMENT_METHOD
      };

    case ADMIN_SHOW_EDIT_PAYMENT_METHOD:
      return {
        ...state,
        showEditPaymentMethod: action.show,
        selectedPaymentMethod: action.value
      };

    case ADMIN_SHOW_DELETE_PAYMENT_METHOD:
      return {
        ...state,
        showDeletePaymentMethod: action.show,
        selectedPaymentMethod: action.value
      };

    case ADMIN_DELETE_PAYMENT_METHOD:
      return {
        ...state,
        inProgress: false,
        errors: action.error ? action.payload.message : null,
        showDeletePaymentMethod: action.error,
        lastAction: action.error ? null : ADMIN_DELETE_PAYMENT_METHOD
      };

    case ADMIN_UPDATE_PAYMENT_METHOD:
      return {
        ...state,
        inProgress: false,
        errors: action.error ? action.payload.message : null,
        showEditPaymentMethod: action.error,
        lastAction: action.error ? null : ADMIN_UPDATE_PAYMENT_METHOD
      };


    case ADMIN_LOAD_TAB_OPERATOR_CATEGORIES:
      return {
        ...state,
        showAddOperatorCategory: false,
        showEditOperatorCategory: false,
        showDeleteOperatorCategory: false,
        selectedOperatorCategory: null,
        errors: action.error ? action.payload.message : null,
        operatorCategories: !action.error ? action.payload.body : [],
        filteredOperatorCategories: !action.error ? action.payload.body : [],
        operatorCategoriesFilterCategory: '',
      };

    case ADMIN_SEARCH_OPERATOR_CATEGORY:
      return {
        ...state,
        filteredOperatorCategories: state.operatorCategories && state.operatorCategories.length > 0 ? state.operatorCategories.filter(item => item.description.toLowerCase().includes(state.operatorCategoriesFilterCategory.toLowerCase())) : []
      };

    case ADMIN_ADD_OPERATOR_CATEGORY:
      return {
        ...state,
        inProgress: false,
        errors: action.error ? action.payload.message : null,
        showAddOperatorCategory: action.error,
        lastAction: action.error ? null : ADMIN_ADD_OPERATOR_CATEGORY
      };

    case ADMIN_SHOW_EDIT_OPERATOR_CATEGORY:
      return {
        ...state,
        showEditOperatorCategory: action.show,
        selectedOperatorCategory: action.value
      };

    case ADMIN_SHOW_DELETE_OPERATOR_CATEGORY:
      return {
        ...state,
        showDeleteOperatorCategory: action.show,
        selectedOperatorCategory: action.value
      };

    case ADMIN_DELETE_OPERATOR_CATEGORY:
      return {
        ...state,
        inProgress: false,
        errors: action.error ? action.payload.message : null,
        showDeleteOperatorCategory: action.error,
        lastAction: action.error ? null : ADMIN_DELETE_OPERATOR_CATEGORY
      };

    case ADMIN_UPDATE_OPERATOR_CATEGORY:
      return {
        ...state,
        inProgress: false,
        errors: action.error ? action.payload.message : null,
        showEditOperatorCategory: action.error,
        lastAction: action.error ? null : ADMIN_UPDATE_OPERATOR_CATEGORY
      };

    case ADMIN_LOAD_TAB_PAYMENT_APIS:
      return {
        ...state,
        showAddPaymentApi: false,
        showEditPaymentApi: false,
        showDeletePaymentApi: false,
        selectedPaymentApi: null,
        errors: action.error ? action.payload.message : null,
        paymentApis: !action.error ? action.payload.body : [],
        filteredPaymentApis: !action.error ? action.payload.body : [],
        paymentApisFilterName: '',
      };

    case ADMIN_SEARCH_PAYMENT_API:
      return {
        ...state,
        filteredPaymentApis: state.paymentApis && state.paymentApis.length > 0 ? state.paymentApis.filter(item => item.name.toLowerCase().includes(state.paymentApisFilterName.toLowerCase())) : []
      };

    case ADMIN_ADD_PAYMENT_API:
      return {
        ...state,
        inProgress: false,
        errors: action.error ? action.payload.message : null,
        showAddPaymentApi: action.error,
        lastAction: action.error ? null : ADMIN_ADD_PAYMENT_API
      };

    case ADMIN_SHOW_EDIT_PAYMENT_API:
      return {
        ...state,
        showEditPaymentApi: action.show,
        selectedPaymentApi: action.value
      };

    case ADMIN_SHOW_DELETE_PAYMENT_API:
      return {
        ...state,
        showDeletePaymentApi: action.show,
        selectedPaymentApi: action.value
      };

    case ADMIN_DELETE_PAYMENT_API:
      return {
        ...state,
        inProgress: false,
        errors: action.error ? action.payload.message : null,
        showDeletePaymentApi: action.error,
        lastAction: action.error ? null : ADMIN_DELETE_PAYMENT_API
      };

    case ADMIN_UPDATE_PAYMENT_API:
      return {
        ...state,
        inProgress: false,
        errors: action.error ? action.payload.message : null,
        showEditPaymentApi: action.error,
        lastAction: action.error ? null : ADMIN_UPDATE_PAYMENT_API
      };

    case ASYNC_START:
      if (
        action.subtype === ADMIN_LOAD_USERS ||
        action.subtype === ADMIN_ADD_USER ||
        action.subtype === ADMIN_RESET_DEBT_USER ||
        action.subtype === ADMIN_CHECK_PIN_NUMBER ||
        action.subtype === ADMIN_SET_PIN_NUMBER ||
        action.subtype === ADMIN_ADD_COUNTRY ||
        action.subtype === ADMIN_DELETE_COUNTRY ||
        action.subtype === ADMIN_LOAD_TRANSACTIONS ||
        action.subtype === ADMIN_LOAD_TRANSFERENCES ||
        action.subtype === ADMIN_LOAD_PAYMENT_NOTIFICATIONS ||
        action.subtype === ADMIN_LOAD_LOT_PAYMENTS ||
        action.subtype === ADMIN_LOAD_TRANSACTIONS_SUMMARY ||
        action.subtype === ADMIN_LOAD_NAUTA_RECHARGES ||
        action.subtype === ADMIN_SET_COMMISSION_CONFIGURATION
      ) {
        return {...state, inProgress: true, errors: null};
      } else if (
        action.subtype === ADMIN_LOAD_SIM_ACTIVATIONS_SUMMARY
      ) {
        return {...state, inProgressSecondary: true, errorsSecondary: null};
      }
      break;

    default:
      return state;
  }

  return state;
};
