import React from 'react';
import {NavLink} from 'react-router-dom';
import {Image, Nav, Navbar, OverlayTrigger, Tooltip} from "react-bootstrap";

import {LOGOUT} from '../../constants/actionTypes';

import {connect} from "react-redux";

import './NavigationBar.css';

const BottomNavItem = (props) => (
  <div>
    <div className="m-frame">
      <Image src={props.image} className="m-image"/>
    </div>
    <span className="m-caption">{props.text}</span>
  </div>
);

const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch => ({
  onClickLogout: (ev) => {
    ev.preventDefault();
    dispatch({type: LOGOUT})
  },
});

const NavigationBar = (props) => {
  const {currentUser, appMenu} = props;
  return (
    <nav>
      <hr className="mt-0 mb-0 n-divider"/>
      <Navbar>
        <Nav className="mr-auto ml-auto mt-2 mb-2 nav-bar-menu-custom">
          {
            appMenu.map((item, index) => {
              if (item.disabled) {
                return (
                  <OverlayTrigger
                    key={index}
                    placement="bottom"
                    overlay={
                      <Tooltip id={`tooltip-bottom`}>
                        coming soon
                      </Tooltip>
                    }
                  >
                    <NavLink
                      key={index}
                      activeClassName='is-active-link'
                      className="ml-4 mr-4 p-0 mlr-4-mobile-not coming-soon-link"
                      to={item.redirectTo}
                      onClick={ev => ev.preventDefault()}>
                      <BottomNavItem image={item.image} text={item.text}/></NavLink>
                  </OverlayTrigger>
                );
              } else {
                if (item.text === "Home") {
                  return (
                    <NavLink
                      key={index}
                      activeClassName='is-active-link'
                      className="ml-4 mr-4 p-0 mlr-4-mobile-not home-icon-nav-bar-container" to="/home">
                      <BottomNavItem image={item.image} text="Home" className="home-icon-nav-bar"/></NavLink>
                  );
                } else if (item.text === "Admin") {
                  if (currentUser && currentUser.isAdmin)
                    return (
                      <NavLink
                        key={index}
                        activeClassName='is-active-link'
                        className="ml-4 mr-4 p-0 mlr-4-mobile-not"
                        to={item.redirectTo}>
                        <BottomNavItem image={item.image} text={item.text}/></NavLink>
                    );
                } else return (
                  <NavLink
                    key={index}
                    activeClassName='is-active-link'
                    className="ml-4 mr-4 p-0 mlr-4-mobile-not"
                    to={item.redirectTo}>
                    <BottomNavItem image={item.image} text={item.text}/></NavLink>
                );
                return null;
              }
            })
          }
        </Nav>
      </Navbar>
    </nav>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(NavigationBar);