import React, {Component} from 'react';
import {Col, Row, Form, Button, Spinner, Modal} from "react-bootstrap";
import moment from 'moment';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as Yup from "yup";
import {Formik} from "formik";
import user from "../../assets/img/common/user.png";
import ReactTable from "react-table";
import TableInfo from '../TableInfo';

const {userTypes} = require('../../utils');

const Affiliate = props => {
  const {
    affiliate, openSendMessage, openMakeTransference, openUnlinkAffiliate, userType, openAddAffiliate
  } = props;
  const
    userName = affiliate.userName.toUpperCase(),
    fullName = `${affiliate.firstName} ${affiliate.lastName}`,
    joinDate = `Member since ${moment(affiliate.joinDate).format('YYYY-MM-DD')}`,
    email = affiliate.email,
    balance = affiliate.balance.toFixed(2),
    imageUrl = affiliate.imageUrl;
  return (
    <div
      className="affiliate"
      style={{backgroundColor: userType === userTypes.SUPERUSER || userType === userTypes.USER ? '#f9f9f9' : 'inherit'}}>
      <Row className="">
        <Col sm={2} xs={4} md={4} lg={4} xl={4}>
          <img className="affiliate-image" src={imageUrl !== null ? imageUrl : user} alt=""/></Col>
        <Col sm={10} xs={8} md={8} lg={8} xl={8}>
          <Row className="ml-2">
            <Col sm={12} className="font-weight-bold mb-1" style={{fontSize: '1rem'}}>{userName}</Col>
            <Col sm={12} className="affiliate-info">{fullName}</Col>
            <Col sm={12} className="affiliate-info">{joinDate}</Col>
            <Col sm={12} className="affiliate-info">{email}</Col>
            <Col sm={12}>
              {
                userType === userTypes.USER ?
                  <Button
                    variant="link"
                    className="affiliate-btn border-radius-4 button-affiliate-content"
                    onClick={() => openAddAffiliate(affiliate)}>
                    [ ADD AFFILIATE ]</Button> : ''
              }
              {
                userType === userTypes.AFFILIATE || userType === userTypes.SUPERUSER ?
                  <div>
                    <Button
                      variant="link"
                      className="affiliate-btn border-radius-4 button-affiliate-content"
                      onClick={() => openSendMessage(affiliate)}>
                      [ SEND MESSAGE ]</Button>
                    <Button
                      variant="link"
                      className="affiliate-btn border-radius-4 button-affiliate-content"
                      onClick={() => openMakeTransference(affiliate)}>
                      [ MAKE A TRANSFERENCE ]</Button>
                  </div> : ''
              }
              {
                userType === userTypes.AFFILIATE ?
                  <Button
                    variant="link"
                    className="affiliate-btn border-radius-4 button-affiliate-content"
                    onClick={() => openUnlinkAffiliate(affiliate)}>
                    [ UNLINK AFFILIATE ]</Button> : ''
              }
            </Col>
          </Row>
        </Col>
        {userType === userTypes.AFFILIATE ?
          <div className="font-weight-bold text-right affiliate-balance">{balance}</div> : ''}
      </Row>
    </div>
  );
};

const SendMessage = (props) => {
  const {affiliateSelected, submitSendMessage, inProgress, ...rest} = props;
  const formSendMessageId = 'formSendMessageId';
  return (
    <Modal
      {...rest}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-app"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="app-header">
          Send Message
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <Row className="text-left">
          <Col md={12} sm={12}>
            <Form.Group className="mb-4">
              <Form.Control
                className="form-input border-radius-4"
                type="text"
                placeholder="To"
                id="messageTo"
                defaultValue={affiliateSelected ? affiliateSelected.userName : ''}
                disabled={true}
              />
            </Form.Group>
          </Col>
        </Row>
        <Formik
          initialValues={{
            messageSubject: '',
            messageText: ''
          }}
          onSubmit={(values) => submitSendMessage(
            values.messageSubject,
            values.messageText,
          )}
          validationSchema={Yup.object().shape({
            messageText: Yup.string().required('Required')
          })}
        >
          {props => {
            const {
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              handleSubmit
            } = props;
            return (
              <Form id={formSendMessageId} onSubmit={handleSubmit}>
                <Row className="text-left">
                  <Col md={12} sm={12}>
                    <Form.Group className="mb-4">
                      <Form.Control
                        className="form-input border-radius-4"
                        type="text"
                        placeholder="Subject"
                        id="messageSubject"
                        value={values.messageSubject}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.messageSubject && touched.messageSubject && (
                        <div className="error-message">{errors.messageSubject}</div>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={12} sm={12}>
                    <Form.Group>
                      <Form.Control
                        as="textarea"
                        placeholder="Enter the message"
                        id="messageText"
                        rows="3"
                        value={values.messageText}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.messageText && touched.messageText && (
                        <div className="error-message">{errors.messageText}</div>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn btn-form-app btn-option border-radius-4" type="submit" form={formSendMessageId}>
          {
            inProgress ?
              <Spinner
                animation="grow"
                variant="secondary"
                style={{marginRight: '5px', marginBottom: '4px'}}
                size="sm"
              />
              : ''
          }
          Send
          <FontAwesomeIcon icon="arrow-right" className="icon-right"/>
        </Button>
        <Button onClick={props.onHide} className="btn btn-outline-dark btn-form-app border-radius-4">Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

const MakeTransference = (props) => {
  const {
    affiliateSelected, submitMakeTransference, inProgress, ...rest
  } = props;
  const formSendMessageId = 'formSendMessageId';
  return (
    <Modal
      {...rest}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-app"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="app-header">
          Transference
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <Formik
          initialValues={{
            amount: '',
          }}
          onSubmit={(values) => submitMakeTransference(
            values.amount,
          )}
          validationSchema={Yup.object().shape({
            amount: Yup.number().required('Required').typeError('Invalid number')
          })}
        >
          {props => {
            const {
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              handleSubmit
            } = props;
            return (
              <Form id={formSendMessageId} onSubmit={handleSubmit}>
                <Row className="text-left">
                  <Col md={12} sm={12}>
                    <Form.Group>
                      <Form.Label
                        className="font-weight-bold">{`To ${affiliateSelected ? affiliateSelected.userName.toUpperCase() : ''}`}
                      </Form.Label>
                      <Form.Control
                        className="form-input border-radius-4"
                        type="text"
                        placeholder="Amount"
                        id="amount"
                        value={values.amount}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.amount && touched.amount && (
                        <div className="error-message">{errors.amount}</div>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn btn-form-app btn-option border-radius-4" type="submit" form={formSendMessageId}
                disabled={inProgress === true}>
          {
            inProgress ?
              <Spinner
                animation="grow"
                variant="secondary"
                style={{marginRight: '5px', marginBottom: '4px'}}
                size="sm"
              />
              : ''
          }
          Accept
          <FontAwesomeIcon icon="arrow-right" className="icon-right"/>
        </Button>
        <Button onClick={props.onHide} className="btn btn-outline-dark btn-form-app border-radius-4">Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

const AddAffiliate = (props) => {
  const {
    affiliateSelected, inProgress, submitAddAffiliate, ...rest
  } = props;
  return (
    <Modal
      {...rest}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-app"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="app-header">
          Add Affiliate
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Label>
          Sure you want to add&nbsp;
          <span className="font-weight-bold">{affiliateSelected ? affiliateSelected.userName.toUpperCase() : ''}</span>
          &nbsp;as an affiliate?
        </Form.Label>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="btn btn-form-app btn-option border-radius-4"
          type="submit"
          onClick={() => submitAddAffiliate(affiliateSelected)}
        >
          {
            inProgress ?
              <Spinner
                animation="grow"
                variant="secondary"
                style={{marginRight: '5px', marginBottom: '4px'}}
                size="sm"
              />
              : ''
          }
          Accept
          <FontAwesomeIcon icon="arrow-right" className="icon-right"/>
        </Button>
        <Button onClick={props.onHide} className="btn btn-outline-dark btn-form-app border-radius-4">Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

const UnlinkAffiliate = (props) => {
  const {
    affiliateSelected, inProgress, submitUnlinkAffiliate, ...rest
  } = props;
  return (
    <Modal
      {...rest}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-app"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="app-header">
          Unlink Affiliate
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Label>
          Sure you want to unlink the affiliate&nbsp;
          <span className="font-weight-bold">{affiliateSelected ? affiliateSelected.userName.toUpperCase() : ''}</span>?
        </Form.Label>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="btn btn-form-app btn-option border-radius-4"
          type="submit"
          onClick={() => submitUnlinkAffiliate()}
        >
          {
            inProgress ?
              <Spinner
                animation="grow"
                variant="secondary"
                style={{marginRight: '5px', marginBottom: '4px'}}
                size="sm"
              />
              : ''
          }
          Accept
          <FontAwesomeIcon icon="arrow-right" className="icon-right"/>
        </Button>
        <Button onClick={props.onHide} className="btn btn-outline-dark btn-form-app border-radius-4">Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

class Affiliates extends Component {
  updateTableData = () => this.table.fireFetchData();

  render() {
    const {
      inProgress, loadAffiliates, affiliatesState, affiliateSelected, superUser,
      openSendMessage, showSendMessage, closeSendMessage, submitSendMessage,
      openMakeTransference, showMakeTransference, closeMakeTransference, submitMakeTransference,
      openUnlinkAffiliate, showUnlinkAffiliate, closeUnlinkAffiliate, submitUnlinkAffiliate,
      submitSearchUserByEmail, openAddAffiliate, showAddAffiliate, closeAddAffiliate, submitAddAffiliate, userByEmail
    } = this.props;
    return (
      <Row>
        <Col sm={12}>
          <div className="pl-5-desktop pl-5-desktop-affiliates">
            <h4 className="mb-4">Affiliates</h4>
            <Formik
              initialValues={{email: ''}}
              onSubmit={(values) => submitSearchUserByEmail(values.email)}
              validationSchema={Yup.object().shape({
                email: Yup.string().required('Required').email('Invalid email'),
              })}
            >
              {props => {
                const {
                  touched,
                  errors,
                  handleSubmit,
                  values,
                  handleChange,
                  handleBlur
                } = props;
                return (
                  <Form className="mt-4" onSubmit={handleSubmit}>
                    <div className="px-4 py-4 mb-3 profile-affiliate-form-container"
                         style={{border: '1px solid rgba(0,0,0,.1)'}}>
                      <Row className="text-left">
                        <Col sm={8} md={6} xs={8} className="profile-email-input-container">
                          <Form.Label className="font-weight-bold">Email</Form.Label>
                          <Form.Group className="mb-4">
                            <Form.Control
                              className="form-input border-radius-4"
                              type="text"
                              id="email"
                              value={values.email}
                              placeholder="Email"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.email && touched.email && (
                              <div className="error-message">{errors.email}</div>
                            )}
                          </Form.Group>
                        </Col>
                        <Col sm={4} md={6} xs={4}>
                          <Form.Group className="mb-3">
                            <Button
                              className="btn btn-black btn-inline border-radius-4 search-button-profile"
                              type="submit"
                              disabled={inProgress}
                            >
                              <FontAwesomeIcon icon="search" size="sm" className="o-btn-icon"/>
                              Search
                            </Button>
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                );
              }}
            </Formik>
            {
              userByEmail && userByEmail !== null ?
                <div className="mb-5 padding-7-5">
                  <Affiliate
                    inProgress={inProgress}
                    affiliate={userByEmail}
                    affiliateSelected={affiliateSelected}
                    openAddAffiliate={openAddAffiliate}
                    showAddAffiliate={showAddAffiliate}
                    closeAddAffiliate={closeAddAffiliate}
                    userType={userTypes.USER}
                  />
                </div>
                : ''
            }
            {
              superUser && superUser !== null ?
                <div className="mt-5 padding-7-5">
                  <h5>Affiliated With</h5>
                  <div className="affiliate-with-container">
                    <Affiliate
                      inProgress={inProgress}
                      affiliate={superUser}
                      affiliateSelected={affiliateSelected}
                      openSendMessage={openSendMessage}
                      showSendMessage={showSendMessage}
                      closeSendMessage={closeSendMessage}
                      openMakeTransference={openMakeTransference}
                      showMakeTransference={showMakeTransference}
                      submitSendMessage={submitSendMessage}
                      submitMakeTransference={submitMakeTransference}
                      closeMakeTransference={closeMakeTransference}
                      userType={userTypes.SUPERUSER}
                    />
                  </div>
                </div> : ''
            }
            <Formik
              initialValues={{username: ''}}
              onSubmit={(values) => this.updateTableData(values.username)}
            >
              {props => {
                const {
                  values,
                  touched,
                  errors,
                  handleChange,
                  handleBlur,
                  handleSubmit
                } = props;
                return (
                  <Form className="mt-5" onSubmit={handleSubmit}>
                    <div style={{position: 'relative'}} className="row">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 affiliates-header-amount-container">
                        <h5
                          className="affiliates-header-amount">{`Affiliates Found: ${affiliatesState.data.length}`}</h5>
                      </div>
                      <div className="col-12 col-sm-12 col-md-12 col-lg-7 col-xl-7">
                        <Row className="float-right">
                          <div className="col-sm-6 col-md-12 col-lg-7 col-xl-7">
                            <Form.Group>
                              <Form.Control
                                className="form-input no-editable search-affiliate border-radius-4"
                                type="text"
                                id="username"
                                value={values.username}
                                placeholder="Username"
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.username && touched.username && (
                                <div className="error-message">{errors.username}</div>
                              )}
                            </Form.Group>
                          </div>
                          <div className="col-sm-6 col-md-12 col-lg-5 col-xl-5">
                            <Form.Group className="">
                              <div className="mb-3">
                                <Button
                                  className="btn btn-black btn-inline border-radius-4"
                                  type="submit"
                                  disabled={inProgress}
                                  style={{marginTop: '.2rem'}}
                                >
                                  <FontAwesomeIcon icon="search" size="sm" className="o-btn-icon"/>
                                  Search
                                </Button>
                              </div>
                            </Form.Group>
                          </div>
                        </Row>
                      </div>
                    </div>
                    <ReactTable
                      ref={(instance) => {
                        this.table = instance;
                      }}
                      columns={[
                        {
                          Header: "Unlink",
                          accessor: "d",
                          Cell: ({row}) => (
                            <Affiliate
                              inProgress={inProgress}
                              affiliate={row._original}
                              affiliateSelected={affiliateSelected}
                              openSendMessage={openSendMessage}
                              showSendMessage={showSendMessage}
                              closeSendMessage={closeSendMessage}
                              openMakeTransference={openMakeTransference}
                              showMakeTransference={showMakeTransference}
                              submitSendMessage={submitSendMessage}
                              submitMakeTransference={submitMakeTransference}
                              closeMakeTransference={closeMakeTransference}
                              openUnlinkAffiliate={openUnlinkAffiliate}
                              showUnlinkAffiliate={showUnlinkAffiliate}
                              closeUnlinkAffiliate={closeUnlinkAffiliate}
                              userType={userTypes.AFFILIATE}
                            />),
                          sortable: false
                        }
                      ]}
                      manual
                      data={affiliatesState.data}
                      pages={affiliatesState.pages}
                      loading={inProgress}
                      onFetchData={(state) => loadAffiliates(state)}
                      defaultPageSize={affiliatesState.pageSize}
                      pageSizeOptions={[5, 10, 20]}
                      className="no-table"
                      filter={{username: values.username}}
                      minRows={0}
                      showPagination={affiliatesState.data.length > 0}
                      NoDataComponent={() => null}
                    >
                      {(state, makeTable) => {
                        return (
                          <div>
                            {makeTable()}
                            <TableInfo
                              totalRows={affiliatesState.total}
                              page={state.page}
                              pageSize={state.pageSize}
                            />
                          </div>
                        )
                      }}
                    </ReactTable>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </Col>
        <SendMessage
          inProgress={inProgress}
          show={showSendMessage}
          onHide={closeSendMessage}
          affiliateSelected={affiliateSelected}
          submitSendMessage={submitSendMessage}
        />
        <MakeTransference
          inProgress={inProgress}
          show={showMakeTransference}
          onHide={closeMakeTransference}
          affiliateSelected={affiliateSelected}
          submitMakeTransference={submitMakeTransference}
        />
        <AddAffiliate
          inProgress={inProgress}
          show={showAddAffiliate}
          onHide={closeAddAffiliate}
          affiliateSelected={affiliateSelected}
          submitAddAffiliate={submitAddAffiliate}
        />
        <UnlinkAffiliate
          inProgress={inProgress}
          show={showUnlinkAffiliate}
          onHide={closeUnlinkAffiliate}
          affiliateSelected={affiliateSelected}
          submitUnlinkAffiliate={submitUnlinkAffiliate}
        />
      </Row>
    );
  }
}

export default Affiliates;