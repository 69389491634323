import React from 'react';

const TableInfo = (props) => {
  let rowCount = props.totalRows > 0 ? props.totalRows : 0;
  let rowMin = 0;
  let rowMax = 0;

  if (rowCount > 0) {
    rowMin = props.page * props.pageSize + 1;
    rowMax = Math.min((props.page + 1) * props.pageSize, rowCount);
  }
  return (
    <div className="py-4 text-center">
      {`Showing ${rowMin} to ${rowMax} of ${rowCount} entries`}
    </div>
  );
};

export default TableInfo;
