import React, {Component} from 'react';
import './ReactPagination.css';
import ReactPaginate from 'react-paginate';

class ReactPagination extends Component {
constructor(props) {
    super(props);

    this.changePage = ev => this.props.onPaginate(
      ev.selected + 1
    );    
  }

  render() {
    const { nroPages } = this.props;
    return (
      <div className="commentBox">
        <ReactPaginate
          previousLabel={'previous'}
          nextLabel={'next'}
          breakLabel={'...'}
          breakClassName={'break-me'}
          pageCount={nroPages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={this.changePage}
          containerClassName={'pagination'}
          subContainerClassName={'pages pagination'}
          activeClassName={'active'}
        />
      </div>
    );
  }
}

export default ReactPagination;