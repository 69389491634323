import React, {Component} from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import {push} from 'react-router-redux';

import agent from '../../agent';
import {store} from '../../store';
import {
  APP_LOAD,
  LOGOUT_TO_LOGIN,
  REDIRECT,
  APP_INITILIZE,
  LOAD_USER
} from '../../constants/actionTypes';

import '../../assets/css/bootstrap.min.css';
import './App.css';

import HeaderUI from '../HeaderUI/HeaderUI';
import NavigationBar from '../NavigationBar/NavigationBar';
import Footer from "../Footer/Footer";
import Pay from "../Pay/Pay";
import Home from "../Pay/Home";
import Repair from "../Repair";
import Unlock from "../Unlock";
import Activation from "../Activation";
import Buy from "../Buy";
import Sell from "../Sell";
import LoginUX from "../LoginUX/LoginUX";
import RegisterUX from "../RegisterUX/RegisterUX";
import Profile from "../Profile/Profile";
import Admin from "../Admin/Admin";
import ResetPassword from "../ResetPassword";
import Confirmation from "../Confirmation";
import WrongRequest from "../WrongRequest";

import logo from "../../assets/img/logo.png";
import closeIcon from "../../assets/img/mobile-app/close-icon.svg";

import {
  isAndroid,
  isIOS
} from "react-device-detect";

import './App.css';

import {library} from '@fortawesome/fontawesome-svg-core';
import {
  faUser, faPencilAlt, faSearch, faBars, faArrowRight, faArrowLeft, faMapMarkerAlt, faEnvelope, faPhone, faBook,
  faExchangeAlt, faScroll, faDownload, faChevronLeft, faChevronRight, faSignOutAlt, faDollarSign, faCreditCard,
  faPlus, faTrash, faCheck, faStickyNote, faAngleDown, faAngleUp, faTimes, faSave, faSync
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faUser, faPencilAlt, faSearch, faBars, faArrowRight, faArrowLeft, faMapMarkerAlt, faEnvelope, faPhone, faBook,
  faExchangeAlt, faScroll, faDownload, faChevronLeft, faChevronRight, faSignOutAlt, faDollarSign, faCreditCard,
  faPlus, faTrash, faCheck, faStickyNote, faAngleDown, faAngleUp, faTimes, faSave, faSync
);

const mapStateToProps = state => {
  return {
    appLoaded: state.common.appLoaded,
    currentUser: state.common.currentUser,
    redirectTo: state.common.redirectTo,
    appMenu: state.common.appMenu,
  }
};

const mapDispatchToProps = dispatch => ({
  onLoad: (payload, token) =>
    dispatch({type: APP_LOAD, payload, token, skipTracking: true}),
  onRedirect: () =>
    dispatch({type: REDIRECT})
});

const PublicRoute = ({...props}) => {
  const isAllowed = agent.Auth.isLoggedIn();
  return isAllowed && (props.path === '/login' || props.path === '/register')
    ? (<Redirect to="/pay"/>)
    : (<Route {...props} />)
};

const ProtectedRoute = ({...props}) => {
  const isAllowed = agent.Auth.isLoggedIn();
  return isAllowed
    ? (<Route {...props} />)
    : (<Redirect to="/login"/>)
};

const ProtectedAdminRoute = ({...props}) => {
  const isAllowed = agent.Auth.isLoggedIn();
  return isAllowed && props.currentUser.isAdmin && props.path === '/admin'
    ? (<Route {...props} />)
    : (<Redirect to="/pay"/>)
};

class App extends Component {

  componentWillReceiveProps(nextProps) {
    if (nextProps.redirectTo) {
      store.dispatch(push(nextProps.redirectTo));
      this.props.onRedirect();
    }
  }

  componentWillMount() {
    store.dispatch({type: APP_INITILIZE});
    const token = window.localStorage.getItem('jwt-cellshop');
    if (token) {
      agent.setToken(token);
      agent.Auth.current()
        .then(userResponse => {
          store.dispatch({type: LOAD_USER, payload: userResponse});
          this.props.onLoad(userResponse, token);
        })
        .catch(err => {
          if (err.status === 401)
            store.dispatch({type: LOGOUT_TO_LOGIN});
        })
    } else {
      this.props.onLoad(null, token);
    }
  }

  doNotOpenApp() {
    localStorage.setItem('doNotOpenApp', true);
    let mobileAppContainer = document.getElementById("mobile-app-container");
    mobileAppContainer.parentNode.removeChild(mobileAppContainer);
  }

  redirectToPlayStore(isIOS) {
    if (isIOS) {
      window.location.href = 'https://apps.apple.com/uy/app/cellshop/id1100406746';
    } else {
      window.location.href = 'https://play.google.com/store/apps/details?id=com.app.cellshop.shop&hl=en';
    }
  }

  render() {
    let doNotOpenApp = localStorage.getItem('doNotOpenApp');
    if (this.props.appLoaded) {
      return (
        <div className="container p-0">
          {(isIOS === true || isAndroid === true) && doNotOpenApp !== "true" ?
            <div className="mobile-app-warning row" id="mobile-app-container">
              <div className="col-10 text-left logo-text-container-mobile-app">
                <img src={logo} alt="" className="logo-switch-app"/>
                <span className="switch-to-cellshop-app-text" onClick={() => this.redirectToPlayStore(isIOS)}>Switch to Cellshop App</span>
              </div>
              <div className="col-2 text-right">
                <img className="close-icon-mobile-app" src={closeIcon} alt="" onClick={() => this.doNotOpenApp(isIOS)}/>
              </div>
            </div>
            : null}
          <div className="header-ui-app-container">
            <HeaderUI
              currentUser={this.props.currentUser}
              appMenu={this.props.appMenu}/>
          </div>
          <div className="navigation-bar-app-container">
            <NavigationBar
              currentUser={this.props.currentUser}
              appMenu={this.props.appMenu}/>
          </div>
          <div className="swtich-app-container">
            <Switch>
              <PublicRoute exact path='/' component={Home}/>
              <PublicRoute exact path='/home' component={Home}/>
              <PublicRoute exact path='/pay' component={Pay}/>
              <PublicRoute exact path='/repair' component={Repair}/>
              <PublicRoute exact path='/unlock' component={Unlock}/>
              <PublicRoute exact path='/activation' component={Activation}/>
              <PublicRoute exact path='/buy' component={Buy}/>
              <PublicRoute exact path='/sell' component={Sell}/>
              <PublicRoute exact path='/login' component={LoginUX}/>
              <PublicRoute exact path='/register' component={RegisterUX}/>
              <PublicRoute exact path='/user/password/reset' component={ResetPassword}/>
              <PublicRoute exact path='/user/password/reset/wrong' component={WrongRequest}/>
              <PublicRoute exact path='/confirmation/:correct' component={Confirmation}/>
              <PublicRoute exact path='/confirmation' component={Confirmation}/>
              <ProtectedRoute exact path='/profile' component={Profile}/>
              <ProtectedAdminRoute exact path='/admin' component={Admin} currentUser={this.props.currentUser}/>
              <PublicRoute
                component={() =>
                  <div
                    className="text-center"
                    style={{marginTop: '7rem', marginBottom: '10rem', fontSize: '1.5rem'}}>
                    <span style={{fontSize: '4.5rem', marginRight: '1rem', color: '#f1602a'}}>404</span> You Are Lost
                  </div>
                }
              />
            </Switch>
          </div>
          <Footer/>
        </div>
      );
    } else {
      return <p>Loading...</p>;
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
