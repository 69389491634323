import {
  ASYNC_START,
  LOAD_USER,
  UPDATE_PROFILE,
  PROFILE_PAGE_UNLOADED,
  UPDATE_FIELD_PROFILE,
  UPDATE_PROFILE_RESET,
  PROFILE_LOAD_TAB_TRANSFERENCES,
  PROFILE_LOAD_TRANSFERENCES,
  PROFILE_LOAD_TAB_TRANSACTIONS,
  PROFILE_LOAD_TRANSACTIONS,
  PROFILE_LOAD_TRANSACTIONS_SUMMARY,
  PROFILE_SHOW_TRANSACTION_DETAIL,
  PROFILE_LOAD_TAB_BATCH_PAYMENTS,
  PROFILE_LOAD_BATCH_PAYMENTS,
  PROFILE_SHOW_BATCH_PAYMENT_DETAIL,
  PROFILE_LOAD_TAB_PAYMENT_NOTIFICATIONS,
  PROFILE_LOAD_PAYMENT_NOTIFICATIONS,
  PROFILE_SHOW_PAYMENT_NOTIFICATION_DETAIL,
  PROFILE_LOAD_TAB_RESELLER_INFO,
  PROFILE_LOAD_RESELLER_INFO,
  PROFILE_LOAD_COMMISSIONS,
  PROFILE_LOAD_DING_PRODUCTS,
  PROFILE_SEARCH_RESELLER_INFO,
  PROFILE_LOAD_TAB_REFERRALS,
  PROFILE_LOAD_REFERRALS,
  PROFILE_SHOW_RESET_DEBT,
  PROFILE_TRANSFER_REFERRAL,
  PROFILE_LOAD_TAB_AFFILIATES,
  PROFILE_LOAD_AFFILIATES,
  PROFILE_SHOW_SEND_MESSAGE,
  PROFILE_SHOW_MAKE_TRANSFERENCE,
  PROFILE_SEARCH_USER_BY_EMAIL,
  PROFILE_USER_ALREADY_AFFILIATED,
  PROFILE_SHOW_UNLINK_AFFILIATE,
  PROFILE_SEND_MESSAGE,
  PROFILE_MAKE_TRANSFERENCE,
  PROFILE_LOAD_SUPER_USER,
  PROFILE_SHOW_ADD_AFFILIATE,
  APP_LOAD,
  PROFILE_USER_NOT_ACTIVATED,
  PROFILE_USER_DOES_NOT_EXIST,
} from '../constants/actionTypes';

const {getUserCommissions} = require('../utils');

export default (state = {}, action) => {
  switch (action.type) {
    case APP_LOAD:
      return {
        currentTab: 'profile'
      };

    case LOAD_USER:
      return {
        ...state,
        inProgress: false,
        errors: action.error ? action.payload.message : null,
        lastAction: null,
      };

    case PROFILE_PAGE_UNLOADED:
      return {};

    case UPDATE_FIELD_PROFILE:
      return {...state, [action.key]: action.value};

    case UPDATE_PROFILE:
      return {
        ...state,
        inProgress: false,
        errors: action.error ? action.payload.message : null,
        lastAction: action.lastAction
      };

    case UPDATE_PROFILE_RESET:
      return {
        ...state,
        errors: null,
        lastAction: null
      };

    case PROFILE_LOAD_TAB_AFFILIATES:
      return {
        ...state,
        affiliateSelected: null,
        superUser: null,
        userByEmail: null,
        affiliatesState: {
          page: 0,
          pageSize: 10,
          pages: 0,
          data: [],
          filter: null,
          total: 0
        }
      };

    case PROFILE_LOAD_SUPER_USER:
      return {
        ...state,
        superUser: action.error ? null : action.payload.body
      };

    case PROFILE_SEARCH_USER_BY_EMAIL:
      return {
        ...state,
        inProgress: false,
        userByEmail: action.error ? null : action.payload.body.superUserId !== '' && action.payload.body.superUserId !== null ? null 
                                        : !action.payload.body.emailConfirmed ? null:action.payload.body,
        lastAction: !action.error && action.payload.body.superUserId !== '' && action.payload.body.superUserId !== null ? PROFILE_USER_ALREADY_AFFILIATED 
                      :!action.error && !action.payload.body.emailConfirmed ? PROFILE_USER_NOT_ACTIVATED 
                      :!action.error ? null :PROFILE_USER_DOES_NOT_EXIST
      };

    case PROFILE_SHOW_ADD_AFFILIATE:
      return {
        ...state,
        showAddAffiliate: action.show,
        affiliateSelected: action.value
      };

    case PROFILE_LOAD_AFFILIATES:
      if (action.error) {
        return {
          ...state,
          errors: action.payload.message,
          inProgress: false,
          superUser: null,
          affiliatesState: {
            page: 0,
            pageSize: 10,
            pages: 0,
            data: [],
            filter: null,
            total: 0
          },
        };
      } else {
        const pagination = JSON.parse(action.payload.header['x-pagination']);
        return {
          ...state,
          errors: action.error ? action.payload.message : null,
          inProgress: false,
          affiliatesState: {
            page: pagination.currentPage - 1,
            pageSize: pagination.pageSize,
            pages: pagination.totalPages,
            data: action.payload.body,
            filter: action.affiliatesState.filter,
            total: pagination.totalCount
          },
          showMakeTransference: false,
          showAddAffiliate: false,
          showUnlinkAffiliate: false,
          lastAction: action.lastAction,
          affiliateSelected: null,
        };
      }

    case PROFILE_SHOW_SEND_MESSAGE:
      return {
        ...state,
        showSendMessage: action.show,
        affiliateSelected: action.value
      };

    case PROFILE_SEND_MESSAGE:
      return {
        ...state,
        inProgress: false,
        errors: action.error ? action.payload.message : null,
        lastAction: action.error ? null : action.lastAction,
        affiliateSelected: action.error ? state.affiliateSelected : null,
        showSendMessage: action.error,
      };

    case PROFILE_SHOW_MAKE_TRANSFERENCE:
      return {
        ...state,
        showMakeTransference: action.show,
        affiliateSelected: action.value
      };

    case PROFILE_SHOW_UNLINK_AFFILIATE:
      return {
        ...state,
        showUnlinkAffiliate: action.show,
        affiliateSelected: action.value
      };

    case PROFILE_LOAD_TAB_REFERRALS:
      return {
        ...state,
        referrals: [],
        debt: null,
      };

    case PROFILE_LOAD_REFERRALS:
      return {
        ...state,
        inProgress: false,
        errors: action.error ? action.payload.message : null,
        referrals: action.error ? null : action.payload.body,
        showResetDebt: false,
        lastAction: action.lastAction
      };

    case PROFILE_SHOW_RESET_DEBT:
      return {
        ...state,
        showResetDebt: action.show,
        debt: action.value
      };

    case PROFILE_LOAD_TAB_TRANSFERENCES:
      return {
        ...state,
        transferencesState: {
          page: 0,
          pageSize: 10,
          pages: 0,
          data: [],
          total: 0
        }
      };

    case PROFILE_LOAD_TRANSFERENCES:
      if (action.error) {
        return {
          ...state,
          errors: action.payload.message,
          inProgress: false,
          transferencesState: {
            page: 0,
            pageSize: 10,
            pages: 0,
            data: [],
            total: 0
          }
        };
      } else {
        const pagination = JSON.parse(action.payload.header['x-pagination']);
        return {
          ...state,
          errors: null,
          inProgress: false,
          transferencesState: {
            page: pagination.currentPage - 1,
            pageSize: pagination.pageSize,
            pages: pagination.totalPages,
            data: action.payload.body,
            total: pagination.totalCount
          }
        };
      }

    case PROFILE_LOAD_TAB_TRANSACTIONS:
      return {
        ...state,
        showTransactionsSummary: false,
        transactionsState: {
          page: 0,
          pageSize: 10,
          pages: 0,
          data: [],
          total: 0
        },
        transactionsSummaryState: {
          page: 0,
          pageSize: 10,
          pages: 0,
          data: [],
          total: 0
        }
      };

    case PROFILE_LOAD_TRANSACTIONS:
      if (action.error) {
        return {
          ...state,
          errors: action.payload.message,
          inProgress: false,
          transactionsState: {
            page: 0,
            pageSize: 10,
            pages: 0,
            data: [],
            total: 0
          }
        };
      } else {
        const pagination = JSON.parse(action.payload.header['x-pagination']);
        return {
          ...state,
          errors: null,
          inProgress: false,
          transactionsState: {
            page: pagination.currentPage - 1,
            pageSize: pagination.pageSize,
            pages: pagination.totalPages,
            data: action.payload.body,
            total: pagination.totalCount
          }
        };
      }

    case PROFILE_LOAD_TRANSACTIONS_SUMMARY:
      if (action.error) {
        return {
          ...state,
          errors: action.payload.message,
          inProgress: false,
          transactionsSummaryState: {
            page: 0,
            pageSize: 10,
            pages: 0,
            data: [],
            total: 0
          }
        };
      } else {
        const pagination = JSON.parse(action.payload.header['x-pagination']);
        return {
          ...state,
          errors: null,
          inProgress: false,
          transactionsSummaryState: {
            page: pagination.currentPage - 1,
            pageSize: pagination.pageSize,
            pages: pagination.totalPages,
            data: action.payload.body,
            total: pagination.totalCount
          }
        };
      }

    case PROFILE_SHOW_TRANSACTION_DETAIL:
      return {
        ...state,
        showTransactionDetail: action.show,
        transactionDetail: action.value
      };

    case PROFILE_LOAD_TAB_BATCH_PAYMENTS:
      return {
        ...state,
        batchPaymentsState: {
          page: 0,
          pageSize: 10,
          pages: 0,
          data: [],
          total: 0
        }
      };

    case PROFILE_LOAD_BATCH_PAYMENTS:
      if (action.error) {
        return {
          ...state,
          errors: action.payload.message,
          inProgress: false,
          batchPaymentsState: {
            page: 0,
            pageSize: 10,
            pages: 0,
            data: [],
            total: 0
          }
        };
      } else {
        const pagination = JSON.parse(action.payload.header['x-pagination']);
        return {
          ...state,
          errors: null,
          inProgress: false,
          batchPaymentsState: {
            page: pagination.currentPage - 1,
            pageSize: pagination.pageSize,
            pages: pagination.totalPages,
            data: action.payload.body,
            total: pagination.totalCount
          }
        };
      }

    case PROFILE_SHOW_BATCH_PAYMENT_DETAIL:
      return {
        ...state,
        showBatchPaymentDetail: action.show,
        batchPaymentDetail: action.value
      };

    case PROFILE_LOAD_TAB_PAYMENT_NOTIFICATIONS:
      return {
        ...state,
        paymentNotificationsState: {
          page: 0,
          pageSize: 10,
          pages: 0,
          data: [],
          total: 0
        }
      };

    case PROFILE_LOAD_PAYMENT_NOTIFICATIONS:
      if (action.error) {
        return {
          ...state,
          errors: action.payload.message,
          inProgress: false,
          paymentNotificationsState: {
            page: 0,
            pageSize: 10,
            pages: 0,
            data: [],
            total: 0
          }
        };
      } else {
        const pagination = JSON.parse(action.payload.header['x-pagination']);
        return {
          ...state,
          errors: null,
          inProgress: false,
          paymentNotificationsState: {
            page: pagination.currentPage - 1,
            pageSize: pagination.pageSize,
            pages: pagination.totalPages,
            data: action.payload.body,
            total: pagination.totalCount
          }
        };
      }

    case PROFILE_SHOW_PAYMENT_NOTIFICATION_DETAIL:
      return {
        ...state,
        showPaymentNotificationDetail: action.show,
        paymentNotificationDetail: action.value
      };

    case PROFILE_LOAD_TAB_RESELLER_INFO:
      return {
        ...state,
        resellerInfo: null,
        userCommissions: [],
        commissions: [],
        dingProducts: []
      };

    case PROFILE_LOAD_RESELLER_INFO:
      return {
        ...state,
        inProgress: false,
        errors: action.error ? action.payload.message : null,
        resellerInfo: action.error ? null : action.payload.body,
        resellerInfoFilterProduct: '',
        commissions: [],
        dingProducts: [],
        filteredCommissions: []
      };

    case PROFILE_LOAD_COMMISSIONS:
      return {
        ...state,
        inProgress: false,
        errors: action.error ? action.payload.message : null,
        userCommissions: action.error ? [] : action.payload.body,
        commissions: action.error ? [] : getUserCommissions(action.payload.body, state.dingProducts),
        filteredCommissions: action.error ? [] : getUserCommissions(action.payload.body, state.dingProducts),
      };

    case PROFILE_LOAD_DING_PRODUCTS:
      return {
        ...state,
        inProgress: false,
        errors: action.error ? action.payload.message : null,
        dingProducts: action.error ? [] : action.payload.body,
        commissions: action.error ? [] : getUserCommissions(state.userCommissions, action.payload.body),
        filteredCommissions: action.error ? [] : getUserCommissions(state.userCommissions, action.payload.body)
      };

    case PROFILE_SEARCH_RESELLER_INFO:
      return {
        ...state,
        filteredCommissions: state.commissions && state.commissions.length > 0 ? state.commissions.filter(item => item.productName.toLowerCase().includes(state.resellerInfoFilterProduct.toLowerCase())) : []
      };

    case ASYNC_START:
      if (
        action.subtype === UPDATE_PROFILE ||
        action.subtype === PROFILE_LOAD_TRANSFERENCES ||
        action.subtype === PROFILE_LOAD_TRANSACTIONS ||
        action.subtype === PROFILE_LOAD_BATCH_PAYMENTS ||
        action.subtype === PROFILE_LOAD_PAYMENT_NOTIFICATIONS ||
        action.subtype === PROFILE_TRANSFER_REFERRAL ||
        action.subtype === PROFILE_SEND_MESSAGE ||
        action.subtype === PROFILE_MAKE_TRANSFERENCE ||
        action.subtype === PROFILE_SEARCH_USER_BY_EMAIL
      ) {
        return {...state, inProgress: true, errors: null};
      }
      break;

    default:
      return state;
  }

  return state;
};
