import React, { Component } from 'react'
import { GoogleLogin } from 'react-google-login';
import './Google.css';

export default class Google extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userId: '',
            name: '',
            email: '',
            picture: '',
            title: this.props.title
        }
    }

    responseGoogle = response => {
        // this.setState({
        //     userID: response.userID,
        //     name: response.name,
        //     email: response.email,
        //     picture: response.picture.data.url
        // })
        // this.loginOrRegister();
        console.log(response);
    }

    loginOrRegister() {

        var facebookUser = {
            "userId": this.state.userID,
            "name": this.state.name,
            "email": this.state.email,
            "picture": this.state.picture
        }

        console.log(facebookUser);
    }

    render() {
        let gContent;
       
        if (this.state.title === "register") {
            gContent = (
                 <GoogleLogin clientId="658977310896-knrl3gka66fldh83dao2rhgbblmd4un9.apps.googleusercontent.com"
                                buttonText="Register with Google"
                                onSuccess={this.responseGoogle}
                                onFailure={this.responseGoogle}
                                cookiePolicy={'single_host_origin'}
                        />

            );
        }
        else {
            gContent = (
               <GoogleLogin clientId="658977310896-knrl3gka66fldh83dao2rhgbblmd4un9.apps.googleusercontent.com"
                            buttonText="Login  with Google"
                            onSuccess={this.responseGoogle}
                            onFailure={this.responseGoogle}
                            cookiePolicy={'single_host_origin'}
                        />

            );
        }
        return (
            <div className="google-content-login">
                {gContent}
            </div>
        )
    }
}