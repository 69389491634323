import React, {Component} from 'react';
import ReactTable from "react-table";
import "react-table/react-table.css";
import {Button, Col, Form, Row} from "react-bootstrap";
import {Formik} from "formik";
import moment from 'moment';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Datepicker} from "react-formik-ui";
import * as Yup from "yup";
import TableInfo from '../TableInfo';

class Transferences extends Component {
  updateTableData = () => this.table.fireFetchData();

  render() {
    const {
      inProgress, loadTransferences, transferencesState,
    } = this.props;
    return (
      <Row className="transferences-super-container">
        <Col sm={12}>
          <div className="pl-5-desktop-transferences">
            <h4 className="mb-4">Transferences</h4>

            <Formik
              initialValues={{
                fromDate: moment().subtract(1, 'month'),
                toDate: moment(),
                fromUserName: '',
                toUserName: '',
              }}
              onSubmit={(values) => this.updateTableData(
                values.fromDate,
                values.toDate,
                values.fromUserName,
                values.toUserName
              )}
              validationSchema={Yup.object().shape({
                fromDate: Yup.date().typeError('Invalid date'),
                toDate: Yup.date().typeError('Invalid date'),
              })}
            >
              {props => {
                const {
                  touched,
                  errors,
                  handleSubmit,
                  values,
                  handleChange,
                  handleBlur
                } = props;
                return (
                  <Form className="mt-4" onSubmit={handleSubmit}>
                    <div className="px-4 py-4 mb-4" style={{border: '1px solid rgba(0,0,0,.1)'}}>
                      <Row className="text-left">
                        <Col sm={12} md={4}>
                          <Form.Label className="font-weight-bold">From Date</Form.Label>
                          <Form.Group className="mb-4">
                            <Datepicker
                              className="form-input form-control border-radius-4"
                              name="fromDate"
                              placeholder="From"
                              dateFormat="yyyy-MM-dd"
                            />
                            {errors.fromDate && touched.fromDate && (
                              <div className="error-message">{errors.fromDate}</div>
                            )}
                          </Form.Group>
                        </Col>
                        <Col sm={12} md={4}>
                          <Form.Label className="font-weight-bold">To Date</Form.Label>
                          <Form.Group className="mb-4">
                            <Datepicker
                              className="form-input form-control border-radius-4"
                              name="toDate"
                              placeholder="To"
                              dateFormat="yyyy-MM-dd"
                            />
                            {errors.toDate && touched.toDate && (
                              <div className="error-message">{errors.toDate}</div>
                            )}
                          </Form.Group>
                        </Col>
                        <Col sm={12} md={4}>
                          <Form.Label className="font-weight-bold">From User</Form.Label>
                          <Form.Group className="mb-4">
                            <Form.Control
                              className="form-input border-radius-4"
                              type="text"
                              id="fromUserName"
                              value={values.fromUserName}
                              placeholder="From User"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.fromUserName && touched.fromUserName && (
                              <div className="error-message">{errors.fromUserName}</div>
                            )}
                          </Form.Group>
                        </Col>
                        <Col sm={12} md={4}>
                          <Form.Label className="font-weight-bold">To User</Form.Label>
                          <Form.Group className="mb-4">
                            <Form.Control
                              className="form-input border-radius-4"
                              type="text"
                              id="toUserName"
                              value={values.toUserName}
                              placeholder="To User"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.toUserName && touched.toUserName && (
                              <div className="error-message">{errors.toUserName}</div>
                            )}
                          </Form.Group>
                        </Col>
                        <Col sm={12} md={4}>
                          <Form.Group className="mb-3">
                            <Button
                              className="btn btn-black btn-inline border-radius-4 search-button-transactions"
                              type="submit"
                              disabled={inProgress}
                            >
                              <FontAwesomeIcon icon="search" size="sm" className="o-btn-icon"/>
                              Search
                            </Button>
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                    <ReactTable
                      ref={(instance) => {
                        this.table = instance;
                      }}
                      columns={[
                        {
                          Header: "Date",
                          id: "date",
                          accessor: d => moment(d.date).format('YYYY-MM-DD'),
                        },
                        {
                          Header: "Time",
                          id: "time",
                          accessor: d => moment(d.date).format('HH:mm'),
                          sortable: false,
                        },
                        {
                          Header: "Amount",
                          id: "amount",
                          accessor: d => d.amount.toFixed(2),
                          sortable: false,
                        },
                        {
                          Header: "From User",
                          accessor: "fromUserName",
                          sortable: false,
                        },
                        {
                          Header: "From User Email",
                          accessor: "fromEmail",
                          sortable: false,
                        },
                        {
                          Header: "To User",
                          accessor: "toUserName",
                          sortable: false,
                        },
                        {
                          Header: "To User Email",
                          accessor: "toEmail",
                          sortable: false,
                        },
                      ]}
                      defaultSorted={[
                        {
                          id: "date",
                          desc: false
                        }
                      ]}
                      manual
                      data={transferencesState.data}
                      pages={transferencesState.pages}
                      loading={inProgress}
                      onFetchData={(state) => loadTransferences(state)}
                      defaultPageSize={transferencesState.pageSize}
                      pageSizeOptions={[5, 10, 20]}
                      className="-striped -highlight"
                      filter={{
                        fromDate: values.fromDate,
                        toDate: values.toDate,
                        fromUserName: values.fromUserName,
                        toUserName: values.toUserName,
                      }}
                      minRows={0}
                      NoDataComponent={() => null}
                    >
                      {(state, makeTable) => {
                        return (
                          <div>
                            {makeTable()}
                            <TableInfo
                              totalRows={transferencesState.total}
                              page={state.page}
                              pageSize={state.pageSize}
                            />
                          </div>
                        )
                      }}
                    </ReactTable>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </Col>
      </Row>
    );
  }
}

export default Transferences;