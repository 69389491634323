import React, {Component} from 'react';
import ReactTable from "react-table";
import "react-table/react-table.css";
import {Button, Col, Form, Modal, Row, Spinner} from "react-bootstrap";
import {Formik} from "formik";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as Yup from "yup";
import TableInfo from '../TableInfo';

const AddCountry = (props) => {
  const {
    submitAddCountry, inProgress, regions, ...rest
  } = props;
  const formAddCountryId = 'formAddCountryId';
  return (
    <Modal
      {...rest}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-app"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="app-header">
          Add Country
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <Formik
          initialValues={{
            countryName: '',
            isO2: '',
            longCountryName: '',
            isO3: '',
            numCode: '',
            unMemberState: '',
            callingCode: '',
            cctld: '',
            internationalRegion: '',
          }}
          onSubmit={(values) => submitAddCountry(
            values.countryName,
            values.isO2,
            values.longCountryName,
            values.isO3,
            values.numCode,
            values.unMemberState,
            values.callingCode,
            values.cctld,
            values.internationalRegion,
          )}
          validationSchema={Yup.object().shape({
            countryName: Yup.string().required('Required'),
            isO2: Yup.string().required('Required').length(2, '2 letters'),
            longCountryName: Yup.string().required('Required'),
            isO3: Yup.string().required('Required').length(3, '3 letters'),
            numCode: Yup.string().required('Required'),
            callingCode: Yup.string().required('Required'),
            cctld: Yup.string().required('Required'),
            internationalRegion: Yup.string().required('Required'),
          })}
        >
          {props => {
            const {
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              handleSubmit
            } = props;
            return (
              <Form id={formAddCountryId} onSubmit={handleSubmit}>
                <Row className="text-left">
                  <Col md={6} sm={12}>
                    <Form.Group>
                      <Form.Label
                        className="font-weight-bold">Country Name
                      </Form.Label>
                      <Form.Control
                        className="form-input border-radius-4"
                        type="text"
                        placeholder="United States"
                        id="countryName"
                        value={values.countryName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.countryName && touched.countryName && (
                        <div className="error-message">{errors.countryName}</div>
                      )}
                    </Form.Group>
                    <Form.Group>
                      <Form.Label
                        className="font-weight-bold">Abbrv (2 letters)
                      </Form.Label>
                      <Form.Control
                        className="form-input border-radius-4"
                        type="text"
                        placeholder="US"
                        id="isO2"
                        value={values.isO2}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.isO2 && touched.isO2 && (
                        <div className="error-message">{errors.isO2}</div>
                      )}
                    </Form.Group>
                    <Form.Group>
                      <Form.Label
                        className="font-weight-bold">Full Country Name
                      </Form.Label>
                      <Form.Control
                        className="form-input border-radius-4"
                        type="text"
                        placeholder="United States of America"
                        id="longCountryName"
                        value={values.longCountryName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.longCountryName && touched.longCountryName && (
                        <div className="error-message">{errors.longCountryName}</div>
                      )}
                    </Form.Group>
                    <Form.Group>
                      <Form.Label
                        className="font-weight-bold">Abbrv (3 letters)
                      </Form.Label>
                      <Form.Control
                        className="form-input border-radius-4"
                        type="text"
                        placeholder="USA"
                        id="isO3"
                        value={values.isO3}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.isO3 && touched.isO3 && (
                        <div className="error-message">{errors.isO3}</div>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={6} sm={12}>
                    <Form.Group>
                      <Form.Label
                        className="font-weight-bold">Code
                      </Form.Label>
                      <Form.Control
                        className="form-input border-radius-4"
                        type="text"
                        placeholder="840"
                        id="numCode"
                        value={values.numCode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.numCode && touched.numCode && (
                        <div className="error-message">{errors.numCode}</div>
                      )}
                    </Form.Group>
                    <Form.Group>
                      <Form.Label
                        className="font-weight-bold">Calling Code
                      </Form.Label>
                      <Form.Control
                        className="form-input border-radius-4"
                        type="text"
                        placeholder="1"
                        id="callingCode"
                        value={values.callingCode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.callingCode && touched.callingCode && (
                        <div className="error-message">{errors.callingCode}</div>
                      )}
                    </Form.Group>
                    <Form.Group>
                      <Form.Label
                        className="font-weight-bold">Country Top-Level Domain
                      </Form.Label>
                      <Form.Control
                        className="form-input border-radius-4"
                        type="text"
                        placeholder=".us"
                        id="cctld"
                        value={values.cctld}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.cctld && touched.cctld && (
                        <div className="error-message">{errors.cctld}</div>
                      )}
                    </Form.Group>
                    <Form.Group className="mb-4">
                      <Form.Label className="font-weight-bold">International Region</Form.Label>
                      <Form.Control
                        as="select"
                        id="internationalRegion"
                        className="form-input border-radius-4"
                        value={values.internationalRegion}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option key={-1} value="">Select a Region</option>
                        {
                          regions ? regions.map((item, i) =>
                              <option key={i} value={item}>{item}</option>)
                            : ''
                        }
                      </Form.Control>
                      {errors.internationalRegion && touched.internationalRegion && (
                        <div className="error-message">{errors.internationalRegion}</div>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn btn-form-app btn-option border-radius-4" type="submit" form={formAddCountryId}
                disabled={inProgress === true}>
          {
            inProgress ?
              <Spinner
                animation="grow"
                variant="secondary"
                style={{marginRight: '5px', marginBottom: '4px'}}
                size="sm"
              />
              : ''
          }
          Accept
          <FontAwesomeIcon icon="arrow-right" className="icon-right"/>
        </Button>
        <Button onClick={props.onHide} className="btn btn-outline-dark btn-form-app border-radius-4">Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

const EditCountry = (props) => {
  const {
    submitEditCountry, inProgress, regions, selectedCountry, ...rest
  } = props;
  const formAddCountryId = 'formAddCountryId';
  return (
    <Modal
      {...rest}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-app"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="app-header">
          Edit Country
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <Formik
          initialValues={{
            id: selectedCountry ? selectedCountry.id : '',
            countryName: selectedCountry ? selectedCountry.countryName : '',
            isO2: selectedCountry ? selectedCountry.isO2 : '',
            longCountryName: selectedCountry ? selectedCountry.longCountryName : '',
            isO3: selectedCountry ? selectedCountry.isO3 : '',
            numCode: selectedCountry ? selectedCountry.numCode : '',
            unMemberState: selectedCountry ? selectedCountry.unMemberState : '',
            callingCode: selectedCountry ? selectedCountry.callingCode : '',
            cctld: selectedCountry ? selectedCountry.cctld : '',
            internationalRegion: selectedCountry ? selectedCountry.internationalRegion : '',
          }}
          onSubmit={(values) => submitEditCountry(
            values.id,
            values.countryName,
            values.isO2,
            values.longCountryName,
            values.isO3,
            values.numCode,
            values.unMemberState,
            values.callingCode,
            values.cctld,
            values.internationalRegion,
          )}
          validationSchema={Yup.object().shape({
            countryName: Yup.string().required('Required'),
            isO2: Yup.string().required('Required').length(2, '2 letters'),
            longCountryName: Yup.string().required('Required'),
            isO3: Yup.string().required('Required').length(3, '3 letters'),
            numCode: Yup.string().required('Required'),
            callingCode: Yup.string().required('Required'),
            cctld: Yup.string().required('Required'),
            internationalRegion: Yup.string().required('Required'),
          })}
        >
          {props => {
            const {
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              handleSubmit
            } = props;
            return (
              <Form id={formAddCountryId} onSubmit={handleSubmit}>
                <Row className="text-left">
                  <Col md={6} sm={12}>
                    <Form.Group>
                      <Form.Label
                        className="font-weight-bold">Country Name
                      </Form.Label>
                      <Form.Control
                        className="form-input border-radius-4"
                        type="text"
                        placeholder="United States"
                        id="countryName"
                        value={values.countryName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.countryName && touched.countryName && (
                        <div className="error-message">{errors.countryName}</div>
                      )}
                    </Form.Group>
                    <Form.Group>
                      <Form.Label
                        className="font-weight-bold">Abbrv (2 letters)
                      </Form.Label>
                      <Form.Control
                        className="form-input border-radius-4"
                        type="text"
                        placeholder="US"
                        id="isO2"
                        value={values.isO2}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.isO2 && touched.isO2 && (
                        <div className="error-message">{errors.isO2}</div>
                      )}
                    </Form.Group>
                    <Form.Group>
                      <Form.Label
                        className="font-weight-bold">Full Country Name
                      </Form.Label>
                      <Form.Control
                        className="form-input border-radius-4"
                        type="text"
                        placeholder="United States of America"
                        id="longCountryName"
                        value={values.longCountryName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.longCountryName && touched.longCountryName && (
                        <div className="error-message">{errors.longCountryName}</div>
                      )}
                    </Form.Group>
                    <Form.Group>
                      <Form.Label
                        className="font-weight-bold">Abbrv (3 letters)
                      </Form.Label>
                      <Form.Control
                        className="form-input border-radius-4"
                        type="text"
                        placeholder="USA"
                        id="isO3"
                        value={values.isO3}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.isO3 && touched.isO3 && (
                        <div className="error-message">{errors.isO3}</div>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={6} sm={12}>
                    <Form.Group>
                      <Form.Label
                        className="font-weight-bold">Code
                      </Form.Label>
                      <Form.Control
                        className="form-input border-radius-4"
                        type="text"
                        placeholder="840"
                        id="numCode"
                        value={values.numCode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.numCode && touched.numCode && (
                        <div className="error-message">{errors.numCode}</div>
                      )}
                    </Form.Group>
                    <Form.Group>
                      <Form.Label
                        className="font-weight-bold">Calling Code
                      </Form.Label>
                      <Form.Control
                        className="form-input border-radius-4"
                        type="text"
                        placeholder="1"
                        id="callingCode"
                        value={values.callingCode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.callingCode && touched.callingCode && (
                        <div className="error-message">{errors.callingCode}</div>
                      )}
                    </Form.Group>
                    <Form.Group>
                      <Form.Label
                        className="font-weight-bold">Country Top-Level Domain
                      </Form.Label>
                      <Form.Control
                        className="form-input border-radius-4"
                        type="text"
                        placeholder=".us"
                        id="cctld"
                        value={values.cctld}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.cctld && touched.cctld && (
                        <div className="error-message">{errors.cctld}</div>
                      )}
                    </Form.Group>
                    <Form.Group className="mb-4">
                      <Form.Label className="font-weight-bold">International Region</Form.Label>
                      <Form.Control
                        as="select"
                        id="internationalRegion"
                        className="form-input border-radius-4"
                        value={values.internationalRegion}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option key={-1} value="">Select a Region</option>
                        {
                          regions ? regions.map((item, i) =>
                              <option key={i} value={item}>{item}</option>)
                            : ''
                        }
                      </Form.Control>
                      {errors.internationalRegion && touched.internationalRegion && (
                        <div className="error-message">{errors.internationalRegion}</div>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn btn-form-app btn-option border-radius-4" type="submit" form={formAddCountryId}
                disabled={inProgress === true}>
          {
            inProgress ?
              <Spinner
                animation="grow"
                variant="secondary"
                style={{marginRight: '5px', marginBottom: '4px'}}
                size="sm"
              />
              : ''
          }
          Accept
          <FontAwesomeIcon icon="arrow-right" className="icon-right"/>
        </Button>
        <Button onClick={props.onHide} className="btn btn-outline-dark btn-form-app border-radius-4">Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

const DeleteCountry = (props) => {
  const {
    selectedCountry, inProgress, submitDeleteCountry, ...rest
  } = props;
  return (
    <Modal
      {...rest}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-app"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="app-header">
          Delete Country
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Label>
          Sure you want to delete the country&nbsp;
          <span className="font-weight-bold">{selectedCountry ? selectedCountry.countryName.toUpperCase() : ''}</span>?
        </Form.Label>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="btn btn-form-app btn-option border-radius-4"
          type="submit"
          onClick={() => submitDeleteCountry(selectedCountry.id)}
        >
          {
            inProgress ?
              <Spinner
                animation="grow"
                variant="secondary"
                style={{marginRight: '5px', marginBottom: '4px'}}
                size="sm"
              />
              : ''
          }
          Accept
          <FontAwesomeIcon icon="arrow-right" className="icon-right"/>
        </Button>
        <Button onClick={props.onHide} className="btn btn-outline-dark btn-form-app border-radius-4">Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

class Countries extends Component {
  render() {
    const {
      inProgress, filteredCountries, selectedCountry, submitSearch, regions,
      openAddCountry, showAddCountry, closeAddCountry, submitAddCountry,
      openEditCountry, showEditCountry, closeEditCountry, submitEditCountry, onUpdateFilterCountry,
      openDeleteCountry, showDeleteCountry, closeDeleteCountry, submitDeleteCountry,
    } = this.props;
    return (
      <Row className="transferences-super-container">
        <Col sm={12}>
          <div className="pl-5-desktop-transferences">
            <h4 className="mb-4">Countries</h4>
            <Button
              variant="light"
              onClick={openAddCountry}
              className="header-toolbar-btn border-radius-4"
            >
              <FontAwesomeIcon icon="plus" className="icon"/>
              Add
            </Button>
            <Formik
              initialValues={{countryName: ''}}
              onSubmit={() => submitSearch()}
            >
              {props => {
                const {
                  touched,
                  errors,
                  handleSubmit,
                  values,
                  handleChange,
                  handleBlur
                } = props;
                return (
                  <Form className="mt-4" onSubmit={handleSubmit}>
                    <div className="px-4 py-4 mb-4" style={{border: '1px solid rgba(0,0,0,.1)'}}>
                      <Row className="text-left">
                        <Col sm={12} md={4}>
                          <Form.Group className="mb-4">
                            <Form.Label className="font-weight-bold">Country</Form.Label>
                            <Form.Control
                              className="form-input border-radius-4"
                              type="text"
                              id="countryName"
                              value={values.countryName}
                              onChange={(ev) => {
                                handleChange(ev);
                                onUpdateFilterCountry(ev.target.value);
                              }}
                              onBlur={handleBlur}
                            />
                            {errors.countryName && touched.countryName && (
                              <div className="error-message">{errors.countryName}</div>
                            )}
                          </Form.Group>
                        </Col>
                        <Col sm={12} md={6}>
                          <Form.Group className="mb-3">
                            <Button
                              className="btn btn-black btn-inline border-radius-4 search-button-transactions"
                              type="submit"
                              disabled={inProgress}
                            >
                              <FontAwesomeIcon icon="search" size="sm" className="o-btn-icon"/>
                              Search
                            </Button>
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                    <ReactTable
                      ref={(instance) => {
                        this.table = instance;
                      }}
                      columns={[
                        {
                          Header: "Name",
                          accessor: "countryName",
                        },
                        {
                          Header: "Full Name",
                          accessor: "longCountryName",
                        },
                        {
                          Header: "Region",
                          accessor: "internationalRegion",
                        },
                        {
                          Header: "Abbrv2",
                          accessor: "isO2",
                        },
                        {
                          Header: "Calling Code",
                          accessor: "callingCode",
                        },
                        {
                          Header: "Edit",
                          accessor: "d",
                          Cell: ({row}) => (
                            <Button
                              variant="link" className="btn-row border-radius-4"
                              onClick={() => openEditCountry(row._original)}>
                              <FontAwesomeIcon icon="pencil-alt"/>
                            </Button>),
                          sortable: false
                        },
                        {
                          Header: "Delete",
                          accessor: "d",
                          Cell: ({row}) => (
                            <Button
                              variant="link" className="btn-row border-radius-4"
                              onClick={() => openDeleteCountry(row._original)}>
                              <FontAwesomeIcon icon="trash"/>
                            </Button>),
                          sortable: false
                        }
                      ]}
                      defaultSorted={[
                        {
                          id: "countryName",
                          desc: false
                        }
                      ]}
                      data={filteredCountries}
                      defaultPageSize={20}
                      pageSizeOptions={[5, 10, 20]}
                      className="-striped -highlight"
                      minRows={0}
                      NoDataComponent={() => null}
                    >
                      {(state, makeTable) => {
                        return (
                          <div>
                            {makeTable()}
                            <TableInfo
                              totalRows={state.sortedData.length}
                              page={state.page}
                              pageSize={state.pageSize}
                            />
                          </div>
                        )
                      }}
                    </ReactTable>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </Col>
        <AddCountry
          inProgress={inProgress}
          show={showAddCountry}
          onHide={closeAddCountry}
          submitAddCountry={submitAddCountry}
          regions={regions}
        />
        <DeleteCountry
          inProgress={inProgress}
          show={showDeleteCountry}
          onHide={closeDeleteCountry}
          submitDeleteCountry={submitDeleteCountry}
          selectedCountry={selectedCountry}
        />
        <EditCountry
          inProgress={inProgress}
          show={showEditCountry}
          onHide={closeEditCountry}
          submitEditCountry={submitEditCountry}
          selectedCountry={selectedCountry}
          regions={regions}
        />
      </Row>
    );
  }
}

export default Countries;