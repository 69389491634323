import React, {Component} from 'react';
import ReactTable from "react-table";
import "react-table/react-table.css";
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import {Formik} from "formik";
import moment from 'moment';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import TableInfo from '../TableInfo';

const NautaRechargeDetail = (props) => {
  const {selectedNautaRecharge, ...rest} = props;
  const cubacelOrder = selectedNautaRecharge && selectedNautaRecharge.cubacelOrder ? JSON.parse(selectedNautaRecharge.cubacelOrder) : null;
  return (
    <Modal
      {...rest}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-app"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="app-header">
          Recharges Detail
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
          cubacelOrder !== null && cubacelOrder.length > 0 ?
            <ReactTable
              columns={[
                {
                  Header: "Phone",
                  accessor: "phone",
                },
                {
                  Header: "Amount",
                  id: "amount",
                  accessor: d => parseFloat(d.amount).toFixed(2),

                },
                {
                  Header: "Status",
                  accessor: "status",
                },
                {
                  Header: "Receipt",
                  accessor: "receipt",
                },
              ]}
              defaultSorted={[
                {
                  id: "phone",
                  desc: false
                }
              ]}
              data={cubacelOrder}
              pageSize={cubacelOrder.length}
              className="-striped -highlight"
              showPaginationBottom={false}
            /> : ''
        }
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className="btn btn-outline-dark btn-form-app border-radius-4">Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

class NautaRecharges extends Component {
  updateTableData = () => this.table.fireFetchData();

  render() {
    const {
      inProgress, loadNautaRecharges, nautaRechargesState, selectedNautaRecharge,
      openNautaRechargeDetail, showNautaRechargeDetail, closeNautaRechargeDetail,
    } = this.props;
    return (
      <Row className="transferences-super-container">
        <Col sm={12}>
          <div className="pl-5-desktop-transferences">
            <h4 className="mb-4">Nauta Recharges</h4>

            <Formik
              initialValues={{userName: ''}}
              onSubmit={(values) => this.updateTableData(values.userName)}
            >
              {props => {
                const {
                  touched,
                  errors,
                  handleSubmit,
                  values,
                  handleChange,
                  handleBlur
                } = props;
                return (
                  <Form className="mt-4" onSubmit={handleSubmit}>
                    <div className="px-4 py-4 mb-4" style={{border: '1px solid rgba(0,0,0,.1)'}}>
                      <Row className="text-left">
                        <Col sm={12} md={4}>
                          <Form.Label className="font-weight-bold">User</Form.Label>
                          <Form.Group className="mb-4">
                            <Form.Control
                              className="form-input border-radius-4"
                              type="text"
                              id="userName"
                              value={values.userName}
                              placeholder="User"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.userName && touched.userName && (
                              <div className="error-message">{errors.userName}</div>
                            )}
                          </Form.Group>
                        </Col>
                        <Col sm={12} md={4}>
                          <Form.Group className="mb-3">
                            <Button
                              className="btn btn-black btn-inline border-radius-4 search-button-transactions"
                              type="submit"
                              disabled={inProgress}
                            >
                              <FontAwesomeIcon icon="search" size="sm" className="o-btn-icon"/>
                              Search
                            </Button>
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                    <ReactTable
                      ref={(instance) => {
                        this.table = instance;
                      }}
                      columns={[
                        {
                          Header: "Date and Time",
                          id: "date",
                          accessor: d => moment(d.date).format('YYYY-MM-DD HH:mm'),
                        },
                        {
                          Header: "From",
                          accessor: "from",
                        },
                        {
                          Header: "Username",
                          id: "userName",
                          accessor: d => d.transactionUser ? d.transactionUser.userName : '',
                        },
                        {
                          Header: "Status",
                          accessor: "status",
                        },
                        {
                          Header: "Recharges",
                          id: "cubacelOrder",
                          accessor: d => d.cubacelOrder ? JSON.parse(d.cubacelOrder).length : '0',
                        },
                        {
                          Header: "Amount",
                          id: "amount",
                          accessor: d => {
                            let amount = 0;
                            if (d.cubacelOrder) {
                              const array = JSON.parse(d.cubacelOrder);
                              for (let i = 0; i < array.length; i++)
                                amount += parseFloat(array[i].amount);
                            }
                            return amount.toFixed(2);
                          },
                        },
                        {
                          Header: "Recharges",
                          accessor: "d",
                          Cell: ({row}) => (
                            row._original.cubacelOrder && row._original.cubacelOrder.length > 0 ?
                              <Button
                                variant="link" className="btn-row border-radius-4"
                                onClick={() => openNautaRechargeDetail(row._original)}>
                                <FontAwesomeIcon icon="sticky-note"/>
                              </Button> : ''),
                          sortable: false
                        }
                      ]}
                      defaultSorted={[
                        {
                          id: "date",
                          desc: false
                        }
                      ]}
                      manual
                      data={nautaRechargesState.data}
                      pages={nautaRechargesState.pages}
                      loading={inProgress}
                      onFetchData={(state) => loadNautaRecharges(state)}
                      defaultPageSize={nautaRechargesState.pageSize}
                      pageSizeOptions={[5, 10, 20]}
                      className="-striped -highlight"
                      filter={{userName: values.userName}}
                      minRows={0}
                      NoDataComponent={() => null}
                    >
                      {(state, makeTable) => {
                        return (
                          <div>
                            {makeTable()}
                            <TableInfo
                              totalRows={nautaRechargesState.total}
                              page={state.page}
                              pageSize={state.pageSize}
                            />
                          </div>
                        )
                      }}
                    </ReactTable>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </Col>
        <NautaRechargeDetail
          show={showNautaRechargeDetail}
          onHide={closeNautaRechargeDetail}
          selectedNautaRecharge={selectedNautaRecharge}
        />
      </Row>
    );
  }
}

export default NautaRecharges;