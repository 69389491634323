import React, {Component} from 'react';
import ReactTable from "react-table";
import "react-table/react-table.css";
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import {Formik} from "formik";
import moment from 'moment';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Datepicker} from "react-formik-ui";
import * as Yup from "yup";
import TableInfo from '../TableInfo';

const PaymentNotificationDetail = (props) => {
  const {selectedPaymentNotification, ...rest} = props;
  return (
    <Modal
      {...rest}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-app"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="app-header">
          Note
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <Form.Control
          as="textarea"
          rows="3"
          disabled={true}
          value={selectedPaymentNotification && selectedPaymentNotification !== null ? selectedPaymentNotification.note : ''}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className="btn btn-outline-dark btn-form-app border-radius-4">Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

class PaymentNotifications extends Component {
  updateTableData = () => this.table.fireFetchData();

  render() {
    const {
      inProgress, loadPaymentNotifications, paymentNotificationsState, selectedPaymentNotification,
      openPaymentNotificationDetail, showPaymentNotificationDetail, closePaymentNotificationDetail,
    } = this.props;
    return (
      <Row className="transferences-super-container">
        <Col sm={12}>
          <div className="pl-5-desktop-transferences">
            <h4 className="mb-4">Payment Notifications</h4>

            <Formik
              initialValues={{
                fromDate: moment().subtract(1, 'month'),
                toDate: moment(),
                userName: '',
              }}
              onSubmit={(values) => this.updateTableData(
                values.fromDate,
                values.toDate,
                values.userName,
              )}
              validationSchema={Yup.object().shape({
                fromDate: Yup.date().typeError('Invalid date'),
                toDate: Yup.date().typeError('Invalid date'),
              })}
            >
              {props => {
                const {
                  touched,
                  errors,
                  handleSubmit,
                  values,
                  handleChange,
                  handleBlur
                } = props;
                return (
                  <Form className="mt-4" onSubmit={handleSubmit}>
                    <div className="px-4 py-4 mb-4" style={{border: '1px solid rgba(0,0,0,.1)'}}>
                      <Row className="text-left">
                        <Col sm={12} md={4}>
                          <Form.Label className="font-weight-bold">From</Form.Label>
                          <Form.Group className="mb-4">
                            <Datepicker
                              className="form-input form-control border-radius-4"
                              name="fromDate"
                              placeholder="From"
                              dateFormat="yyyy-MM-dd"
                            />
                            {errors.fromDate && touched.fromDate && (
                              <div className="error-message">{errors.fromDate}</div>
                            )}
                          </Form.Group>
                        </Col>
                        <Col sm={12} md={4}>
                          <Form.Label className="font-weight-bold">To</Form.Label>
                          <Form.Group className="mb-4">
                            <Datepicker
                              className="form-input form-control border-radius-4"
                              name="toDate"
                              placeholder="To"
                              dateFormat="yyyy-MM-dd"
                            />
                            {errors.toDate && touched.toDate && (
                              <div className="error-message">{errors.toDate}</div>
                            )}
                          </Form.Group>
                        </Col>
                        <Col sm={12} md={4}>
                          <Form.Label className="font-weight-bold">User</Form.Label>
                          <Form.Group className="mb-4">
                            <Form.Control
                              className="form-input border-radius-4"
                              type="text"
                              id="userName"
                              value={values.userName}
                              placeholder="User"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.userName && touched.userName && (
                              <div className="error-message">{errors.userName}</div>
                            )}
                          </Form.Group>
                        </Col>
                        <Col sm={12} md={4}>
                          <Form.Group className="mb-3">
                            <Button
                              className="btn btn-black btn-inline border-radius-4 search-button-transactions"
                              type="submit"
                              disabled={inProgress}
                            >
                              <FontAwesomeIcon icon="search" size="sm" className="o-btn-icon"/>
                              Search
                            </Button>
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                    <ReactTable
                      ref={(instance) => {
                        this.table = instance;
                      }}
                      columns={[
                        {
                          Header: "Date and Time",
                          id: "date",
                          accessor: d => moment(d.date).format('YYYY-MM-DD HH:mm'),
                          sortable: false
                        },
                        {
                          Header: "User",
                          accessor: "payerUser",
                          sortable: false
                        },
                        {
                          Header: "Reseller",
                          accessor: "resellerUserName",
                          sortable: false
                        },
                        {
                          Header: "Amount",
                          id: "amount",
                          accessor: d => d.amount.toFixed(2),
                          sortable: false
                        },
                        {
                          Header: "Detail",
                          accessor: "d",
                          Cell: ({row}) => row._original.note !== null && row._original.note !== '' ?
                            (<Button
                              variant="link" className="btn-row border-radius-4"
                              onClick={() => openPaymentNotificationDetail(row._original)}>
                              <FontAwesomeIcon icon="sticky-note"/>
                            </Button>) : '',
                          sortable: false
                        }
                      ]}
                      manual
                      data={paymentNotificationsState.data}
                      pages={paymentNotificationsState.pages}
                      loading={inProgress}
                      onFetchData={(state) => loadPaymentNotifications(state)}
                      defaultPageSize={paymentNotificationsState.pageSize}
                      pageSizeOptions={[5, 10, 20]}
                      className="-striped -highlight"
                      filter={{
                        fromDate: values.fromDate,
                        toDate: values.toDate,
                        userName: values.userName,
                      }}
                      minRows={0}
                      NoDataComponent={() => null}
                    >
                      {(state, makeTable) => {
                        return (
                          <div>
                            {makeTable()}
                            <TableInfo
                              totalRows={paymentNotificationsState.total}
                              page={state.page}
                              pageSize={state.pageSize}
                            />
                          </div>
                        )
                      }}
                    </ReactTable>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </Col>
        <PaymentNotificationDetail
          show={showPaymentNotificationDetail}
          onHide={closePaymentNotificationDetail}
          selectedPaymentNotification={selectedPaymentNotification}
        />
      </Row>
    );
  }
}

export default PaymentNotifications;