import React, {Component} from 'react';
import {Row, Col} from "react-bootstrap";

class Activation extends Component {
  render() {
    return (
      <div>
        <div className="page-top">
          <Row className="vertical-align">
            <Col xs={12} md={6}>
              <div className="font-white">
                <h3 className="c-caption-title">Activation</h3>
                <p className="c-caption-text">Activate your sim and handsets. **Only available in Cuba</p>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default Activation;