import React, {Component} from 'react';
import {Col, Row, Form, Button, Modal} from "react-bootstrap";
import moment from 'moment';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Formik} from "formik";
import ReactTable from "react-table";
import TableInfo from '../TableInfo';

const PaymentNotificationDetail = (props) => {
  const {paymentNotificationDetail, ...rest} = props;
  return (
    <Modal
      {...rest}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-app"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="app-header">
          Note
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Control
          as="textarea"
          rows="3"
          disabled={true}
          value={paymentNotificationDetail !== null ? paymentNotificationDetail : ''}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className="btn btn-outline-dark btn-form-app border-radius-4">Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

class PaymentNotifications extends Component {
  updateTableData = () => this.table.fireFetchData();

  render() {
    const {
      inProgress, loadPaymentNotifications, paymentNotificationsState, showPaymentNotificationDetail,
      paymentNotificationDetail, openPaymentNotificationDetail, closePaymentNotificationDetail
    } = this.props;
    return (
      <Row className="payment-notifications-super-container">
        <Col sm={12}>
          <div className="pl-5-desktop">
            <h4 className="mb-4">Payment Notifications</h4>
            <Formik
              onSubmit={() => this.updateTableData()}
            >
              {props => {
                const {
                  handleSubmit,
                } = props;
                return (
                  <Form className="mt-4 mb-3" onSubmit={handleSubmit}>
                    <ReactTable
                      ref={(instance) => {
                        this.table = instance;
                      }}
                      columns={[
                        {
                          Header: "Reseller",
                          accessor: "resellerUserName",
                          sortable: false
                        },
                        {
                          Header: "Date",
                          id: "date",
                          accessor: d => moment(d.date).format('YYYY-MM-DD'),
                          sortable: false
                        },
                        {
                          Header: "Amount",
                          id: "amount",
                          accessor: d => d.amount.toFixed(2),
                          sortable: false
                        },
                        {
                          Header: "Note",
                          accessor: "note",
                          Cell: ({value}) => (value !== '' ?
                            <Button
                              variant="link" className="btn-row border-radius-4"
                              onClick={() => openPaymentNotificationDetail(value)}>
                              <FontAwesomeIcon icon="sticky-note"/>
                            </Button> : ''),
                          sortable: false
                        }
                      ]}
                      manual
                      data={paymentNotificationsState.data.paymentnotifications}
                      pages={paymentNotificationsState.pages}
                      loading={inProgress}
                      onFetchData={(state) => loadPaymentNotifications(state)}
                      defaultPageSize={paymentNotificationsState.pageSize}
                      pageSizeOptions={[5, 10, 20]}
                      className="-striped -highlight"
                      minRows={0}
                      NoDataComponent={() => null}
                    >
                      {(state, makeTable) => {
                        return (
                          <div>
                            {makeTable()}
                            <TableInfo
                              totalRows={paymentNotificationsState.total}
                              page={state.page}
                              pageSize={state.pageSize}
                            />
                          </div>
                        )
                      }}
                    </ReactTable>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </Col>
        <PaymentNotificationDetail
          show={showPaymentNotificationDetail}
          onHide={closePaymentNotificationDetail}
          paymentNotificationDetail={paymentNotificationDetail}
        />
      </Row>
    );
  }
}

export default PaymentNotifications;