import {
  APP_LOAD,
  LOAD_USER,
  REDIRECT,
  LOGOUT,
  LOGIN,
  REGISTER,
  LOGOUT_TO_LOGIN,
  RESET_ACTION,
  RESET_PASSWORD,
  UPDATE_FIELD_COMMON,
  APP_INITILIZE,
  ADMIN_CHECK_PIN_NUMBER,
} from '../constants/actionTypes';

import homeImage from "../assets/img/common/header/bottom-nav-bar/baseline-home-24px.svg";
import payImage from "../assets/img/common/header/bottom-nav-bar/pay.png";
import repairImage from "../assets/img/common/header/bottom-nav-bar/repair.png";
import unlockImage from "../assets/img/common/header/bottom-nav-bar/unlock.png";
import activationImage from "../assets/img/common/header/bottom-nav-bar/activation.png";
import buyImage from "../assets/img/common/header/bottom-nav-bar/buy.png";
import sellImage from "../assets/img/common/header/bottom-nav-bar/sell.png";
import adminImage from "../assets/img/common/header/bottom-nav-bar/admin.png";

const defaultState = {
  appName: 'Cellshop',
  token: null
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case APP_INITILIZE:
      return {
        appMenu: [
          {text: 'Home', redirectTo: "/home", image: homeImage, className: "home-image-mobile-menu", disabled: false},
          {text: 'Pay', redirectTo: "/pay", image: payImage, disabled: false},
          {text: 'Repair', redirectTo: "/repair", image: repairImage, disabled: true},
          {text: 'Unlock', redirectTo: "/unlock", image: unlockImage, disabled: true},
          {text: 'Activation', redirectTo: "/activation", image: activationImage, disabled: true},
          {text: 'Buy', redirectTo: "/buy", image: buyImage, disabled: true},
          {text: 'Sell', redirectTo: "/sell", image: sellImage, disabled: true},
          {text: 'Admin', redirectTo: "/admin", image: adminImage, disabled: false},
        ],
      };

    case APP_LOAD:
      return {
        ...state,
        token: action.token || null,
        appLoaded: true,
        pinChecked: false,
      };

    case ADMIN_CHECK_PIN_NUMBER:
      return {
        ...state,
        pinChecked: !action.error,
      };

    case UPDATE_FIELD_COMMON:
      return {...state, [action.key]: action.value};

    case LOAD_USER:
      let currentUser = action.payload ? action.payload.body : null;
      let isAdmin = false;
      if (currentUser !== null) {
        const userRoles = currentUser.roles;
        for (let i = 0; i < userRoles.length; i++) {
          if (userRoles[i] === 'Admin') {
            isAdmin = true;
            break;
          }
        }
      }
      currentUser.isAdmin = isAdmin;
      return {
        ...state,
        currentUser
      };

    case REDIRECT:
      return {...state, redirectTo: null};

    case LOGOUT:
      return {
        ...state,
        redirectTo: '/',
        token: null,
        currentUser: null,
        appLoaded: true,
        pinChecked: false,
      };

    case LOGOUT_TO_LOGIN:
      return {
        ...state,
        redirectTo: '/login',
        token: null,
        currentUser: null,
        appLoaded: true,
        pinChecked: false,
      };

    case LOGIN:
      return {
        ...state,
        redirectTo: action.error ? null : '/',
        token: action.error ? null : action.payload.body.access_token,
      };

    case REGISTER:
      return {
        ...state,
        redirectTo: action.error ? null : '/login',
        lastAction: REGISTER
      };

    case RESET_PASSWORD:
      return {
        ...state,
        redirectTo: action.error ? null : '/login',
        lastAction: action.error ? null : RESET_PASSWORD,
      };

    case RESET_ACTION:
      return {
        ...state,
        inProgress: false,
        errors: null,
        lastAction: null
      };

    default:
      return state;
  }
};
