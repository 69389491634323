import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import {makeStyles, useTheme} from '@material-ui/core/styles';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {NavLink} from 'react-router-dom';

import logo from "../../assets/img/logo.png";

import affiliatesImage from "../../assets/img/profile/affiliates.svg";
import referralsImage from "../../assets/img/profile/referrals.svg";
import batchPaymentsImage from "../../assets/img/profile/batchPayments.svg";
import paymentNotificationsImage from "../../assets/img/profile/paymentNotifications.svg";
import resellerInfoImage from "../../assets/img/profile/resellerInfo.svg";
import transactionsImage from "../../assets/img/profile/transactions.svg";
import transfersImage from "../../assets/img/profile/transfers.svg";

import {
  LOGOUT,
  LOAD_USER,
  PROFILE_LOAD_TAB_REFERRALS,
  PROFILE_LOAD_TAB_TRANSFERENCES,
  PROFILE_LOAD_TAB_TRANSACTIONS,
  PROFILE_LOAD_TAB_BATCH_PAYMENTS,
  PROFILE_LOAD_TAB_PAYMENT_NOTIFICATIONS,
  PROFILE_LOAD_TAB_RESELLER_INFO,
  PROFILE_LOAD_TAB_AFFILIATES,
  UPDATE_FIELD_PROFILE
} from '../../constants/actionTypes';
import {connect} from "react-redux";

import agent from "../../agent";

import './HeaderUI.css';
import {OverlayTrigger, Tooltip} from "react-bootstrap";

const drawerWidth = 260;

const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch => ({
  onClickLogout: (ev) => {
    ev.preventDefault();
    dispatch({type: LOGOUT})
  },
  onProfileActiveTab: (value) => {
    if (value === 'profile')
      dispatch({type: LOAD_USER, payload: agent.Auth.current()});
    else if (value === 'referrals')
      dispatch({type: PROFILE_LOAD_TAB_REFERRALS});
    else if (value === 'transferences')
      dispatch({type: PROFILE_LOAD_TAB_TRANSFERENCES});
    else if (value === 'transactions')
      dispatch({type: PROFILE_LOAD_TAB_TRANSACTIONS});
    else if (value === 'batchPayments')
      dispatch({type: PROFILE_LOAD_TAB_BATCH_PAYMENTS});
    else if (value === 'paymentNotifications')
      dispatch({type: PROFILE_LOAD_TAB_PAYMENT_NOTIFICATIONS});
    else if (value === 'resellerInfo')
      dispatch({type: PROFILE_LOAD_TAB_RESELLER_INFO});
    else if (value === 'affiliates')
      dispatch({type: PROFILE_LOAD_TAB_AFFILIATES});
    dispatch({type: UPDATE_FIELD_PROFILE, key: 'currentTab', value});
  }
});

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

function ResponsiveDrawer(props) {

  const {container} = props;
  const {currentUser, appMenu} = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen);
  }

  const drawer = (

    <div>
      <div className={classes.toolbar}/>
      <Divider/>
      <div className="logo-container-navigation-drawer">
        <img src={logo} alt="" className=""/>
      </div>
      <List>
        {
          appMenu.map((item, index) => {
            if (item.disabled)
              return (
                <NavLink
                  key={index} to={item.redirectTo} className="menu-item-line disabled"
                  activeClassName='is-active-link'
                  onClick={(ev) => ev.preventDefault()}>
                  <div className={`menu-item-line-image menu-item-image-${index}`}>
                    <img src={item.image} alt=""/>
                  </div>
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id={`tooltip-right`}>
                        coming soon
                      </Tooltip>
                    }
                  >
                    <div className="menu-item-line-text">
                      <span className="menu-item-line-text-content">{item.text}</span>
                    </div>
                  </OverlayTrigger>
                </NavLink>
              );
            else if (item.text === "Admin") {
              if (currentUser && currentUser.isAdmin)
                return (
                  <NavLink
                    key={index} to={item.redirectTo} className="menu-item-line"
                    activeClassName='is-active-link'
                    onClick={handleDrawerToggle}>
                    <div className={`menu-item-line-image menu-item-image-${index}`}>
                      <img src={item.image} alt=""/>
                    </div>
                    <div className="menu-item-line-text">
                      <span className="menu-item-line-text-content">{item.text}</span>
                    </div>
                  </NavLink>
                );
            } else return (
              <NavLink
                key={index} to={item.redirectTo} className="menu-item-line"
                activeClassName='is-active-link'
                onClick={handleDrawerToggle}>
                <div className={`menu-item-line-image menu-item-image-${index}`}>
                  <img src={item.image} alt=""/>
                </div>
                <div className="menu-item-line-text">
                  <span className="menu-item-line-text-content">{item.text}</span>
                </div>
              </NavLink>
            );
            return null;
          })
        }
      </List>
      <Divider/>
      <List>
        {
          !currentUser ?
            <div className="login-register-container login-register-container-mobile">
              <NavLink to="/login" className="menu-item-line login-register-mobile-view"
                       activeClassName='is-active-link' onClick={handleDrawerToggle}>
                <div className={`menu-item-line-image menu-item-image-10`}>
                  <FontAwesomeIcon icon="user" size="1x" className="icon-md color-dark-gray"/>
                </div>
                <div className="menu-item-line-text">
                  <span className="menu-item-line-text-content login-font-awesome-content">Login</span>
                </div>
              </NavLink>
              <NavLink to="/register" className="menu-item-line login-register-mobile-view"
                       activeClassName='is-active-link' onClick={handleDrawerToggle}>
                <div className={`menu-item-line-image menu-item-image-10`}>
                  <FontAwesomeIcon icon="pencil-alt" size="1x" className="icon-md color-dark-gray"/>
                </div>
                <div className="menu-item-line-text">
                  <span className="menu-item-line-text-content">Register</span>
                </div>
              </NavLink>
            </div>
            :
            <div className="login-register-container login-register-container-mobile">
              {/* <NavLink to="/profile" className="menu-item-line" onClick={handleDrawerToggle}>
                <div className={`menu-item-line-image menu-item-image-10`}>
                  <FontAwesomeIcon icon="user" size="1x" className="icon-md color-dark-gray"/>
                </div>
                <div className="menu-item-line-text">
                  <span
                    className="menu-item-line-text-content login-font-awesome-content">{currentUser.firstName}</span>
                </div>
              </NavLink> */}
              <NavLink to="/profile" className="menu-item-line" onClick={() => {
                props.onProfileActiveTab("profile");
                handleDrawerToggle();
              }}>
                <div className={`menu-item-line-image menu-item-image-10`}>
                  <FontAwesomeIcon icon="user" size="1x" className="icon-md color-dark-gray"/>
                </div>
                <div className="menu-item-line-text">
                  <span
                    className="menu-item-line-text-content login-font-awesome-content">My Profile</span>
                </div>
              </NavLink>
              <NavLink to="/profile" className="menu-item-line" onClick={() => {
                props.onProfileActiveTab("affiliates");
                handleDrawerToggle();
              }}>
                <div className={`menu-item-line-image menu-item-image-11`}>
                  <img src={affiliatesImage} alt="" className="profile-image-nav affiliate-image-nav-menu"/>
                </div>
                <div className="menu-item-line-text">
                  <span
                    className="menu-item-line-text-content login-font-awesome-content">Affiliates</span>
                </div>
              </NavLink>
              <NavLink to="/profile" className="menu-item-line" onClick={() => {
                props.onProfileActiveTab("referrals");
                handleDrawerToggle();
              }}>
                <div className={`menu-item-line-image menu-item-image-12`}>
                  <img src={referralsImage} alt="" className="profile-image-nav referral-image-nav-menu"/>
                </div>
                <div className="menu-item-line-text">
                  <span
                    className="menu-item-line-text-content login-font-awesome-content">Referrals</span>
                </div>
              </NavLink>
              <NavLink to="/profile" className="menu-item-line" onClick={() => {
                props.onProfileActiveTab("transactions");
                handleDrawerToggle();
              }}>
                <div className={`menu-item-line-image menu-item-image-13`}>
                  <img src={transactionsImage} alt="" className="profile-image-nav transactions-image-nav-menu"/>
                </div>
                <div className="menu-item-line-text">
                  <span
                    className="menu-item-line-text-content login-font-awesome-content">Transactions</span>
                </div>
              </NavLink>
              <NavLink to="/profile" className="menu-item-line" onClick={() => {
                props.onProfileActiveTab("transferences");
                handleDrawerToggle();
              }}>
                <div className={`menu-item-line-image menu-item-image-14`}>
                  <img src={transfersImage} alt="" className="profile-image-nav transferences-image-nav-menu"/>
                </div>
                <div className="menu-item-line-text">
                  <span
                    className="menu-item-line-text-content login-font-awesome-content">Transferences</span>
                </div>
              </NavLink>
              <NavLink to="/profile" className="menu-item-line" onClick={() => {
                props.onProfileActiveTab("batchPayments");
                handleDrawerToggle();
              }}>
                <div className={`menu-item-line-image menu-item-image-15`}>
                  <img src={batchPaymentsImage} alt="" className="profile-image-nav batch-payments-image-nav-menu"/>
                </div>
                <div className="menu-item-line-text">
                  <span
                    className="menu-item-line-text-content login-font-awesome-content">Batch Payments</span>
                </div>
              </NavLink>
              <NavLink to="/profile" className="menu-item-line" onClick={() => {
                props.onProfileActiveTab("paymentNotifications");
                handleDrawerToggle();
              }}>
                <div className={`menu-item-line-image menu-item-image-16`}>
                  <img src={paymentNotificationsImage} alt=""
                       className="profile-image-nav payments-notifications-image-nav-menu"/>
                </div>
                <div className="menu-item-line-text">
                  <span
                    className="menu-item-line-text-content login-font-awesome-content">Payment Notifications</span>
                </div>
              </NavLink>
              <NavLink to="/profile" className="menu-item-line" onClick={() => {
                props.onProfileActiveTab("resellerInfo");
                handleDrawerToggle();
              }}>
                <div className={`menu-item-line-image menu-item-image-17`}>
                  <img src={resellerInfoImage} alt="" className="profile-image-nav reseller-info-image-nav-menu"/>
                </div>
                <div className="menu-item-line-text">
                  <span
                    className="menu-item-line-text-content login-font-awesome-content">Reseller Info</span>
                </div>
              </NavLink>
              <NavLink to="/logout" className="menu-item-line login-register-link" onClick={props.onClickLogout}>
                <div className={`menu-item-line-image menu-item-image-18`}>
                  <FontAwesomeIcon icon="pencil-alt" size="1x" className="icon-md color-dark-gray"/>
                </div>
                <div className="menu-item-line-text">
                  <span className="menu-item-line-text-content">Logout</span>
                </div>
              </NavLink>
            </div>
        }
      </List>
    </div>
  );

  return (
    <div className={classes.root}>
      <img src={logo} alt="" className="tn-logo display-none logo-image-cell-shop"/>
      <CssBaseline/>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>

          <IconButton
            color="inherit"
            aria-label="Open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon/>
          </IconButton>
          {!currentUser ?
            <div className="text-right login-register-container display-none-mobile">
              <NavLink to="/login" className="mr-4 tn-link login-register-link" activeClassName='is-active-link'>
                <FontAwesomeIcon icon="user" size="1x" className="icon-md color-dark-gray"/><span
                className="login-register-text">Login</span>
              </NavLink>
              <NavLink to="/register" className="tn-link login-register-link" activeClassName='is-active-link'>
                <FontAwesomeIcon icon="pencil-alt" size="1x" className="icon-md color-dark-gray"/><span
                className="login-register-text">Register</span>
              </NavLink>
            </div>
            :
            <div className="text-right login-register-container display-none-mobile">
              <NavLink to="/profile" className="mr-4 tn-link login-register-link">
                <FontAwesomeIcon icon="user" size="1x" className="icon-md color-dark-gray"/>{currentUser.firstName}
              </NavLink>
              <NavLink to="/logout" className="tn-link login-register-link" onClick={props.onClickLogout}>
                {/* <FontAwesomeIcon icon="pencil-alt" size="1x" className="icon-md color-dark-gray"/>Logout */}
                <FontAwesomeIcon icon="sign-out-alt" size="1x" className="icon-md color-dark-gray"/>Logout
              </NavLink>
            </div>
          }

        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="Mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
    </div>
  );
}

ResponsiveDrawer.propTypes = {
  container: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResponsiveDrawer);
// export default ResponsiveDrawer;
