import React, {Component} from 'react';
import ReactTable from "react-table";
import "react-table/react-table.css";
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import {Formik} from "formik";
import moment from 'moment';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import TableInfo from '../TableInfo';

const {rechargeStatus, paymentMethod} = require('../../utils');

const LotPaymentDetail = (props) => {
  const {selectedLotPayment, ...rest} = props;
  const cubacelRecharges = selectedLotPayment ? selectedLotPayment.cubacelRecharges : null;
  return (
    <Modal
      {...rest}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-app"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="app-header">
          Cubacel Recharges
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
          cubacelRecharges !== null && cubacelRecharges.length > 0 ?
            <ReactTable
              columns={[
                {
                  Header: "Date and Time",
                  id: "date",
                  accessor: d => moment(d.date).format('YYYY-MM-DD HH:mm'),
                },
                {
                  Header: "Amount",
                  id: "amount",
                  accessor: d => d.amount.toFixed(2),
                },
                {
                  Header: "Phone Number",
                  accessor: "phoneNumber",
                },
                {
                  Header: "Payment",
                  id: "paymentMethodId",
                  accessor: d => paymentMethod(d.paymentMethodId),
                },
                {
                  Header: "Status",
                  id: "cubacelRechargeStatusId",
                  accessor: d => rechargeStatus(d.cubacelRechargeStatusId),
                },

              ]}
              defaultSorted={[
                {
                  id: "date",
                  desc: true
                }
              ]}
              data={cubacelRecharges}
              pageSize={cubacelRecharges.length}
              className="-striped -highlight"
              showPaginationBottom={false}
            /> : ''
        }
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className="btn btn-outline-dark btn-form-app border-radius-4">Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

class LotPayments extends Component {
  updateTableData = () => this.table.fireFetchData();

  render() {
    const {
      inProgress, loadLotPayments, lotPaymentsState, selectedLotPayment,
      openLotPaymentDetail, showLotPaymentDetail, closeLotPaymentDetail,
    } = this.props;
    return (
      <Row className="transferences-super-container">
        <Col sm={12}>
          <div className="pl-5-desktop-transferences">
            <h4 className="mb-4">Lot Payments</h4>

            <Formik
              initialValues={{userName: ''}}
              onSubmit={(values) => this.updateTableData(values.userName)}
            >
              {props => {
                const {
                  touched,
                  errors,
                  handleSubmit,
                  values,
                  handleChange,
                  handleBlur
                } = props;
                return (
                  <Form className="mt-4" onSubmit={handleSubmit}>
                    <div className="px-4 py-4 mb-4" style={{border: '1px solid rgba(0,0,0,.1)'}}>
                      <Row className="text-left">
                        <Col sm={12} md={4}>
                          <Form.Label className="font-weight-bold">User</Form.Label>
                          <Form.Group className="mb-4">
                            <Form.Control
                              className="form-input border-radius-4"
                              type="text"
                              id="userName"
                              value={values.userName}
                              placeholder="User"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.userName && touched.userName && (
                              <div className="error-message">{errors.userName}</div>
                            )}
                          </Form.Group>
                        </Col>
                        <Col sm={12} md={4}>
                          <Form.Group className="mb-3">
                            <Button
                              className="btn btn-black btn-inline border-radius-4 search-button-transactions"
                              type="submit"
                              disabled={inProgress}
                            >
                              <FontAwesomeIcon icon="search" size="sm" className="o-btn-icon"/>
                              Search
                            </Button>
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                    <ReactTable
                      ref={(instance) => {
                        this.table = instance;
                      }}
                      columns={[
                        {
                          Header: "Date and Time",
                          id: "executionDate",
                          accessor: d => moment(d.executionDate).format('YYYY-MM-DD HH:mm'),
                        },
                        {
                          Header: "Amount",
                          id: "totalAmount",
                          accessor: d => d.totalAmount.toFixed(2),
                        },
                        {
                          Header: "Name",
                          accessor: "name",
                        },
                        {
                          Header: "User",
                          id: "userName",
                          accessor: d => d.transactionUser.userName,
                        },
                        {
                          Header: "Synchronized",
                          id: "synchronized",
                          accessor: d => d.synchronized ? 'Yes' : 'No',
                        },
                        {
                          Header: "Executed",
                          id: "executed",
                          accessor: d => d.executed ? 'Yes' : 'No',
                        },
                        {
                          Header: "Immediate",
                          id: "immediate",
                          accessor: d => d.immediate ? 'Yes' : 'No',
                        },
                        {
                          Header: "Recharges",
                          accessor: "d",
                          Cell: ({row}) => (
                            row._original.cubacelRecharges.length > 0 ?
                              <Button
                                variant="link" className="btn-row border-radius-4"
                                onClick={() => openLotPaymentDetail(row._original)}>
                                <FontAwesomeIcon icon="sticky-note"/>
                              </Button> : ''),
                          sortable: false
                        }
                      ]}
                      defaultSorted={[
                        {
                          id: "date",
                          desc: false
                        }
                      ]}
                      manual
                      data={lotPaymentsState.data}
                      pages={lotPaymentsState.pages}
                      loading={inProgress}
                      onFetchData={(state) => loadLotPayments(state)}
                      defaultPageSize={lotPaymentsState.pageSize}
                      pageSizeOptions={[5, 10, 20]}
                      className="-striped -highlight"
                      filter={{userName: values.userName}}
                      minRows={0}
                      NoDataComponent={() => null}
                    >
                      {(state, makeTable) => {
                        return (
                          <div>
                            {makeTable()}
                            <TableInfo
                              totalRows={lotPaymentsState.total}
                              page={state.page}
                              pageSize={state.pageSize}
                            />
                          </div>
                        )
                      }}
                    </ReactTable>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </Col>
        <LotPaymentDetail
          show={showLotPaymentDetail}
          onHide={closeLotPaymentDetail}
          selectedLotPayment={selectedLotPayment}
        />
      </Row>
    );
  }
}

export default LotPayments;