export const APP_INITILIZE = 'APP_INITILIZE';
export const APP_LOAD = 'APP_LOAD';
export const REDIRECT = 'REDIRECT';
export const ASYNC_START = 'ASYNC_START';
export const ASYNC_END = 'ASYNC_END';
export const UPDATE_FIELD_COMMON = 'UPDATE_FIELD_COMMON';

export const REGISTER = 'REGISTER';
export const LOGIN = 'LOGIN';
export const LOGIN_PAGE_LOADED = 'LOGIN_PAGE_LOADED';
export const UPDATE_FIELD_LOGIN = 'UPDATE_FIELD_LOGIN';
export const LOAD_USER = 'LOAD_USER';
export const LOGIN_PAGE_UNLOADED = 'LOGIN_PAGE_UNLOADED';
export const REGISTER_PAGE_UNLOADED = 'REGISTER_PAGE_UNLOADED';
export const LOGOUT = 'LOGOUT';
export const LOGOUT_TO_LOGIN = 'LOGOUT_TO_LOGIN';
export const RESET_ACTION = 'RESET_ACTION';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';

export const PAY_PAGE_LOADED = 'PAY_PAGE_LOADED';
export const PAY_PAGE_UNLOADED = 'PAY_PAGE_UNLOADED';
export const UPDATE_FIELD_PAY = 'UPDATE_FIELD_PAY';
export const PAGINATE_PAY = 'PAGINATE_PAY';
export const PAGINATE_PROMOTION = 'PAGINATE_PROMOTION';
export const PROMOTION_LOAD_INFO = 'PROMOTION_LOAD_INFO';
export const PROMOTION_UNLOAD_INFO = 'PROMOTION_UNLOAD_INFO';
export const PAY_PAGE_LOAD_SERVICES = 'PAY_PAGE_LOAD_SERVICES';
export const PAY_PAGE_LOAD_SERVICES_MASK = 'PAY_PAGE_LOAD_SERVICES_MASK';
export const PAY_PAGE_UNLOAD_SERVICES = 'PAY_PAGE_UNLOAD_SERVICES';
export const UPDATE_FIELD_PAY_SERVICE = 'UPDATE_FIELD_PAY_SERVICE';
export const PAY_SELECT_SERVICE = 'PAY_SELECT_SERVICE';
export const PAY_DESELECT_SERVICE = 'PAY_DESELECT_SERVICE';
export const UPDATE_FIELD_FORM_PAY_SERVICE = 'UPDATE_FIELD_FORM_PAY_SERVICE';
export const MAKE_PAYMENT = 'MAKE_PAYMENT';
export const PAY_RESET_PAYMENT = 'PAY_RESET_PAYMENT';
export const PAY_AMOUNT_TO_RECEIVE = 'PAY_AMOUNT_TO_RECEIVE';
export const PAY_ADD_PHONE = 'PAY_ADD_PHONE';
export const PAY_DELETE_PHONE = 'PAY_DELETE_PHONE';

export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const UPDATE_PROFILE_RESET = 'UPDATE_PROFILE_RESET';
export const PROFILE_PAGE_UNLOADED = 'PROFILE_PAGE_UNLOADED';
export const UPDATE_FIELD_PROFILE = 'UPDATE_FIELD_PROFILE';
export const PROFILE_LOAD_TAB_TRANSFERENCES = 'PROFILE_LOAD_TAB_TRANSFERENCES';
export const PROFILE_LOAD_TRANSFERENCES = 'PROFILE_LOAD_TRANSFERENCES';
export const PROFILE_LOAD_TAB_TRANSACTIONS = 'PROFILE_LOAD_TAB_TRANSACTIONS';
export const PROFILE_LOAD_TRANSACTIONS = 'PROFILE_LOAD_TRANSACTIONS';
export const PROFILE_LOAD_TRANSACTIONS_SUMMARY = 'PROFILE_LOAD_TRANSACTIONS_SUMMARY';
export const PROFILE_SHOW_TRANSACTION_DETAIL = 'PROFILE_SHOW_TRANSACTION_DETAIL';
export const PROFILE_LOAD_TAB_BATCH_PAYMENTS = 'PROFILE_LOAD_TAB_BATCH_PAYMENTS';
export const PROFILE_LOAD_BATCH_PAYMENTS = 'PROFILE_LOAD_BATCH_PAYMENTS';
export const PROFILE_SHOW_BATCH_PAYMENT_DETAIL = 'PROFILE_SHOW_BATCH_PAYMENT_DETAIL';
export const PROFILE_LOAD_TAB_PAYMENT_NOTIFICATIONS = 'PROFILE_LOAD_TAB_PAYMENT_NOTIFICATIONS';
export const PROFILE_LOAD_PAYMENT_NOTIFICATIONS = 'PROFILE_LOAD_PAYMENT_NOTIFICATIONS';
export const PROFILE_SHOW_PAYMENT_NOTIFICATION_DETAIL = 'PROFILE_SHOW_PAYMENT_NOTIFICATION_DETAIL';
export const PROFILE_LOAD_TAB_RESELLER_INFO = 'PROFILE_LOAD_TAB_RESELLER_INFO';
export const PROFILE_LOAD_RESELLER_INFO = 'PROFILE_LOAD_RESELLER_INFO';
export const PROFILE_LOAD_COMMISSIONS = 'PROFILE_LOAD_COMMISSIONS';
export const PROFILE_LOAD_DING_PRODUCTS = 'PROFILE_LOAD_DING_PRODUCTS';
export const PROFILE_SEARCH_RESELLER_INFO = 'PROFILE_SEARCH_RESELLER_INFO';
export const PROFILE_LOAD_TAB_REFERRALS = 'PROFILE_LOAD_TAB_REFERRALS';
export const PROFILE_LOAD_REFERRALS = 'PROFILE_LOAD_REFERRALS';
export const PROFILE_SHOW_RESET_DEBT = 'PROFILE_SHOW_RESET_DEBT';
export const PROFILE_RESET_DEBT = 'PROFILE_RESET_DEBT';
export const PROFILE_TRANSFER_REFERRAL = 'PROFILE_TRANSFER_REFERRAL';
export const PROFILE_LOAD_TAB_AFFILIATES = 'PROFILE_LOAD_TAB_AFFILIATES';
export const PROFILE_LOAD_AFFILIATES = 'PROFILE_LOAD_AFFILIATES';
export const PROFILE_LOAD_SUPER_USER = 'PROFILE_LOAD_SUPER_USER';
export const PROFILE_SHOW_SEND_MESSAGE = 'PROFILE_SHOW_SEND_MESSAGE';
export const PROFILE_SEND_MESSAGE = 'PROFILE_SEND_MESSAGE';
export const PROFILE_SHOW_MAKE_TRANSFERENCE = 'PROFILE_SHOW_MAKE_TRANSFERENCE';
export const PROFILE_MAKE_TRANSFERENCE = 'PROFILE_MAKE_TRANSFERENCE';
export const PROFILE_SEARCH_USER_BY_EMAIL = 'PROFILE_SEARCH_USER_BY_EMAIL';
export const PROFILE_SHOW_ADD_AFFILIATE = 'PROFILE_SHOW_ADD_AFFILIATE';
export const PROFILE_ADD_AFFILIATE = 'PROFILE_ADD_AFFILIATE';
export const PROFILE_USER_ALREADY_AFFILIATED = 'PROFILE_USER_ALREADY_AFFILIATED';
export const PROFILE_USER_NOT_ACTIVATED = 'PROFILE_USER_NOT_ACTIVATED';
export const PROFILE_USER_DOES_NOT_EXIST = 'PROFILE_USER_DOES_NOT_EXIST';
export const PROFILE_SHOW_UNLINK_AFFILIATE = 'PROFILE_SHOW_UNLINK_AFFILIATE';
export const PROFILE_UNLINK_AFFILIATE = 'PROFILE_UNLINK_AFFILIATE';

export const ADMIN_PAGE_UNLOADED = 'ADMIN_PAGE_UNLOADED';
export const UPDATE_FIELD_ADMIN = 'UPDATE_FIELD_ADMIN';
export const ADMIN_CHECK_PIN_NUMBER = 'ADMIN_CHECK_PIN_NUMBER';
export const ADMIN_LOAD_TAB_USERS = 'ADMIN_LOAD_TAB_USERS';
export const ADMIN_LOAD_USERS = 'ADMIN_LOAD_USERS';
export const ADMIN_ADD_USER = 'ADMIN_ADD_USER';
export const ADMIN_EDIT_USER = 'ADMIN_EDIT_USER';
export const ADMIN_CHANGE_USER_STATE = 'ADMIN_CHANGE_USER_STATE';
export const ADMIN_LOAD_DING_PRODUCT_RATE = 'ADMIN_LOAD_DING_PRODUCT_RATE';
export const ADMIN_UPDATE_COMMISSION_CONFIGURATION = 'ADMIN_UPDATE_COMMISSION_CONFIGURATION';
export const ADMIN_SET_COMMISSION_CONFIGURATION = 'ADMIN_SET_COMMISSION_CONFIGURATION';
export const ADMIN_SEARCH_RESELLER_CONFIGURATION = 'ADMIN_SEARCH_RESELLER_CONFIGURATION';
export const ADMIN_DELETE_USER = 'ADMIN_DELETE_USER';
export const ADMIN_RESET_DEBT_USER = 'ADMIN_RESET_DEBT_USER';
export const ADMIN_SHOW_DELETE_USER = 'ADMIN_SHOW_DELETE_USER';
export const ADMIN_SHOW_CHANGE_USER_STATE = 'ADMIN_SHOW_CHANGE_USER_STATE';
export const ADMIN_SHOW_EDIT_USER = 'ADMIN_SHOW_EDIT_USER';
export const ADMIN_SHOW_USER_RESET_DEBT = 'ADMIN_SHOW_USER_RESET_DEBT';
export const ADMIN_SET_PIN_NUMBER = 'ADMIN_SET_PIN_NUMBER';
export const ADMIN_LOAD_TAB_COUNTRIES = 'ADMIN_LOAD_TAB_COUNTRIES';
export const ADMIN_ADD_COUNTRY = 'ADMIN_ADD_COUNTRY';
export const ADMIN_UPDATE_COUNTRY = 'ADMIN_UPDATE_COUNTRY';
export const ADMIN_DELETE_COUNTRY = 'ADMIN_DELETE_COUNTRY';
export const ADMIN_SEARCH_COUNTRY = 'ADMIN_SEARCH_COUNTRY';
export const ADMIN_SHOW_DELETE_COUNTRY = 'ADMIN_SHOW_DELETE_COUNTRY';
export const ADMIN_SHOW_EDIT_COUNTRY = 'ADMIN_SHOW_EDIT_COUNTRY';
export const ADMIN_LOAD_TAB_TRANSACTIONS = 'ADMIN_LOAD_TAB_TRANSACTIONS';
export const ADMIN_LOAD_TRANSACTIONS = 'ADMIN_LOAD_TRANSACTIONS';
export const ADMIN_SHOW_TRANSACTION_DETAIL = 'ADMIN_SHOW_TRANSACTION_DETAIL';
export const ADMIN_LOAD_TAB_TRANSFERENCES = 'ADMIN_LOAD_TAB_TRANSFERENCES';
export const ADMIN_LOAD_TRANSFERENCES = 'ADMIN_LOAD_TRANSFERENCES';
export const ADMIN_LOAD_TAB_PAYMENT_NOTIFICATIONS = 'ADMIN_LOAD_TAB_PAYMENT_NOTIFICATIONS';
export const ADMIN_LOAD_PAYMENT_NOTIFICATIONS = 'ADMIN_LOAD_PAYMENT_NOTIFICATIONS';
export const ADMIN_SHOW_PAYMENT_NOTIFICATION_DETAIL = 'ADMIN_SHOW_PAYMENT_NOTIFICATION_DETAIL';
export const ADMIN_LOAD_TAB_LOT_PAYMENTS = 'ADMIN_LOAD_TAB_LOT_PAYMENTS';
export const ADMIN_LOAD_LOT_PAYMENTS = 'ADMIN_LOAD_LOT_PAYMENTS';
export const ADMIN_SHOW_LOT_PAYMENT_DETAIL = 'ADMIN_SHOW_LOT_PAYMENT_DETAIL';
export const ADMIN_LOAD_TAB_SUMMARY = 'ADMIN_LOAD_TAB_SUMMARY';
export const ADMIN_LOAD_TRANSACTIONS_SUMMARY = 'ADMIN_LOAD_TRANSACTIONS_SUMMARY';
export const ADMIN_LOAD_SIM_ACTIVATIONS_SUMMARY = 'ADMIN_LOAD_SIM_ACTIVATIONS_SUMMARY';
export const ADMIN_SHOW_SIM_ACTIVATION_SUMMARY_DETAIL = 'ADMIN_SHOW_SIM_ACTIVATION_SUMMARY_DETAIL';
export const ADMIN_LOAD_TAB_NAUTA_RECHARGES = 'ADMIN_LOAD_TAB_NAUTA_RECHARGES';
export const ADMIN_LOAD_NAUTA_RECHARGES = 'ADMIN_LOAD_NAUTA_RECHARGES';
export const ADMIN_SHOW_NAUTA_RECHARGE_DETAIL = 'ADMIN_SHOW_NAUTA_RECHARGE_DETAIL';
export const ADMIN_LOAD_TAB_RESELLERS = 'ADMIN_LOAD_TAB_RESELLERS';
export const ADMIN_SEARCH_RESELLER = 'ADMIN_SEARCH_RESELLER';
export const ADMIN_LOAD_TAB_RESELLER_COMMISSION = 'ADMIN_LOAD_TAB_RESELLER_COMMISSION';
export const ADMIN_SEARCH_RESELLER_COMMISSION = 'ADMIN_SEARCH_RESELLER_COMMISSION';
export const ADMIN_SHOW_DELETE_RESELLER_COMMISSION = 'ADMIN_SHOW_DELETE_RESELLER_COMMISSION';
export const ADMIN_UPDATE_COMMISSION = 'ADMIN_UPDATE_COMMISSION';
export const ADMIN_UPDATE_MASTER_COMMISSION = 'ADMIN_UPDATE_MASTER_COMMISSION';
export const ADMIN_SET_COMMISSION = 'ADMIN_SET_COMMISSION';
export const ADMIN_LOAD_PLANS = 'ADMIN_LOAD_PLANS';
export const ADMIN_ADD_RESELLER_COMMISSION = 'ADMIN_ADD_RESELLER_COMMISSION';
export const ADMIN_DELETE_RESELLER_COMMISSION = 'ADMIN_DELETE_RESELLER_COMMISSION';
export const ADMIN_LOAD_TAB_PROVIDERS = 'ADMIN_LOAD_TAB_PROVIDERS';
export const ADMIN_SEARCH_PROVIDER = 'ADMIN_SEARCH_PROVIDER';
export const ADMIN_SHOW_DELETE_PROVIDER = 'ADMIN_SHOW_DELETE_PROVIDER';
export const ADMIN_SHOW_EDIT_PROVIDER = 'ADMIN_SHOW_EDIT_PROVIDER';
export const ADMIN_ADD_PROVIDER = 'ADMIN_ADD_PROVIDER';
export const ADMIN_FILL_PROVIDERS = 'ADMIN_FILL_PROVIDERS';
export const ADMIN_DELETE_PROVIDER = 'ADMIN_DELETE_PROVIDER';
export const ADMIN_UPDATE_PROVIDER = 'ADMIN_UPDATE_PROVIDER';
export const ADMIN_LOAD_TAB_PAYMENT_METHODS = 'ADMIN_LOAD_TAB_PAYMENT_METHODS';
export const ADMIN_SEARCH_PAYMENT_METHOD = 'ADMIN_SEARCH_PAYMENT_METHOD';
export const ADMIN_SHOW_DELETE_PAYMENT_METHOD = 'ADMIN_SHOW_DELETE_PAYMENT_METHOD';
export const ADMIN_SHOW_EDIT_PAYMENT_METHOD = 'ADMIN_SHOW_EDIT_PAYMENT_METHOD';
export const ADMIN_ADD_PAYMENT_METHOD = 'ADMIN_ADD_PAYMENT_METHOD';
export const ADMIN_DELETE_PAYMENT_METHOD = 'ADMIN_DELETE_PAYMENT_METHOD';
export const ADMIN_UPDATE_PAYMENT_METHOD = 'ADMIN_UPDATE_PAYMENT_METHOD';
export const ADMIN_LOAD_TAB_OPERATOR_CATEGORIES = 'ADMIN_LOAD_TAB_OPERATOR_CATEGORIES';
export const ADMIN_SEARCH_OPERATOR_CATEGORY = 'ADMIN_SEARCH_OPERATOR_CATEGORY';
export const ADMIN_SHOW_DELETE_OPERATOR_CATEGORY = 'ADMIN_SHOW_DELETE_OPERATOR_CATEGORY';
export const ADMIN_SHOW_EDIT_OPERATOR_CATEGORY = 'ADMIN_SHOW_EDIT_OPERATOR_CATEGORY';
export const ADMIN_ADD_OPERATOR_CATEGORY = 'ADMIN_ADD_OPERATOR_CATEGORY';
export const ADMIN_DELETE_OPERATOR_CATEGORY = 'ADMIN_DELETE_OPERATOR_CATEGORY';
export const ADMIN_UPDATE_OPERATOR_CATEGORY = 'ADMIN_UPDATE_OPERATOR_CATEGORY';
export const ADMIN_LOAD_TAB_PAYMENT_APIS = 'ADMIN_LOAD_TAB_PAYMENT_APIS';
export const ADMIN_SEARCH_PAYMENT_API = 'ADMIN_SEARCH_PAYMENT_API';
export const ADMIN_SHOW_DELETE_PAYMENT_API = 'ADMIN_SHOW_DELETE_PAYMENT_API';
export const ADMIN_SHOW_EDIT_PAYMENT_API = 'ADMIN_SHOW_EDIT_PAYMENT_API';
export const ADMIN_ADD_PAYMENT_API = 'ADMIN_ADD_PAYMENT_API';
export const ADMIN_DELETE_PAYMENT_API = 'ADMIN_DELETE_PAYMENT_API';
export const ADMIN_UPDATE_PAYMENT_API = 'ADMIN_UPDATE_PAYMENT_API';

export const REPAIR_PAGE_LOADED = 'REPAIR_PAGE_LOADED';
export const REPAIR_PAGE_UNLOADED = 'REPAIR_PAGE_UNLOADED';
export const UPDATE_FIELD_REPAIR = 'UPDATE_FIELD_REPAIR';

export const RESIZE_WINDOWS = 'RESIZE_WINDOWS';