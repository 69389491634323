import React, {Component} from 'react';
import agent from "../../agent";
import ReactFileReader from 'react-file-reader';

import checkImage from "../../assets/img/icons/check-24px.svg";
import clearImage from "../../assets/img/icons/clear-24px.svg";
import errorOutlineImage from "../../assets/img/icons/error_outline-24px.svg";

class HomeBanner extends Component {

  constructor(props) {
    super(props);
    this.state = {
      files: [],
      fileName: null,
      fileImage: null,
      isValidFormat: null,
      isValidDimension: null
    }
  }

  fileUploadHandler = () => {
    if (this.state) {
      let isValidDimension = this.validateImage();
      let isValidFormat = this.validateFormat();

      if (isValidDimension === true && isValidFormat) {
        let onlyImageCode = this.state.fileImage[0].split(',')[1];
        agent.Admin.homeBanner(onlyImageCode);

        alert("Home banner was updated");

        this.setState({
          files: [],
          fileName: null,
          fileImage: null,
          isValidFormat: null,
          isValidDimension: null
          // }, () => {window.location = '/'});   
        }, () => {
          console.log('Image changed')
        });
      } else {
        this.setState({
          isValidFormat: isValidFormat,
          isValidDimension: isValidDimension
        });
      }
    }
  };

  validateImage = () => {
    let image = document.getElementById('my-image');
    let width = image.naturalWidth;
    let height = image.naturalHeight;
    let isValidDimension = false;

    if (width === 1230 && height === 490) {
      isValidDimension = true;
    }
    return isValidDimension;
  };

  validateFormat = () => {
    if (this.state.files && this.state.files.length > 0 && this.state.files[0].type === "image/png") {
      return true;
    } else {
      return false;
    }
  };

  handleFiles = files => {
    this.setState({
      files: files.fileList,
      fileName: files.fileList[0].name,
      fileImage: files.base64
    });
  };

  render() {
    return (
      <div className="transferences-super-container pl-5-desktop-transferences">
        <div className="validation-file-upload-container">
          <div className="col-12">
            {this.state.isValidFormat === null ?
              <img src={errorOutlineImage} alt="" className="file-upload-icon"/>
              : this.state.isValidFormat === true ?
                <img src={checkImage} alt="" className="file-upload-icon"/> :
                <img src={clearImage} alt="" className="file-upload-icon"/>}
            <span className={`image-format-valid-text-${this.state.isValidFormat}`}>Image must be in PNG format</span>
          </div>
          <div className="col-12">
            {this.state.isValidDimension === null ?
              <img src={errorOutlineImage} alt="" className="file-upload-icon"/>
              : this.state.isValidDimension === true ?
                <img src={checkImage} alt="" className="file-upload-icon"/> :
                <img src={clearImage} alt="" className="file-upload-icon"/>
            }
            <span className={`image-dimension-valid-text-${this.state.isValidDimension}`}>Image must have 1230 x 490 pixels.</span>
          </div>
        </div>
        <div className="react-file-reader-super-container">
          <ReactFileReader fileTypes={[".png"]} base64={true} multipleFiles={true} handleFiles={this.handleFiles}>
            <button className='react-file-reader-component-btn file-upload-button'>Choose File</button>
          </ReactFileReader>
          <div className="file-upload-chosen">
            <span>{this.state.fileName ? this.state.fileName : "No file chosen"}</span>
          </div>
        </div>
        {this.state.fileImage ?
          <div className="file-upload-image-preview-container">
            <img src={this.state.fileImage} alt="banner preview" className="file-upload-image-preview" id="my-image"/>
          </div>
          : null}

        {this.state.fileImage ?
          <div className="file-upload-button-container text-right">
            <button onClick={this.fileUploadHandler} className="file-upload-button">Upload</button>
          </div>
          : null}
      </div>
    );
  }
}

export default HomeBanner;