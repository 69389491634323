import React from 'react';
import {Row, Col, Alert} from "react-bootstrap";
import {Link} from 'react-router-dom';

const WrongRequest = () => (
  <Row>
    <Col md={12}>
      <div className="content text-center" style={{maxWidth: '45.5rem', marginLeft: 'auto', marginRight: 'auto'}}>
        <h1 className="section-title text-center">Sorry!</h1>
        <p className="fp-text pass-info mb-3 text-center">
          There is something wrong with your request
        </p>
        <Alert variant="warning" className="mb-4">
          <p>Something was wrong with your request, maybe is too old. Please try again from the beginning!</p>
        </Alert>
        <Link
          to="/login"
          className="btn btn-black btn-inline border-radius-4 search-button-transactions"
          style={{marginTop: '.5rem'}}
        >Continue to Login</Link>
      </div>
    </Col>
  </Row>
);

export default WrongRequest;