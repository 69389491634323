import React, {Component} from 'react';
import {Row, Col, Form, Button, Spinner, Alert} from "react-bootstrap";
import {connect} from "react-redux";
import {
  LOGOUT_TO_LOGIN,
  RESET_ACTION,
  RESET_PASSWORD,
  UPDATE_FIELD_LOGIN,
} from '../constants/actionTypes';
import * as Yup from "yup";
import {Formik} from "formik";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import agent from "../agent";
import Message from './Message';

const mapStateToProps = state => ({
  ...state.auth,
});

const mapDispatchToProps = dispatch => ({
  onLoad: (userId, code) => {
    if (userId === null || code === null)
      dispatch({type: LOGOUT_TO_LOGIN});
    else {
      dispatch({type: UPDATE_FIELD_LOGIN, key: 'userId', value: userId});
      dispatch({type: UPDATE_FIELD_LOGIN, key: 'code', value: code});
    }
  },
  onSubmitResetPassword: (userId, code, newPassword) => {
    const payload = agent.Profile.resetPasswordNew({userId, code, newPassword});
    dispatch({type: RESET_PASSWORD, payload});
  },
  onResetAction: () =>
    dispatch({type: RESET_ACTION}),
});

class ResetPassword extends Component {

  componentWillMount() {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const userId = params.get('userId');
    const code = params.get('code');
    this.props.onLoad(userId, code);
  }

  render() {
    const {inProgress, onSubmitResetPassword, userId, code, errors, onResetAction} = this.props;
    const formId = 'form-submit-reset-password';
    return (
      <div className="content">
        <h1 className="section-title text-center mb-5">Reset Password</h1>
        <Formik
          initialValues={{
            password: '',
            passwordConfirm: '',
          }}
          onSubmit={(values) => onSubmitResetPassword(
            userId,
            code,
            values.password
          )}
          validationSchema={Yup.object().shape({
            password: Yup.string().required('Required').min(6, 'At least 6 characters').matches(/\d/, 'Must contain at least 1 number').matches(/[*@!#%$&()^~{}+-/]+/, 'Must contain at least 1 special character'),
            passwordConfirm: Yup.string().oneOf([Yup.ref('password'), null], "Passwords must match").required('Required')
          })}
        >
          {props => {
            const {
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              handleSubmit
            } = props;
            return (
              <Form
                id={formId}
                onSubmit={handleSubmit}
                style={{maxWidth: '35rem', marginLeft: 'auto', marginRight: 'auto'}}
              >
                <Alert variant="info" className="mb-4">
                  <p><b>Your password must meet the following minimum requirements: </b></p>
                  <ul>
                    <li>Must be at&nbsp;least 6 characters in length</li>
                    <li>Must contain at least 1 special character (for example *, #, %, $, among others)</li>
                    <li>Must contain at least 1 number</li>
                  </ul>
                </Alert>
                <Row className="text-left">
                  <Col md={12}>
                    <Form.Group className="mb-4">
                      <Form.Label className="font-weight-bold">Password</Form.Label>
                      <Form.Control
                        className="form-input border-radius-4"
                        id="password"
                        type="password"
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.password && touched.password && (
                        <div className="error-message">{errors.password}</div>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="text-left">
                  <Col md={12}>
                    <Form.Group className="mb-4">
                      <Form.Label className="font-weight-bold">Confirm Password</Form.Label>
                      <Form.Control
                        className="form-input border-radius-4"
                        id="passwordConfirm"
                        type="password"
                        value={values.passwordConfirm}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.passwordConfirm && touched.passwordConfirm && (
                        <div className="error-message">{errors.passwordConfirm}</div>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="text-left">
                  <Col md={12}>
                    <Button
                      className="btn btn-black btn-inline border-radius-4 search-button-transactions w-100"
                      type="submit"
                      style={{marginTop: '.5rem'}}
                      form={formId}>
                      {
                        inProgress ?
                          <Spinner
                            animation="grow"
                            variant="secondary"
                            style={{marginRight: '5px', marginBottom: '4px'}}
                            size="sm"
                          />
                          : ''
                      }
                      Reset Password
                      <FontAwesomeIcon icon="arrow-right" className="icon-right"/>
                    </Button>
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
        {
          errors && errors !== null ?
            <Message type="error" text={errors} onClose={onResetAction}/>
            : ''
        }
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);