import React, {Component} from 'react';
import ReactTable from "react-table";
import "react-table/react-table.css";
import {Button, Col, Form, Modal, Row, Spinner} from "react-bootstrap";
import {Formik} from "formik";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import moment from 'moment';
import AddUser from './AddUser';
import EditUser from './EditUser';
import DeleteUser from './DeleteUser';
import TableInfo from '../TableInfo';
import Switch from "react-switch";

const ChangeUserState = (props) => {
  const {
    submitEditUser, inProgress, selectedUser, currentEditUserTab, onChangeEditUserTab, resellerConfigurationFilterPlan,
    isSelectedUserAdmin, isSelectedUserReseller, filteredResellerConfiguration, resellerConfiguration,
    submitSetPinNumber, submitSaveCommissionConfiguration, context,closeChangeUserState,submitChangeUserState,
    showResetDebtUser, openResetDebtUser, closeResetDebtUser, submitResetDebtUser,
    onUpdateFilterResellerConfiguration, onSubmitSearchResellerConfiguration, onUpdateCommissionConfiguration,
    ...rest
  } = props;
  return (
    <Modal
      {...rest}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-app"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="app-header">
        Change the status of the confirmed email
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Label>
          {selectedUser ? (selectedUser.emailconfirmed ? 'Sure you want to unconfirm the email of the user  ':'Sure you want to confirm the email of the user  '):''}&nbsp;

          <span className="font-weight-bold">{selectedUser ?(selectedUser.firstname.toUpperCase() +'  '+ selectedUser.lastname.toUpperCase() ): ''}</span> ? 
        </Form.Label>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="btn btn-form-app btn-option border-radius-4"
          type="submit"
          onClick={() => submitChangeUserState(
            selectedUser.username,
            !selectedUser.emailconfirmed
          )}
        >
          {
            inProgress ?
              <Spinner
                animation="grow"
                variant="secondary"
                style={{marginRight: '5px', marginBottom: '4px'}}
                size="sm"
              />
              : ''
          }
          Accept
          <FontAwesomeIcon icon="arrow-right" className="icon-right"/>
        </Button>
        <Button onClick={props.onHide} className="btn btn-outline-dark btn-form-app border-radius-4">Close</Button>
      </Modal.Footer>
    </Modal>
  );
};


class Users extends Component {
  constructor() {
    super();
    this.state = { checked: false };
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(checked) {
    this.setState({ checked });
  }
  updateTableData = () => this.table.fireFetchData();

  render() {
    const {
      inProgress, loadUsers, usersState, selectedUser, isSelectedUserAdmin, isSelectedUserReseller, resellerConfiguration, filteredResellerConfiguration,
      submitSetPinNumber, submitSaveCommissionConfiguration, resellerConfigurationFilterPlan,
      openAddUser, showAddUser, closeAddUser, submitAddUser,openChangeUserState,showChangeUserState,closeChangeUserState,
      openEditUser, showEditUser, closeEditUser, submitEditUser,submitChangeUserState, onChangeEditUserTab, currentEditUserTab,
      showResetDebtUser, openResetDebtUser, closeResetDebtUser, submitResetDebtUser,
      /*openDeleteUser,*/ showDeleteUser, closeDeleteUser, submitDeleteUser,
      onUpdateFilterResellerConfiguration, onSubmitSearchResellerConfiguration, onUpdateCommissionConfiguration,
    } = this.props;

    return (
      <Row className="transferences-super-container">
        <Col sm={12}>
          <div className="pl-5-desktop-transferences">
            <h4 className="mb-4">Users</h4>
            <Button
              variant="light"
              onClick={openAddUser}
              className="header-toolbar-btn border-radius-4"
            >
              <FontAwesomeIcon icon="plus" className="icon"/>
              Add
            </Button>
            <Formik
              initialValues={{user: ''}}
              onSubmit={(values) => this.updateTableData(values.user)}
            >
              {props => {
                const {
                  touched,
                  errors,
                  handleSubmit,
                  values,
                  handleChange,
                  handleBlur
                } = props;
                return (
                  <Form className="mt-4" onSubmit={handleSubmit}>
                    <div className="px-4 py-4 mb-4" style={{border: '1px solid rgba(0,0,0,.1)'}}>
                      <Row className="text-left">
                        <Col sm={12} md={4}>
                          <Form.Group className="mb-0">
                            <Form.Label className="font-weight-bold">Username</Form.Label>
                            <Form.Control
                              className="form-input border-radius-4"
                              type="text"
                              id="user"
                              value={values.user}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.user && touched.user && (
                              <div className="error-message">{errors.user}</div>
                            )}
                          </Form.Group>
                        </Col>
                        <Col sm={12} md={6}>
                          <Form.Group className="mb-3">
                            <Button
                              className="btn btn-black btn-inline border-radius-4 search-button-transactions"
                              type="submit"
                              disabled={inProgress}
                            >
                              <FontAwesomeIcon icon="search" size="sm" className="o-btn-icon"/>
                              Search
                            </Button>
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                    <ReactTable
                      ref={(instance) => {
                        this.table = instance;
                      }}
                      columns={[
                        {
                          Header: "Username",
                          accessor: "username",
                        },
                        {
                          Header: "Full Name",
                          id: "fullName",
                          accessor: d => `${d.firstname} ${d.lastname}`,
                          sortable: false,
                        },
                        {
                          Header: "Email",
                          accessor: "email",
                        },
                        {
                          Header: "Confirmed",
                          id: "confirmed",
                          accessor: "d",
                          Cell: ({row}) =>( <Switch
                          onChange={() => {openChangeUserState(row._original)}}
                          checked={row._original.emailconfirmed}
                          height={15}
                          width={40}
                          id="normal-switch"
                            />
                            
                            ),
                          sortable: false
                        },
                        {
                          Header: "Balance",
                          id: "balance",
                          accessor: d => d.balance.toFixed(2),
                        },
                        {
                          Header: "Unlock Balance",
                          id: "unlockbalance",
                          accessor: d => d.unlockbalance.toFixed(2),
                        },
                        {
                          Header: "Phone",
                          accessor: "phonenumber",
                        },
                        {
                          Header: "Super User",
                          accessor: "superusername",
                          sortable: false
                        },
                        {
                          Header: "Join Date",
                          id: "joindate",
                          accessor: d => moment(d.joindate).format('YYYY-MM-DD'),
                        },
                        {
                          Header: "Active",
                          id: "active",

                           accessor: d => d.active ? <FontAwesomeIcon icon="check" className="item-enabled"/> :
                             <FontAwesomeIcon icon="times" className="item-disabled"/>,
                          sortable: false
                        },
                        {
                          Header: "Edit",
                          accessor: "d",
                          Cell: ({row}) => (
                            <Button
                              variant="link" className="btn-row border-radius-4"
                              onClick={() => openEditUser(row._original)}>
                              <FontAwesomeIcon icon="pencil-alt"/>
                            </Button>),
                          sortable: false
                        },
                        /*{
                          Header: "Delete",
                          accessor: "d",
                          Cell: ({row}) => (
                            <Button
                              variant="link" className="btn-row border-radius-4"
                              onClick={() => openDeleteUser(row._original)}>
                              <FontAwesomeIcon icon="trash"/>
                            </Button>),
                          sortable: false
                        }*/
                      ]}
                      defaultSorted={[
                        {
                          id: "username",
                          desc: true
                        }
                      ]}
                      manual
                      data={usersState.data}
                      pages={usersState.pages}
                      loading={inProgress}
                      onFetchData={(state) => loadUsers(state)}
                      defaultPageSize={usersState.pageSize}
                      pageSizeOptions={[5, 10, 20]}
                      className="-striped -highlight"
                      filter={{user: values.user}}
                      minRows={0}
                      NoDataComponent={() => null}
                    >
                      {(state, makeTable) => {
                        return (
                          <div>
                            {makeTable()}
                            <TableInfo
                              totalRows={usersState.total}
                              page={state.page}
                              pageSize={state.pageSize}
                            />
                          </div>
                        )
                      }}
                    </ReactTable>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </Col>
        <AddUser
          inProgress={inProgress}
          show={showAddUser}
          onHide={closeAddUser}
          submitAddUser={submitAddUser}
        />
        <DeleteUser
          inProgress={inProgress}
          show={showDeleteUser}
          onHide={closeDeleteUser}
          submitDeleteUser={submitDeleteUser}
          selectedUser={selectedUser}
        />
          <EditUser
          inProgress={inProgress}
          show={showEditUser}
          onHide={closeEditUser}
          submitEditUser={submitEditUser}
          selectedUser={selectedUser}
          showResetDebtUser={showResetDebtUser}
          openResetDebtUser={openResetDebtUser}
          closeResetDebtUser={closeResetDebtUser}
          submitResetDebtUser={submitResetDebtUser}
          currentEditUserTab={currentEditUserTab}
          onChangeEditUserTab={onChangeEditUserTab}
          isSelectedUserAdmin={isSelectedUserAdmin}
          isSelectedUserReseller={isSelectedUserReseller}
          resellerConfiguration={resellerConfiguration}
          filteredResellerConfiguration={filteredResellerConfiguration}
          onUpdateFilterResellerConfiguration={onUpdateFilterResellerConfiguration}
          onSubmitSearchResellerConfiguration={onSubmitSearchResellerConfiguration}
          onUpdateCommissionConfiguration={onUpdateCommissionConfiguration}
          submitSaveCommissionConfiguration={submitSaveCommissionConfiguration}
          resellerConfigurationFilterPlan={resellerConfigurationFilterPlan}
          submitSetPinNumber={submitSetPinNumber}
        />          
          <ChangeUserState
          inProgress={inProgress}
          show={showChangeUserState}
          onHide={closeChangeUserState}
          submitChangeUserState={submitChangeUserState}
          selectedUser={selectedUser}
          showResetDebtUser={showResetDebtUser}
          openResetDebtUser={openResetDebtUser}
          closeResetDebtUser={closeResetDebtUser}
          submitResetDebtUser={submitResetDebtUser}
          currentEditUserTab={currentEditUserTab}
          onChangeEditUserTab={onChangeEditUserTab}
        />      
      </Row>
    );
  }
}

export default Users;