import React from 'react';
import {Row, Col, Image, Button} from "react-bootstrap";

import repairImg from '../../assets/img/content/pay/services/repair.png';
import unlockImg from '../../assets/img/content/pay/services/unlock.png';
import activateImg from '../../assets/img/content/pay/services/activate.png';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Services = () => (
  <div className="bg-white content text-align-center pt-4">
    <div className="pl-5-pr-5-desktop">
      <div className="ss-section text-left mt-0">
        <Row>
          <Col xl={6} lg={6} md={6} sm={12} xs={12}>
            <Image src={repairImg} className="service-image"/>
          </Col>
          <Col xl={6} lg={6} md={6} sm={12} xs={12}>
            <div className="ss-info">
              <h4 className="category mb-2">REPAIR</h4>
              <h1 className="ss-title">Need Help with Fixing Your Phone?</h1>
              <p className="ss-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore et dolore magna aliqua. </p>
              <Button className="btn-app btn-black mt-4 payment-mobile-button border-radius-4">Explore Our Repair Services<FontAwesomeIcon icon="arrow-right"
                                                                                                     size="lg"
                                                                                                     className="c-btn-icon"/></Button>
            </div>
          </Col>
        </Row>
      </div>
      <div className="ss-section text-left">
        <Row className="unlock-container">
          <Col xl={6} lg={6} md={6} sm={12} xs={12}>
            <div className="ss-info pr-5-desktop">
              <h4 className="category mb-2">UNLOCK</h4>
              <h1 className="ss-title">Unlock Any Device for Any Provider of Any Country</h1>
              <p className="ss-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore et dolore magna aliqua. </p>
              <Button className="btn-app btn-black mt-4 payment-mobile-button border-radius-4">Unlock Your Phone<FontAwesomeIcon icon="arrow-right" size="lg"
                                                                                           className="c-btn-icon"/></Button>
            </div>
          </Col>
          <Col xl={6} lg={6} md={6} sm={12} xs={12}>
            <Image src={unlockImg} className="service-image unlock-image"/>
          </Col>
        </Row>
      </div>
      <div className="ss-section text-left">
        <Row>
          <Col xl={6} lg={6} md={6} sm={12} xs={12}>
            <Image src={activateImg} className="service-image"/>
          </Col>
          <Col xl={6} lg={6} md={6} sm={12} xs={12}>
            <div className="ss-info pl-5-desktop">
              <h4 className="category mb-2">ACTIVATE</h4>
              <h1 className="ss-title">Activate Your Sim and Handsets</h1>
              <p className="ss-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore et dolore magna aliqua. </p>
              <Button className="btn-app btn-black mt-4 payment-mobile-button border-radius-4">Get Started<FontAwesomeIcon icon="arrow-right" size="lg"
                                                                                     className="c-btn-icon"/></Button>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  </div>
);

export default Services;