import React, {Component} from 'react';
import ReactTable from "react-table";
import "react-table/react-table.css";
import {Button, Col, Form, Modal, Row, Spinner} from "react-bootstrap";
import {Formik} from "formik";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as Yup from "yup";
import Select from 'react-select';
import TableInfo from '../TableInfo';

const AddResellerCommission = (props) => {
  const {
    submitAddResellerCommission, inProgress, plans, ...rest
  } = props;

  const selectSortStyles = {
    control: (base, state) => ({
      ...base,
      borderRadius: "4px",
      height: "3rem",
      paddingLeft: ".5rem",
      paddingRight: ".5rem",
      borderColor: state.isFocused ? "#80bdff" : "#ced4da",
      boxShadow: state.isFocused ? "0 0 0 3px #bfdeff" : "none",
      '&:hover': {
        borderColor: state.isFocused ? "#80bdff" : "#ced4da",
      },
    })
  };

  const formAddResellerCommissionId = 'formAddResellerCommissionId';

  return (
    <Modal
      {...rest}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-app"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="app-header">
          Register a new plan and his commission
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <Formik
          initialValues={{
            dingPlan: '',
            commission: '',
            masterCommission: '',
          }}
          onSubmit={(values) => submitAddResellerCommission(
            values.dingPlan,
            values.commission,
            values.masterCommission
          )}
          validationSchema={Yup.object().shape({
            dingPlan: Yup.string().required('Required'),
            commission: Yup.number().required('Required').typeError('Invalid number'),
            masterCommission: Yup.number().required('Required').typeError('Invalid number'),
          })}
        >
          {props => {
            const {
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue
            } = props;
            return (
              <Form id={formAddResellerCommissionId} onSubmit={handleSubmit}>
                <Row className="text-left">
                  <Col md={12} sm={12}>
                    <Form.Group>
                      <Form.Label
                        className="font-weight-bold">Ding Plan
                      </Form.Label>
                      <Select
                        id="dingPlan"
                        onBlur={handleBlur}
                        onChange={(ev) => {
                          setFieldValue('dingPlan', ev.value);
                        }}
                        options={(Array.isArray(plans) && plans.length) ? plans.map(item => ({
                          value: item,
                          label: item.productName
                        })) : []}
                        styles={selectSortStyles}
                        components={
                          {
                            IndicatorSeparator: () => null
                          }
                        }
                      />
                      {errors.dingPlan && touched.dingPlan && (
                        <div className="error-message">{errors.dingPlan}</div>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={6} sm={12}>
                    <Form.Group>
                      <Form.Label
                        className="font-weight-bold">Commission (%)
                      </Form.Label>
                      <Form.Control
                        className="form-input border-radius-4"
                        type="text"
                        placeholder="Commission"
                        id="commission"
                        value={values.commission}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.commission && touched.commission && (
                        <div className="error-message">{errors.commission}</div>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={6} sm={12}>
                    <Form.Group>
                      <Form.Label
                        className="font-weight-bold">Master Commission (%)
                      </Form.Label>
                      <Form.Control
                        className="form-input border-radius-4"
                        type="text"
                        placeholder="Master Commission"
                        id="masterCommission"
                        value={values.masterCommission}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.masterCommission && touched.masterCommission && (
                        <div className="error-message">{errors.masterCommission}</div>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn btn-form-app btn-option border-radius-4" type="submit" form={formAddResellerCommissionId}
                disabled={inProgress === true}>
          {
            inProgress ?
              <Spinner
                animation="grow"
                variant="secondary"
                style={{marginRight: '5px', marginBottom: '4px'}}
                size="sm"
              />
              : ''
          }
          Accept
          <FontAwesomeIcon icon="arrow-right" className="icon-right"/>
        </Button>
        <Button onClick={props.onHide} className="btn btn-outline-dark btn-form-app border-radius-4">Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

const DeleteResellerCommission = (props) => {
  const {
    selectedResellerCommission, inProgress, submitDeleteResellerCommission, ...rest
  } = props;
  return (
    <Modal
      {...rest}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-app"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="app-header">
          Delete Reseller Commission
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Label>
          Sure you want to delete the commission&nbsp;
          <span
            className="font-weight-bold">{selectedResellerCommission ? selectedResellerCommission.dingProductName.toUpperCase() : ''}</span>?
        </Form.Label>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="btn btn-form-app btn-option border-radius-4"
          type="submit"
          onClick={() => submitDeleteResellerCommission(selectedResellerCommission.id)}
        >
          {
            inProgress ?
              <Spinner
                animation="grow"
                variant="secondary"
                style={{marginRight: '5px', marginBottom: '4px'}}
                size="sm"
              />
              : ''
          }
          Accept
          <FontAwesomeIcon icon="arrow-right" className="icon-right"/>
        </Button>
        <Button onClick={props.onHide} className="btn btn-outline-dark btn-form-app border-radius-4">Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

class ResellerCommission extends Component {
  render() {
    const {
      inProgress, onUpdateFilterResellerCommission, filteredResellerCommission, selectedResellerCommission, submitSearchResellerCommission, plans,
      openAddResellerCommission, showAddResellerCommission, closeAddResellerCommission, submitAddResellerCommission,
      openDeleteResellerCommission, showDeleteResellerCommission, closeDeleteResellerCommission, submitDeleteResellerCommission,
      updateCommission, updateMasterCommission, submitSetCommission, selectedResellerCommissionId,
    } = this.props;
    return (
      <Row className="transferences-super-container">
        <Col sm={12}>
          <div className="pl-5-desktop-transferences">
            <h4 className="mb-4">Plans with Commissions</h4>
            <Button
              variant="light"
              onClick={openAddResellerCommission}
              className="header-toolbar-btn border-radius-4"
            >
              <FontAwesomeIcon icon="plus" className="icon"/>
              Add
            </Button>
            <Formik
              initialValues={{plan: ''}}
              onSubmit={() => submitSearchResellerCommission()}
            >
              {props => {
                const {
                  touched,
                  errors,
                  handleSubmit,
                  values,
                  handleChange,
                  handleBlur
                } = props;
                return (
                  <Form className="mt-4" onSubmit={handleSubmit}>
                    <div className="px-4 py-4 mb-4" style={{border: '1px solid rgba(0,0,0,.1)'}}>
                      <Row className="text-left">
                        <Col sm={12} md={4}>
                          <Form.Group className="mb-4">
                            <Form.Label className="font-weight-bold">Plan</Form.Label>
                            <Form.Control
                              className="form-input border-radius-4"
                              type="text"
                              id="plan"
                              value={values.plan}
                              onChange={(ev) => {
                                handleChange(ev);
                                onUpdateFilterResellerCommission(ev.target.value);
                              }}
                              onBlur={handleBlur}
                            />
                            {errors.plan && touched.plan && (
                              <div className="error-message">{errors.plan}</div>
                            )}
                          </Form.Group>
                        </Col>
                        <Col sm={12} md={6}>
                          <Form.Group className="mb-3">
                            <Button
                              className="btn btn-black btn-inline border-radius-4 search-button-transactions"
                              type="submit"
                              disabled={inProgress}
                            >
                              <FontAwesomeIcon icon="search" size="sm" className="o-btn-icon"/>
                              Search
                            </Button>
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                    <ReactTable
                      ref={(instance) => {
                        this.table = instance;
                      }}
                      columns={[
                        {
                          Header: "Name",
                          accessor: "dingProductName",
                        },
                        {
                          Header: "Min Value",
                          id: "minValue",
                          accessor: d => parseFloat(d.minValue).toFixed(2),
                        },
                        {
                          Header: "Max Value",
                          id: "maxValue",
                          accessor: d => parseFloat(d.maxValue).toFixed(2),
                        },
                        {
                          Header: "Has Commission",
                          id: "commission",
                          accessor: d => d.commission > 0 ? 'Yes' : 'No',
                        },
                        {
                          Header: "Commission",
                          accessor: "d",
                          Cell: ({row}) => (
                            <Form.Control
                              className="border-radius-4"
                              type="text"
                              key={`commission-${row._original.id}`}
                              id={`commission-${row._original.id}`}
                              value={row._original.commission}
                              onChange={(ev) => updateCommission(row._original.id, ev.target.value)}
                            />

                          ),
                          sortable: false
                        },
                        {
                          Header: "Master Commission",
                          accessor: "d",
                          Cell: ({row}) => (
                            <Form.Control
                              className="border-radius-4"
                              type="text"
                              key={`masterCommission-${row._original.id}`}
                              id={`masterCommission-${row._original.id}`}
                              value={row._original.masterCommission}
                              onChange={(ev) => updateMasterCommission(row._original.id, ev.target.value)}
                            />),
                          sortable: false
                        },
                        {
                          Header: "Update",
                          accessor: "d",
                          Cell: ({row}) => (
                            selectedResellerCommissionId !== row._original.id ?
                              <Button
                                variant="link" className="btn-row border-radius-4"
                                onClick={() => submitSetCommission(row._original.id, row._original.commission, row._original.masterCommission)}
                              >
                                <FontAwesomeIcon icon="save"/>
                              </Button>
                              : <div>Saving...</div>
                          ),
                          sortable: false
                        },
                        {
                          Header: "Delete",
                          accessor: "d",
                          Cell: ({row}) => (
                            <Button
                              variant="link" className="btn-row border-radius-4"
                              onClick={() => openDeleteResellerCommission(row._original)}
                            >
                              <FontAwesomeIcon icon="trash"/>
                            </Button>),
                          sortable: false
                        }
                      ]}
                      defaultSorted={[
                        {
                          id: "dingProductName",
                          desc: false
                        }
                      ]}
                      data={filteredResellerCommission}
                      defaultPageSize={20}
                      pageSizeOptions={[5, 10, 20]}
                      className="-striped -highlight"
                      minRows={0}
                      NoDataComponent={() => null}
                    >
                      {(state, makeTable) => {
                        return (
                          <div>
                            {makeTable()}
                            <TableInfo
                              totalRows={state.sortedData.length}
                              page={state.page}
                              pageSize={state.pageSize}
                            />
                          </div>
                        )
                      }}
                    </ReactTable>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </Col>
        <AddResellerCommission
          inProgress={inProgress}
          show={showAddResellerCommission}
          onHide={closeAddResellerCommission}
          submitAddResellerCommission={submitAddResellerCommission}
          plans={plans}
        />
        <DeleteResellerCommission
          inProgress={inProgress}
          show={showDeleteResellerCommission}
          onHide={closeDeleteResellerCommission}
          submitDeleteResellerCommission={submitDeleteResellerCommission}
          selectedResellerCommission={selectedResellerCommission}
        />
      </Row>
    );
  }
}

export default ResellerCommission;