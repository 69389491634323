import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Col, Row, Nav, Form, Button, Spinner} from "react-bootstrap";
import agent from "../../agent";
import {Formik} from "formik";
import * as Yup from "yup";
import moment from 'moment';

import Users from './Users'
import HomeBanner from './HomeBanner'
import Message from '../Message'
import Countries from './Countries';
import Transactions from './Transactions';
import Transferences from './Transferences';
import PaymentNotifications from './PaymentNotifications';
import LotPayments from './LotPayments';
import Summary from "./Summary";
import Resellers from "./Resellers";
import ResellerCommission from "./ResellerCommission";
import Providers from "./Providers";
import OperatorCategories from "./OperatorCategories";
import PaymentMethods from "./PaymentMethods";
import PaymentApis from "./PaymentApis";
import NautaRecharges from "./NautaRecharges";

import './Admin.css';

import {
  ADMIN_PAGE_UNLOADED,
  ADMIN_CHECK_PIN_NUMBER,
  UPDATE_FIELD_ADMIN,
  RESET_ACTION,
  ADMIN_LOAD_TAB_USERS,
  ADMIN_LOAD_USERS,
  ADMIN_ADD_USER,
  ADMIN_EDIT_USER,
  ADMIN_CHANGE_USER_STATE,
  ADMIN_RESET_DEBT_USER,
  ADMIN_SHOW_DELETE_USER,
  ADMIN_DELETE_USER,
  ADMIN_SHOW_CHANGE_USER_STATE,
  ADMIN_SHOW_EDIT_USER,
  ADMIN_LOAD_DING_PRODUCT_RATE,
  ADMIN_UPDATE_COMMISSION_CONFIGURATION,
  ADMIN_SET_COMMISSION_CONFIGURATION,
  ADMIN_SHOW_USER_RESET_DEBT,
  ADMIN_SET_PIN_NUMBER,
  ADMIN_LOAD_TAB_COUNTRIES,
  ADMIN_SEARCH_COUNTRY,
  ADMIN_ADD_COUNTRY,
  ADMIN_UPDATE_COUNTRY,
  ADMIN_DELETE_COUNTRY,
  ADMIN_SHOW_DELETE_COUNTRY,
  ADMIN_SHOW_EDIT_COUNTRY,
  ADMIN_LOAD_TAB_TRANSACTIONS,
  ADMIN_LOAD_TRANSACTIONS,
  ADMIN_SHOW_TRANSACTION_DETAIL,
  ADMIN_LOAD_TAB_TRANSFERENCES,
  ADMIN_LOAD_TRANSFERENCES,
  ADMIN_LOAD_TAB_PAYMENT_NOTIFICATIONS,
  ADMIN_LOAD_PAYMENT_NOTIFICATIONS,
  ADMIN_SHOW_PAYMENT_NOTIFICATION_DETAIL,
  ADMIN_LOAD_TAB_LOT_PAYMENTS,
  ADMIN_LOAD_LOT_PAYMENTS,
  ADMIN_SHOW_LOT_PAYMENT_DETAIL,
  ADMIN_LOAD_TAB_SUMMARY,
  ADMIN_LOAD_TRANSACTIONS_SUMMARY,
  ADMIN_LOAD_SIM_ACTIVATIONS_SUMMARY,
  ADMIN_SHOW_SIM_ACTIVATION_SUMMARY_DETAIL,
  ADMIN_LOAD_TAB_NAUTA_RECHARGES,
  ADMIN_LOAD_NAUTA_RECHARGES,
  ADMIN_SHOW_NAUTA_RECHARGE_DETAIL,
  ADMIN_LOAD_TAB_RESELLERS,
  ADMIN_SEARCH_RESELLER,
  ADMIN_LOAD_TAB_RESELLER_COMMISSION,
  ADMIN_SEARCH_RESELLER_COMMISSION,
  ADMIN_SHOW_DELETE_RESELLER_COMMISSION,
  ADMIN_UPDATE_COMMISSION,
  ADMIN_UPDATE_MASTER_COMMISSION,
  ADMIN_SET_COMMISSION,
  ADMIN_LOAD_PLANS,
  ADMIN_ADD_RESELLER_COMMISSION,
  ADMIN_DELETE_RESELLER_COMMISSION,
  ADMIN_LOAD_TAB_PROVIDERS,
  ADMIN_SEARCH_PROVIDER,
  ADMIN_SHOW_DELETE_PROVIDER,
  ADMIN_SHOW_EDIT_PROVIDER,
  ADMIN_ADD_PROVIDER,
  ADMIN_FILL_PROVIDERS,
  ADMIN_DELETE_PROVIDER,
  ADMIN_UPDATE_PROVIDER,
  ADMIN_LOAD_TAB_PAYMENT_METHODS,
  ADMIN_SEARCH_PAYMENT_METHOD,
  ADMIN_SHOW_DELETE_PAYMENT_METHOD,
  ADMIN_SHOW_EDIT_PAYMENT_METHOD,
  ADMIN_ADD_PAYMENT_METHOD,
  ADMIN_DELETE_PAYMENT_METHOD,
  ADMIN_UPDATE_PAYMENT_METHOD,
  ADMIN_LOAD_TAB_OPERATOR_CATEGORIES,
  ADMIN_SEARCH_OPERATOR_CATEGORY,
  ADMIN_ADD_OPERATOR_CATEGORY,
  ADMIN_SHOW_EDIT_OPERATOR_CATEGORY,
  ADMIN_SHOW_DELETE_OPERATOR_CATEGORY,
  ADMIN_DELETE_OPERATOR_CATEGORY,
  ADMIN_UPDATE_OPERATOR_CATEGORY,
  ADMIN_LOAD_TAB_PAYMENT_APIS,
  ADMIN_SEARCH_PAYMENT_API,
  ADMIN_ADD_PAYMENT_API,
  ADMIN_SHOW_EDIT_PAYMENT_API,
  ADMIN_SHOW_DELETE_PAYMENT_API,
  ADMIN_DELETE_PAYMENT_API,
  ADMIN_UPDATE_PAYMENT_API,
  ADMIN_SEARCH_RESELLER_CONFIGURATION,
} from '../../constants/actionTypes';

const mapStateToProps = state => ({
  ...state.common,
  ...state.admin
});

const mapDispatchToProps = (dispatch) => ({
  onLoad: () => {
    dispatch({type: ADMIN_LOAD_TAB_USERS});
  },
  onUnload: () =>
    dispatch({type: ADMIN_PAGE_UNLOADED}),
  onSubmitCheckPinNumber: (user, pin) => {
    const payload = agent.User.checkPinNumber(user, pin);
    dispatch({type: ADMIN_CHECK_PIN_NUMBER, payload});
  },
  onResetAction: () =>
    dispatch({type: RESET_ACTION}),
  onChangeTab: value => {
    if (value === 'users') {
      dispatch({type: ADMIN_LOAD_TAB_USERS});
    } else if (value === 'countries') {
      const payload = agent.Country.getAll();
      dispatch({type: ADMIN_LOAD_TAB_COUNTRIES, payload});
    } else if (value === 'transactions') {
      dispatch({type: ADMIN_LOAD_TAB_TRANSACTIONS});
    } else if (value === 'transferences') {
      dispatch({type: ADMIN_LOAD_TAB_TRANSFERENCES});
    } else if (value === 'paymentNotifications') {
      dispatch({type: ADMIN_LOAD_TAB_PAYMENT_NOTIFICATIONS});
    } else if (value === 'lotPayments') {
      dispatch({type: ADMIN_LOAD_TAB_LOT_PAYMENTS});
    } else if (value === 'summary') {
      dispatch({type: ADMIN_LOAD_TAB_SUMMARY});
    } else if (value === 'nautaRecharges') {
      dispatch({type: ADMIN_LOAD_TAB_NAUTA_RECHARGES});
    } else if (value === 'resellers') {
      const payload = agent.Admin.resellers();
      dispatch({type: ADMIN_LOAD_TAB_RESELLERS, payload});
    } else if (value === 'commissionsConfiguration') {
      const payload = agent.Admin.resellerCommission();
      dispatch({type: ADMIN_LOAD_TAB_RESELLER_COMMISSION, payload});
      const payloadPlans = agent.Admin.plans();
      dispatch({type: ADMIN_LOAD_PLANS, payload: payloadPlans});
    } else if (value === 'providers') {
      const payload = Promise.all([
        agent.Country.getAll(),
        agent.Provider.categories(),
        agent.Provider.getAll()
      ]);
      dispatch({type: ADMIN_LOAD_TAB_PROVIDERS, payload});
    } else if (value === 'paymentMethods') {
      const payload = agent.PaymentMethod.getAll();
      dispatch({type: ADMIN_LOAD_TAB_PAYMENT_METHODS, payload});
    } else if (value === 'operatorCategories') {
      const payload = agent.OperatorCategory.getAll();
      dispatch({type: ADMIN_LOAD_TAB_OPERATOR_CATEGORIES, payload});
    } else if (value === 'paymentApis') {
      const payload = agent.PaymentApi.getAll();
      dispatch({type: ADMIN_LOAD_TAB_PAYMENT_APIS, payload});
    }
    dispatch({type: UPDATE_FIELD_ADMIN, key: 'currentTab', value});
  },

  // Users
  onLoadUsers: (usersState, page, pageSize, sort, ascending, user) => {
    const payload = agent.Admin.users(page, pageSize, sort, ascending, user);
    dispatch({type: ADMIN_LOAD_USERS, payload, usersState});
  },
  onToggleShowAddUser: (value) =>
    dispatch({type: UPDATE_FIELD_ADMIN, key: 'showAddUser', value}),
  onSubmitAddUser: (user) => {
    const payload = agent.User.registerWithRoles(user);
    dispatch({type: ADMIN_ADD_USER, payload});
  },
  onToggleShowDeleteUser: (show, value) =>
    dispatch({type: ADMIN_SHOW_DELETE_USER, show, value}),
  onSubmitDeleteUser: (id) => {
    const payload = agent.User.delete(id);
    dispatch({type: ADMIN_DELETE_USER, payload});
  },
  onToggleShowResetDebtUser: (show, value) =>
    dispatch({type: ADMIN_SHOW_USER_RESET_DEBT, show, value}),
  onSubmitResetDebtUser: (id) => {
    const payload = agent.User.resetDebt(id);
    dispatch({type: ADMIN_RESET_DEBT_USER, payload});
  },
  onToggleShowChangeUserState: (show, value) =>
  dispatch({type: ADMIN_SHOW_CHANGE_USER_STATE, show, value}),

  onToggleShowEditUser: (show, value) =>
    dispatch({type: ADMIN_SHOW_EDIT_USER, show, value}),

  onChangeEditUserTab: (tab) =>
    dispatch({type: UPDATE_FIELD_ADMIN, key: 'currentEditUserTab', value: tab}),
  onLoadDingProductRate: (user) => {
    const payload = Promise.all([
      agent.Admin.resellerCommission(),
      agent.User.commissionConfiguration(user.id)
    ]);
    dispatch({type: ADMIN_LOAD_DING_PRODUCT_RATE, payload});
  },
  onUpdateFilterResellerConfiguration: (value) =>
    dispatch({type: UPDATE_FIELD_ADMIN, key: 'resellerConfigurationFilterPlan', value}),
  onSubmitSearchResellerConfiguration: () =>
    dispatch({type: ADMIN_SEARCH_RESELLER_CONFIGURATION}),
  onUpdateCommissionConfiguration: (id, value) =>
    dispatch({type: ADMIN_UPDATE_COMMISSION_CONFIGURATION, id, value}),
  onSubmitSaveCommissionConfiguration: (configuration) => {
    const payload = agent.User.setCommissionConfiguration(configuration);
    dispatch({type: ADMIN_SET_COMMISSION_CONFIGURATION, payload});
  },
  onSubmitChangeUserState: async (userName, data) => {
    try {
      dispatch({type: UPDATE_FIELD_ADMIN, key: 'inProgress', value: true});
      const response = await agent.User.getByUsername(userName);
      let user = response.body;
      user.emailConfirmed = data.emailconfirmed;

      const payload = await agent.User.updateWithRoles(user.id, user);
      dispatch({type: ADMIN_CHANGE_USER_STATE, payload});
    } catch (err) {
      dispatch({type: UPDATE_FIELD_ADMIN, key: 'inProgress', value: false});
      dispatch({
        type: ADMIN_CHANGE_USER_STATE,
        payload: {message: err.response.body ? err.response.body.message : 'Petition can not be completed'},
        error: true,
      });
    }
  },
  onSubmitEditUser: async (userName, data) => {
    try {
      dispatch({type: UPDATE_FIELD_ADMIN, key: 'inProgress', value: true});
      const response = await agent.User.getByUsername(userName);
      let user = response.body;
      user.firstName = data.firstName;
      user.lastName = data.lastName;
      user.email = data.email;
      user.balance = data.balance;
      user.credit = data.credit;
      user.debt = data.debt;
      user.phoneNumber = data.phoneNumber;
      user.unlockBalance = data.unlockBalance;
      user.isReseller = data.isReseller;
      user.active = data.active;
      user.roles = data.rolesToAssign;
      const payload = await agent.User.updateWithRoles(user.id, user);
      dispatch({type: ADMIN_EDIT_USER, payload});
    } catch (err) {
      dispatch({type: UPDATE_FIELD_ADMIN, key: 'inProgress', value: false});
      dispatch({
        type: ADMIN_EDIT_USER,
        payload: {message: err.response.body ? err.response.body.message : 'Petition can not be completed'},
        error: true,
      });
    }
  },
  onSubmitSetPinNumber: async (id, data) => {
    const payload = agent.User.updatePinNumber(id, data);
    dispatch({type: ADMIN_SET_PIN_NUMBER, payload});
  },

  // Countries
  onUpdateFilterCountry: (value) =>
    dispatch({type: UPDATE_FIELD_ADMIN, key: 'countriesFilterCountry', value}),
  onSubmitSearchCountry: () =>
    dispatch({type: ADMIN_SEARCH_COUNTRY}),
  onToggleShowAddCountry: (value) =>
    dispatch({type: UPDATE_FIELD_ADMIN, key: 'showAddCountry', value}),
  onSubmitAddCountry: async (country) => {
    dispatch({type: UPDATE_FIELD_ADMIN, key: 'inProgress', value: true});
    const payload = await agent.Country.add(country);
    dispatch({type: ADMIN_ADD_COUNTRY, payload});
    const payloadCountries = await agent.Country.getAll();
    dispatch({type: UPDATE_FIELD_ADMIN, key: 'countries', value: payloadCountries.body ? payloadCountries.body : []});
    dispatch({type: ADMIN_SEARCH_COUNTRY});
  },
  onToggleShowDeleteCountry: (show, value) =>
    dispatch({type: ADMIN_SHOW_DELETE_COUNTRY, show, value}),
  onSubmitDeleteCountry: async (id) => {
    dispatch({type: UPDATE_FIELD_ADMIN, key: 'inProgress', value: true});
    const payload = await agent.Country.delete(id);
    dispatch({type: ADMIN_DELETE_COUNTRY, payload});
    const payloadCountries = await agent.Country.getAll();
    dispatch({type: UPDATE_FIELD_ADMIN, key: 'countries', value: payloadCountries.body ? payloadCountries.body : []});
    dispatch({type: ADMIN_SEARCH_COUNTRY});
  },
  onToggleShowEditCountry: (show, value) =>
    dispatch({type: ADMIN_SHOW_EDIT_COUNTRY, show, value}),
  onSubmitEditCountry: async (id, country) => {
    dispatch({type: UPDATE_FIELD_ADMIN, key: 'inProgress', value: true});
    const payload = await agent.Country.update(id, country);
    dispatch({type: ADMIN_UPDATE_COUNTRY, payload});
    const payloadCountries = await agent.Country.getAll();
    dispatch({type: UPDATE_FIELD_ADMIN, key: 'countries', value: payloadCountries.body ? payloadCountries.body : []});
    dispatch({type: ADMIN_SEARCH_COUNTRY});
  },

  // Transactions
  onLoadTransactions: (transactionsState, page, pageSize, sort, ascending, service, fromDate, toDate, phoneNumber, userName, superUser, successfulStatus) => {
    const payload = agent.Admin.transactions(page, pageSize, sort, ascending, service, fromDate, toDate, phoneNumber, userName, superUser, successfulStatus);
    dispatch({type: ADMIN_LOAD_TRANSACTIONS, payload, transactionsState});
  },
  onToggleShowTransactionDetail: (show, value) =>
    dispatch({type: ADMIN_SHOW_TRANSACTION_DETAIL, show, value}),

  // Transferences
  onLoadTransferences: (transferencesState, page, pageSize, sort, ascending, fromDate, toDate, fromUserName, toUserName) => {
    const payload = agent.Admin.transferences(page, pageSize, sort, ascending, fromDate, toDate, fromUserName, toUserName);
    dispatch({type: ADMIN_LOAD_TRANSFERENCES, payload, transferencesState});
  },

  // Payment Notifications
  onLoadPaymentNotifications: (paymentNotificationsState, page, pageSize, fromDate, toDate, userName) => {
    const payload = agent.Admin.paymentNotifications(page, pageSize, fromDate, toDate, userName);
    dispatch({type: ADMIN_LOAD_PAYMENT_NOTIFICATIONS, payload, paymentNotificationsState});
  },
  onToggleShowPaymentNotificationDetail: (show, value) =>
    dispatch({type: ADMIN_SHOW_PAYMENT_NOTIFICATION_DETAIL, show, value}),

  // Lot Payments
  onLoadLotPayments: (lotPaymentsState, page, pageSize, sort, ascending, userName) => {
    const payload = agent.Admin.lotPayments(page, pageSize, sort, ascending, userName);
    dispatch({type: ADMIN_LOAD_LOT_PAYMENTS, payload, lotPaymentsState});
  },
  onToggleShowLotPaymentDetail: (show, value) =>
    dispatch({type: ADMIN_SHOW_LOT_PAYMENT_DETAIL, show, value}),

  // Summary
  onLoadTransactionsSummary: (transactionsSummaryState, page, pageSize, sort, ascending, fromDate, toDate, userName, superUser) => {
    const payload = agent.Admin.transactionsSummary(page, pageSize, sort, ascending, fromDate, toDate, userName, superUser);
    dispatch({type: ADMIN_LOAD_TRANSACTIONS_SUMMARY, payload, transactionsSummaryState});
  },
  onLoadSimActivationsSummary: (simActivationsSummaryState, page, pageSize, sort, ascending, fromDate, toDate, userName) => {
    const payload = agent.Admin.simActivationsSummary(page, pageSize, sort, ascending, fromDate, toDate, userName);
    dispatch({type: ADMIN_LOAD_SIM_ACTIVATIONS_SUMMARY, payload, simActivationsSummaryState});
  },
  onToggleShowSimActivationSummaryDetail: (show, value) =>
    dispatch({type: ADMIN_SHOW_SIM_ACTIVATION_SUMMARY_DETAIL, show, value}),

  // Nauta Recharges
  onLoadNautaRecharges: (nautaRechargesState, page, pageSize, sort, ascending, userName) => {
    const payload = agent.Admin.nautaRecharges(page, pageSize, sort, ascending, userName);
    dispatch({type: ADMIN_LOAD_NAUTA_RECHARGES, payload, nautaRechargesState});
  },
  onToggleShowNautaRechargeDetail: (show, value) =>
    dispatch({type: ADMIN_SHOW_NAUTA_RECHARGE_DETAIL, show, value}),

  // Resellers
  onLoadResellers: async () => {
    const payload = await agent.Admin.resellers();
    dispatch({type: UPDATE_FIELD_ADMIN, key: 'resellers', value: payload.body});
  },
  onUpdateFilterReseller: (value) =>
    dispatch({type: UPDATE_FIELD_ADMIN, key: 'resellersFilterReseller', value}),
  onSubmitSearchReseller: () =>
    dispatch({type: ADMIN_SEARCH_RESELLER}),

  // Reseller Commission
  onUpdateFilterResellerCommission: (value) =>
    dispatch({type: UPDATE_FIELD_ADMIN, key: 'resellerCommissionFilterPlan', value}),
  onSubmitSearchResellerCommission: () =>
    dispatch({type: ADMIN_SEARCH_RESELLER_COMMISSION}),
  onToggleShowAddResellerCommission: (value) =>
    dispatch({type: UPDATE_FIELD_ADMIN, key: 'showAddResellerCommission', value}),
  onSubmitAddResellerCommission: async (value) => {
    dispatch({type: UPDATE_FIELD_ADMIN, key: 'inProgress', value: true});
    const payload = await agent.Admin.addResellerCommission(value);
    dispatch({type: ADMIN_ADD_RESELLER_COMMISSION, payload});
    const payloadData = await agent.Admin.resellerCommission();
    dispatch({type: UPDATE_FIELD_ADMIN, key: 'resellerCommission', value: payloadData.body});
    dispatch({type: ADMIN_SEARCH_RESELLER_COMMISSION});
  },
  onToggleShowDeleteResellerCommission: (show, value) =>
    dispatch({type: ADMIN_SHOW_DELETE_RESELLER_COMMISSION, show, value}),
  onSubmitDeleteResellerCommission: async (id) => {
    dispatch({type: UPDATE_FIELD_ADMIN, key: 'inProgress', value: true});
    const payload = await agent.Admin.deleteResellerCommission(id);
    dispatch({type: ADMIN_DELETE_RESELLER_COMMISSION, payload});
    const payloadData = await agent.Admin.resellerCommission();
    dispatch({type: UPDATE_FIELD_ADMIN, key: 'resellerCommission', value: payloadData.body});
    dispatch({type: ADMIN_SEARCH_RESELLER_COMMISSION});
  },
  onUpdateCommission: (id, value) =>
    dispatch({type: ADMIN_UPDATE_COMMISSION, id, value}),
  onUpdateMasterCommission: (id, value) =>
    dispatch({type: ADMIN_UPDATE_MASTER_COMMISSION, id, value}),
  onSubmitSetCommission: (id, commission, masterCommission) => {
    dispatch({type: UPDATE_FIELD_ADMIN, key: 'inProgressSave', value: true});
    dispatch({type: UPDATE_FIELD_ADMIN, key: 'selectedResellerCommissionId', value: id});
    agent.Admin.setCommission(id, masterCommission)
      .then(() => {
        agent.Admin.setCommission(id, commission)
          .then((response) => {
            dispatch({type: ADMIN_SET_COMMISSION, response});
          })
          .catch(err => {
            console.log(err);
            dispatch({type: UPDATE_FIELD_ADMIN, key: 'inProgressSave', value: false});
            dispatch({
              type: ADMIN_SET_COMMISSION,
              payload: {message: err.response.body ? err.response.body.message : 'Petition can not be completed'},
              error: true
            });
          });
      })
      .catch(err => {
        console.log(err);
        dispatch({type: UPDATE_FIELD_ADMIN, key: 'inProgressSave', value: false});
        dispatch({
          type: ADMIN_SET_COMMISSION,
          payload: {message: err.response.body ? err.response.body.message : 'Petition can not be completed'},
          error: true
        });
      });
  },

  // Providers
  onUpdateFilterProvider: (value) =>
    dispatch({type: UPDATE_FIELD_ADMIN, key: 'providersFilterProvider', value}),
  onSubmitSearchProvider: () =>
    dispatch({type: ADMIN_SEARCH_PROVIDER}),
  onToggleShowAddProvider: (value) =>
    dispatch({type: UPDATE_FIELD_ADMIN, key: 'showAddProvider', value}),
  onSubmitAddProvider: async (provider) => {
    dispatch({type: UPDATE_FIELD_ADMIN, key: 'inProgress', value: true});
    const payload = await agent.Provider.add(provider);
    dispatch({type: ADMIN_ADD_PROVIDER, payload});
    const payloadProviders = await agent.Provider.getAll();
    dispatch({type: UPDATE_FIELD_ADMIN, key: 'providers', value: payloadProviders.body ? payloadProviders.body : []});
    dispatch({type: ADMIN_FILL_PROVIDERS});
    dispatch({type: ADMIN_SEARCH_PROVIDER});
  },
  onToggleShowDeleteProvider: (show, value) =>
    dispatch({type: ADMIN_SHOW_DELETE_PROVIDER, show, value}),
  onSubmitDeleteProvider: async (id) => {
    dispatch({type: UPDATE_FIELD_ADMIN, key: 'inProgress', value: true});
    const payload = await agent.Provider.delete(id);
    dispatch({type: ADMIN_DELETE_PROVIDER, payload});
    const payloadProviders = await agent.Provider.getAll();
    dispatch({type: UPDATE_FIELD_ADMIN, key: 'providers', value: payloadProviders.body ? payloadProviders.body : []});
    dispatch({type: ADMIN_FILL_PROVIDERS});
    dispatch({type: ADMIN_SEARCH_PROVIDER});
  },
  onToggleShowEditProvider: (show, value) =>
    dispatch({type: ADMIN_SHOW_EDIT_PROVIDER, show, value}),
  onSubmitEditProvider: async (id, provider) => {
    dispatch({type: UPDATE_FIELD_ADMIN, key: 'inProgress', value: true});
    const payload = await agent.Provider.update(id, provider);
    dispatch({type: ADMIN_UPDATE_PROVIDER, payload});
    const payloadProviders = await agent.Provider.getAll();
    dispatch({type: UPDATE_FIELD_ADMIN, key: 'providers', value: payloadProviders.body ? payloadProviders.body : []});
    dispatch({type: ADMIN_FILL_PROVIDERS});
    dispatch({type: ADMIN_SEARCH_PROVIDER});
  },

  // Payment Methods
  onUpdateFilterPaymentMethod: (value) =>
    dispatch({type: UPDATE_FIELD_ADMIN, key: 'paymentMethodsFilterMethod', value}),
  onSubmitSearchPaymentMethod: () =>
    dispatch({type: ADMIN_SEARCH_PAYMENT_METHOD}),
  onToggleShowAddPaymentMethod: (value) =>
    dispatch({type: UPDATE_FIELD_ADMIN, key: 'showAddPaymentMethod', value}),
  onSubmitAddPaymentMethod: async (paymentMethod) => {
    dispatch({type: UPDATE_FIELD_ADMIN, key: 'inProgress', value: true});
    const payload = await agent.PaymentMethod.add(paymentMethod);
    dispatch({type: ADMIN_ADD_PAYMENT_METHOD, payload});
    const payloadPaymentMethods = await agent.PaymentMethod.getAll();
    dispatch({
      type: UPDATE_FIELD_ADMIN,
      key: 'paymentMethods',
      value: payloadPaymentMethods.body ? payloadPaymentMethods.body : []
    });
    dispatch({type: ADMIN_SEARCH_PAYMENT_METHOD});
  },
  onToggleShowDeletePaymentMethod: (show, value) =>
    dispatch({type: ADMIN_SHOW_DELETE_PAYMENT_METHOD, show, value}),
  onSubmitDeletePaymentMethod: async (id) => {
    dispatch({type: UPDATE_FIELD_ADMIN, key: 'inProgress', value: true});
    const payload = await agent.PaymentMethod.delete(id);
    dispatch({type: ADMIN_DELETE_PAYMENT_METHOD, payload});
    const payloadPaymentMethods = await agent.PaymentMethod.getAll();
    dispatch({
      type: UPDATE_FIELD_ADMIN,
      key: 'paymentMethods',
      value: payloadPaymentMethods.body ? payloadPaymentMethods.body : []
    });
    dispatch({type: ADMIN_SEARCH_PAYMENT_METHOD});
  },
  onToggleShowEditPaymentMethod: (show, value) =>
    dispatch({type: ADMIN_SHOW_EDIT_PAYMENT_METHOD, show, value}),
  onSubmitEditPaymentMethod: async (id, paymentMethod) => {
    dispatch({type: UPDATE_FIELD_ADMIN, key: 'inProgress', value: true});
    const payload = await agent.PaymentMethod.update(id, paymentMethod);
    dispatch({type: ADMIN_UPDATE_PAYMENT_METHOD, payload});
    const payloadPaymentMethods = await agent.PaymentMethod.getAll();
    dispatch({
      type: UPDATE_FIELD_ADMIN,
      key: 'paymentMethods',
      value: payloadPaymentMethods.body ? payloadPaymentMethods.body : []
    });
    dispatch({type: ADMIN_SEARCH_PAYMENT_METHOD});
  },

  // Operator Categories
  onUpdateFilterOperatorCategory: (value) =>
    dispatch({type: UPDATE_FIELD_ADMIN, key: 'operatorCategoriesFilterCategory', value}),
  onSubmitSearchOperatorCategory: () =>
    dispatch({type: ADMIN_SEARCH_OPERATOR_CATEGORY}),
  onToggleShowAddOperatorCategory: (value) =>
    dispatch({type: UPDATE_FIELD_ADMIN, key: 'showAddOperatorCategory', value}),
  onSubmitAddOperatorCategory: async (category) => {
    dispatch({type: UPDATE_FIELD_ADMIN, key: 'inProgress', value: true});
    const payload = await agent.OperatorCategory.add(category);
    dispatch({type: ADMIN_ADD_OPERATOR_CATEGORY, payload});
    const payloadOperatorCategories = await agent.OperatorCategory.getAll();
    dispatch({
      type: UPDATE_FIELD_ADMIN,
      key: 'operatorCategories',
      value: payloadOperatorCategories.body ? payloadOperatorCategories.body : []
    });
    dispatch({type: ADMIN_SEARCH_OPERATOR_CATEGORY});
  },
  onToggleShowDeleteOperatorCategory: (show, value) =>
    dispatch({type: ADMIN_SHOW_DELETE_OPERATOR_CATEGORY, show, value}),
  onSubmitDeleteOperatorCategory: async (id) => {
    dispatch({type: UPDATE_FIELD_ADMIN, key: 'inProgress', value: true});
    const payload = await agent.OperatorCategory.delete(id);
    dispatch({type: ADMIN_DELETE_OPERATOR_CATEGORY, payload});
    const payloadOperatorCategories = await agent.OperatorCategory.getAll();
    dispatch({
      type: UPDATE_FIELD_ADMIN,
      key: 'operatorCategories',
      value: payloadOperatorCategories.body ? payloadOperatorCategories.body : []
    });
    dispatch({type: ADMIN_SEARCH_OPERATOR_CATEGORY});
  },
  onToggleShowEditOperatorCategory: (show, value) =>
    dispatch({type: ADMIN_SHOW_EDIT_OPERATOR_CATEGORY, show, value}),
  onSubmitEditOperatorCategory: async (id, category) => {
    dispatch({type: UPDATE_FIELD_ADMIN, key: 'inProgress', value: true});
    const payload = await agent.OperatorCategory.update(id, category);
    dispatch({type: ADMIN_UPDATE_OPERATOR_CATEGORY, payload});
    const payloadOperatorCategories = await agent.OperatorCategory.getAll();
    dispatch({
      type: UPDATE_FIELD_ADMIN,
      key: 'operatorCategories',
      value: payloadOperatorCategories.body ? payloadOperatorCategories.body : []
    });
    dispatch({type: ADMIN_SEARCH_OPERATOR_CATEGORY});
  },

  // Payment Apis
  onUpdateFilterPaymentApi: (value) =>
    dispatch({type: UPDATE_FIELD_ADMIN, key: 'paymentApisFilterName', value}),
  onSubmitSearchPaymentApi: () =>
    dispatch({type: ADMIN_SEARCH_PAYMENT_API}),
  onToggleShowAddPaymentApi: (value) =>
    dispatch({type: UPDATE_FIELD_ADMIN, key: 'showAddPaymentApi', value}),
  onSubmitAddPaymentApi: async (api) => {
    dispatch({type: UPDATE_FIELD_ADMIN, key: 'inProgress', value: true});
    const payload = await agent.PaymentApi.add(api);
    dispatch({type: ADMIN_ADD_PAYMENT_API, payload});
    const payloadPaymentApis = await agent.PaymentApi.getAll();
    dispatch({
      type: UPDATE_FIELD_ADMIN, key: 'paymentApis', value: payloadPaymentApis.body ? payloadPaymentApis.body : []
    });
    dispatch({type: ADMIN_SEARCH_PAYMENT_API});
  },
  onToggleShowDeletePaymentApi: (show, value) =>
    dispatch({type: ADMIN_SHOW_DELETE_PAYMENT_API, show, value}),
  onSubmitDeletePaymentApi: async (id) => {
    dispatch({type: UPDATE_FIELD_ADMIN, key: 'inProgress', value: true});
    const payload = await agent.PaymentApi.delete(id);
    dispatch({type: ADMIN_DELETE_PAYMENT_API, payload});
    const payloadPaymentApis = await agent.PaymentApi.getAll();
    dispatch({
      type: UPDATE_FIELD_ADMIN, key: 'paymentApis', value: payloadPaymentApis.body ? payloadPaymentApis.body : []
    });
    dispatch({type: ADMIN_SEARCH_PAYMENT_API});
  },
  onToggleShowEditPaymentApi: (show, value) =>
    dispatch({type: ADMIN_SHOW_EDIT_PAYMENT_API, show, value}),
  onSubmitEditPaymentApi: async (id, api) => {
    dispatch({type: UPDATE_FIELD_ADMIN, key: 'inProgress', value: true});
    const payload = await agent.PaymentApi.update(id, api);
    dispatch({type: ADMIN_UPDATE_PAYMENT_API, payload});
    const payloadPaymentApis = await agent.PaymentApi.getAll();
    dispatch({
      type: UPDATE_FIELD_ADMIN, key: 'paymentApis', value: payloadPaymentApis.body ? payloadPaymentApis.body : []
    });
    dispatch({type: ADMIN_SEARCH_PAYMENT_API});
  },
});

class Admin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mobileView: window.innerWidth <= 600
    };

    this.submitCheckPinNumber = (pin) => this.props.onSubmitCheckPinNumber(this.props.currentUser, pin);

    // Users
    this.loadUsers = (state) => {
      let sort = 'date';
      let ascending = 'false';
      if (state.sorted && state.sorted.length > 0) {
        sort = state.sorted[0].id;
        ascending = state.sorted[0].desc;
      }
      const user = state.filter.user;

      return this.props.onLoadUsers(state, parseInt(state.page) + 1, parseInt(state.pageSize), sort, ascending, user);
    };
    this.openAddUser = () => this.props.onToggleShowAddUser(true);
    this.closeAddUser = () => this.props.onToggleShowAddUser(false);
    this.submitAddUser = async (email, userName, firstName, lastName, phoneNumber, password, resellerPromoCode, isReseller, rolesToAssign) => {
      await this.props.onSubmitAddUser({
        email,
        userName,
        firstName,
        lastName,
        phoneNumber,
        password,
        resellerPromoCode,
        isReseller: isReseller === 'true',
        rolesToAssign
      });
      this.loadUsers(this.props.usersState);
    };

    this.openDeleteUser = value => this.props.onToggleShowDeleteUser(true, value);
    this.closeDeleteUser = () => this.props.onToggleShowDeleteUser(false, null);
    this.submitDeleteUser = async (id) => {
      await this.props.onSubmitDeleteUser(id);
      this.loadUsers(this.props.usersState);
    };

    this.openChangeUserState = value => this.props.onToggleShowChangeUserState(true, value);
    this.closeChangeUserState = () => this.props.onToggleShowChangeUserState(false, null);
    this.submitChangeUserState = async (userName, emailconfirmed) => {
      await this.props.onSubmitChangeUserState(userName, {
        emailconfirmed
      });
      this.loadUsers(this.props.usersState);
    };
    this.openEditUser = value => {
      this.props.onToggleShowEditUser(true, value);
      if (value.isreseller) {
        this.props.onLoadDingProductRate(value);
      }
    };
    this.submitSaveCommissionConfiguration = (userId, values) => {
      let configurations = [];
      for (let i = 0; i < values.length; i++) {
        const commission = parseFloat(values[i].discount);
        if (commission > 0) {
          const element = {
            productId: values[i].dingProductId,
            commission,
            maxValue: parseFloat(values[i].maxValue),
            minValue: parseFloat(values[i].minValue),
            productName: values[i].dingProductName
          };
          configurations.push(element);
        }
      }
      const payload = {
        userId,
        values: configurations
      };
      this.props.onSubmitSaveCommissionConfiguration(payload);
    };
    this.closeEditUser = () => this.props.onToggleShowEditUser(false, null);
    this.submitEditUser = async (userName, email, firstName, lastName, phoneNumber, isReseller, rolesToAssign, balance, unlockBalance, credit, debt, active) => {
      await this.props.onSubmitEditUser(userName, {
        email,
        firstName,
        lastName,
        phoneNumber,
        isReseller,
        rolesToAssign,
        balance,
        unlockBalance,
        credit,
        debt,
        active
      });
      this.loadUsers(this.props.usersState);
    };
    this.submitSetPinNumber = (id, pinNumber) => this.props.onSubmitSetPinNumber(id, {pinNumber});

    this.openResetDebtUser = value => this.props.onToggleShowResetDebtUser(true, value);
    this.closeResetDebtUser = () => this.props.onToggleShowResetDebtUser(false, null);
    this.submitResetDebtUser = (id) => this.props.onSubmitResetDebtUser(id);

    // Countries
    this.submitSearchCountry = (filter) => this.props.onSubmitSearchCountry(filter);

    this.openAddCountry = () => this.props.onToggleShowAddCountry(true);
    this.closeAddCountry = () => this.props.onToggleShowAddCountry(false);
    this.submitAddCountry = (countryName, isO2, longCountryName, isO3, numCode, unMemberState, callingCode, cctld, internationalRegion) => this.props.onSubmitAddCountry({
      countryName, isO2, longCountryName, isO3, numCode, unMemberState, callingCode, cctld, internationalRegion
    });

    this.openDeleteCountry = value => this.props.onToggleShowDeleteCountry(true, value);
    this.closeDeleteCountry = () => this.props.onToggleShowDeleteCountry(false, null);
    this.submitDeleteCountry = (id) => this.props.onSubmitDeleteCountry(id);

    this.openEditCountry = value => this.props.onToggleShowEditCountry(true, value);
    this.closeEditCountry = () => this.props.onToggleShowEditCountry(false, null);
    this.submitEditCountry = (id, countryName, isO2, longCountryName, isO3, numCode, unMemberState, callingCode, cctld, internationalRegion) => this.props.onSubmitEditCountry(id, {
      id,
      countryName,
      isO2,
      longCountryName,
      isO3,
      numCode,
      unMemberState,
      callingCode,
      cctld,
      internationalRegion
    });

    // Transactions
    this.loadTransactions = (state) => {
      let sort = 'date';
      let ascending = 'false';
      if (state.sorted.length > 0) {
        sort = state.sorted[0].id;
        ascending = state.sorted[0].desc;
      }

      const service = state.filter.service !== '' ? state.filter.service : '';
      const fromDate = state.filter.fromDate !== '' ? moment(state.filter.fromDate).format('YYYY-MM-DD') : '';
      const toDate = state.filter.toDate !== '' ? moment(state.filter.toDate).format('YYYY-MM-DD') : '';
      const phoneNumber = state.filter.phoneNumber !== '' ? state.filter.phoneNumber : '';
      const userName = state.filter.userName !== '' ? state.filter.userName : '';
      const superUser = state.filter.superUser !== '' ? state.filter.superUser : '';
      const successfulStatus = state.filter.successfulStatus !== '' ? state.filter.successfulStatus : '';

      return this.props.onLoadTransactions(state, parseInt(state.page) + 1, parseInt(state.pageSize), sort, ascending, service, fromDate, toDate, phoneNumber, userName, superUser, successfulStatus);
    };
    this.openTransactionDetail = value => this.props.onToggleShowTransactionDetail(true, value);
    this.closeTransactionDetail = () => this.props.onToggleShowTransactionDetail(false, null);

    // Transferences
    this.loadTransferences = (state) => {
      let sort = 'date';
      let ascending = 'false';
      if (state.sorted.length > 0) {
        sort = state.sorted[0].id;
        ascending = state.sorted[0].desc;
      }

      const fromDate = state.filter.fromDate !== '' ? moment(state.filter.fromDate).format('YYYY-MM-DD') : '';
      const toDate = state.filter.toDate !== '' ? moment(state.filter.toDate).format('YYYY-MM-DD') : '';
      const fromUserName = state.filter.fromUserName !== '' ? state.filter.fromUserName : '';
      const toUserName = state.filter.toUserName !== '' ? state.filter.toUserName : '';

      return this.props.onLoadTransferences(state, parseInt(state.page) + 1, parseInt(state.pageSize), sort, ascending, fromDate, toDate, fromUserName, toUserName);
    };

    // Payment Notifications
    this.loadPaymentNotifications = (state) => {
      const fromDate = state.filter.fromDate !== '' ? moment(state.filter.fromDate).format('YYYY-MM-DD') : '';
      const toDate = state.filter.toDate !== '' ? moment(state.filter.toDate).format('YYYY-MM-DD') : '';
      const userName = state.filter.userName !== '' ? state.filter.userName : '';

      return this.props.onLoadPaymentNotifications(state, parseInt(state.page) + 1, parseInt(state.pageSize), fromDate, toDate, userName);
    };
    this.openPaymentNotificationDetail = value => this.props.onToggleShowPaymentNotificationDetail(true, value);
    this.closePaymentNotificationDetail = () => this.props.onToggleShowPaymentNotificationDetail(false, null);

    // Lot Payments
    this.loadLotPayments = (state) => {
      let sort = 'date';
      let ascending = 'false';
      if (state.sorted.length > 0) {
        sort = state.sorted[0].id;
        ascending = state.sorted[0].desc;
      }

      const userName = state.filter.userName !== '' ? state.filter.userName : '';

      return this.props.onLoadLotPayments(state, parseInt(state.page) + 1, parseInt(state.pageSize), sort, ascending, userName);
    };
    this.openLotPaymentDetail = value => this.props.onToggleShowLotPaymentDetail(true, value);
    this.closeLotPaymentDetail = () => this.props.onToggleShowLotPaymentDetail(false, null);

    // Summary
    this.loadTransactionsSummary = (state) => {
      let sort = 'firstName';
      let ascending = 'false';
      if (state.sorted.length > 0) {
        sort = state.sorted[0].id;
        ascending = state.sorted[0].desc;
      }

      const fromDate = state.filter.fromDate !== '' ? moment(state.filter.fromDate).format('YYYY-MM-DD') : '';
      const toDate = state.filter.toDate !== '' ? moment(state.filter.toDate).format('YYYY-MM-DD') : '';
      const userName = state.filter.userName !== '' ? state.filter.userName : '';
      const superUser = state.filter.superUser !== '' ? state.filter.superUser : '';

      return this.props.onLoadTransactionsSummary(state, parseInt(state.page) + 1, parseInt(state.pageSize), sort, ascending, fromDate, toDate, userName, superUser);
    };
    this.loadSimActivationsSummary = (state) => {
      let sort = 'userName';
      let ascending = 'false';
      if (state.sorted.length > 0) {
        sort = state.sorted[0].id;
        ascending = state.sorted[0].desc;
      }

      const fromDate = state.filter.fromDate !== '' ? moment(state.filter.fromDate).format('YYYY-MM-DD') : '';
      const toDate = state.filter.toDate !== '' ? moment(state.filter.toDate).format('YYYY-MM-DD') : '';
      const userName = state.filter.userName !== '' ? state.filter.userName : '';

      return this.props.onLoadSimActivationsSummary(state, parseInt(state.page) + 1, parseInt(state.pageSize), sort, ascending, fromDate, toDate, userName);
    };
    this.openSimActivationSummaryDetail = value => this.props.onToggleShowSimActivationSummaryDetail(true, value);
    this.closeSimActivationSummaryDetail = () => this.props.onToggleShowSimActivationSummaryDetail(false, null);

    // Nauta Recharges
    this.loadNautaRecharges = (state) => {
      let sort = 'date';
      let ascending = 'false';
      if (state.sorted.length > 0) {
        sort = state.sorted[0].id;
        ascending = state.sorted[0].desc;
      }

      const userName = state.filter.userName !== '' ? state.filter.userName : '';

      return this.props.onLoadNautaRecharges(state, parseInt(state.page) + 1, parseInt(state.pageSize), sort, ascending, userName);
    };
    this.openNautaRechargeDetail = value => this.props.onToggleShowNautaRechargeDetail(true, value);
    this.closeNautaRechargeDetail = () => this.props.onToggleShowNautaRechargeDetail(false, null);

    // Resellers
    this.loadResellers = () => this.props.onLoadResellers();
    this.submitSearchReseller = () => this.props.onSubmitSearchReseller();

    this.submitAddReseller = async (email, userName, firstName, lastName, phoneNumber, password, resellerPromoCode, isReseller, rolesToAssign) => {
      await this.props.onSubmitAddUser({
        email,
        userName,
        firstName,
        lastName,
        phoneNumber,
        password,
        resellerPromoCode,
        isReseller: isReseller === 'true',
        rolesToAssign
      });
      await this.loadResellers();
      await this.submitSearchReseller();
    };

    this.submitDeleteReseller = (id) => this.props.onSubmitDeleteUser(id);

    this.submitEditReseller = async (userName, email, firstName, lastName, phoneNumber, isReseller, rolesToAssign, balance, unlockBalance, credit, debt, active) => {
      await this.props.onSubmitEditUser(userName, {
        email,
        firstName,
        lastName,
        phoneNumber,
        isReseller,
        rolesToAssign,
        balance,
        unlockBalance,
        credit,
        debt,
        active
      });
      await this.loadResellers();
      await this.submitSearchReseller();
    };

    // Reseller Commission
    this.submitSearchResellerCommission = (filter) => this.props.onSubmitSearchResellerCommission(filter);

    this.openAddResellerCommission = () => this.props.onToggleShowAddResellerCommission(true);
    this.closeAddResellerCommission = () => this.props.onToggleShowAddResellerCommission(false);
    this.submitAddResellerCommission = (dingPlan, commission, masterCommission) => this.props.onSubmitAddResellerCommission({
      'DingProductId': dingPlan.productId,
      'DingProductName': dingPlan.productName,
      'minValue': dingPlan.minValue,
      'maxValue': dingPlan.maxValue,
      commission,
      masterCommission
    });

    this.openDeleteResellerCommission = value => this.props.onToggleShowDeleteResellerCommission(true, value);
    this.closeDeleteResellerCommission = () => this.props.onToggleShowDeleteResellerCommission(false, null);
    this.submitDeleteResellerCommission = (id) => this.props.onSubmitDeleteResellerCommission(id);

    this.submitSetCommission = (id, commissionValue, masterCommissionValue) => {
      let commission = [];
      commission.push({
        op: 'replace',
        path: '/commission',
        value: commissionValue,
      });
      let masterCommission = [];
      masterCommission.push({
        op: 'replace',
        path: '/masterCommission',
        value: masterCommissionValue,
      });
      return this.props.onSubmitSetCommission(id, commission, masterCommission);
    };

    // Providers
    this.submitSearchProvider = (filter) => this.props.onSubmitSearchProvider(filter);

    this.openAddProvider = () => this.props.onToggleShowAddProvider(true);
    this.closeAddProvider = () => this.props.onToggleShowAddProvider(false);
    this.submitAddProvider = (name, description, imageBase64, imageFileName, countryId, operatorCategoryId, enabled, operatorCode, paymentApiId) => this.props.onSubmitAddProvider({
      name,
      description,
      imageBase64: imageBase64.split(',')[1],
      imageFileName,
      countryId,
      operatorCategoryId,
      enabled,
      operatorCode,
      paymentApiId
    });

    this.openDeleteProvider = value => this.props.onToggleShowDeleteProvider(true, value);
    this.closeDeleteProvider = () => this.props.onToggleShowDeleteProvider(false, null);
    this.submitDeleteProvider = (id) => this.props.onSubmitDeleteProvider(id);

    this.openEditProvider = value => this.props.onToggleShowEditProvider(true, value);
    this.closeEditProvider = () => this.props.onToggleShowEditProvider(false, null);
    this.submitEditProvider = (id, name, description, imageUrl, imageBase64, imageFileName, countryId, operatorCategoryId, enabled, operatorCode, paymentApiId) => this.props.onSubmitEditProvider(id, {
      id,
      name,
      description,
      imageUrl,
      imageBase64: imageBase64 !== '' ? imageBase64.split(',')[1] : '',
      imageFileName,
      countryId,
      operatorCategoryId,
      enabled,
      operatorCode,
      paymentApiId
    });

    // Payment Methods
    this.submitSearchPaymentMethod = (filter) => this.props.onSubmitSearchPaymentMethod(filter);

    this.openAddPaymentMethod = () => this.props.onToggleShowAddPaymentMethod(true);
    this.closeAddPaymentMethod = () => this.props.onToggleShowAddPaymentMethod(false);
    this.submitAddPaymentMethod = (name, fee) => this.props.onSubmitAddPaymentMethod({name, fee});

    this.openDeletePaymentMethod = value => this.props.onToggleShowDeletePaymentMethod(true, value);
    this.closeDeletePaymentMethod = () => this.props.onToggleShowDeletePaymentMethod(false, null);
    this.submitDeletePaymentMethod = (id) => this.props.onSubmitDeletePaymentMethod(id);

    this.openEditPaymentMethod = value => this.props.onToggleShowEditPaymentMethod(true, value);
    this.closeEditPaymentMethod = () => this.props.onToggleShowEditPaymentMethod(false, null);
    this.submitEditPaymentMethod = (id, name, fee) => this.props.onSubmitEditPaymentMethod(id, {id, name, fee});

    // Operator Categories
    this.submitSearchOperatorCategory = (filter) => this.props.onSubmitSearchOperatorCategory(filter);

    this.openAddOperatorCategory = () => this.props.onToggleShowAddOperatorCategory(true);
    this.closeAddOperatorCategory = () => this.props.onToggleShowAddOperatorCategory(false);
    this.submitAddOperatorCategory = (description) => this.props.onSubmitAddOperatorCategory({description});

    this.openDeleteOperatorCategory = value => this.props.onToggleShowDeleteOperatorCategory(true, value);
    this.closeDeleteOperatorCategory = () => this.props.onToggleShowDeleteOperatorCategory(false, null);
    this.submitDeleteOperatorCategory = (id) => this.props.onSubmitDeleteOperatorCategory(id);

    this.openEditOperatorCategory = value => this.props.onToggleShowEditOperatorCategory(true, value);
    this.closeEditOperatorCategory = () => this.props.onToggleShowEditOperatorCategory(false, null);
    this.submitEditOperatorCategory = (id, description) => this.props.onSubmitEditOperatorCategory(id, {
      id,
      description
    });

    // Payment Apis
    this.submitSearchPaymentApi = (filter) => this.props.onSubmitSearchPaymentApi(filter);

    this.openAddPaymentApi = () => this.props.onToggleShowAddPaymentApi(true);
    this.closeAddPaymentApi = () => this.props.onToggleShowAddPaymentApi(false);
    this.submitAddPaymentApi = (name, url, fee, username, password, rest) => this.props.onSubmitAddPaymentApi({
      name,
      url,
      fee,
      username,
      password,
      rest
    });

    this.openDeletePaymentApi = value => this.props.onToggleShowDeletePaymentApi(true, value);
    this.closeDeletePaymentApi = () => this.props.onToggleShowDeletePaymentApi(false, null);
    this.submitDeletePaymentApi = (id) => this.props.onSubmitDeletePaymentApi(id);

    this.openEditPaymentApi = value => this.props.onToggleShowEditPaymentApi(true, value);
    this.closeEditPaymentApi = () => this.props.onToggleShowEditPaymentApi(false, null);
    this.submitEditPaymentApi = (id, name, url, fee, username, password, rest) => this.props.onSubmitEditPaymentApi(id, {
      id,
      name,
      url,
      fee,
      username,
      password,
      rest
    });
  }

  componentWillMount() {
    this.props.onLoad();
  }

  componentWillUnmount() {
    this.props.onUnload();
  }

  handleSelect = eventKey => this.props.onChangeTab(eventKey);

  renderContent = () => {
    const {
      inProgress, currentTab, inProgressSecondary,
      usersState, selectedUser, showAddUser, showDeleteUser, showEditUser,showChangeUserState, showResetDebtUser, currentEditUserTab, onChangeEditUserTab, isSelectedUserAdmin, isSelectedUserReseller, resellerConfiguration, filteredResellerConfiguration, onSubmitSearchResellerConfiguration, onUpdateCommissionConfiguration, onUpdateFilterResellerConfiguration, resellerConfigurationFilterPlan,
      filteredCountries, selectedCountry, regions, showAddCountry, showDeleteCountry, showEditCountry, onUpdateFilterCountry,
      transactionsState, selectedTransaction, showTransactionDetail,
      transferencesState,
      paymentNotificationsState, selectedPaymentNotification, showPaymentNotificationDetail,
      lotPaymentsState, selectedLotPayment, showLotPaymentDetail,
      transactionsSummaryState, simActivationsSummaryState, transactionsSummaryTotalColumns, selectedSimActivationSummary, showSimActivationSummaryDetail,
      nautaRechargesState, selectedNautaRecharge, showNautaRechargeDetail,
      filteredResellers, onUpdateFilterReseller,
      filteredResellerCommission, selectedResellerCommission, showAddResellerCommission, showDeleteResellerCommission, onUpdateFilterResellerCommission, onUpdateCommission, onUpdateMasterCommission, selectedResellerCommissionId, plans,
      filteredProviders, selectedProvider, showAddProvider, showDeleteProvider, showEditProvider, onUpdateFilterProvider, countries, categories,
      filteredPaymentMethods, selectedPaymentMethod, showAddPaymentMethod, showDeletePaymentMethod, showEditPaymentMethod, onUpdateFilterPaymentMethod,
      filteredOperatorCategories, selectedOperatorCategory, showAddOperatorCategory, showDeleteOperatorCategory, showEditOperatorCategory, onUpdateFilterOperatorCategory,
      filteredPaymentApis, selectedPaymentApi, showAddPaymentApi, showDeletePaymentApi, showEditPaymentApi, onUpdateFilterPaymentApi,
    } = this.props;

    if (currentTab === 'users') {
      return (
        <Users
          inProgress={inProgress}
          loadUsers={this.loadUsers}
          usersState={usersState}
          selectedUser={selectedUser}
          showAddUser={showAddUser}
          openAddUser={this.openAddUser}
          closeAddUser={this.closeAddUser}
          submitAddUser={this.submitAddUser}
          showDeleteUser={showDeleteUser}
          openDeleteUser={this.openDeleteUser}
          closeDeleteUser={this.closeDeleteUser}
          submitDeleteUser={this.submitDeleteUser}
          showEditUser={showEditUser}
          showChangeUserState={showChangeUserState}
          openChangeUserState={this.openChangeUserState}
          closeChangeUserState={this.closeChangeUserState}
          openEditUser={this.openEditUser}
          closeEditUser={this.closeEditUser}
          submitEditUser={this.submitEditUser}
          submitChangeUserState={this.submitChangeUserState}          
          showResetDebtUser={showResetDebtUser}
          openResetDebtUser={this.openResetDebtUser}
          closeResetDebtUser={this.closeResetDebtUser}
          submitResetDebtUser={this.submitResetDebtUser}
          currentEditUserTab={currentEditUserTab}
          onChangeEditUserTab={onChangeEditUserTab}
          isSelectedUserAdmin={isSelectedUserAdmin}
          isSelectedUserReseller={isSelectedUserReseller}
          resellerConfiguration={resellerConfiguration}
          filteredResellerConfiguration={filteredResellerConfiguration}
          onUpdateFilterResellerConfiguration={onUpdateFilterResellerConfiguration}
          onSubmitSearchResellerConfiguration={onSubmitSearchResellerConfiguration}
          onUpdateCommissionConfiguration={onUpdateCommissionConfiguration}
          resellerConfigurationFilterPlan={resellerConfigurationFilterPlan}
          submitSaveCommissionConfiguration={this.submitSaveCommissionConfiguration}
          submitSetPinNumber={this.submitSetPinNumber}
        />
      );
    } else if (currentTab === 'homeBanner') {
      return (
        <HomeBanner/>
      );
    } else if (currentTab === 'countries') {
      return (
        <Countries
          inProgress={inProgress}
          filteredCountries={filteredCountries}
          selectedCountry={selectedCountry}
          submitSearch={this.submitSearchCountry}
          showAddCountry={showAddCountry}
          openAddCountry={this.openAddCountry}
          closeAddCountry={this.closeAddCountry}
          submitAddCountry={this.submitAddCountry}
          showDeleteCountry={showDeleteCountry}
          openDeleteCountry={this.openDeleteCountry}
          closeDeleteCountry={this.closeDeleteCountry}
          submitDeleteCountry={this.submitDeleteCountry}
          showEditCountry={showEditCountry}
          openEditCountry={this.openEditCountry}
          closeEditCountry={this.closeEditCountry}
          submitEditCountry={this.submitEditCountry}
          regions={regions}
          onUpdateFilterCountry={onUpdateFilterCountry}
        />
      );
    } else if (currentTab === 'transactions') {
      return (
        <Transactions
          inProgress={inProgress}
          loadTransactions={this.loadTransactions}
          transactionsState={transactionsState}
          selectedTransaction={selectedTransaction}
          showTransactionDetail={showTransactionDetail}
          openTransactionDetail={this.openTransactionDetail}
          closeTransactionDetail={this.closeTransactionDetail}
        />
      );
    } else if (currentTab === 'transferences') {
      return (
        <Transferences
          inProgress={inProgress}
          loadTransferences={this.loadTransferences}
          transferencesState={transferencesState}
        />
      );
    } else if (currentTab === 'paymentNotifications') {
      return (
        <PaymentNotifications
          inProgress={inProgress}
          loadPaymentNotifications={this.loadPaymentNotifications}
          paymentNotificationsState={paymentNotificationsState}
          selectedPaymentNotification={selectedPaymentNotification}
          showPaymentNotificationDetail={showPaymentNotificationDetail}
          openPaymentNotificationDetail={this.openPaymentNotificationDetail}
          closePaymentNotificationDetail={this.closePaymentNotificationDetail}
        />
      );
    } else if (currentTab === 'lotPayments') {
      return (
        <LotPayments
          inProgress={inProgress}
          loadLotPayments={this.loadLotPayments}
          lotPaymentsState={lotPaymentsState}
          selectedLotPayment={selectedLotPayment}
          showLotPaymentDetail={showLotPaymentDetail}
          openLotPaymentDetail={this.openLotPaymentDetail}
          closeLotPaymentDetail={this.closeLotPaymentDetail}
        />
      );
    } else if (currentTab === 'summary') {
      return (
        <Summary
          inProgress={inProgress}
          inProgressSecondary={inProgressSecondary}
          transactionsSummaryState={transactionsSummaryState}
          simActivationsSummaryState={simActivationsSummaryState}
          transactionsSummaryTotalColumns={transactionsSummaryTotalColumns}
          loadTransactionsSummary={this.loadTransactionsSummary}
          loadSimActivationsSummary={this.loadSimActivationsSummary}
          selectedSimActivationSummary={selectedSimActivationSummary}
          showSimActivationSummaryDetail={showSimActivationSummaryDetail}
          openSimActivationSummaryDetail={this.openSimActivationSummaryDetail}
          closeSimActivationSummaryDetail={this.closeSimActivationSummaryDetail}
        />
      );
    } else if (currentTab === 'nautaRecharges') {
      return (
        <NautaRecharges
          inProgress={inProgress}
          loadNautaRecharges={this.loadNautaRecharges}
          nautaRechargesState={nautaRechargesState}
          selectedNautaRecharge={selectedNautaRecharge}
          showNautaRechargeDetail={showNautaRechargeDetail}
          openNautaRechargeDetail={this.openNautaRechargeDetail}
          closeNautaRechargeDetail={this.closeNautaRechargeDetail}
        />
      );
    } else if (currentTab === 'resellers') {
      return (
        <Resellers
          inProgress={inProgress}
          filteredResellers={filteredResellers}
          selectedUser={selectedUser}
          submitSearchReseller={this.submitSearchReseller}
          showAddUser={showAddUser}
          openAddUser={this.openAddUser}
          closeAddUser={this.closeAddUser}
          submitAddReseller={this.submitAddReseller}
          showEditUser={showEditUser}
          currentEditUserTab={currentEditUserTab}
          onChangeEditUserTab={onChangeEditUserTab}
          showResetDebtUser={showResetDebtUser}
          openResetDebtUser={this.openResetDebtUser}
          closeResetDebtUser={this.closeResetDebtUser}
          submitResetDebtUser={this.submitResetDebtUser}
          openChangeUserState={this.openChangeUserState}
          closeChangeUserState={this.closeChangeUserState}
          openEditUser={this.openEditUser}
          closeEditUser={this.closeEditUser}
          submitEditReseller={this.submitEditReseller}
          showDeleteUser={showDeleteUser}
          openDeleteUser={this.openDeleteUser}
          closeDeleteUser={this.closeDeleteUser}
          submitDeleteReseller={this.submitDeleteReseller}
          onUpdateFilterReseller={onUpdateFilterReseller}
          isSelectedUserAdmin={isSelectedUserAdmin}
          isSelectedUserReseller={isSelectedUserReseller}
          submitSetPinNumber={this.submitSetPinNumber}
          resellerConfiguration={resellerConfiguration}
          filteredResellerConfiguration={filteredResellerConfiguration}
          onUpdateFilterResellerConfiguration={onUpdateFilterResellerConfiguration}
          onSubmitSearchResellerConfiguration={onSubmitSearchResellerConfiguration}
          onUpdateCommissionConfiguration={onUpdateCommissionConfiguration}
          resellerConfigurationFilterPlan={resellerConfigurationFilterPlan}
          submitSaveCommissionConfiguration={this.submitSaveCommissionConfiguration}
        />
      );
    } else if (currentTab === 'commissionsConfiguration') {
      return (
        <ResellerCommission
          inProgress={inProgress}
          filteredResellerCommission={filteredResellerCommission}
          selectedResellerCommission={selectedResellerCommission}
          submitSearchResellerCommission={this.submitSearchResellerCommission}
          showAddResellerCommission={showAddResellerCommission}
          openAddResellerCommission={this.openAddResellerCommission}
          closeAddResellerCommission={this.closeAddResellerCommission}
          submitAddResellerCommission={this.submitAddResellerCommission}
          showDeleteResellerCommission={showDeleteResellerCommission}
          openDeleteResellerCommission={this.openDeleteResellerCommission}
          closeDeleteResellerCommission={this.closeDeleteResellerCommission}
          submitDeleteResellerCommission={this.submitDeleteResellerCommission}
          onUpdateFilterResellerCommission={onUpdateFilterResellerCommission}
          updateCommission={onUpdateCommission}
          updateMasterCommission={onUpdateMasterCommission}
          submitSetCommission={this.submitSetCommission}
          selectedResellerCommissionId={selectedResellerCommissionId}
          plans={plans}
        />
      );
    } else if (currentTab === 'providers') {
      return (
        <Providers
          inProgress={inProgress}
          filteredProviders={filteredProviders}
          selectedProvider={selectedProvider}
          submitSearchProvider={this.submitSearchProvider}
          showAddProvider={showAddProvider}
          openAddProvider={this.openAddProvider}
          closeAddProvider={this.closeAddProvider}
          submitAddProvider={this.submitAddProvider}
          showDeleteProvider={showDeleteProvider}
          openDeleteProvider={this.openDeleteProvider}
          closeDeleteProvider={this.closeDeleteProvider}
          submitDeleteProvider={this.submitDeleteProvider}
          showEditProvider={showEditProvider}
          openEditProvider={this.openEditProvider}
          closeEditProvider={this.closeEditProvider}
          submitEditProvider={this.submitEditProvider}
          onUpdateFilterProvider={onUpdateFilterProvider}
          countries={countries}
          categories={categories}
        />
      );
    } else if (currentTab === 'paymentMethods') {
      return (
        <PaymentMethods
          inProgress={inProgress}
          filteredPaymentMethods={filteredPaymentMethods}
          selectedPaymentMethod={selectedPaymentMethod}
          submitSearchPaymentMethod={this.submitSearchPaymentMethod}
          showAddPaymentMethod={showAddPaymentMethod}
          openAddPaymentMethod={this.openAddPaymentMethod}
          closeAddPaymentMethod={this.closeAddPaymentMethod}
          submitAddPaymentMethod={this.submitAddPaymentMethod}
          showDeletePaymentMethod={showDeletePaymentMethod}
          openDeletePaymentMethod={this.openDeletePaymentMethod}
          closeDeletePaymentMethod={this.closeDeletePaymentMethod}
          submitDeletePaymentMethod={this.submitDeletePaymentMethod}
          showEditPaymentMethod={showEditPaymentMethod}
          openEditPaymentMethod={this.openEditPaymentMethod}
          closeEditPaymentMethod={this.closeEditPaymentMethod}
          submitEditPaymentMethod={this.submitEditPaymentMethod}
          onUpdateFilterPaymentMethod={onUpdateFilterPaymentMethod}
        />
      );
    } else if (currentTab === 'operatorCategories') {
      return (
        <OperatorCategories
          inProgress={inProgress}
          filteredOperatorCategories={filteredOperatorCategories}
          selectedOperatorCategory={selectedOperatorCategory}
          submitSearchOperatorCategory={this.submitSearchOperatorCategory}
          showAddOperatorCategory={showAddOperatorCategory}
          openAddOperatorCategory={this.openAddOperatorCategory}
          closeAddOperatorCategory={this.closeAddOperatorCategory}
          submitAddOperatorCategory={this.submitAddOperatorCategory}
          showDeleteOperatorCategory={showDeleteOperatorCategory}
          openDeleteOperatorCategory={this.openDeleteOperatorCategory}
          closeDeleteOperatorCategory={this.closeDeleteOperatorCategory}
          submitDeleteOperatorCategory={this.submitDeleteOperatorCategory}
          showEditOperatorCategory={showEditOperatorCategory}
          openEditOperatorCategory={this.openEditOperatorCategory}
          closeEditOperatorCategory={this.closeEditOperatorCategory}
          submitEditOperatorCategory={this.submitEditOperatorCategory}
          onUpdateFilterOperatorCategory={onUpdateFilterOperatorCategory}
        />
      );
    } else if (currentTab === 'paymentApis') {
      return (
        <PaymentApis
          inProgress={inProgress}
          filteredPaymentApis={filteredPaymentApis}
          selectedPaymentApi={selectedPaymentApi}
          submitSearchPaymentApi={this.submitSearchPaymentApi}
          showAddPaymentApi={showAddPaymentApi}
          openAddPaymentApi={this.openAddPaymentApi}
          closeAddPaymentApi={this.closeAddPaymentApi}
          submitAddPaymentApi={this.submitAddPaymentApi}
          showDeletePaymentApi={showDeletePaymentApi}
          openDeletePaymentApi={this.openDeletePaymentApi}
          closeDeletePaymentApi={this.closeDeletePaymentApi}
          submitDeletePaymentApi={this.submitDeletePaymentApi}
          showEditPaymentApi={showEditPaymentApi}
          openEditPaymentApi={this.openEditPaymentApi}
          closeEditPaymentApi={this.closeEditPaymentApi}
          submitEditPaymentApi={this.submitEditPaymentApi}
          onUpdateFilterPaymentApi={onUpdateFilterPaymentApi}
        />
      );
    } else {
      return <div>{currentTab}</div>
    }
  };

  showMessage = () => {
    const {
      errors, lastAction, onResetAction,
    } = this.props;

    if (errors && errors !== null) {
      return <Message type="error" text={errors} onClose={onResetAction}/>;
    } else {
      let message = '';
      if (lastAction === ADMIN_ADD_USER) {
        message = 'User added successfully';
      } else if (lastAction === ADMIN_EDIT_USER) {
        message = 'User updated successfully';
      } else if (lastAction === ADMIN_CHANGE_USER_STATE) {
        message = 'User STATE updated successfully';        
      } else if (lastAction === ADMIN_DELETE_USER) {
        message = 'User deleted successfully';
      } else if (lastAction === ADMIN_RESET_DEBT_USER) {
        message = 'Debt successfully reset';
      } else if (lastAction === ADMIN_ADD_COUNTRY) {
        message = 'Country added successfully';
      } else if (lastAction === ADMIN_SET_PIN_NUMBER) {
        message = 'Pin number updated successfully';
      } else if (lastAction === ADMIN_UPDATE_COUNTRY) {
        message = 'Country updated successfully';
      } else if (lastAction === ADMIN_DELETE_COUNTRY) {
        message = 'Country deleted successfully';
      } else if (lastAction === ADMIN_SET_COMMISSION_CONFIGURATION) {
        message = 'Configuration updated successfully';
      } else if (lastAction === ADMIN_SET_COMMISSION) {
        message = 'Commission updated successfully';
      } else if (lastAction === ADMIN_ADD_RESELLER_COMMISSION) {
        message = 'Commission added successfully';
      } else if (lastAction === ADMIN_DELETE_RESELLER_COMMISSION) {
        message = 'Commission deleted successfully';
      } else if (lastAction === ADMIN_ADD_PROVIDER) {
        message = 'Provider added successfully';
      } else if (lastAction === ADMIN_DELETE_PROVIDER) {
        message = 'Provider deleted successfully';
      } else if (lastAction === ADMIN_UPDATE_PROVIDER) {
        message = 'Provider updated successfully';
      } else if (lastAction === ADMIN_ADD_PAYMENT_METHOD) {
        message = 'Payment method added successfully';
      } else if (lastAction === ADMIN_DELETE_PAYMENT_METHOD) {
        message = 'Payment method deleted successfully';
      } else if (lastAction === ADMIN_UPDATE_PAYMENT_METHOD) {
        message = 'Payment method updated successfully';
      } else if (lastAction === ADMIN_ADD_OPERATOR_CATEGORY) {
        message = 'Category added successfully';
      } else if (lastAction === ADMIN_DELETE_OPERATOR_CATEGORY) {
        message = 'Category deleted successfully';
      } else if (lastAction === ADMIN_UPDATE_OPERATOR_CATEGORY) {
        message = 'Category updated successfully';
      } else if (lastAction === ADMIN_ADD_PAYMENT_API) {
        message = 'Api added successfully';
      } else if (lastAction === ADMIN_DELETE_PAYMENT_API) {
        message = 'Api deleted successfully';
      } else if (lastAction === ADMIN_UPDATE_PAYMENT_API) {
        message = 'Api updated successfully';
      }
      return message !== '' ? <Message type="success" text={message} onClose={onResetAction}/> : '';
    }
  };

  render() {
    const {
      pinChecked, inProgress, currentTab, errors, onResetAction,
    } = this.props;

    if (pinChecked)
      return (
        <div className="bg-white profile-page-super-container">
          <div className="row">

          </div>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="profile-tab-menu">
              <div className="vertical-tab">

                <Nav
                  defaultActiveKey={currentTab && currentTab !== '' ? currentTab : 'users'}
                  className="navigation-row-container"
                  onSelect={ev => ev !== currentTab ? this.handleSelect(ev) : null}>

                  <div className="dropdown dropdown-users">
                    <button eventkey="users" className="menu-section mt-3 dropbtn">MAIN</button>
                    <div className="dropdown-content dropdown-content-users">
                      <Nav.Link className="event-menu-nav-bar-item" eventKey="users">Users</Nav.Link>
                      <Nav.Link className="event-menu-nav-bar-item" eventKey="countries">Countries</Nav.Link>
                      <Nav.Link className="event-menu-nav-bar-item" eventKey="transactions">Transactions</Nav.Link>
                      <Nav.Link className="event-menu-nav-bar-item" eventKey="transferences">Transferences</Nav.Link>
                      <Nav.Link className="event-menu-nav-bar-item" eventKey="paymentNotifications">Payment
                        Notifications</Nav.Link>
                      <Nav.Link className="event-menu-nav-bar-item" eventKey="lotPayments">Lot Payments</Nav.Link>
                      <Nav.Link className="event-menu-nav-bar-item" eventKey="summary">Summary</Nav.Link>
                    </div>
                  </div>

                  <div className="dropdown dropdown-users">
                    <button eventkey="commissions" className="menu-section mt-3 dropbtn">COMMISSIONS</button>
                    <div className="dropdown-content dropdown-content-users">
                      <Nav.Link eventKey="resellers">Resellers</Nav.Link>
                      <Nav.Link eventKey="commissionsConfiguration">Configuration</Nav.Link>
                    </div>
                  </div>

                  <div className="dropdown dropdown-users">
                    <button eventkey="providersCategory" className="menu-section mt-3 dropbtn">PROVIDERS</button>
                    <div className="dropdown-content dropdown-content-users">
                      <Nav.Link eventKey="providers">Providers</Nav.Link>
                      <Nav.Link eventKey="operatorCategories">Categories</Nav.Link>
                      {/*<Nav.Link eventKey="otherServices">Other Services</Nav.Link>*/}
                      <Nav.Link eventKey="paymentApis">Payment APIs</Nav.Link>
                      <Nav.Link eventKey="paymentMethods">Payment Methods</Nav.Link>
                    </div>
                  </div>

                  <div className="dropdown dropdown-users">
                    <button eventkey="unlockCategory" className="menu-section mt-3 dropbtn">UNLOCK</button>
                    {/*<div className="dropdown-content dropdown-content-users">
                      <Nav.Link eventKey="ordersUnlock">Orders</Nav.Link>
                      <Nav.Link eventKey="package">Package</Nav.Link>
                      <Nav.Link eventKey="payment">Payment</Nav.Link>
                      <Nav.Link eventKey="countryUnlock">Country</Nav.Link>
                      <Nav.Link eventKey="carrier">Carrier</Nav.Link>
                      <Nav.Link eventKey="brand">Brand</Nav.Link>
                    </div>*/}
                  </div>

                  <div className="dropdown dropdown-users">
                    <button eventkey="activation" className="menu-section mt-3 dropbtn">ACTIVATION</button>
                    {/*<div className="dropdown-content dropdown-content-users">
                      <Nav.Link eventKey="ordersActivation">Orders</Nav.Link>
                      <Nav.Link eventKey="provinces">Provinces</Nav.Link>
                      <Nav.Link eventKey="commercialOffices">Commercial Offices</Nav.Link>
                    </div>*/}
                  </div>

                  <div className="dropdown dropdown-users">
                    <button eventkey="recharges" className="menu-section mt-3 dropbtn">NAUTA</button>
                    <div className="dropdown-content dropdown-content-users">
                      <Nav.Link eventKey="nautaRecharges">Recharges</Nav.Link>
                    </div>
                  </div>

                  <div className="dropdown dropdown-users">
                    <button eventkey="configuration" className="menu-section mt-3 dropbtn">CONFIGURATION</button>
                    <div className="dropdown-content dropdown-content-users">
                      <Nav.Link eventKey="homeBanner">Home Banner</Nav.Link>
                    </div>
                  </div>
                </Nav>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="tab-content-container tab-content-container-new">
              {this.renderContent()}
            </Col>
          </Row>
          {this.showMessage()}
        </div>
      );
    else return (
      <div className="bg-white profile-page-super-container">
        <Row style={{maxWidth: '30rem'}} className="mx-auto py-5">
          <Col md={12}>
            <h1 className="text-center section-title">CellShop Admin</h1>
            <Formik
              initialValues={{pin: ''}}
              onSubmit={(values) => this.submitCheckPinNumber(values.pin)}
              validationSchema={Yup.object().shape({pin: Yup.string().required('Required')})}
            >
              {props => {
                const {
                  touched,
                  errors,
                  handleSubmit,
                  values,
                  handleChange,
                  handleBlur
                } = props;
                return (
                  <Form className="mt-4" onSubmit={handleSubmit}>
                    <div className="px-4 py-4 mb-4" style={{border: '1px solid rgba(0,0,0,.1)'}}>
                      <Row className="text-center">
                        <Col sm={12} md={12}>
                          <h5 className={`section-subtitle mb-lg-5`}>Enter your pin to start your session</h5>
                          <Form.Group className="mb-4">
                            <Form.Control
                              className="form-input border-radius-4"
                              type="password"
                              id="pin"
                              placeholder="Pin Number"
                              value={values.pin}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.pin && touched.pin && (
                              <div className="error-message text-left">{errors.pin}</div>
                            )}
                          </Form.Group>
                        </Col>
                        <Col sm={12} md={12}>
                          <Form.Group>
                            <Button
                              className="btn btn-black border-radius-4"
                              type="submit"
                              disabled={inProgress}
                            >
                              {inProgress ?
                                <Spinner
                                  animation="grow"
                                  variant="secondary"
                                  style={{marginRight: '5px', marginBottom: '4px'}}
                                  size="sm"
                                /> : ''}
                              Confirm
                            </Button>
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </Col>
        </Row>
        {
          errors && errors !== null ?
            <Message type="error" text={errors} onClose={onResetAction}/>
            : ''
        }
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Admin);