import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from "react-redux";
import {Formik} from 'formik';
import * as Yup from 'yup';

import {
  REGISTER,
  REGISTER_PAGE_UNLOADED,
} from '../../constants/actionTypes';
import agent from '../../agent';

import './RegisterUX.css';
import Message from '../Message';

import Facebook from '../Login/Facebook/Facebook';
import Google from '../Login/Google/Google';

import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';
//import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';

import emailImage from "../../assets/img/icons/baseline-email-24px.svg";
import lockImage from "../../assets/img/icons/baseline-lock-24px.svg";
import personImage from "../../assets/img/icons/baseline-person-24px.svg";
import receiptImage from "../../assets/img/icons/baseline-receipt-24px.svg";
import phoneImage from "../../assets/img/icons/baseline-phone-24px.svg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Spinner, Button} from "react-bootstrap";

const theme = createMuiTheme({
  palette: {
    primary: {main: '#000'},
    secondary: {main: '#FFF'},
  },
  typography: {useNextVariants: true}
});

const mapStateToProps = state => ({...state.auth});

const mapDispatchToProps = dispatch => ({
  onSubmitAddUser: (user) => {
    const payload = agent.User.registerWithRoles(user);
    dispatch({type: REGISTER, payload});
  },
  onUnload: () =>
    dispatch({type: REGISTER_PAGE_UNLOADED}),
});

class RegisterUX extends Component {
  constructor(props) {
    super(props);

    this.submitAddUser = async (email, userName, firstName, lastName, phoneNumber, password, resellerPromoCode, isReseller, rolesToAssign) => {
      await this.props.onSubmitAddUser({
        email,
        userName,
        firstName,
        lastName,
        phoneNumber,
        password,
        resellerPromoCode,
        isReseller: isReseller === 'true',
        rolesToAssign
      });
    };
  }

  componentWillUnmount() {
    this.props.onUnload();
  }

  render() {
    const {inProgress, errors} = this.props;
    return (
      <div>
        <div className="content-register">
          <div className="text-center">
            <div className="register-content mr-auto ml-auto cmt-2 px-2">
              {
                (!inProgress && typeof errors !== 'undefined') ?
                  <Message type="error" text={errors}/> : ''
              }
              <Formik
                initialValues={{
                  userName: '',
                  firstName: '',
                  lastName: '',
                  email: '',
                  phoneNumber: '',
                  password: '',
                  passwordConfirm: '',
                  terms: false,
                  referralCode: ''
                }}
                onSubmit={(values) => this.submitAddUser(
                  values.email,
                  values.userName,
                  values.firstName,
                  values.lastName,
                  values.phoneNumber,
                  values.password,
                  values.referralCode,
                  'false',
                  []
                )}
                validationSchema={Yup.object().shape({
                  userName: Yup.string().required('Required'),
                  firstName: Yup.string().required('Required'),
                  lastName: Yup.string().required('Required'),
                  email: Yup.string().required('Required').email('Invalid email'),
                  phoneNumber: Yup.string().required('Required'),
                  password: Yup.string().required('Required').min(6, 'At least 6 characters').matches(/\d/, 'Must contain at least 1 number').matches(/[*@!#%$&()^~{}+-/]+/, 'Must contain at least 1 special character'),
                  passwordConfirm: Yup.string().oneOf([Yup.ref('password'), null], "Passwords must match").required('Required'),
                  terms: Yup
                    .boolean()
                    .oneOf([true], 'Must accept Terms and Conditions')
                })}
              >
                {props => {
                  const {
                    values,
                    touched,
                    errors,
                    handleChange,
                    handleBlur,
                    handleSubmit
                  } = props;
                  return (
                    <div className="col-12 register-ux-super-container-2">


                      {/* Facebook + Google + text*/}
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 facebook-container-in-login-ui">
                          <Facebook title="register"/>
                        </div>

                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                          <Google title="register"/>
                        </div>

                        <div className="col-12 register-with-your-email-container">
                          <p className="color-dark-gray">Or register with your email</p>
                        </div>
                      </div>
                      {/* Facebook + Google + text*/}
                      <div>

                        <MuiThemeProvider theme={theme}>
                          <form className="login-ui-form"
                                noValidate
                                autoComplete="off"
                                onSubmit={handleSubmit}>
                            < div className="login-user-password">

                              <div className="login-user-password-fields">

                                <div
                                  className="login-input-field col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 login-input-field-left">
                                  <div className="col-1 icon-div-container-register">
                                    <img src={personImage} alt=""/>
                                  </div>
                                  <div className="col-11 col-sm-11 col-md-10 col-lg-10 col-xl-10 input-div-container">
                                    <TextField required
                                               label="First Name"
                                               className="input-email"
                                               margin="normal"
                                               id="firstName"
                                               value={values.firstName}
                                               onChange={handleChange}
                                               onBlur={handleBlur}
                                    />
                                    {errors.firstName && touched.firstName && (
                                      <div className="error-message">{errors.firstName}</div>
                                    )}
                                  </div>
                                </div>

                                <div
                                  className="login-input-field col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 login-input-field-right">
                                  <div className="col-1 icon-div-container-register">
                                    <img src={personImage} alt=""/>
                                  </div>
                                  <div className="col-11 col-sm-11 col-md-10 col-lg-10 col-xl-10 input-div-container">
                                    <TextField required
                                               label="Last Name"
                                               className="input-email"
                                               margin="normal"
                                               type="text"
                                               id="lastName"
                                               value={values.lastName}
                                               onChange={handleChange}
                                               onBlur={handleBlur}
                                    />
                                    {errors.lastName && touched.lastName && (
                                      <div className="error-message">{errors.lastName}</div>
                                    )}
                                  </div>
                                </div>

                                <div
                                  className="login-input-field col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 login-input-field-left">
                                  <div className="col-1 icon-div-container-register">
                                    <img src={personImage} alt=""/>
                                  </div>
                                  <div className="col-11 col-sm-11 col-md-10 col-lg-10 col-xl-10 input-div-container">
                                    <TextField required
                                               label="Username"
                                               className="input-email"
                                               margin="normal"
                                               id="userName"
                                               value={values.userName}
                                               onChange={handleChange}
                                               onBlur={handleBlur}
                                    />
                                    {errors.userName && touched.userName && (
                                      <div className="error-message">{errors.userName}</div>
                                    )}
                                  </div>
                                </div>
                                <div
                                  className="login-input-field col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 login-input-field-right">
                                  <div className="col-1 icon-div-container-register">
                                    <img src={emailImage} alt=""/>
                                  </div>
                                  <div className="col-11 col-sm-11 col-md-10 col-lg-10 col-xl-10 input-div-container">
                                    <TextField required
                                               label="Email"
                                               className="input-email"
                                               margin="normal"
                                               type="email"
                                               id="email"
                                               value={values.email}
                                               onChange={handleChange}
                                               onBlur={handleBlur}
                                    />
                                    {errors.email && touched.email && (
                                      <div className="error-message">{errors.email}</div>
                                    )}
                                  </div>
                                </div>
                                <div
                                  className="login-input-field col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 login-input-field-left input-password-container">
                                  <div className="col-1 icon-div-container-register">
                                    <img src={lockImage} alt=""/>
                                  </div>
                                  <div className="col-11 col-sm-11 col-md-10 col-lg-10 col-xl-10 input-div-container">
                                    <TextField required
                                               label="Password"
                                               className="input-password"
                                               margin="normal"
                                               type="password"
                                               id="password"
                                               value={values.password}
                                               onChange={handleChange}
                                               onBlur={handleBlur}
                                    />
                                    {errors.password && touched.password && (
                                      <div className="error-message">{errors.password}</div>
                                    )}
                                  </div>
                                </div>

                                <div
                                  className="login-input-field col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 login-input-field-right input-password-container">
                                  <div className="col-1 icon-div-container-register">
                                    <img src={lockImage} alt=""/>
                                  </div>
                                  <div className="col-11 col-sm-11 col-md-10 col-lg-10 col-xl-10 input-div-container">
                                    <TextField required
                                               label="Confirm Password"
                                               className="input-password"
                                               margin="normal"
                                               id="passwordConfirm"
                                               type="password"
                                               value={values.passwordConfirm}
                                               onChange={handleChange}
                                               onBlur={handleBlur}
                                    />
                                    {errors.passwordConfirm && touched.passwordConfirm && (
                                      <div className="error-message">{errors.passwordConfirm}</div>
                                    )}
                                  </div>
                                </div>

                                <div className="login-input-field col-12 advice-password-container">
                                  <p className="fp-text pass-info mb-3">
                                    Please note that for security reason your password must be at least six characters
                                    in
                                    length
                                    nad must contain at least one number and one special character (for example: *, #,
                                    %,
                                    $,
                                    among others)
                                  </p>
                                </div>

                                <div
                                  className="login-input-field col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 login-input-field-left">
                                  <div className="col-1 icon-div-container-register">
                                    <img src={phoneImage} alt=""/>
                                  </div>
                                  <div className="col-11 col-sm-11 col-md-10 col-lg-10 col-xl-10 input-div-container">
                                    <TextField required
                                               label="Phone Number"
                                               className="input-email"
                                               margin="normal"
                                               type="text"
                                               id="phoneNumber"
                                               value={values.phoneNumber}
                                               onChange={handleChange}
                                               onBlur={handleBlur}
                                    />
                                    {errors.phoneNumber && touched.phoneNumber && (
                                      <div className="error-message">{errors.phoneNumber}</div>
                                    )}
                                  </div>
                                </div>

                                <div
                                  className="login-input-field col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 login-input-field-right">
                                  <div className="col-1 icon-div-container-register">
                                    <img src={receiptImage} alt=""/>
                                  </div>
                                  <div className="col-11 col-sm-11 col-md-10 col-lg-10 col-xl-10 input-div-container">
                                    <TextField label="Referral Promo Code"
                                               className="input-email"
                                               margin="normal"
                                               type="text"
                                               id="referralCode"
                                               value={values.referralCode}
                                               onChange={handleChange}
                                               onBlur={handleBlur}
                                    />

                                  </div>
                                </div>

                                <div className="login-input-field col-12 i-read-and-agree-container">
                                  <div className="i-read-and-agree-checkbox">
                                    <Checkbox id="terms"
                                              value={values.terms}
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                    />

                                  </div>
                                  <div className="">
                                  <span>
                                      I read and agree to the
                                      <Link to="/"> Terms and Conditions</Link>
                                  </span>
                                  </div>

                                </div>
                                <div className="error-terms-message">
                                  {errors.terms && touched.terms && (
                                    <div className="error-message">{errors.terms}</div>
                                  )}
                                </div>
                                <br/>
                              </div>
                              <div className="register-user-password-submit">
                                {/* <div> */}
                                {/*<Button className="register-button"
                                        color="secondary"
                                        type="submit"
                                  //   disabled={inProgress}
                                >Register</Button>*/}
                                {/* </div> */}
                              </div>
                              <Button
                                className="btn btn-form-app btn-black btn-inline border-radius-4 w-100 mt-0"
                                type="submit"
                                disabled={inProgress}>
                                {
                                  inProgress ?
                                    <Spinner
                                      animation="grow"
                                      variant="secondary"
                                      style={{marginRight: '5px', marginBottom: '4px'}}
                                      size="sm"
                                    />
                                    : ''
                                }
                                Register
                                <FontAwesomeIcon icon="arrow-right" className="icon-right"/>
                              </Button>
                            </div>

                          </form>
                        </MuiThemeProvider>

                      </div>
                    </div>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterUX);