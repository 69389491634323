import React, {Component} from 'react';
import {Pagination} from "react-bootstrap";
import './PaginationSimple.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class PaginationSimple extends Component {
  render() {
    let active = this.props.active;
    let nroPages = this.props.nroPages;
    let items = [];
    items.push(<Pagination.Prev key="prev" className="pi-simple"><FontAwesomeIcon icon="arrow-left" size="1x"
                                                                                  className=""/></Pagination.Prev>);
    for (let number = 1; number <= nroPages; number++) {
      items.push(
        <Pagination.Item key={number} active={number === active} className="pi-simple">
          {number}
        </Pagination.Item>
      );
    }
    items.push(<Pagination.Next key="next" className="pi-simple"><FontAwesomeIcon icon="arrow-right" size="1x"
                                                                                  className=""/></Pagination.Next>);
    return (
      <div className="ml-auto mr-auto text-xs-center pagination-simple">
        <Pagination>{items}</Pagination>
      </div>
    );
  }
}

export default PaginationSimple;