import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Col, Row, Nav} from "react-bootstrap";
import moment from 'moment';
import agent from "../../agent";

import Message from '../Message';
import MyProfile from './MyProfile';
import Affiliates from './Affiliates';
import Transactions from './Transactions';
import Transferences from './Transferences';
import BatchPayments from './BatchPayments';
import PaymentNotifications from './PaymentNotifications';
import ResellerInfo from './ResellerInfo';
import Referrals from './Referrals';

import "react-table/react-table.css";
import './Profile.css';

import {
  LOAD_USER,
  UPDATE_FIELD_PROFILE,
  UPDATE_PROFILE,
  UPDATE_PROFILE_RESET,
  PROFILE_LOAD_TAB_TRANSFERENCES,
  PROFILE_LOAD_TRANSFERENCES,
  PROFILE_LOAD_TAB_TRANSACTIONS,
  PROFILE_LOAD_TRANSACTIONS,
  PROFILE_LOAD_TRANSACTIONS_SUMMARY,
  PROFILE_SHOW_TRANSACTION_DETAIL,
  PROFILE_PAGE_UNLOADED,
  PROFILE_LOAD_TAB_BATCH_PAYMENTS,
  PROFILE_LOAD_BATCH_PAYMENTS,
  PROFILE_SHOW_BATCH_PAYMENT_DETAIL,
  PROFILE_LOAD_TAB_PAYMENT_NOTIFICATIONS,
  PROFILE_LOAD_PAYMENT_NOTIFICATIONS,
  PROFILE_SHOW_PAYMENT_NOTIFICATION_DETAIL,
  PROFILE_LOAD_TAB_RESELLER_INFO,
  PROFILE_LOAD_RESELLER_INFO,
  PROFILE_LOAD_COMMISSIONS,
  PROFILE_LOAD_DING_PRODUCTS,
  PROFILE_SEARCH_RESELLER_INFO,
  PROFILE_LOAD_TAB_REFERRALS,
  PROFILE_LOAD_REFERRALS,
  PROFILE_SHOW_RESET_DEBT,
  PROFILE_RESET_DEBT,
  PROFILE_TRANSFER_REFERRAL,
  PROFILE_LOAD_TAB_AFFILIATES,
  PROFILE_LOAD_AFFILIATES,
  PROFILE_SHOW_SEND_MESSAGE,
  PROFILE_SHOW_MAKE_TRANSFERENCE,
  PROFILE_ADD_AFFILIATE,
  PROFILE_SHOW_UNLINK_AFFILIATE,
  PROFILE_SEARCH_USER_BY_EMAIL,
  PROFILE_USER_ALREADY_AFFILIATED,
  PROFILE_USER_NOT_ACTIVATED,
  PROFILE_USER_DOES_NOT_EXIST,
  PROFILE_SHOW_ADD_AFFILIATE,
  PROFILE_SEND_MESSAGE,
  PROFILE_MAKE_TRANSFERENCE,
  PROFILE_UNLINK_AFFILIATE,
  PROFILE_LOAD_SUPER_USER,
} from '../../constants/actionTypes';

const mapStateToProps = state => ({
  ...state.common,
  ...state.profile
});

const mapDispatchToProps = (dispatch) => ({
  onLoad: () =>
    dispatch({type: LOAD_USER, payload: agent.Auth.current()}),
  onChangeTab: value => {
    if (value === 'profile')
      dispatch({type: LOAD_USER, payload: agent.Auth.current()});
    else if (value === 'referrals')
      dispatch({type: PROFILE_LOAD_TAB_REFERRALS});
    else if (value === 'transferences')
      dispatch({type: PROFILE_LOAD_TAB_TRANSFERENCES});
    else if (value === 'transactions')
      dispatch({type: PROFILE_LOAD_TAB_TRANSACTIONS});
    else if (value === 'batchPayments')
      dispatch({type: PROFILE_LOAD_TAB_BATCH_PAYMENTS});
    else if (value === 'paymentNotifications')
      dispatch({type: PROFILE_LOAD_TAB_PAYMENT_NOTIFICATIONS});
    else if (value === 'resellerInfo')
      dispatch({type: PROFILE_LOAD_TAB_RESELLER_INFO});
    else if (value === 'affiliates')
      dispatch({type: PROFILE_LOAD_TAB_AFFILIATES});
    dispatch({type: UPDATE_FIELD_PROFILE, key: 'currentTab', value});
  },
  onResetAction: () =>
    dispatch({type: UPDATE_PROFILE_RESET}),
  onSubmitEditProfile: (firstName, lastName, phoneNumber, user) => {
    user.firstName = firstName;
    user.lastName = lastName;
    user.phoneNumber = phoneNumber;
    dispatch({type: UPDATE_PROFILE, payload: agent.User.update(user), lastAction: UPDATE_PROFILE})
  },
  onLoadAffiliates: (user, affiliatesState, page, pageSize, username) => {
    const payload = agent.Profile.affiliates(user, page, pageSize, username);
    dispatch({type: PROFILE_LOAD_AFFILIATES, payload, affiliatesState});
    if (user && user.superUserId !== '') {
      dispatch({type: PROFILE_LOAD_SUPER_USER, payload: agent.Profile.getUserById(user.superUserId)});
    }
  },
  onToggleShowSendMessage: (show, value) =>
    dispatch({type: PROFILE_SHOW_SEND_MESSAGE, show, value}),
  onSubmitSendMessage: (user, subject, body, affiliate) => {
    const payload = {
      from: user.userName,
      subject,
      body,
      fromUserEmail: user.email,
      fromUserAppId: user.id,
      applicationUserId: affiliate ? affiliate.id : null,
      messageStatusId: 1,
    };
    dispatch({
      type: PROFILE_SEND_MESSAGE,
      payload: agent.Profile.sendMessage(payload),
      lastAction: PROFILE_SEND_MESSAGE
    });
  },
  onToggleShowMakeTransference: (show, value) =>
    dispatch({type: PROFILE_SHOW_MAKE_TRANSFERENCE, show, value}),
  onSubmitMakeTransference: (user, amount, affiliate, affiliatesState, page, pageSize, username) => {
    const payload = {
      amount,
      affiliateId: affiliate.id
    };
    dispatch({type: UPDATE_FIELD_PROFILE, key: 'inProgress', value: true});
    agent.Profile.makeTransference(user, payload)
      .then(() => {
        const payloadMessage = {
          from: user.userName,
          subject: 'Transfer notification',
          body: `I transferred $${amount} to your user. Regards.`,
          fromUserEmail: user.email,
          fromUserAppId: user.id,
          applicationUserId: affiliate ? affiliate.id : null,
          messageStatusId: 1,
        };
        dispatch({type: PROFILE_SEND_MESSAGE, payload: agent.Profile.sendMessage(payloadMessage), lastAction: null});
        const payloadAffiliates = agent.Profile.affiliates(user, page, pageSize, username);
        dispatch({
          type: PROFILE_LOAD_AFFILIATES,
          payload: payloadAffiliates,
          affiliatesState,
          lastAction: PROFILE_MAKE_TRANSFERENCE
        });
      });
  },
  onToggleShowAddAffiliate: (show, value) =>
    dispatch({type: PROFILE_SHOW_ADD_AFFILIATE, show, value}),
  onSubmitSearchUserByEmail: (email) => {
    const payload = agent.Profile.getUserByEmail({userEmail: email});
    dispatch({type: PROFILE_SEARCH_USER_BY_EMAIL, payload});
  },
  onSubmitAddAffiliate: (user, affiliate, affiliatesState, page, pageSize, username) => {
    dispatch({type: UPDATE_FIELD_PROFILE, key: 'inProgress', value: true});
    agent.Profile.getUserById(affiliate.id)
      .then((responseAffiliate) => {
        let affiliateUser = responseAffiliate.body;
        affiliateUser.superUserId = user.id;
        agent.User.update(affiliateUser)
          .then((responseAffiliateUpdated) => {
            let affiliateUserUpdated = responseAffiliateUpdated.body;
            const payloadMessage = {
              from: user.userName,
              subject: 'Affiliation notification',
              body: `You have been affiliated with the user ${user.userName}. Regards.`,
              fromUserEmail: user.email,
              fromUserAppId: user.id,
              applicationUserId: affiliateUserUpdated.id,
              messageStatusId: 1,
            };
            dispatch({
              type: PROFILE_SEND_MESSAGE,
              payload: agent.Profile.sendMessage(payloadMessage),
              lastAction: null
            });
            const payloadAffiliates = agent.Profile.affiliates(user, page, pageSize, username);
            dispatch({
              type: PROFILE_LOAD_AFFILIATES,
              payload: payloadAffiliates,
              affiliatesState,
              lastAction: PROFILE_ADD_AFFILIATE
            });
            dispatch({type: UPDATE_FIELD_PROFILE, key: 'userByEmail', value: null})
          });
      });
  },
  onToggleShowUnlinkAffiliate: (show, value) =>
    dispatch({type: PROFILE_SHOW_UNLINK_AFFILIATE, show, value}),
  onSubmitUnlinkAffiliate: (user, affiliate, affiliatesState, page, pageSize, username) => {
    dispatch({type: UPDATE_FIELD_PROFILE, key: 'inProgress', value: true});
    agent.Profile.getUserById(affiliate.id)
      .then((responseAffiliate) => {
        let affiliateUser = responseAffiliate.body;
        affiliateUser.superUserId = '';
        agent.User.update(affiliateUser)
          .then((responseAffiliateUpdated) => {
            let affiliateUserUpdated = responseAffiliateUpdated.body;
            const payloadMessage = {
              from: user.userName,
              subject: 'Disaffiliation notification',
              body: `You have been disaffiliated with the user ${user.userName}. Regards.`,
              fromUserEmail: user.email,
              fromUserAppId: user.id,
              applicationUserId: affiliateUserUpdated.id,
              messageStatusId: 1,
            };
            dispatch({
              type: PROFILE_SEND_MESSAGE,
              payload: agent.Profile.sendMessage(payloadMessage),
              lastAction: null
            });
            const payloadAffiliates = agent.Profile.affiliates(user, page, pageSize, username);
            dispatch({
              type: PROFILE_LOAD_AFFILIATES,
              payload: payloadAffiliates,
              affiliatesState,
              lastAction: PROFILE_UNLINK_AFFILIATE
            });
          });
      });
  },
  onLoadReferrals: (user) =>
    dispatch({type: PROFILE_LOAD_REFERRALS, payload: agent.Profile.referrals(user), lastAction: null}),
  onToggleShowResetDebt: (show, value) =>
    dispatch({type: PROFILE_SHOW_RESET_DEBT, show, value}),
  onSubmitResetDebt: (user, userId, amount, note) => {
    const payload = {UserId: userId, Amount: amount, Note: note};
    dispatch({type: UPDATE_FIELD_PROFILE, key: 'inProgress', value: true});
    agent.Profile.resetDebt(user, payload)
      .then(() =>
        dispatch({type: PROFILE_LOAD_REFERRALS, payload: agent.Profile.referrals(user), lastAction: PROFILE_RESET_DEBT})
      );
  },
  onSubmitTransferReferral: (user, amount, toUserId, TransferFrom, TransferTo) => {
    agent.Profile.transferReferral(user, {amount, toUserId, TransferFrom, TransferTo})
      .then(() => dispatch({
        type: PROFILE_LOAD_REFERRALS,
        payload: agent.Profile.referrals(user),
        lastAction: PROFILE_TRANSFER_REFERRAL
      }),)
  },
  onLoadTransferences: (user, transferencesState, page, pageSize, sort, ascending, fromDate, toDate) => {
    const payload = agent.Profile.transferences(user, page, pageSize, sort, ascending, fromDate, toDate);
    dispatch({type: PROFILE_LOAD_TRANSFERENCES, payload, transferencesState});
  },
  onLoadTransactions: (user, transactionsState, page, pageSize, sort, ascending, fromDate, toDate, phoneNumber, service) => {
    const payload = agent.Profile.transactions(user, page, pageSize, sort, ascending, fromDate, toDate, phoneNumber, service);
    dispatch({type: PROFILE_LOAD_TRANSACTIONS, payload, transactionsState});
  },
  onLoadTransactionsSummary: (user, transactionsSummaryState, page, pageSize, sort, ascending, fromDate, toDate) => {
    const payload = agent.Profile.transactionsSummary(user, page, pageSize, sort, ascending, fromDate, toDate);
    dispatch({type: PROFILE_LOAD_TRANSACTIONS_SUMMARY, payload, transactionsSummaryState});
  },
  onToggleShowTransactionDetail: (show, value) =>
    dispatch({type: PROFILE_SHOW_TRANSACTION_DETAIL, show, value}),
  onToggleShowTransactionsSummary: (value) =>
    dispatch({type: UPDATE_FIELD_PROFILE, key: 'showTransactionsSummary', value}),
  onLoadBatchPayments: (user, batchPaymentsState, page, pageSize) => {
    const payload = agent.Profile.batchPayments(user, page, pageSize);
    dispatch({type: PROFILE_LOAD_BATCH_PAYMENTS, payload, batchPaymentsState});
  },
  onToggleShowBatchPaymentDetail: (show, value) =>
    dispatch({type: PROFILE_SHOW_BATCH_PAYMENT_DETAIL, show, value}),
  onLoadPaymentNotifications: (user, paymentNotificationsState, page, pageSize) => {
    const payload = agent.Profile.paymentNotifications(user, page, pageSize);
    dispatch({type: PROFILE_LOAD_PAYMENT_NOTIFICATIONS, payload, paymentNotificationsState});
  },
  onToggleShowPaymentNotificationDetail: (show, value) =>
    dispatch({type: PROFILE_SHOW_PAYMENT_NOTIFICATION_DETAIL, show, value}),
  onLoadResellerInfo: (user) =>
    dispatch({type: PROFILE_LOAD_RESELLER_INFO, payload: agent.Profile.resellerInfo(user)}),
  onLoadCommissions: (user) =>
    dispatch({type: PROFILE_LOAD_COMMISSIONS, payload: agent.Profile.commissions(user)}),
  onLoadDingProducts: () =>
    dispatch({type: PROFILE_LOAD_DING_PRODUCTS, payload: agent.Profile.dingProducts()}),
  onUpdateFilterResellerInfo: (value) =>
    dispatch({type: UPDATE_FIELD_PROFILE, key: 'resellerInfoFilterProduct', value}),
  onSubmitSearchResellerInfo: () =>
    dispatch({type: PROFILE_SEARCH_RESELLER_INFO}),
  onUnload: () =>
    dispatch({type: PROFILE_PAGE_UNLOADED}),
});

class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mobileView: window.innerWidth <= 600
    };

    this.loadAffiliates = (state) => {
      const username = state.filter.username !== '' ? state.filter.username : '';
      return this.props.onLoadAffiliates(this.props.currentUser, state, parseInt(state.page) + 1, parseInt(state.pageSize), username);
    };
    this.openSendMessage = value => this.props.onToggleShowSendMessage(true, value);
    this.closeSendMessage = () => this.props.onToggleShowSendMessage(false, null);
    this.submitSendMessage = (subject, body) => this.props.onSubmitSendMessage(this.props.currentUser, subject, body, this.props.affiliateSelected);
    this.openMakeTransference = value => this.props.onToggleShowMakeTransference(true, value);
    this.closeMakeTransference = () => this.props.onToggleShowMakeTransference(false, null);
    this.submitMakeTransference = (amount) => {
      const {affiliatesState} = this.props;
      const username = affiliatesState.filter && affiliatesState.filter.username !== '' ? affiliatesState.filter.username : '';
      return this.props.onSubmitMakeTransference(this.props.currentUser, amount, this.props.affiliateSelected, affiliatesState, parseInt(affiliatesState.page) + 1, parseInt(affiliatesState.pageSize), username);
    };
    this.openAddAffiliate = value => this.props.onToggleShowAddAffiliate(true, value);
    this.closeAddAffiliate = () => this.props.onToggleShowAddAffiliate(false, null);
    this.submitSearchUserByEmail = value => this.props.onSubmitSearchUserByEmail(value);
    this.submitAddAffiliate = () => {
      const {affiliatesState} = this.props;
      const username = affiliatesState.filter && affiliatesState.filter.username !== '' ? affiliatesState.filter.username : '';
      return this.props.onSubmitAddAffiliate(this.props.currentUser, this.props.affiliateSelected, affiliatesState, parseInt(affiliatesState.page) + 1, parseInt(affiliatesState.pageSize), username);
    };

    this.openUnlinkAffiliate = value => this.props.onToggleShowUnlinkAffiliate(true, value);
    this.closeUnlinkAffiliate = () => this.props.onToggleShowUnlinkAffiliate(false, null);
    this.submitUnlinkAffiliate = () => {
      const {affiliatesState} = this.props;
      const username = affiliatesState.filter && affiliatesState.filter.username !== '' ? affiliatesState.filter.username : '';
      return this.props.onSubmitUnlinkAffiliate(this.props.currentUser, this.props.affiliateSelected, affiliatesState, parseInt(affiliatesState.page) + 1, parseInt(affiliatesState.pageSize), username);
    };

    this.loadReferrals = () => this.props.onLoadReferrals(this.props.currentUser);
    this.openResetDebt = value => this.props.onToggleShowResetDebt(true, value);
    this.closeResetDebt = () => this.props.onToggleShowResetDebt(false, null);
    this.submitResetDebt = (userId, amount, note) => this.props.onSubmitResetDebt(this.props.currentUser, userId, amount, note);
    this.submitTransferReferral = (amount, toUserId, transferFrom, transferTo) => this.props.onSubmitTransferReferral(this.props.currentUser, amount, toUserId, transferFrom, transferTo);

    this.loadTransferences = (state) => {
      let sort = 'date';
      let ascending = 'false';
      if (state.sorted.length > 0) {
        sort = state.sorted[0].id;
        ascending = state.sorted[0].desc;
      }
      const fromDate = state.filter.fromDate !== '' ? moment(state.filter.fromDate).format('YYYY-MM-DD') : '';
      const toDate = state.filter.toDate !== '' ? moment(state.filter.toDate).format('YYYY-MM-DD') : '';
      return this.props.onLoadTransferences(this.props.currentUser, state, parseInt(state.page) + 1, parseInt(state.pageSize), sort, ascending, fromDate, toDate);
    };

    this.loadTransactions = (state) => {
      let sort = 'date';
      let ascending = 'false';
      if (state.sorted.length > 0) {
        sort = state.sorted[0].id;
        ascending = state.sorted[0].desc;
      }
      const fromDate = state.filter.fromDate !== '' ? moment(state.filter.fromDate).format('YYYY-MM-DD') : '';
      const toDate = state.filter.toDate !== '' ? moment(state.filter.toDate).format('YYYY-MM-DD') : '';
      const phoneNumber = state.filter.phoneNumber !== '' ? state.filter.phoneNumber : '';
      const service = state.filter.service !== '' ? state.filter.service : '';
      // return
      this.props.onLoadTransactions(this.props.currentUser, state, parseInt(state.page) + 1, parseInt(state.pageSize), sort, ascending, fromDate, toDate, phoneNumber, service);
      this.loadTransactionsSummary(state);
    };

    this.loadTransactionsSummary = (state) => {
      let sort = 'firstName';
      let ascending = 'false';
      if (state.sorted.length > 0) {
        sort = state.sorted[0].id;
        ascending = state.sorted[0].desc;
      }
      const fromDate = state.filter.fromDate !== '' ? moment(state.filter.fromDate).format('YYYY-MM-DD') : '';
      const toDate = state.filter.toDate !== '' ? moment(state.filter.toDate).format('YYYY-MM-DD') : '';
      return this.props.onLoadTransactionsSummary(this.props.currentUser, state, parseInt(state.page) + 1, parseInt(state.pageSize), sort, ascending, fromDate, toDate);
    };

    this.openTransactionDetail = value => this.props.onToggleShowTransactionDetail(true, value);
    this.closeTransactionDetail = () => this.props.onToggleShowTransactionDetail(false, null);
    this.toggleShowTransactionsSummary = () => this.props.onToggleShowTransactionsSummary(!this.props.showTransactionsSummary);

    this.loadBatchPayments = (state) => this.props.onLoadBatchPayments(this.props.currentUser, state, parseInt(state.page) + 1, parseInt(state.pageSize));
    this.openBatchPaymentDetail = value => this.props.onToggleShowBatchPaymentDetail(true, value);
    this.closeBatchPaymentDetail = () => this.props.onToggleShowBatchPaymentDetail(false, null);

    this.loadPaymentNotifications = (state) => this.props.onLoadPaymentNotifications(this.props.currentUser, state, parseInt(state.page) + 1, parseInt(state.pageSize));
    this.openPaymentNotificationDetail = value => this.props.onToggleShowPaymentNotificationDetail(true, value);
    this.closePaymentNotificationDetail = () => this.props.onToggleShowPaymentNotificationDetail(false, null);

    this.loadResellerInfo = () => this.props.onLoadResellerInfo(this.props.currentUser);
    this.loadCommissions = () => this.props.onLoadCommissions(this.props.currentUser);
    this.loadDingProducts = () => this.props.onLoadDingProducts();
    this.submitSearchResellerInfo = (filter) => this.props.onSubmitSearchResellerInfo(filter);
  }

  componentWillMount() {
    this.props.onLoad();
  }

  componentWillUnmount() {
    this.props.onUnload();
  }

  handleSelect = eventKey => this.props.onChangeTab(eventKey);

  render() {
    const {
      currentTab, onSubmitEditProfile, inProgress, errors, lastAction, onResetAction, currentUser,
      affiliatesState, showSendMessage, showMakeTransference, showUnlinkAffiliate, affiliateSelected, superUser, showAddAffiliate, userByEmail,
      referrals, showResetDebt, debt,
      transferencesState,
      transactionsState, showTransactionDetail, transactionDetail, showTransactionsSummary, transactionsSummaryState,
      batchPaymentsState, showBatchPaymentDetail, batchPaymentDetail,
      paymentNotificationsState, showPaymentNotificationDetail, paymentNotificationDetail,
      resellerInfo, filteredCommissions, onUpdateFilterResellerInfo
    } = this.props;

    return (
      <div className="bg-white profile-page-super-container">
        <Row>
          <Col xs={12} sm={3} md={3} lg={3} xl={3} className="profile-tab-menu">
            <div className="vertical-tab">
              <Nav
                defaultActiveKey={currentTab && currentTab !== '' ? currentTab : 'profile'}
                className="flex-column"
                onSelect={ev => ev !== currentTab ? this.handleSelect(ev) : null}>
                <Nav.Link eventKey="profile">My Profile</Nav.Link>
                <Nav.Link eventKey="affiliates">Affiliates</Nav.Link>
                {currentUser && currentUser.isReseller ?
                  <Nav.Link eventKey="referrals">Referrals</Nav.Link> : ''}
                <Nav.Link eventKey="transactions">Transactions</Nav.Link>
                <Nav.Link eventKey="transferences">Transferences</Nav.Link>
                <Nav.Link eventKey="batchPayments">Batch Payments</Nav.Link>
                <Nav.Link eventKey="paymentNotifications">Payment Notifications</Nav.Link>
                {currentUser && currentUser.isReseller ?
                  <Nav.Link eventKey="resellerInfo">Reseller Info</Nav.Link> : ''}
              </Nav>
            </div>
          </Col>

          <Col xs={12} sm={9} md={9} lg={9} xl={9} className="tab-content tab-content-container">
            {
              currentTab === 'affiliates' ?
                <Affiliates
                  inProgress={inProgress}
                  loadAffiliates={this.loadAffiliates}
                  affiliatesState={affiliatesState}
                  showSendMessage={showSendMessage}
                  openSendMessage={this.openSendMessage}
                  closeSendMessage={this.closeSendMessage}
                  submitSendMessage={this.submitSendMessage}
                  submitMakeTransference={this.submitMakeTransference}
                  showMakeTransference={showMakeTransference}
                  openMakeTransference={this.openMakeTransference}
                  closeMakeTransference={this.closeMakeTransference}
                  showAddAffiliate={showAddAffiliate}
                  openAddAffiliate={this.openAddAffiliate}
                  closeAddAffiliate={this.closeAddAffiliate}
                  submitSearchUserByEmail={this.submitSearchUserByEmail}
                  submitAddAffiliate={this.submitAddAffiliate}
                  showUnlinkAffiliate={showUnlinkAffiliate}
                  openUnlinkAffiliate={this.openUnlinkAffiliate}
                  closeUnlinkAffiliate={this.closeUnlinkAffiliate}
                  submitUnlinkAffiliate={this.submitUnlinkAffiliate}
                  affiliateSelected={affiliateSelected}
                  superUser={superUser}
                  userByEmail={userByEmail}
                />
                : currentTab === 'referrals' ?
                <Referrals
                  inProgress={inProgress}
                  loadReferrals={this.loadReferrals}
                  referrals={referrals}
                  openResetDebt={this.openResetDebt}
                  closeResetDebt={this.closeResetDebt}
                  showResetDebt={showResetDebt}
                  debt={debt}
                  submitResetDebt={this.submitResetDebt}
                  submitTransferReferral={this.submitTransferReferral}
                />
                : currentTab === 'transactions' ?
                  <Transactions
                    inProgress={inProgress}
                    loadTransactions={this.loadTransactions}
                    transactionsState={transactionsState}
                    openTransactionDetail={this.openTransactionDetail}
                    closeTransactionDetail={this.closeTransactionDetail}
                    showTransactionDetail={showTransactionDetail}
                    transactionDetail={transactionDetail}
                    showTransactionsSummary={showTransactionsSummary}
                    toggleShowTransactionsSummary={this.toggleShowTransactionsSummary}
                    transactionsSummaryState={transactionsSummaryState}
                    loadTransactionsSummary={this.loadTransactionsSummary}
                  />
                  : currentTab === 'transferences' ?
                    <Transferences
                      inProgress={inProgress}
                      loadTransferences={this.loadTransferences}
                      transferencesState={transferencesState}
                    />
                    : currentTab === 'batchPayments' ?
                      <BatchPayments
                        inProgress={inProgress}
                        loadBatchPayments={this.loadBatchPayments}
                        batchPaymentsState={batchPaymentsState}
                        openBatchPaymentDetail={this.openBatchPaymentDetail}
                        closeBatchPaymentDetail={this.closeBatchPaymentDetail}
                        showBatchPaymentDetail={showBatchPaymentDetail}
                        batchPaymentDetail={batchPaymentDetail}
                      />
                      : currentTab === 'paymentNotifications' ?
                        <PaymentNotifications
                          inProgress={inProgress}
                          loadPaymentNotifications={this.loadPaymentNotifications}
                          paymentNotificationsState={paymentNotificationsState}
                          openPaymentNotificationDetail={this.openPaymentNotificationDetail}
                          closePaymentNotificationDetail={this.closePaymentNotificationDetail}
                          showPaymentNotificationDetail={showPaymentNotificationDetail}
                          paymentNotificationDetail={paymentNotificationDetail}
                        />
                        : currentTab === 'resellerInfo' ?
                          <ResellerInfo
                            referralCode={currentUser.resellerPromoCode}
                            resellerInfo={resellerInfo}
                            loadResellerInfo={this.loadResellerInfo}
                            loadCommissions={this.loadCommissions}
                            loadDingProducts={this.loadDingProducts}
                            filteredCommissions={filteredCommissions}
                            onUpdateFilterResellerInfo={onUpdateFilterResellerInfo}
                            submitSearchResellerInfo={this.submitSearchResellerInfo}
                          />
                          : <MyProfile
                            currentUser={this.props.currentUser}
                            onSubmitEditProfile={onSubmitEditProfile}
                            inProgress={inProgress}
                          />
            }
          </Col>
        </Row>
        {
          errors && errors !== null ?
            <Message type="error" text={errors} onClose={onResetAction}/>
            : lastAction === UPDATE_PROFILE ?
            <Message type="success" text="Profile updated successfully" onClose={onResetAction}/>
            : lastAction === PROFILE_RESET_DEBT ?
              <Message type="success" text="Debt reset successfully" onClose={onResetAction}/>
              : lastAction === PROFILE_TRANSFER_REFERRAL || lastAction === PROFILE_MAKE_TRANSFERENCE ?
                <Message type="success" text="Transference executed successfully" onClose={onResetAction}/>
                : lastAction === PROFILE_SEND_MESSAGE ?
                  <Message type="success" text="Message sended successfully" onClose={onResetAction}/>
                  : lastAction === PROFILE_ADD_AFFILIATE ?
                    <Message type="success" text="Affiliation executed successfully" onClose={onResetAction}/>
                    : lastAction === PROFILE_USER_ALREADY_AFFILIATED ?
                      <Message type="error" text="User already affiliated" onClose={onResetAction}/>
                      : lastAction === PROFILE_USER_NOT_ACTIVATED ?
                        <Message type="error" text="User not activated" onClose={onResetAction}/>
                        : lastAction === PROFILE_USER_DOES_NOT_EXIST ?
                          <Message type="error" text="User does not exist" onClose={onResetAction}/>
                            : lastAction === PROFILE_UNLINK_AFFILIATE ?
                              <Message type="success" text="Successful disaffiliation" onClose={onResetAction}/>
                                : ''
        }
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);