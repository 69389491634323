import React, {Component} from 'react';
import ReactTable from "react-table";
import "react-table/react-table.css";
import {Button, Col, Form, Row} from "react-bootstrap";
import {Formik} from "formik";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import AddUser from './AddUser';
import EditUser from "./EditUser";
import DeleteUser from './DeleteUser';
import TableInfo from '../TableInfo';

class Resellers extends Component {
  render() {
    const {
      inProgress, filteredResellers, selectedUser, isSelectedUserAdmin, isSelectedUserReseller,
      submitSearchReseller, submitSetPinNumber, submitSaveCommissionConfiguration,
      resellerConfiguration, filteredResellerConfiguration, onUpdateFilterResellerConfiguration, onSubmitSearchResellerConfiguration, onUpdateCommissionConfiguration, resellerConfigurationFilterPlan,
      openAddUser, showAddUser, closeAddUser, submitAddReseller, onChangeEditUserTab, currentEditUserTab,
      openEditUser, showEditUser, closeEditUser, submitEditReseller, onUpdateFilterReseller,
      showResetDebtUser, openResetDebtUser, closeResetDebtUser, submitResetDebtUser,
      /*openDeleteUser,*/ showDeleteUser, closeDeleteUser, submitDeleteReseller,
    } = this.props;
    return (
      <Row className="transferences-super-container">
        <Col sm={12}>
          <div className="pl-5-desktop-transferences">
            <h4 className="mb-4">Resellers</h4>
            <Button
              variant="light"
              onClick={openAddUser}
              className="header-toolbar-btn border-radius-4"
            >
              <FontAwesomeIcon icon="plus" className="icon"/>
              Add
            </Button>
            <Formik
              initialValues={{userName: ''}}
              onSubmit={() => submitSearchReseller()}
            >
              {props => {
                const {
                  touched,
                  errors,
                  handleSubmit,
                  values,
                  handleChange,
                  handleBlur
                } = props;
                return (
                  <Form className="mt-4" onSubmit={handleSubmit}>
                    <div className="px-4 py-4 mb-4" style={{border: '1px solid rgba(0,0,0,.1)'}}>
                      <Row className="text-left">
                        <Col sm={12} md={4}>
                          <Form.Group className="mb-4">
                            <Form.Label className="font-weight-bold">User</Form.Label>
                            <Form.Control
                              className="form-input border-radius-4"
                              type="text"
                              id="userName"
                              value={values.userName}
                              onChange={(ev) => {
                                handleChange(ev);
                                onUpdateFilterReseller(ev.target.value);
                              }}
                              onBlur={handleBlur}
                            />
                            {errors.userName && touched.userName && (
                              <div className="error-message">{errors.userName}</div>
                            )}
                          </Form.Group>
                        </Col>
                        <Col sm={12} md={6}>
                          <Form.Group className="mb-3">
                            <Button
                              className="btn btn-black btn-inline border-radius-4 search-button-transactions"
                              type="submit"
                              disabled={inProgress}
                            >
                              <FontAwesomeIcon icon="search" size="sm" className="o-btn-icon"/>
                              Search
                            </Button>
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                    <ReactTable
                      ref={(instance) => {
                        this.table = instance;
                      }}
                      columns={[
                        {
                          Header: "User",
                          accessor: "username",
                        },
                        {
                          Header: "Full Name",
                          id: "fullName",
                          accessor: d => `${d.firstname} ${d.lastname}`,
                        },
                        {
                          Header: "Email",
                          accessor: "email",
                        },
                        {
                          Header: "Confirmed",
                          id: "emailconfirmed",
                          accessor: d => d.emailconfirmed ? 'Yes' : 'No',
                        },
                        {
                          Header: "Balance",
                          id: "balance",
                          accessor: d => d.balance.toFixed(2),
                        },
                        {
                          Header: "Transactions",
                          id: "transactions",
                          accessor: d => d.transactions.length,
                        },
                        {
                          Header: "Phone",
                          accessor: "phonenumber",
                        },
                        {
                          Header: "Super User",
                          accessor: "superUserName",
                        },
                        {
                          Header: "Active",
                          id: "active",
                          accessor: d => d.active ? 'Yes' : 'No'
                        },
                        {
                          Header: "Edit",
                          accessor: "d",
                          Cell: ({row}) => (
                            <Button
                              variant="link" className="btn-row border-radius-4"
                              onClick={() => openEditUser(row._original)}
                            >
                              <FontAwesomeIcon icon="pencil-alt"/>
                            </Button>),
                          sortable: false
                        },
                        /*{
                          Header: "Delete",
                          accessor: "d",
                          Cell: ({row}) => (
                            <Button
                              variant="link" className="btn-row border-radius-4"
                              onClick={() => openDeleteUser(row._original)}
                            >
                              <FontAwesomeIcon icon="trash"/>
                            </Button>),
                          sortable: false
                        }*/
                      ]}
                      defaultSorted={[
                        {
                          id: "username",
                          desc: false
                        }
                      ]}
                      data={filteredResellers}
                      defaultPageSize={20}
                      pageSizeOptions={[5, 10, 20]}
                      className="-striped -highlight"
                      minRows={0}
                      NoDataComponent={() => null}
                    >
                      {(state, makeTable) => {
                        return (
                          <div>
                            {makeTable()}
                            <TableInfo
                              totalRows={state.sortedData.length}
                              page={state.page}
                              pageSize={state.pageSize}
                            />
                          </div>
                        )
                      }}
                    </ReactTable>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </Col>
        <AddUser
          inProgress={inProgress}
          show={showAddUser}
          onHide={closeAddUser}
          submitAddUser={submitAddReseller}
          context="resellers"
        />
        <DeleteUser
          inProgress={inProgress}
          show={showDeleteUser}
          onHide={closeDeleteUser}
          submitDeleteUser={submitDeleteReseller}
          selectedUser={selectedUser}
          context="resellers"
        />
        <EditUser
          inProgress={inProgress}
          show={showEditUser}
          onHide={closeEditUser}
          submitEditUser={submitEditReseller}
          selectedUser={selectedUser}
          showResetDebtUser={showResetDebtUser}
          openResetDebtUser={openResetDebtUser}
          closeResetDebtUser={closeResetDebtUser}
          submitResetDebtUser={submitResetDebtUser}
          currentEditUserTab={currentEditUserTab}
          onChangeEditUserTab={onChangeEditUserTab}
          isSelectedUserAdmin={isSelectedUserAdmin}
          isSelectedUserReseller={isSelectedUserReseller}
          resellerConfiguration={resellerConfiguration}
          filteredResellerConfiguration={filteredResellerConfiguration}
          onUpdateFilterResellerConfiguration={onUpdateFilterResellerConfiguration}
          onSubmitSearchResellerConfiguration={onSubmitSearchResellerConfiguration}
          onUpdateCommissionConfiguration={onUpdateCommissionConfiguration}
          submitSaveCommissionConfiguration={submitSaveCommissionConfiguration}
          resellerConfigurationFilterPlan={resellerConfigurationFilterPlan}
          submitSetPinNumber={submitSetPinNumber}
          context="resellers"
        />
      </Row>
    );
  }
}

export default Resellers;