import {
  ASYNC_START,
  PAGINATE_PAY,
  PAGINATE_PROMOTION,
  PROMOTION_LOAD_INFO,
  PROMOTION_UNLOAD_INFO,
  PAY_PAGE_LOADED,
  PAY_PAGE_UNLOADED,
  UPDATE_FIELD_PAY,
  PAY_PAGE_LOAD_SERVICES,
  PAY_PAGE_UNLOAD_SERVICES,
  UPDATE_FIELD_PAY_SERVICE,
  PAY_SELECT_SERVICE,
  PAY_DESELECT_SERVICE,
  UPDATE_FIELD_FORM_PAY_SERVICE,
  MAKE_PAYMENT,
  PAY_PAGE_LOAD_SERVICES_MASK,
  PAY_AMOUNT_TO_RECEIVE,
  PAY_ADD_PHONE,
  PAY_DELETE_PHONE,
  PAY_RESET_PAYMENT,
  LOGOUT_TO_LOGIN,
  RESIZE_WINDOWS
} from '../constants/actionTypes';

const {paymentMethods} = require('../utils');

export default (state = {}, action) => {
  switch (action.type) {
    case PAY_PAGE_LOADED:
      if (action.error) {
        return {
          ...state,
          inProgress: false,
          errors: action.error ? action.payload.message : null,
          errorsPayment: null, errorsServices: null
        };
      } else {
        const countries = Array.isArray(action.payload[0].body) && action.payload[0].body.length ? action.payload[0].body : [];
        const categories = Array.isArray(action.payload[1].body) && action.payload[0].body.length ? Array.prototype.concat(
          action.payload[1].body, [{
            id: -1,
            description: 'All'
          }])/*.filter(c => c.description !== null)*/ : [];
        const providers = Array.isArray(action.payload[2].body) && action.payload[2].body.length ? action.payload[2].body.filter(item => {
          const found = categories.find(c => c.id === item.operatorCategoryId);
          if (found) {
            item.category = found.description;
            return item;
          }
          return null;
        }) : [];

        const promotions = action.payload[3] && Array.isArray(action.payload[3].body) && action.payload[3].body.length ? action.payload[3].body : [];

        let country = {};
        if (countries.length) {
          let found = countries.find(c => c.isO2 === 'US');
          if (found) {
            country = {
              value: found.id,
              label: found.countryName,
              flag: found.isO2,
              callingCode: found.callingCode
            };
          } else {
            country = {
              value: countries[0].id,
              label: countries[0].countryName,
              flag: countries[0].isO2,
              callingCode: countries[0].callingCode
            };
          }
        }
        const category = categories.length ? {value: -1, label: 'All'} : {};
        const provider = '';
        const preFilteredProviders = providers.filter(p => (
          country.value === p.countryId &&
          (category.value === -1 || p.operatorCategoryId === category.value)
        ));
        const start = 0;
        const length = 8;
        const currentPage = 1;
        const currentPagePromotions = 1;
        const rate = preFilteredProviders.length / length;
        const rest = preFilteredProviders.length % length;
        const filteredProviders = preFilteredProviders.slice(start, length);
        let screenSize = window.innerWidth;
        let itemsPagination = screenSize > 600 ? 5 : 2;

        let firstElement = 0;
        let lastElement = firstElement + (itemsPagination - 1);

        let promotionsPaginated = [];

        promotions.forEach((promo, index) => {
          if (promo.providerCode.length === 4) {
            promo.flag = promo.providerCode[2].toLowerCase() + promo.providerCode[3].toLowerCase();

            // if(providers && providers.length > 0){
            //   let promoProviderCode = promo.providerCode[0] + promo.providerCode[1];
            //   const promoProviders = providers.find( p => p.operatorCode === promoProviderCode );

            //   if(promoProviders){
            //     promo.providerImageUrl = promoProviders.imageUrl;
            //   }
            // }           
          }

          if (index >= firstElement && index <= lastElement) {
            promotionsPaginated.push(promo);
          }
        });        

        const totalPagesPromotions = promotions.length % itemsPagination === 0 ? Math.floor(promotions.length / itemsPagination) : Math.floor(promotions.length / itemsPagination) + 1;
        return {
          ...state,
          inProgress: false,
          countries,
          promotions,
          promotionsPaginated,
          totalPagesPromotions,
          categories,
          providers,
          country,
          category,
          provider,
          filteredProviders,
          length,
          currentPage,
          currentPagePromotions,
          totalPages: rest === 0 ? rate : Math.trunc(rate) + 1,
          start,
          errorsPayment: null,
          errorsServices: null,
          paymentStatus: null,
          openModalPromotion: false,
          selectedPromotion: null,
          simplePaymentResponse: null,
        };
      }

    case PAY_PAGE_UNLOADED:
      return {};

    case UPDATE_FIELD_PAY:
      let provider = action.key === 'provider' ? action.value === null ? '' : action.value : state.provider;
      let country = action.key === 'country' ? action.value : state.country;
      let category = action.key === 'category' ? action.value : state.category;
      // let promotions = action.key === 'promotions' ? action.value : state.promotions;
      let preFilteredProviders = [];

      if (action.key === 'provider') {
        if (typeof provider === "object") {
          preFilteredProviders = state.providers.filter(p => (
            p.id === provider.value &&
            (category.value === -1 || p.operatorCategoryId === category.value)
          ));
          let found = state.countries.find(c => c.id === provider.countryId);
          if (found) {
            country = {
              value: found.id,
              label: found.countryName,
              flag: found.isO2,
              callingCode: found.callingCode
            };
          }
        } else {
          preFilteredProviders = state.providers.filter(p => (
            country.value === p.countryId &&
            (category.value === -1 || p.operatorCategoryId === category.value) &&
            (provider === '' || p.name.toLowerCase().includes(provider.toLowerCase()))
          ));
        }
      } else {
        if (typeof provider === "object") {
          preFilteredProviders = state.providers.filter(p => (
            p.id === provider.value &&
            country.value === p.countryId &&
            (category.value === -1 || p.operatorCategoryId === category.value)
          ));
        } else {
          preFilteredProviders = state.providers.filter(p => (
            country.value === p.countryId &&
            (category.value === -1 || p.operatorCategoryId === category.value) &&
            (provider === '' || p.name.toLowerCase().includes(provider.toLowerCase()))
          ));
        }
      }

      let currentPage = 1;
      let currentPagePromotions = 1;
      let start = 0;
      let length = state.length;
      let rate = preFilteredProviders.length / length;
      let rest = preFilteredProviders.length % length;
      let filteredProviders = preFilteredProviders.slice(start, length);

      return {
        ...state,
        [action.key]: action.value,
        filteredProviders,
        country,
        provider,
        length,
        currentPage,
        totalPages: rest === 0 ? rate : Math.trunc(rate) + 1,
        start
      };

    case PAGINATE_PAY:
      if (action.page === 'prev') {
        currentPage = state.currentPage > 1 ? state.currentPage - 1 : 1;
      } else if (action.page === 'next') {
        currentPage = state.currentPage < state.totalPages ? state.currentPage + 1 : state.totalPages;
      } else {
        currentPage = action.page ? action.page : 1;
      }
      provider = state.provider;
      country = state.country;
      category = state.category;
      preFilteredProviders = state.providers.filter(p => (
        country.value === p.countryId &&
        (category.value === -1 || p.operatorCategoryId === category.value) &&
        (provider === '' || p.name.toLowerCase().includes(provider.toLowerCase()))
      ));
      length = state.length;
      start = (currentPage - 1) * state.length;
      filteredProviders = preFilteredProviders.slice(start, start + length);
      return {
        ...state,
        currentPage,
        filteredProviders,
        start
      };

    case PAGINATE_PROMOTION:
      if (action.payload.page === 'prev') {
        currentPagePromotions = state.currentPagePromotions > 1 ? state.currentPagePromotions - 1 : 1;
      } else if (action.payload.page === 'next') {
        currentPagePromotions = state.currentPagePromotions < state.totalPagesPromotions ? state.currentPagePromotions + 1 : state.totalPagesPromotions;
      } else {
        currentPagePromotions = action.payload.page ? action.payload.page : 1;
      }

      let screenSize = window.innerWidth;
      let itemsPagination = screenSize > 600 ? 5 : 2;

      let firstElement = (currentPagePromotions - 1) * itemsPagination;
      let lastElement = firstElement + (itemsPagination - 1);

      let promotionsPaginated = [];

      state.promotions.forEach((promo, index) => {
        if (index >= firstElement && index <= lastElement) {
          promotionsPaginated.push(promo);
        }
      });

      return {
        ...state,
        currentPagePromotions,
        promotionsPaginated
      };

      case RESIZE_WINDOWS: {
        if(state.promotions !== null && state.promotions !== undefined && state.promotions.length > 0){
          let screenSize = action.screenWidth;
          let itemsPagination = screenSize > 600 ? 5 : 2;
          let firstElement = (state.currentPagePromotions - 1) * itemsPagination;
          let lastElement = firstElement + (itemsPagination - 1);
          let promotionsPaginated = [];
          state.promotions.forEach((promo, index) => {
            if (index >= firstElement && index <= lastElement) {
              promotionsPaginated.push(promo);
            }
          });
          const totalPagesPromotions = state.promotions.length % itemsPagination === 0 ? Math.floor(state.promotions.length / itemsPagination) : Math.floor(state.promotions.length / itemsPagination) + 1;
           return {
            ...state,
            promotionsPaginated,
            totalPagesPromotions
          }
        }
        else{
          return {
            ...state
          }
        }
       
      }

    case PAY_SELECT_SERVICE: {
      const plans = state.plans;
      const found = plans.find(p => p.id === parseInt(action.id));
      return {
        ...state,
        selectedService: found ? found : null,
        paymentMethod: paymentMethods.BALANCE,
        errorsPayment: null,
        paymentStatus: null,
        totalPrice: 0,
        totalFee: 0,
        totalToPay: 0,
        phones: [],
        simplePaymentResponse: null,
        amountToReceive: found && found.paymentApiId === 4 && state.plans.length > 1 ? found.receiveValueIncludingTax + ' ' + state.selectedProvider.currencyIso : 0
      }
    }

    case PAY_DESELECT_SERVICE: {
      return {
        ...state,
        selectedService: null,
        phones: [],
        errorsPayment: null,
      }
    }

    case PAY_ADD_PHONE: {
      let totalPrice = 0;
      let totalFee = 0;
      const phones = Array.prototype.concat(
        state.phones, [{
          id: action.id,
          amount: parseFloat(action.amount) + parseFloat(action.fee),
          item: action.phone,
          fee: action.fee,
          receive: 0
        }]);

      for (let i = 0; i < phones.length; i++) {
        totalPrice += parseFloat(phones[i].amount);
        totalFee += parseFloat(phones[i].fee);
      }

      return {
        ...state,
        phones,
        totalPrice,
        totalFee,
        totalToPay: totalPrice + totalFee,
        showAddPhone: false
      }
    }

    case PAY_AMOUNT_TO_RECEIVE:
      return {
        ...state,
        phones: state.phones.map(item => {
          if (item.id === action.id) {
            item.receive = action.payload.body === 0 || action.error ? 0 : action.payload.body.amount
          }
          return item;
        })
      };

    case PAY_DELETE_PHONE: {
      const phones = state.phones.filter((item, i) => i !== action.index);
      let totalPrice = 0;
      let totalFee = 0;

      for (let i = 0; i < phones.length; i++) {
        totalPrice += parseFloat(phones[i].amount);
        totalFee += parseFloat(phones[i].fee);
      }

      return {
        ...state,
        phones,
        totalPrice,
        totalFee,
        totalToPay: totalPrice + totalFee
      }
    }

    case PAY_PAGE_LOAD_SERVICES_MASK:
      return {
        ...state,
        providerMask: action.id
      };

    case PROMOTION_LOAD_INFO:
      let openModalPromotion = true;
      let selectedPromotion = action.payload.promotion;

      return {
        ...state,
        openModalPromotion,
        selectedPromotion
      };

    case PROMOTION_UNLOAD_INFO:
      return {
        ...state,
        openModalPromotion: false,
        selectedPromotion: null
      };

    case PAY_PAGE_LOAD_SERVICES:
      if (action.error) {
        return {
          ...state,
          loadingServices: false,
          showAddPhone: false,
          providerMask: null,
          errorsServices: action.error ? action.payload.message : null,
        };
      } else {
        const services = action.payload.body.providerCategories;
        let serviceCategories = [];
        let servicePlans = [];
        for (let i = 0; i < services.length; i++) {
          serviceCategories.push({
            id: services[i].id,
            description: services[i].description,
          });
          let categoryPlans = services[i].plans;
          for (let j = 0; j < categoryPlans.length; j++) {
            categoryPlans[j].categoryId = services[i].id;
            servicePlans.push(categoryPlans[j]);
          }
        }
        let serviceCategory = {value: -1, label: 'All'};
        if (serviceCategories.length > 0) {
          serviceCategories.length > 1 ?
            serviceCategories.push({id: serviceCategory.value, description: serviceCategory.label}) :
            serviceCategory = {value: serviceCategories[0].id, label: serviceCategories[0].description}
        }
        const filteredPlans = serviceCategory.value === -1 ? servicePlans : servicePlans.filter(p => p.categoryId === serviceCategory.value);

        return {
          ...state,
          selectedProvider: action.payload.body,
          servicePlans,
          loadingServices: false,
          selectedService: null,
          serviceCategories,
          serviceCategory,
          plans: filteredPlans,
          showServices: !action.error,
          showAddPhone: false,
          errorsPayment: null,
          errorsServices: null,
          paymentStatus: null,
          providerMask: null,
        };
      }

    case UPDATE_FIELD_PAY_SERVICE:
      const servicePlans = state.servicePlans;
      const newOption = action.value;
      const filteredPlans = newOption.value === -1 ? servicePlans : servicePlans.filter(p => p.categoryId === newOption.value);
      return {
        ...state,
        [action.key]: action.value,
        plans: filteredPlans
      };

    case UPDATE_FIELD_FORM_PAY_SERVICE:
      return {
        ...state,
        [action.key]: action.value,
      };

    case PAY_PAGE_UNLOAD_SERVICES:
      return {
        ...state,
        showServices: false,
        serviceCategories: [],
        selectedProvider: null,
        selectedService: null,
        serviceCategory: null,
        errorsServices: null,
        paymentStatus: null,
        simplePaymentResponse: null,
        servicePlans: [],
        plans: [],
      };

    case MAKE_PAYMENT:
      if (action.error) {
        return {
          ...state,
          makingPayment: false,
          errorsPayment: action.error ? (action.payload.body.message ? action.payload.body.message : action.payload.message ? action.payload.message : null) : null
        };
      } else {
        return {
          ...state,
          makingPayment: false,
          errorsPayment: null,
          simplePaymentResponse: action.payload.body.simplePaymentResponse,
          //selectedService: null,
          paymentStatus: 'Successful'
        };
      }

    case LOGOUT_TO_LOGIN:
      return {
        ...state,
        makingPayment: false,
        errorsPayment: null,
        selectedService: null,
        showServices: false,
        serviceCategories: [],
        selectedProvider: null,
        serviceCategory: null,
        errorsServices: null,
        paymentStatus: null,
        simplePaymentResponse: null,
        servicePlans: [],
        plans: [],
        phones: []
      };

    case PAY_RESET_PAYMENT:
      return {...state, makingPayment: false, errorsPayment: null};

    case ASYNC_START:
      if (action.subtype === PAY_PAGE_LOADED) {
        return {...state, inProgress: true, errors: null};
      } else if (action.subtype === PAY_PAGE_LOAD_SERVICES) {
        return {...state, loadingServices: true, errorsServices: null};
      } else if (action.subtype === MAKE_PAYMENT) {
        return {...state, makingPayment: true, errorsPayment: null};
      }
      break;

    default:
      return state;
  }

  return state;
};
