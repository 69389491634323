import {
  REPAIR_PAGE_LOADED,
  REPAIR_PAGE_UNLOADED,
  UPDATE_FIELD_REPAIR
} from '../constants/actionTypes';

const sortOptions = [
  {value: 1, label: 'Price (Lowest to Highest)'},
  {value: 2, label: 'Price (Highest to Lowest)'}
];

export default (state = {}, action) => {
  switch (action.type) {
    case REPAIR_PAGE_LOADED:
      return {
        ...state,
        sort: sortOptions[0],
        sortOptions: sortOptions
      };

    case UPDATE_FIELD_REPAIR:
      return { ...state, [action.key]: action.value };

    case REPAIR_PAGE_UNLOADED:
      return {};

    default:
      return state;
  }
};
