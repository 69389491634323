import React, {Component} from 'react';
import {Pagination} from "react-bootstrap";
import './PaginationCircle.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class PaginationCircle extends Component {
  constructor(props) {
    super(props);

    this.changePage = ev => this.props.onPaginate(
      ev.target.getAttribute('page-link') !== null ?
        ev.target.getAttribute('page-link') :
        ev.target.parentNode.getAttribute('page-link')
    );
  }

  render() {
    const {active, nroPages} = this.props;

    let items = [];
    items.push(
      <Pagination.Prev key="prev" className="pi-circle" onClick={this.changePage} page-link="prev">
        <FontAwesomeIcon icon="arrow-left" size="1x" className="" page-link="prev"/>
      </Pagination.Prev>
    );
    for (let number = 1; number <= nroPages; number++) {
      items.push(
        <Pagination.Item key={number} active={number === parseInt(active)} className="pi-circle" onClick={this.changePage} page-link={number}>
          {/*number*/}
        </Pagination.Item>
      );
    }
    items.push(
      <Pagination.Next key="next" className="pi-circle" onClick={this.changePage} page-link="next">
        <FontAwesomeIcon icon="arrow-right" size="1x" className="" page-link="next"/>
      </Pagination.Next>
    );

    return (
      <div className="ml-auto mr-auto text-xs-center">
        <Pagination>{items}</Pagination>
      </div>
    );
  }
}

export default PaginationCircle;