import React, {Component} from 'react';
import {Row, Col, Form, Button} from "react-bootstrap";
import Select from 'react-select';
import PaginationSimple from "./PaginationSimple/PaginationSimple";

const selectStyles = {
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? "none" : "none",
    boxShadow: state.isFocused ? "none" : "none",
  })
};

const sortOptions = [
  {value: 1, label: 'Price (Lowest to Highest)'},
  {value: 2, label: 'Price (Highest to Lowest)'}
];

class Unlock extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sortSelected: sortOptions[0],
      sortOptions: sortOptions
    };

    this.handleChangeSort = this.handleChangeSort.bind(this);
  }

  handleChangeSort = (selectedOption) => {
    let newState = this.state;
    newState.sortSelected = selectedOption;
    this.setState(newState);
  };

  render() {
    const {sortSelected} = this.state;

    return (
      <div>
        <div className="page-top">
          <Row className="vertical-align">
            <Col xs={12} md={6}>
              <div className="font-white">
                <h3 className="c-caption-title">Unlock</h3>
                <p className="c-caption-text">Unlock any device for any provider of any country!</p>
              </div>
            </Col>
          </Row>
        </div>
        <div className="content pt-4 cmt-1">
          <div className="pl-5 pr-5">
            <Row>
              <Col sm={12} md={6}>
                <div className="d-flex flex-row bd-highlight mb-3">
                  <Form.Label column sm={5} className="text-left pl-0">
                    1-20 of 50 repair services
                  </Form.Label>
                </div>
              </Col>
              <Col sm={12} md={6} className="">
                <div className="d-flex flex-row-reverse bd-highlight">
                  <div className="w-50">
                    <Select value={sortSelected}
                            onChange={this.handleChangeSort}
                            options={this.state.sortOptions}
                            styles={selectStyles}
                            components={
                              {
                                //DropdownIndicator: () => null,
                                IndicatorSeparator: () => null
                              }
                            }
                    /></div>
                  <div className="p-2 bd-highlight">
                    <Form.Label className="font-weight-bold">
                      Sort By:
                    </Form.Label>
                  </div>
                </div>
              </Col>
            </Row>
            <hr className="mt-0 mb-0 n-divider"/>
            <Row className="mt-4">
              <Col sm={6} md={3} className="fp-left">
                <div className="lf-section mb-4">
                  <Form.Label className="font-weight-bold w-100 mb-0">
                    Filter by:
                  </Form.Label>
                </div>
                <div className="lf-section">
                  <Form.Label className="w-100 lf-title">
                    Price
                  </Form.Label>
                  <Form.Control className="w-100 lf-input mb-2" type="text" placeholder="$ Minimum"/>
                  <Form.Control className="w-100 lf-input" type="text" placeholder="$ Maximum"/>
                </div>
                <div className="lf-section">
                  <Form.Label className="w-100 lf-title">
                    Published Date
                  </Form.Label>
                  <Form.Control className="w-100 lf-input mb-2" type="text" placeholder="All"/>
                </div>
                <div className="lf-section">
                  <Form.Label className="w-100 lf-title">
                    Distance
                  </Form.Label>
                  <Form.Control className="w-100 lf-input mb-2" type="text" placeholder="Zin Code"/>
                </div>
                <div className="lf-section">
                  <Button className="btn-app btn-black w-100">Apply Filter/s</Button>
                </div>
              </Col>
              <Col sm={6} md={9} className="fp-right pt-2">
                <Row>
                  <Col md={3} className="pl-0 pr-4 mb-4">
                    <div className="shadow fp-item text-center position-relative">
                      <div className="fp-item-header">
                        <p className="fp-item-category">REPAIR</p>
                        <p className="fp-item-title">iPhone</p>
                        <p className="fp-item-owner">By rcbeyra</p>
                      </div>
                      <div className="fp-item-body">

                      </div>
                      <div className="fp-item-footer fixed-bottom position-absolute">
                        <p className="fp-item-price">$30.00</p>
                        <p className="fp-item-date">03/30/2017</p>
                      </div>
                    </div>
                  </Col>
                  <Col md={3} className="pl-0 pr-4 mb-4">
                    <div className="shadow fp-item text-center position-relative">
                      <div className="fp-item-header">
                        <p className="fp-item-category">REPAIR</p>
                        <p className="fp-item-title">iPhone</p>
                        <p className="fp-item-owner">By rcbeyra</p>
                      </div>
                      <div className="fp-item-body">

                      </div>
                      <div className="fp-item-footer fixed-bottom position-absolute">
                        <p className="fp-item-price">$30.00</p>
                        <p className="fp-item-date">03/30/2017</p>
                      </div>
                    </div>
                  </Col>
                  <Col md={3} className="pl-0 pr-4 mb-4">
                    <div className="shadow fp-item text-center position-relative">
                      <div className="fp-item-header">
                        <p className="fp-item-category">REPAIR</p>
                        <p className="fp-item-title">iPhone</p>
                        <p className="fp-item-owner">By rcbeyra</p>
                      </div>
                      <div className="fp-item-body">

                      </div>
                      <div className="fp-item-footer fixed-bottom position-absolute">
                        <p className="fp-item-price">$30.00</p>
                        <p className="fp-item-date">03/30/2017</p>
                      </div>
                    </div>
                  </Col>
                  <Col md={3} className="pl-0 pr-4 mb-4">
                    <div className="shadow fp-item text-center position-relative">
                      <div className="fp-item-header">
                        <p className="fp-item-category">REPAIR</p>
                        <p className="fp-item-title">iPhone</p>
                        <p className="fp-item-owner">By rcbeyra</p>
                      </div>
                      <div className="fp-item-body">

                      </div>
                      <div className="fp-item-footer fixed-bottom position-absolute">
                        <p className="fp-item-price">$30.00</p>
                        <p className="fp-item-date">03/30/2017</p>
                      </div>
                    </div>
                  </Col>
                  <Col md={3} className="pl-0 pr-4 mb-4">
                    <div className="shadow fp-item text-center position-relative">
                      <div className="fp-item-header">
                        <p className="fp-item-category">REPAIR</p>
                        <p className="fp-item-title">iPhone</p>
                        <p className="fp-item-owner">By rcbeyra</p>
                      </div>
                      <div className="fp-item-body">

                      </div>
                      <div className="fp-item-footer fixed-bottom position-absolute">
                        <p className="fp-item-price">$30.00</p>
                        <p className="fp-item-date">03/30/2017</p>
                      </div>
                    </div>
                  </Col>
                  <Col md={3} className="pl-0 pr-4 mb-4">
                    <div className="shadow fp-item text-center position-relative">
                      <div className="fp-item-header">
                        <p className="fp-item-category">REPAIR</p>
                        <p className="fp-item-title">iPhone</p>
                        <p className="fp-item-owner">By rcbeyra</p>
                      </div>
                      <div className="fp-item-body">

                      </div>
                      <div className="fp-item-footer fixed-bottom position-absolute">
                        <p className="fp-item-price">$30.00</p>
                        <p className="fp-item-date">03/30/2017</p>
                      </div>
                    </div>
                  </Col>
                  <Col md={3} className="pl-0 pr-4 mb-4">
                    <div className="shadow fp-item text-center position-relative">
                      <div className="fp-item-header">
                        <p className="fp-item-category">REPAIR</p>
                        <p className="fp-item-title">iPhone</p>
                        <p className="fp-item-owner">By rcbeyra</p>
                      </div>
                      <div className="fp-item-body">

                      </div>
                      <div className="fp-item-footer fixed-bottom position-absolute">
                        <p className="fp-item-price">$30.00</p>
                        <p className="fp-item-date">03/30/2017</p>
                      </div>
                    </div>
                  </Col>
                  <Col md={3} className="pl-0 pr-4 mb-4">
                    <div className="shadow fp-item text-center position-relative">
                      <div className="fp-item-header">
                        <p className="fp-item-category">REPAIR</p>
                        <p className="fp-item-title">iPhone</p>
                        <p className="fp-item-owner">By rcbeyra</p>
                      </div>
                      <div className="fp-item-body">

                      </div>
                      <div className="fp-item-footer fixed-bottom position-absolute">
                        <p className="fp-item-price">$30.00</p>
                        <p className="fp-item-date">03/30/2017</p>
                      </div>
                    </div>
                  </Col>
                  <Col md={3} className="pl-0 pr-4 mb-4">
                    <div className="shadow fp-item text-center position-relative">
                      <div className="fp-item-header">
                        <p className="fp-item-category">REPAIR</p>
                        <p className="fp-item-title">iPhone</p>
                        <p className="fp-item-owner">By rcbeyra</p>
                      </div>
                      <div className="fp-item-body">

                      </div>
                      <div className="fp-item-footer fixed-bottom position-absolute">
                        <p className="fp-item-price">$30.00</p>
                        <p className="fp-item-date">03/30/2017</p>
                      </div>
                    </div>
                  </Col>
                  <Col md={3} className="pl-0 pr-4 mb-4">
                    <div className="shadow fp-item text-center position-relative">
                      <div className="fp-item-header">
                        <p className="fp-item-category">REPAIR</p>
                        <p className="fp-item-title">iPhone</p>
                        <p className="fp-item-owner">By rcbeyra</p>
                      </div>
                      <div className="fp-item-body">

                      </div>
                      <div className="fp-item-footer fixed-bottom position-absolute">
                        <p className="fp-item-price">$30.00</p>
                        <p className="fp-item-date">03/30/2017</p>
                      </div>
                    </div>
                  </Col>
                  <Col md={3} className="pl-0 pr-4 mb-4">
                    <div className="shadow fp-item text-center position-relative">
                      <div className="fp-item-header">
                        <p className="fp-item-category">REPAIR</p>
                        <p className="fp-item-title">iPhone</p>
                        <p className="fp-item-owner">By rcbeyra</p>
                      </div>
                      <div className="fp-item-body">

                      </div>
                      <div className="fp-item-footer fixed-bottom position-absolute">
                        <p className="fp-item-price">$30.00</p>
                        <p className="fp-item-date">03/30/2017</p>
                      </div>
                    </div>
                  </Col>
                  <Col md={3} className="pl-0 pr-4 mb-4">
                    <div className="shadow fp-item text-center position-relative">
                      <div className="fp-item-header">
                        <p className="fp-item-category">REPAIR</p>
                        <p className="fp-item-title">iPhone</p>
                        <p className="fp-item-owner">By rcbeyra</p>
                      </div>
                      <div className="fp-item-body">

                      </div>
                      <div className="fp-item-footer fixed-bottom position-absolute">
                        <p className="fp-item-price">$30.00</p>
                        <p className="fp-item-date">03/30/2017</p>
                      </div>
                    </div>
                  </Col>
                  <Col md={3} className="pl-0 pr-4 mb-4">
                    <div className="shadow fp-item text-center position-relative">
                      <div className="fp-item-header">
                        <p className="fp-item-category">REPAIR</p>
                        <p className="fp-item-title">iPhone</p>
                        <p className="fp-item-owner">By rcbeyra</p>
                      </div>
                      <div className="fp-item-body">

                      </div>
                      <div className="fp-item-footer fixed-bottom position-absolute">
                        <p className="fp-item-price">$30.00</p>
                        <p className="fp-item-date">03/30/2017</p>
                      </div>
                    </div>
                  </Col>
                  <Col md={3} className="pl-0 pr-4 mb-4">
                    <div className="shadow fp-item text-center position-relative">
                      <div className="fp-item-header">
                        <p className="fp-item-category">REPAIR</p>
                        <p className="fp-item-title">iPhone</p>
                        <p className="fp-item-owner">By rcbeyra</p>
                      </div>
                      <div className="fp-item-body">

                      </div>
                      <div className="fp-item-footer fixed-bottom position-absolute">
                        <p className="fp-item-price">$30.00</p>
                        <p className="fp-item-date">03/30/2017</p>
                      </div>
                    </div>
                  </Col>
                  <Col md={3} className="pl-0 pr-4 mb-4">
                    <div className="shadow fp-item text-center position-relative">
                      <div className="fp-item-header">
                        <p className="fp-item-category">REPAIR</p>
                        <p className="fp-item-title">iPhone</p>
                        <p className="fp-item-owner">By rcbeyra</p>
                      </div>
                      <div className="fp-item-body">

                      </div>
                      <div className="fp-item-footer fixed-bottom position-absolute">
                        <p className="fp-item-price">$30.00</p>
                        <p className="fp-item-date">03/30/2017</p>
                      </div>
                    </div>
                  </Col>
                  <Col md={3} className="pl-0 pr-4 mb-4">
                    <div className="shadow fp-item text-center position-relative">
                      <div className="fp-item-header">
                        <p className="fp-item-category">REPAIR</p>
                        <p className="fp-item-title">iPhone</p>
                        <p className="fp-item-owner">By rcbeyra</p>
                      </div>
                      <div className="fp-item-body">

                      </div>
                      <div className="fp-item-footer fixed-bottom position-absolute">
                        <p className="fp-item-price">$30.00</p>
                        <p className="fp-item-date">03/30/2017</p>
                      </div>
                    </div>
                  </Col>
                  <Col md={3} className="pl-0 pr-4 mb-4">
                    <div className="shadow fp-item text-center position-relative">
                      <div className="fp-item-header">
                        <p className="fp-item-category">REPAIR</p>
                        <p className="fp-item-title">iPhone</p>
                        <p className="fp-item-owner">By rcbeyra</p>
                      </div>
                      <div className="fp-item-body">

                      </div>
                      <div className="fp-item-footer fixed-bottom position-absolute">
                        <p className="fp-item-price">$30.00</p>
                        <p className="fp-item-date">03/30/2017</p>
                      </div>
                    </div>
                  </Col>
                  <Col md={3} className="pl-0 pr-4 mb-4">
                    <div className="shadow fp-item text-center position-relative">
                      <div className="fp-item-header">
                        <p className="fp-item-category">REPAIR</p>
                        <p className="fp-item-title">iPhone</p>
                        <p className="fp-item-owner">By rcbeyra</p>
                      </div>
                      <div className="fp-item-body">

                      </div>
                      <div className="fp-item-footer fixed-bottom position-absolute">
                        <p className="fp-item-price">$30.00</p>
                        <p className="fp-item-date">03/30/2017</p>
                      </div>
                    </div>
                  </Col>
                  <Col md={3} className="pl-0 pr-4 mb-4">
                    <div className="shadow fp-item text-center position-relative">
                      <div className="fp-item-header">
                        <p className="fp-item-category">REPAIR</p>
                        <p className="fp-item-title">iPhone</p>
                        <p className="fp-item-owner">By rcbeyra</p>
                      </div>
                      <div className="fp-item-body">

                      </div>
                      <div className="fp-item-footer fixed-bottom position-absolute">
                        <p className="fp-item-price">$30.00</p>
                        <p className="fp-item-date">03/30/2017</p>
                      </div>
                    </div>
                  </Col>
                  <Col md={3} className="pl-0 pr-4 mb-4">
                    <div className="shadow fp-item text-center position-relative">
                      <div className="fp-item-header">
                        <p className="fp-item-category">REPAIR</p>
                        <p className="fp-item-title">iPhone</p>
                        <p className="fp-item-owner">By rcbeyra</p>
                      </div>
                      <div className="fp-item-body">

                      </div>
                      <div className="fp-item-footer fixed-bottom position-absolute">
                        <p className="fp-item-price">$30.00</p>
                        <p className="fp-item-date">03/30/2017</p>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3 mb-3">
                  <PaginationSimple active={1} nroPages={3}/>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

export default Unlock;