import React, {Component} from 'react';
import {Col, Row, Form, Button, Modal, Collapse} from "react-bootstrap";
import moment from 'moment';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as Yup from "yup";
import {Formik} from "formik";
import ReactTable from "react-table";
import {Datepicker} from "react-formik-ui";
import TableInfo from '../TableInfo';

const {paymentMethod} = require('../../utils');

const TransactionDetail = (props) => {
  const {transactionDetail, ...rest} = props;
  return (
    <Modal
      {...rest}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-app"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="app-header">
          Transaction Detail
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-5">
        {
          transactionDetail ?
            <Row>
              <Col sm={12} md={6}>
                <Row>
                  <Col sm={12}>
                    <Form.Label className="font-weight-bold">Date:</Form.Label>&nbsp;
                    <Form.Label>{moment(transactionDetail.date).format('YYYY-MM-DD')}</Form.Label>
                  </Col>
                  <Col sm={12}>
                    <Form.Label className="font-weight-bold">Time:</Form.Label>&nbsp;
                    <Form.Label>{moment(transactionDetail.date).format('hh:mm')}</Form.Label>
                  </Col>
                  <Col sm={12}>
                    <Form.Label className="font-weight-bold">CellShop Receipt Number:</Form.Label>&nbsp;
                    <Form.Label>{transactionDetail.cellShopReceiptNumber}</Form.Label>
                  </Col>
                  <Col sm={12}>
                    <Form.Label className="font-weight-bold">External Confirmation Number:</Form.Label>&nbsp;
                    <Form.Label>{transactionDetail.externalConfirmationNumber}</Form.Label>
                  </Col>
                  <Col sm={12}>
                    <Form.Label className="font-weight-bold">Amount Excluding Tax:</Form.Label>&nbsp;
                    <Form.Label>{transactionDetail.amountExcludingTax.toFixed(2)}</Form.Label>
                  </Col>
                  <Col sm={12}>
                    <Form.Label className="font-weight-bold">Tax Amount:</Form.Label>&nbsp;
                    <Form.Label>{transactionDetail.taxAmount.toFixed(2)}</Form.Label>
                  </Col>
                  <Col sm={12}>
                    <Form.Label className="font-weight-bold">Destination Amount:</Form.Label>&nbsp;
                    <Form.Label>{transactionDetail.destinationAmount.toFixed(2)}</Form.Label>
                  </Col>
                  <Col sm={12}>
                    <Form.Label className="font-weight-bold">Commission:</Form.Label>&nbsp;
                    <Form.Label>{transactionDetail.commission.toFixed(2)}</Form.Label>
                  </Col>
                </Row>
              </Col>
              <Col sm={12} md={6}>
                <Row>
                  <Col sm={12}>
                    <Form.Label className="font-weight-bold">Amount:</Form.Label>&nbsp;
                    <Form.Label>{transactionDetail.amount.toFixed(2)}</Form.Label>
                  </Col>
                  <Col sm={12}>
                    <Form.Label className="font-weight-bold">Plan/Service:</Form.Label>&nbsp;
                    <Form.Label>{transactionDetail.serviceDescription}</Form.Label>
                  </Col>
                  <Col sm={12}>
                    <Form.Label className="font-weight-bold">Payment Method:</Form.Label>&nbsp;
                    <Form.Label>{transactionDetail.paymentDescription}</Form.Label>
                  </Col>
                  <Col sm={12}>
                    <Form.Label className="font-weight-bold">Transfer Type:</Form.Label>&nbsp;
                    <Form.Label>{transactionDetail.transferType}</Form.Label>
                  </Col>
                  <Col sm={12}>
                    <Form.Label className="font-weight-bold">Destination Currency:</Form.Label>&nbsp;
                    <Form.Label>{transactionDetail.destinationCurrency}</Form.Label>
                  </Col>
                  <Col sm={12}>
                    <Form.Label className="font-weight-bold">Phone Number:</Form.Label>&nbsp;
                    <Form.Label>{transactionDetail.phoneNumber}</Form.Label>
                  </Col>
                  <Col sm={12}>
                    <Form.Label className="font-weight-bold">Status:</Form.Label>&nbsp;
                    <Form.Label>{transactionDetail.status}</Form.Label>
                  </Col>
                </Row>
              </Col>
            </Row> : ''
        }
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className="btn btn-outline-dark btn-form-app border-radius-4">Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

class Transactions extends Component {
  updateTableData = () => this.table.fireFetchData();

  render() {
    const {
      inProgress, loadTransactions, transactionsState, openTransactionDetail, showTransactionDetail,
      closeTransactionDetail, transactionDetail, showTransactionsSummary, toggleShowTransactionsSummary,
      transactionsSummaryState, loadTransactionsSummary
    } = this.props;
    const data = transactionsSummaryState.data;
    return (
      <Row className="transactions-super-container">
        <Col sm={12}>
          <div className="pl-5-desktop-transactions">
            <div>
              <h4 className="mb-4">Transactions</h4>
              <Button
                variant="light"
                onClick={() => toggleShowTransactionsSummary()}
                aria-controls="collapse-summary"
                aria-expanded={showTransactionsSummary}
                className="header-toolbar-btn border-radius-4"
              >
                Summary
                {
                  showTransactionsSummary ?
                    <FontAwesomeIcon icon="angle-up" className="icon-right"/> :
                    <FontAwesomeIcon icon="angle-down" className="icon-right"/>
                }
              </Button>
            </div>
            <Formik
              initialValues={{
                fromDate: moment().subtract(1, 'month'),
                toDate: moment(),
                phoneNumber: '',
                service: 'all',
              }}
              onSubmit={(values) => this.updateTableData(
                values.fromDate,
                values.toDate,
                values.phoneNumber,
                values.service
              )}
              validationSchema={Yup.object().shape({
                fromDate: Yup.date().typeError('Invalid date'),
                toDate: Yup.date().typeError('Invalid date'),
              })}
            >
              {props => {
                const {
                  touched,
                  errors,
                  handleSubmit,
                  values,
                  handleChange,
                  handleBlur,
                } = props;
                return (
                  <Form className="mt-4" onSubmit={handleSubmit}>
                    <div className="px-4 py-4 mb-4" style={{border: '1px solid rgba(0,0,0,.1)'}}>
                      <Row className="text-left">
                        <Col sm={12} md={4}>
                          <Form.Label className="font-weight-bold">From</Form.Label>
                          <Form.Group className="mb-4">
                            <Datepicker
                              className="form-input form-control border-radius-4"
                              name="fromDate"
                              placeholder="From"
                              dateFormat="yyyy-MM-dd"
                            />
                            {errors.fromDate && touched.fromDate && (
                              <div className="error-message">{errors.fromDate}</div>
                            )}
                          </Form.Group>
                        </Col>
                        <Col sm={12} md={4}>
                          <Form.Label className="font-weight-bold">To</Form.Label>
                          <Form.Group className="mb-4">
                            <Datepicker
                              className="form-input form-control border-radius-4"
                              name="toDate"
                              placeholder="To"
                              dateFormat="yyyy-MM-dd"
                            />
                            {errors.toDate && touched.toDate && (
                              <div className="error-message">{errors.toDate}</div>
                            )}
                          </Form.Group>
                        </Col>
                        <Col sm={12} md={4}>
                          <Form.Label className="font-weight-bold">Phone Number</Form.Label>
                          <Form.Group className="mb-4">
                            <Form.Control
                              className="form-input border-radius-4"
                              type="text"
                              id="phoneNumber"
                              value={values.phoneNumber}
                              placeholder="Phone"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.phoneNumber && touched.phoneNumber && (
                              <div className="error-message">{errors.phoneNumber}</div>
                            )}
                          </Form.Group>
                        </Col>
                        <Col sm={12} md={4}>
                          <Form.Label className="font-weight-bold">Type</Form.Label>
                          <Form.Group className="mb-4">
                            <Form.Control
                              as="select"
                              id="service"
                              className="form-input border-radius-4"
                              value={values.service}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <option key={0} value="all">All</option>
                              <option key={1} value="standard">Standard Transactions</option>
                              <option key={2} value="commission">Commissions Transactions</option>
                            </Form.Control>
                            {errors.service && touched.service && (
                              <div className="error-message">{errors.service}</div>
                            )}
                          </Form.Group>
                        </Col>
                        <Col sm={12} md={4}>
                          <Form.Group>
                            <Button
                              className="btn btn-black btn-inline border-radius-4"
                              type="submit"
                              disabled={inProgress}
                            >
                              <FontAwesomeIcon icon="search" size="sm" className="o-btn-icon"/>
                              Search
                            </Button>
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                    <Collapse in={showTransactionsSummary}>
                      <div id="collapse-summary" className="mb-5">
                        <h5>Summary</h5>
                        <div className="transaction-summary mb-4">
                          <Row>
                            <Col sm={12} md={6}>
                              <Col sm={12} className="mb-3">
                                <Form.Label className="font-weight-bold">Total Transactions
                                  Successfull:</Form.Label>&nbsp;
                                <Form.Label>{data.totalColumns ? data.totalColumns.allTotalTransactionsSuccessfull : '0'}</Form.Label>
                              </Col>
                              <Col sm={12} className="mb-3">
                                <Form.Label className="font-weight-bold">Total Transactions Failed:</Form.Label>&nbsp;
                                <Form.Label>{data.totalColumns ? data.totalColumns.allTotalTransactionsFailed : '0'}</Form.Label>
                              </Col>
                              <Col sm={12} className="mb-3">
                                <Form.Label className="font-weight-bold">Total Current Balance:</Form.Label>&nbsp;
                                <Form.Label>{data.totalColumns ? data.totalColumns.totalCurrentBalance.toFixed(2) : '0'}</Form.Label>
                              </Col>
                            </Col>
                            <Col sm={12} md={6}>
                              <Col sm={12} className="mb-3">
                                <Form.Label className="font-weight-bold">Total Spent Amount:</Form.Label>&nbsp;
                                <Form.Label>{data.totalColumns ? data.totalColumns.totalSpentAmount.toFixed(2) : '0'}</Form.Label>
                              </Col>
                              <Col sm={12} className="mb-3">
                                <Form.Label className="font-weight-bold">Total Credit:</Form.Label>&nbsp;
                                <Form.Label>{data.totalColumns ? data.totalColumns.totalCredit.toFixed(2) : '0'}</Form.Label>
                              </Col>
                              <Col sm={12} className="mb-3">
                                <Form.Label className="font-weight-bold">Total Debt:</Form.Label>&nbsp;
                                <Form.Label>{data.totalColumns ? data.totalColumns.totalDebt.toFixed(2) : '0'}</Form.Label>
                              </Col>
                            </Col>
                          </Row>
                        </div>
                        <ReactTable
                          ref={(instance) => {
                            this.table = instance;
                          }}
                          columns={[
                            /*{
                              Header: "First Name",
                              accessor: "firstName",
                            },
                            {
                              Header: "Last Name",
                              accessor: "lastName",
                            },*/
                            {
                              Header: "Username",
                              accessor: "userName",
                            },
                            {
                              Header: "Successfull",
                              accessor: "totalTransactionsSuccessfull",
                            },
                            {
                              Header: "Failed",
                              accessor: "totalTransactionsFailed",
                            },
                            {
                              Header: "Balance",
                              id: "currentBalance",
                              accessor: d => d.currentBalance.toFixed(2)
                            },
                            {
                              Header: "Spent Amount",
                              id: "spentAmount",
                              accessor: d => d.spentAmount.toFixed(2)
                            },
                            {
                              Header: "Credit",
                              id: "credit",
                              accessor: d => d.credit.toFixed(2)
                            },
                            {
                              Header: "Debt",
                              id: "debt",
                              accessor: d => d.debt.toFixed(2)
                            },
                          ]}
                          defaultSorted={[
                            {
                              id: "userName",
                              desc: false
                            }
                          ]}
                          manual
                          data={data.userList}
                          pages={transactionsSummaryState.pages}
                          loading={inProgress}
                          onFetchData={(state) => loadTransactionsSummary(state)}
                          defaultPageSize={transactionsSummaryState.pageSize}
                          pageSizeOptions={[5, 10, 20]}
                          className="-striped -highlight"
                          filter={{fromDate: values.fromDate, toDate: values.toDate}}
                          minRows={0}
                          NoDataComponent={() => null}
                        >
                          {(state, makeTable) => {
                            return (
                              <div>
                                {makeTable()}
                                <TableInfo
                                  totalRows={transactionsSummaryState.total}
                                  page={state.page}
                                  pageSize={state.pageSize}
                                />
                              </div>
                            )
                          }}
                        </ReactTable>
                      </div>
                    </Collapse>
                    <h5>All transactions</h5>
                    <ReactTable
                      ref={(instance) => {
                        this.table = instance;
                      }}
                      columns={[
                        {
                          Header: "Date",
                          id: "date",
                          accessor: d => moment(d.date).format('YYYY-MM-DD'),
                        },
                        {
                          Header: "Time",
                          id: "time",
                          accessor: d => moment(d.date).format('hh:mm'),
                          sortable: false,
                          Footer: (<strong>Total:</strong>)
                        },
                        {
                          Header: "Amount",
                          id: "amount",
                          accessor: d => d.amount.toFixed(2),
                          Footer: (
                            <strong>
                              {transactionsState && transactionsState.data.length > 0 ? transactionsState.data[0].totalAmount.toFixed(2) : ''}
                            </strong>),
                        },
                        {
                          Header: "Commission",
                          id: "commission",
                          accessor: d => d.commission.toFixed(2),
                        },
                        {
                          Header: "Plan/Service",
                          accessor: "serviceDescription",
                        },
                        {
                          Header: "Phone",
                          accessor: "phoneNumber",
                        },
                        {
                          Header: "Payment Method",
                          id: "paymentMethodId",
                          accessor: d => paymentMethod(d.paymentMethodId),
                        },
                        {
                          Header: "Status",
                          id: "status",
                          accessor: d => d.status !== 'Successful' ? d.status.substring(0, 6) : d.status
                        },
                        {
                          Header: "Detail",
                          accessor: "d",
                          Cell: ({row}) => (
                            <Button
                              variant="link" className="btn-row border-radius-4"
                              onClick={() => openTransactionDetail(row._original)}>
                              <FontAwesomeIcon icon="sticky-note"/>
                            </Button>),
                          sortable: false
                        }
                      ]}
                      defaultSorted={[
                        {
                          id: "date",
                          desc: false
                        }
                      ]}
                      manual
                      data={transactionsState.data}
                      pages={transactionsState.pages}
                      loading={inProgress}
                      onFetchData={(state) => loadTransactions(state)}
                      defaultPageSize={transactionsState.pageSize}
                      pageSizeOptions={[5, 10, 20]}
                      className="-striped -highlight"
                      filter={{
                        fromDate: values.fromDate,
                        toDate: values.toDate,
                        phoneNumber: values.phoneNumber,
                        service: values.service,
                      }}
                      minRows={0}
                      NoDataComponent={() => null}
                    >
                      {(state, makeTable) => {
                        return (
                          <div>
                            {makeTable()}
                            <TableInfo
                              totalRows={transactionsState.total}
                              page={state.page}
                              pageSize={state.pageSize}
                            />
                          </div>
                        )
                      }}
                    </ReactTable>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </Col>
        <TransactionDetail
          show={showTransactionDetail}
          onHide={closeTransactionDetail}
          transactionDetail={transactionDetail}
        />
      </Row>
    );
  }
}

export default Transactions;