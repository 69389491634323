import React from 'react';
import {Row, Col, Button, Image} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import samsungImg from '../../assets/img/content/pay/buy/samsung-j5.png';
import laptopImg from '../../assets/img/content/pay/buy/laptop-hp.png';
import iphoneImg from '../../assets/img/content/pay/buy/iphone-5s.png';

const Buy = () => (
  <div className="content gradient-orange">
    <div className="pl-5-pr-5-desktop pl-2-pr-2-mobile">
      <Row>
        <Col><h4 className="category mb-2 font-white">BUY</h4></Col>
      </Row>
      <Row>
        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
          <h1 className="ss-title font-white">Find the Product You Need, Excellent Prices</h1>
        </Col>
        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
          <Button className="btn-app btn-gray float-right show-all-products-button border-radius-4">Shop All Products<FontAwesomeIcon icon="arrow-right"
                                                                                             size="lg"
                                                                                             className="c-btn-icon"/></Button>
        </Col>
      </Row>
      <Row className="mt-4 mb-4">
        <Col className="pl-3 pr-3 buy-image-container" xl={4} lg={4} md={4} sm={12} xs={12}>
          <div className="bg-white">
            <div className="text-center">
              <Image className="buy-image" src={samsungImg}/>
            </div>
            <div className="p-4">
              <p className="bb-header mb-2">ELECTRONICS</p>
              <h4 className="category mb-1">Samsung J5</h4>
              <p className="mb-0 bb-price">$160</p>
            </div>
          </div>
        </Col>
        <Col className="pl-3 pr-3 buy-image-container" xl={4} lg={4} md={4} sm={12} xs={12}>
          <div className="bg-white">
            <div className="text-center">
              <Image className="buy-image" src={laptopImg}/>
            </div>
            <div className="p-4">
              <p className="bb-header mb-2">ELECTRONICS</p>
              <h4 className="category mb-1">HP Laptop</h4>
              <p className="mb-0 bb-price">$259.99</p>
            </div>
          </div>
        </Col>
        <Col className="pl-3 pr-3" xl={4} lg={4} md={4} sm={12} xs={12}>
          <div className="bg-white">
            <div className="text-center">
              <Image className="buy-image" src={iphoneImg}/>
            </div>
            <div className="p-4">
              <p className="bb-header mb-2">ELECTRONICS</p>
              <h4 className="category mb-1">iphone 5S</h4>
              <p className="mb-0 bb-price">$300</p>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="text-center">
          <h1 className="ss-title font-white mt-5 mb-5">Have Any Products to Sell?</h1>
          <Button className="btn-app btn-gray sell-now-button border-radius-4">Sell Now!<FontAwesomeIcon icon="arrow-right" size="lg"
                                                                         className="c-btn-icon"/></Button>
        </Col>
      </Row>
    </div>
  </div>
);

export default Buy;