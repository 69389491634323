import { combineReducers } from 'redux';

import auth from './reducers/auth';
import common from './reducers/common';
import pay from './reducers/pay';
import profile from './reducers/profile';
import admin from './reducers/admin';
import repair from './reducers/repair';
import { routerReducer } from 'react-router-redux';

export default combineReducers({
  auth,
  common,
  pay,
  profile,
  admin,
  repair,
  router: routerReducer
});
