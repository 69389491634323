import React, {Component} from 'react';
import ReactTable from "react-table";
import "react-table/react-table.css";
import {Button, Col, Form, Modal, Row, Spinner} from "react-bootstrap";
import {Formik} from "formik";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as Yup from "yup";
import Select from 'react-select';
import ReactFileReader from 'react-file-reader';
import providerImage from "../../assets/img/common/provider.png";
import TableInfo from '../TableInfo';

const {urlBase} = require('../../utils');

const AddProvider = (props) => {
  const {
    submitAddProvider, inProgress, countries, categories, ...rest
  } = props;

  const selectSortStyles = {
    control: (base, state) => ({
      ...base,
      borderRadius: "4px",
      height: "3rem",
      paddingLeft: ".5rem",
      paddingRight: ".5rem",
      borderColor: state.isFocused ? "#80bdff" : "#ced4da",
      boxShadow: state.isFocused ? "0 0 0 3px #bfdeff" : "none",
      '&:hover': {
        borderColor: state.isFocused ? "#80bdff" : "#ced4da",
      },
    })
  };

  const formAddProviderId = 'formAddProviderId';

  return (
    <Modal
      {...rest}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-app"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="app-header">
          Add Provider
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <Formik
          initialValues={{
            name: '',
            description: '',
            imageBase64: '',
            imageFileName: '',
            countryId: '',
            operatorCategoryId: '',
            enabled: false,
            operatorCode: '',
            paymentApiId: 4,
          }}
          onSubmit={(values) => submitAddProvider(
            values.name,
            values.description,
            values.imageBase64,
            values.imageFileName,
            values.countryId,
            values.operatorCategoryId,
            values.enabled,
            values.operatorCode,
            values.paymentApiId,
          )}
          validationSchema={Yup.object().shape({
            name: Yup.string().required('Required'),
            description: Yup.string().required('Required'),
            countryId: Yup.string().required('Required'),
            operatorCategoryId: Yup.string().required('Required'),
            operatorCode: Yup.string().required('Required'),
          })}
        >
          {props => {
            const {
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              setFieldError,
            } = props;
            return (
              <Form id={formAddProviderId} onSubmit={handleSubmit}>
                <Row className="text-left">
                  <Col md={6} sm={12}>
                    <div className="px-3">
                      <Form.Group>
                        <div className="upload-provider-image-container">
                          <img src={values.imageBase64 !== '' ? values.imageBase64 : providerImage} alt="preview"
                               className="file-upload-image-preview" id="my-image"/>
                        </div>
                        <div className="fp-text" style={{marginBottom: '1rem'}}>
                          Only images with .png extension are supported
                        </div>
                        <ReactFileReader
                          id="providerImage"
                          fileTypes={[".png"]}
                          base64={true}
                          multipleFiles={false}
                          handleFiles={(files) => {
                            if (files.fileList[0].type === "image/png") {
                              setFieldValue('imageFileName', files.fileList[0].name);
                              setFieldValue('imageBase64', files.base64);
                              setFieldError('imageFileName', null);
                            } else {
                              setFieldValue('imageFileName', '');
                              setFieldValue('imageBase64', '');
                              setFieldError('imageFileName', 'Only PNG');
                            }
                            return false;
                          }}>
                          <button className='react-file-reader-component-btn file-upload-button'
                                  onClick={(e) => e.preventDefault()}>Choose File
                          </button>
                        </ReactFileReader>
                        <div className="file-upload-chosen" style={{marginTop: '.5rem'}}>
                          <span>{values.imageFileName !== '' ? values.imageFileName : "No file chosen"}</span>
                        </div>
                        {errors.imageFileName && touched.imageFileName && (
                          <div className="error-message">{errors.imageFileName}</div>
                        )}
                      </Form.Group>
                    </div>
                  </Col>
                  <Col md={6} sm={12}>
                    <Form.Group>
                      <Form.Label
                        className="font-weight-bold">Provider Name
                      </Form.Label>
                      <Form.Control
                        className="form-input border-radius-4"
                        type="text"
                        placeholder="Verizon"
                        id="name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.name && touched.name && (
                        <div className="error-message">{errors.name}</div>
                      )}
                    </Form.Group>
                    <Form.Group>
                      <Form.Label
                        className="font-weight-bold">Description
                      </Form.Label>
                      <Form.Control
                        className="form-input border-radius-4"
                        type="text"
                        placeholder="Verizon Carrier"
                        id="description"
                        value={values.description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.description && touched.description && (
                        <div className="error-message">{errors.description}</div>
                      )}
                    </Form.Group>
                    <Form.Group>
                      <Form.Label
                        className="font-weight-bold">Operator Code
                      </Form.Label>
                      <Form.Control
                        className="form-input border-radius-4"
                        type="text"
                        placeholder="VZ"
                        id="operatorCode"
                        value={values.operatorCode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.operatorCode && touched.operatorCode && (
                        <div className="error-message">{errors.operatorCode}</div>
                      )}
                    </Form.Group>
                    <Form.Group>
                      <Form.Label
                        className="font-weight-bold">Country
                      </Form.Label>
                      <Select
                        id="countryId"
                        onBlur={handleBlur}
                        onChange={(ev) => {
                          setFieldValue('countryId', ev.value);
                        }}
                        options={(Array.isArray(countries) && countries.length) ? countries.map(item => ({
                          value: item.id,
                          label: item.countryName
                        })) : []}
                        styles={selectSortStyles}
                        components={
                          {
                            IndicatorSeparator: () => null
                          }
                        }
                      />
                      {errors.countryId && touched.countryId && (
                        <div className="error-message">{errors.countryId}</div>
                      )}
                    </Form.Group>
                    <Form.Group>
                      <Form.Label
                        className="font-weight-bold">Category
                      </Form.Label>
                      <Select
                        id="operatorCategoryId"
                        onBlur={handleBlur}
                        onChange={(ev) => {
                          setFieldValue('operatorCategoryId', ev.value);
                        }}
                        options={(Array.isArray(categories) && categories.length) ? categories.map(item => ({
                          value: item.id,
                          label: item.description
                        })) : []}
                        styles={selectSortStyles}
                        components={
                          {
                            IndicatorSeparator: () => null
                          }
                        }
                      />
                      {errors.operatorCategoryId && touched.operatorCategoryId && (
                        <div className="error-message">{errors.operatorCategoryId}</div>
                      )}
                    </Form.Group>
                    <Form.Group controlId="enabled">
                      <Form.Check
                        type="checkbox"
                        label={<Form.Label
                          className="font-weight-bold">Active
                        </Form.Label>}
                        checked={values.enabled}
                        onChange={(ev) => {
                          setFieldValue('enabled', ev.target.checked);
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn btn-form-app btn-option border-radius-4" type="submit" form={formAddProviderId}
                disabled={inProgress === true}>
          {
            inProgress ?
              <Spinner
                animation="grow"
                variant="secondary"
                style={{marginRight: '5px', marginBottom: '4px'}}
                size="sm"
              />
              : ''
          }
          Accept
          <FontAwesomeIcon icon="arrow-right" className="icon-right"/>
        </Button>
        <Button onClick={props.onHide} className="btn btn-outline-dark btn-form-app border-radius-4">Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

const EditProvider = (props) => {
  const {
    submitEditProvider, inProgress, countries, categories, selectedProvider, ...rest
  } = props;

  const selectSortStyles = {
    control: (base, state) => ({
      ...base,
      borderRadius: "4px",
      height: "3rem",
      paddingLeft: ".5rem",
      paddingRight: ".5rem",
      borderColor: state.isFocused ? "#80bdff" : "#ced4da",
      boxShadow: state.isFocused ? "0 0 0 3px #bfdeff" : "none",
      '&:hover': {
        borderColor: state.isFocused ? "#80bdff" : "#ced4da",
      },
    })
  };

  const formEditProviderId = 'formEditProviderId';

  return (
    <Modal
      {...rest}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-app"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="app-header">
          Edit Provider
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <Formik
          initialValues={{
            id: selectedProvider ? selectedProvider.id : '',
            name: selectedProvider ? selectedProvider.name : '',
            description: selectedProvider ? selectedProvider.description : '',
            imageUrl: selectedProvider ? selectedProvider.imageUrl : '',
            imageBase64: '',
            imageFileName: '',
            countryId: selectedProvider ? selectedProvider.countryId : '',
            operatorCategoryId: selectedProvider ? selectedProvider.operatorCategoryId : '',
            enabled: selectedProvider ? selectedProvider.enabled : '',
            operatorCode: selectedProvider ? selectedProvider.operatorCode : '',
            paymentApiId: 4,
          }}
          onSubmit={(values) => submitEditProvider(
            values.id,
            values.name,
            values.description,
            values.imageUrl,
            values.imageBase64,
            values.imageFileName,
            values.countryId,
            values.operatorCategoryId,
            values.enabled,
            values.operatorCode,
            values.paymentApiId,
          )}
          validationSchema={Yup.object().shape({
            name: Yup.string().required('Required'),
            description: Yup.string().required('Required'),
            countryId: Yup.string().required('Required'),
            operatorCategoryId: Yup.string().required('Required'),
            operatorCode: Yup.string().required('Required'),
          })}
        >
          {props => {
            const {
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              setFieldError,
            } = props;

            const selectedCountry = countries ? countries.find(item => item.id === values.countryId) : '';
            const selectedCategory = categories ? categories.find(item => item.id === values.operatorCategoryId) : '';
            let srcImage = providerImage;
            if (values.imageBase64 !== '') {
              srcImage = values.imageBase64;
            } else if (selectedProvider && selectedProvider.imageUrl !== '' && selectedProvider.imageUrl !== null) {
              srcImage = `${urlBase}/${selectedProvider.imageUrl}`;
            }
            return (
              <Form id={formEditProviderId} onSubmit={handleSubmit}>
                <Row className="text-left">
                  <Col md={6} sm={12}>
                    <div className="px-3">
                      <Form.Group>
                        <div className="upload-provider-image-container">
                          <img src={srcImage} alt="preview"
                               className="file-upload-image-preview" id="my-image"/>
                        </div>
                        <div className="fp-text" style={{marginBottom: '1rem'}}>
                          Only images with .png extension are supported
                        </div>
                        <ReactFileReader
                          id="providerImage"
                          fileTypes={[".png"]}
                          base64={true}
                          multipleFiles={false}
                          handleFiles={(files) => {
                            if (files.fileList[0].type === "image/png") {
                              setFieldValue('imageFileName', files.fileList[0].name);
                              setFieldValue('imageBase64', files.base64);
                              setFieldError('imageFileName', null);
                            } else {
                              setFieldValue('imageFileName', '');
                              setFieldValue('imageBase64', '');
                              setFieldError('imageFileName', 'Only PNG');
                            }
                            return false;
                          }}>
                          <button className='react-file-reader-component-btn file-upload-button'
                                  onClick={(e) => e.preventDefault()}>Choose File
                          </button>
                        </ReactFileReader>
                        <div className="file-upload-chosen" style={{marginTop: '.5rem'}}>
                          <span>{values.imageFileName !== '' ? values.imageFileName : "No file chosen"}</span>
                        </div>
                        {errors.imageFileName && touched.imageFileName && (
                          <div className="error-message">{errors.imageFileName}</div>
                        )}
                      </Form.Group>
                    </div>
                  </Col>
                  <Col md={6} sm={12}>
                    <Form.Group>
                      <Form.Label
                        className="font-weight-bold">Provider Name
                      </Form.Label>
                      <Form.Control
                        className="form-input border-radius-4"
                        type="text"
                        placeholder="Verizon"
                        id="name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.name && touched.name && (
                        <div className="error-message">{errors.name}</div>
                      )}
                    </Form.Group>
                    <Form.Group>
                      <Form.Label
                        className="font-weight-bold">Description
                      </Form.Label>
                      <Form.Control
                        className="form-input border-radius-4"
                        type="text"
                        placeholder="Verizon Carrier"
                        id="description"
                        value={values.description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.description && touched.description && (
                        <div className="error-message">{errors.description}</div>
                      )}
                    </Form.Group>
                    <Form.Group>
                      <Form.Label
                        className="font-weight-bold">Operator Code
                      </Form.Label>
                      <Form.Control
                        className="form-input border-radius-4"
                        type="text"
                        placeholder="VZ"
                        id="operatorCode"
                        value={values.operatorCode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.operatorCode && touched.operatorCode && (
                        <div className="error-message">{errors.operatorCode}</div>
                      )}
                    </Form.Group>
                    <Form.Group>
                      <Form.Label
                        className="font-weight-bold">Country
                      </Form.Label>
                      <Select
                        id="countryId"
                        onBlur={handleBlur}
                        value={selectedCountry ? {
                          value: selectedCountry.id,
                          label: selectedCountry.countryName
                        } : ''}
                        onChange={(ev) => {
                          setFieldValue('countryId', ev.value);
                        }}
                        options={(Array.isArray(countries) && countries.length) ? countries.map(item => ({
                          value: item.id,
                          label: item.countryName
                        })) : []}
                        styles={selectSortStyles}
                        components={
                          {
                            IndicatorSeparator: () => null
                          }
                        }
                      />
                      {errors.countryId && touched.countryId && (
                        <div className="error-message">{errors.countryId}</div>
                      )}
                    </Form.Group>
                    <Form.Group>
                      <Form.Label
                        className="font-weight-bold">Category
                      </Form.Label>
                      <Select
                        id="operatorCategoryId"
                        onBlur={handleBlur}
                        value={selectedCategory ? {
                          value: selectedCategory.id,
                          label: selectedCategory.description
                        } : ''}
                        onChange={(ev) => {
                          setFieldValue('operatorCategoryId', ev.value);
                        }}
                        options={(Array.isArray(categories) && categories.length) ? categories.map(item => ({
                          value: item.id,
                          label: item.description
                        })) : []}
                        styles={selectSortStyles}
                        components={
                          {
                            IndicatorSeparator: () => null
                          }
                        }
                      />
                      {errors.operatorCategoryId && touched.operatorCategoryId && (
                        <div className="error-message">{errors.operatorCategoryId}</div>
                      )}
                    </Form.Group>
                    <Form.Group controlId="enabled">
                      <Form.Check
                        type="checkbox"
                        label={<Form.Label
                          className="font-weight-bold">Active
                        </Form.Label>}
                        checked={values.enabled}
                        onChange={(ev) => {
                          setFieldValue('enabled', ev.target.checked);
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn btn-form-app btn-option border-radius-4" type="submit" form={formEditProviderId}
                disabled={inProgress === true}>
          {
            inProgress ?
              <Spinner
                animation="grow"
                variant="secondary"
                style={{marginRight: '5px', marginBottom: '4px'}}
                size="sm"
              />
              : ''
          }
          Accept
          <FontAwesomeIcon icon="arrow-right" className="icon-right"/>
        </Button>
        <Button onClick={props.onHide} className="btn btn-outline-dark btn-form-app border-radius-4">Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

const DeleteProvider = (props) => {
  const {
    selectedProvider, inProgress, submitDeleteProvider, ...rest
  } = props;
  return (
    <Modal
      {...rest}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-app"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="app-header">
          Delete Provider
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Label>
          Sure you want to delete the provider&nbsp;
          <span className="font-weight-bold">{selectedProvider ? selectedProvider.name.toUpperCase() : ''}</span>?
        </Form.Label>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="btn btn-form-app btn-option border-radius-4"
          type="submit"
          onClick={() => submitDeleteProvider(selectedProvider.id)}
        >
          {
            inProgress ?
              <Spinner
                animation="grow"
                variant="secondary"
                style={{marginRight: '5px', marginBottom: '4px'}}
                size="sm"
              />
              : ''
          }
          Accept
          <FontAwesomeIcon icon="arrow-right" className="icon-right"/>
        </Button>
        <Button onClick={props.onHide} className="btn btn-outline-dark btn-form-app border-radius-4">Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

class Providers extends Component {
  render() {
    const {
      inProgress, filteredProviders, selectedProvider, submitSearchProvider, countries, categories, onUpdateFilterProvider,
      openAddProvider, showAddProvider, closeAddProvider, submitAddProvider,
      openEditProvider, showEditProvider, closeEditProvider, submitEditProvider,
      openDeleteProvider, showDeleteProvider, closeDeleteProvider, submitDeleteProvider,
    } = this.props;
    return (
      <Row className="transferences-super-container">
        <Col sm={12}>
          <div className="pl-5-desktop-transferences">
            <h4 className="mb-4">Providers</h4>
            <Button
              variant="light"
              onClick={openAddProvider}
              className="header-toolbar-btn border-radius-4"
            >
              <FontAwesomeIcon icon="plus" className="icon"/>
              Add
            </Button>
            <Formik
              initialValues={{name: ''}}
              onSubmit={() => submitSearchProvider()}
            >
              {props => {
                const {
                  touched,
                  errors,
                  handleSubmit,
                  values,
                  handleChange,
                  handleBlur
                } = props;
                return (
                  <Form className="mt-4" onSubmit={handleSubmit}>
                    <div className="px-4 py-4 mb-4" style={{border: '1px solid rgba(0,0,0,.1)'}}>
                      <Row className="text-left">
                        <Col sm={12} md={4}>
                          <Form.Group className="mb-4">
                            <Form.Label className="font-weight-bold">Provider</Form.Label>
                            <Form.Control
                              className="form-input border-radius-4"
                              type="text"
                              id="name"
                              value={values.name}
                              onChange={(ev) => {
                                handleChange(ev);
                                onUpdateFilterProvider(ev.target.value);
                              }}
                              onBlur={handleBlur}
                            />
                            {errors.name && touched.name && (
                              <div className="error-message">{errors.name}</div>
                            )}
                          </Form.Group>
                        </Col>
                        <Col sm={12} md={6}>
                          <Form.Group className="mb-3">
                            <Button
                              className="btn btn-black btn-inline border-radius-4 search-button-transactions"
                              type="submit"
                              disabled={inProgress}
                            >
                              <FontAwesomeIcon icon="search" size="sm" className="o-btn-icon"/>
                              Search
                            </Button>
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                    <ReactTable
                      ref={(instance) => {
                        this.table = instance;
                      }}
                      columns={[
                        {
                          Header: "Name",
                          accessor: "name",
                        },
                        {
                          Header: "Description",
                          accessor: "description",
                        },
                        {
                          Header: "Active",
                          id: "enabled",
                          accessor: d => d.enabled ? 'Yes' : 'No',
                        },
                        {
                          Header: "Country",
                          accessor: "country",
                        },
                        {
                          Header: "Operator",
                          accessor: "operatorCode",
                        },
                        {
                          Header: "Edit",
                          accessor: "d",
                          Cell: ({row}) => (
                            <Button
                              variant="link" className="btn-row border-radius-4"
                              onClick={() => openEditProvider(row._original)}
                            >
                              <FontAwesomeIcon icon="pencil-alt"/>
                            </Button>),
                          sortable: false
                        },
                        {
                          Header: "Delete",
                          accessor: "d",
                          Cell: ({row}) => (
                            <Button
                              variant="link" className="btn-row border-radius-4"
                              onClick={() => openDeleteProvider(row._original)}
                            >
                              <FontAwesomeIcon icon="trash"/>
                            </Button>),
                          sortable: false
                        }
                      ]}
                      defaultSorted={[
                        {
                          id: "name",
                          desc: false
                        }
                      ]}
                      data={filteredProviders}
                      defaultPageSize={20}
                      pageSizeOptions={[5, 10, 20]}
                      className="-striped -highlight"
                      minRows={0}
                      NoDataComponent={() => null}
                    >
                      {(state, makeTable) => {
                        return (
                          <div>
                            {makeTable()}
                            <TableInfo
                              totalRows={state.sortedData.length}
                              page={state.page}
                              pageSize={state.pageSize}
                            />
                          </div>
                        )
                      }}
                    </ReactTable>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </Col>
        <AddProvider
          inProgress={inProgress}
          show={showAddProvider}
          onHide={closeAddProvider}
          submitAddProvider={submitAddProvider}
          countries={countries}
          categories={categories}
        />
        <EditProvider
          inProgress={inProgress}
          show={showEditProvider}
          onHide={closeEditProvider}
          submitEditProvider={submitEditProvider}
          countries={countries}
          categories={categories}
          selectedProvider={selectedProvider}
        />
        <DeleteProvider
          inProgress={inProgress}
          show={showDeleteProvider}
          onHide={closeDeleteProvider}
          submitDeleteProvider={submitDeleteProvider}
          selectedProvider={selectedProvider}
        />
      </Row>
    );
  }
}

export default Providers;