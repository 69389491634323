import agent from './agent';
import {
  ASYNC_START,
  ASYNC_END,
  LOGIN,
  LOGOUT,
  LOAD_USER,
  LOGOUT_TO_LOGIN,
  UPDATE_FIELD_COMMON
} from './constants/actionTypes';

const promiseMiddleware = store => next => action => {
  if (isPromise(action.payload)) {
    store.dispatch({type: ASYNC_START, subtype: action.type});

    action.payload.then(
      res => {
        action.payload = res;
        store.dispatch({type: ASYNC_END, promise: action.payload});
        store.dispatch(action);
      },
      error => {
        console.log('ERROR', error);
        action.error = true;
        action.status = error.status;
        action.payload = error.response ? error.response.body : {
          error: true,
          message: 'Request has been terminated, please check your internet connection and try again'
        };
        if (!action.skipTracking) {
          store.dispatch({type: ASYNC_END, promise: action.payload});
        }
        store.dispatch(action);
      }
    );

    return;
  }

  next(action);
};

const localStorageMiddleware = store => next => action => {
  if (action.error) {
    if (action.status === 401) {
      store.dispatch({type: LOGOUT_TO_LOGIN});
    } else {
      next(action);
    }
  } else {
    if (action.type === LOGIN) {
      window.localStorage.setItem('jwt-cellshop', action.payload.body.access_token);
      agent.setToken(action.payload.body.access_token);
      agent.Auth.current()
        .then(userResponse => {
          store.dispatch({type: LOAD_USER, payload: userResponse});
        })
    } else if (action.type === LOGOUT || action.type === LOGOUT_TO_LOGIN) {
      window.localStorage.setItem('jwt-cellshop', '');
      agent.setToken(null);
    } else if (action.type === '@@router/LOCATION_CHANGE') {
      store.dispatch({type: UPDATE_FIELD_COMMON, key: 'pinChecked', value: false});
    }

    next(action);
  }
};

function isPromise(v) {
  return v && typeof v.then === 'function';
}

export {promiseMiddleware, localStorageMiddleware}
