import React, {Component} from 'react';
import ReactTable from "react-table";
import "react-table/react-table.css";
import {Button, Col, Form, Modal, Row, Spinner} from "react-bootstrap";
import {Formik} from "formik";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as Yup from "yup";
import TableInfo from '../TableInfo';

const AddPaymentMethod = (props) => {
  const {
    submitAddPaymentMethod, inProgress, ...rest
  } = props;

  const formAddPaymentMethodId = 'formAddPaymentMethodId';

  return (
    <Modal
      {...rest}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-app"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="app-header">
          Add Payment Method
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <Formik
          initialValues={{
            name: '',
            fee: '0'
          }}
          onSubmit={(values) => submitAddPaymentMethod(
            values.name,
            values.fee,
          )}
          validationSchema={Yup.object().shape({
            name: Yup.string().required('Required'),
            fee: Yup.number().required('Required').typeError('Invalid number'),
          })}
        >
          {props => {
            const {
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
            } = props;
            return (
              <Form id={formAddPaymentMethodId} onSubmit={handleSubmit}>
                <Row className="text-left">
                  <Col md={6} sm={12}>
                    <Form.Group>
                      <Form.Label
                        className="font-weight-bold">Name
                      </Form.Label>
                      <Form.Control
                        className="form-input border-radius-4"
                        type="text"
                        placeholder="Name"
                        id="name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.name && touched.name && (
                        <div className="error-message">{errors.name}</div>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={6} sm={12}>
                    <Form.Group>
                      <Form.Label
                        className="font-weight-bold">Fee
                      </Form.Label>
                      <Form.Control
                        className="form-input border-radius-4"
                        type="text"
                        placeholder="Fee"
                        id="fee"
                        value={values.fee}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.fee && touched.fee && (
                        <div className="error-message">{errors.fee}</div>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn btn-form-app btn-option border-radius-4" type="submit" form={formAddPaymentMethodId}
                disabled={inProgress === true}>
          {
            inProgress ?
              <Spinner
                animation="grow"
                variant="secondary"
                style={{marginRight: '5px', marginBottom: '4px'}}
                size="sm"
              />
              : ''
          }
          Accept
          <FontAwesomeIcon icon="arrow-right" className="icon-right"/>
        </Button>
        <Button onClick={props.onHide} className="btn btn-outline-dark btn-form-app border-radius-4">Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

const EditPaymentMethod = (props) => {
  const {
    submitEditPaymentMethod, inProgress, selectedPaymentMethod, ...rest
  } = props;

  const formEditPaymentMethodId = 'formEditPaymentMethodId';

  return (
    <Modal
      {...rest}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-app"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="app-header">
          Edit Payment Method
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <Formik
          initialValues={{
            id: selectedPaymentMethod ? selectedPaymentMethod.id : '',
            name: selectedPaymentMethod ? selectedPaymentMethod.name : '',
            fee: selectedPaymentMethod ? selectedPaymentMethod.fee : '',
          }}
          onSubmit={(values) => submitEditPaymentMethod(
            values.id,
            values.name,
            values.fee
          )}
          validationSchema={Yup.object().shape({
            name: Yup.string().required('Required'),
            fee: Yup.number().required('Required').typeError('Invalid number'),
          })}
        >
          {props => {
            const {
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
            } = props;

            return (
              <Form id={formEditPaymentMethodId} onSubmit={handleSubmit}>
                <Row className="text-left">
                  <Col md={6} sm={12}>
                    <Form.Group>
                      <Form.Label
                        className="font-weight-bold">Name
                      </Form.Label>
                      <Form.Control
                        className="form-input border-radius-4"
                        type="text"
                        placeholder="Name"
                        id="name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.name && touched.name && (
                        <div className="error-message">{errors.name}</div>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={6} sm={12}>
                    <Form.Group>
                      <Form.Label
                        className="font-weight-bold">Fee
                      </Form.Label>
                      <Form.Control
                        className="form-input border-radius-4"
                        type="text"
                        placeholder="Fee"
                        id="fee"
                        value={values.fee}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.fee && touched.fee && (
                        <div className="error-message">{errors.fee}</div>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn btn-form-app btn-option border-radius-4" type="submit" form={formEditPaymentMethodId}
                disabled={inProgress === true}>
          {
            inProgress ?
              <Spinner
                animation="grow"
                variant="secondary"
                style={{marginRight: '5px', marginBottom: '4px'}}
                size="sm"
              />
              : ''
          }
          Accept
          <FontAwesomeIcon icon="arrow-right" className="icon-right"/>
        </Button>
        <Button onClick={props.onHide} className="btn btn-outline-dark btn-form-app border-radius-4">Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

const DeletePaymentMethod = (props) => {
  const {
    selectedPaymentMethod, inProgress, submitDeletePaymentMethod, ...rest
  } = props;
  return (
    <Modal
      {...rest}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-app"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="app-header">
          Delete Payment Method
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Label>
          Sure you want to delete the payment method&nbsp;
          <span
            className="font-weight-bold">{selectedPaymentMethod ? selectedPaymentMethod.name.toUpperCase() : ''}</span>?
        </Form.Label>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="btn btn-form-app btn-option border-radius-4"
          type="submit"
          onClick={() => submitDeletePaymentMethod(selectedPaymentMethod.id)}
        >
          {
            inProgress ?
              <Spinner
                animation="grow"
                variant="secondary"
                style={{marginRight: '5px', marginBottom: '4px'}}
                size="sm"
              />
              : ''
          }
          Accept
          <FontAwesomeIcon icon="arrow-right" className="icon-right"/>
        </Button>
        <Button onClick={props.onHide} className="btn btn-outline-dark btn-form-app border-radius-4">Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

class PaymentMethods extends Component {
  render() {
    const {
      inProgress, filteredPaymentMethods, selectedPaymentMethod, submitSearchPaymentMethod, onUpdateFilterPaymentMethod,
      openAddPaymentMethod, showAddPaymentMethod, closeAddPaymentMethod, submitAddPaymentMethod,
      openEditPaymentMethod, showEditPaymentMethod, closeEditPaymentMethod, submitEditPaymentMethod,
      openDeletePaymentMethod, showDeletePaymentMethod, closeDeletePaymentMethod, submitDeletePaymentMethod,
    } = this.props;
    return (
      <Row className="transferences-super-container">
        <Col sm={12}>
          <div className="pl-5-desktop-transferences">
            <h4 className="mb-4">Payment Methods</h4>
            <Button
              variant="light"
              onClick={openAddPaymentMethod}
              className="header-toolbar-btn border-radius-4"
            >
              <FontAwesomeIcon icon="plus" className="icon"/>
              Add
            </Button>
            <Formik
              initialValues={{name: ''}}
              onSubmit={() => submitSearchPaymentMethod()}
            >
              {props => {
                const {
                  touched,
                  errors,
                  handleSubmit,
                  values,
                  handleChange,
                  handleBlur
                } = props;
                return (
                  <Form className="mt-4" onSubmit={handleSubmit}>
                    <div className="px-4 py-4 mb-4" style={{border: '1px solid rgba(0,0,0,.1)'}}>
                      <Row className="text-left">
                        <Col sm={12} md={4}>
                          <Form.Group className="mb-4">
                            <Form.Label className="font-weight-bold">Payment Method</Form.Label>
                            <Form.Control
                              className="form-input border-radius-4"
                              type="text"
                              id="name"
                              value={values.name}
                              onChange={(ev) => {
                                handleChange(ev);
                                onUpdateFilterPaymentMethod(ev.target.value);
                              }}
                              onBlur={handleBlur}
                            />
                            {errors.name && touched.name && (
                              <div className="error-message">{errors.name}</div>
                            )}
                          </Form.Group>
                        </Col>
                        <Col sm={12} md={6}>
                          <Form.Group className="mb-3">
                            <Button
                              className="btn btn-black btn-inline border-radius-4 search-button-transactions"
                              type="submit"
                              disabled={inProgress}
                            >
                              <FontAwesomeIcon icon="search" size="sm" className="o-btn-icon"/>
                              Search
                            </Button>
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                    <ReactTable
                      ref={(instance) => {
                        this.table = instance;
                      }}
                      columns={[
                        {
                          Header: "Name",
                          accessor: "name",
                        },
                        {
                          Header: "Fee",
                          id: "fee",
                          accessor: d => d.fee.toFixed(2),
                        },
                        {
                          Header: "Edit",
                          accessor: "d",
                          Cell: ({row}) => (
                            <Button
                              variant="link" className="btn-row border-radius-4"
                              onClick={() => openEditPaymentMethod(row._original)}
                            >
                              <FontAwesomeIcon icon="pencil-alt"/>
                            </Button>),
                          sortable: false
                        },
                        {
                          Header: "Delete",
                          accessor: "d",
                          Cell: ({row}) => (
                            <Button
                              variant="link" className="btn-row border-radius-4"
                              onClick={() => openDeletePaymentMethod(row._original)}
                            >
                              <FontAwesomeIcon icon="trash"/>
                            </Button>),
                          sortable: false
                        }
                      ]}
                      defaultSorted={[
                        {
                          id: "name",
                          desc: false
                        }
                      ]}
                      data={filteredPaymentMethods}
                      defaultPageSize={20}
                      pageSizeOptions={[5, 10, 20]}
                      className="-striped -highlight"
                      minRows={0}
                      NoDataComponent={() => null}
                    >
                      {(state, makeTable) => {
                        return (
                          <div>
                            {makeTable()}
                            <TableInfo
                              totalRows={state.sortedData.length}
                              page={state.page}
                              pageSize={state.pageSize}
                            />
                          </div>
                        )
                      }}
                    </ReactTable>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </Col>
        <AddPaymentMethod
          inProgress={inProgress}
          show={showAddPaymentMethod}
          onHide={closeAddPaymentMethod}
          submitAddPaymentMethod={submitAddPaymentMethod}
        />
        <EditPaymentMethod
          inProgress={inProgress}
          show={showEditPaymentMethod}
          onHide={closeEditPaymentMethod}
          submitEditPaymentMethod={submitEditPaymentMethod}
          selectedPaymentMethod={selectedPaymentMethod}
        />
        <DeletePaymentMethod
          inProgress={inProgress}
          show={showDeletePaymentMethod}
          onHide={closeDeletePaymentMethod}
          submitDeletePaymentMethod={submitDeletePaymentMethod}
          selectedPaymentMethod={selectedPaymentMethod}
        />
      </Row>
    );
  }
}

export default PaymentMethods;