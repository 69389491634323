import React, {Component} from 'react';
import Select, {components} from 'react-select';
import {Col, Form, Image, Row, Spinner, Modal, Button, Alert} from "react-bootstrap";
import PaginationCircle from "../PaginationCircle/PaginationCircle";
import Message from '../Message';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Formik} from 'formik';
import * as Yup from 'yup';
import {Link} from "react-router-dom";
import {faPaypal} from '@fortawesome/free-brands-svg-icons';
import agent from "../../agent";

const {paymentMethods, urlBase, urlImage} = require('../../utils');

const selectStyles = {
  control: (base, state) => ({
    ...base,
    height: "3.5rem",
    borderRadius: "2rem",
    paddingLeft: ".5rem",
    paddingRight: ".5rem",
    borderColor: state.isFocused ? "#80bdff" : "#ced4da",
    boxShadow: state.isFocused ? "0 0 0 3px #bfdeff" : "none",
    '&:hover': {
      borderColor: state.isFocused ? "#80bdff" : "#ced4da",
    },
  })
};

const selectSortStyles = {
  control: (base, state) => ({
    ...base,
    borderRadius: "2rem",
    paddingLeft: ".5rem",
    paddingRight: ".5rem",
    borderColor: state.isFocused ? "#80bdff" : "#ced4da",
    boxShadow: state.isFocused ? "0 0 0 3px #bfdeff" : "none",
    '&:hover': {
      borderColor: state.isFocused ? "#80bdff" : "#ced4da",
    },
  })
};

const {Option, SingleValue} = components;

const IconOption = (props) => (
  <Option {...props}>
    {props.data.flag ?
      <Image src={`${urlImage}/${props.data.flag.toLowerCase()}.png`} className="mr-2 pp-select-flag"/> : ''}
    {props.data.label}
  </Option>
);

const IconSingleValue = (props) => (
  <SingleValue {...props}>
    {props.data.flag ?
      <Image src={`${urlImage}/${props.data.flag.toLowerCase()}.png`} className="mr-2 pp-select-flag"/> : ''}
    {props.data.label}
  </SingleValue>
);

const PlanItem = (props) => (
  <div className="pp-item mb-5 text-align-center shadow plan-payment-box" id={props.id} onClick={props.selectProvider}>
    <Row>
      <Col sm={12}>
        {
          props.providerMask && props.providerMask !== null && props.providerMask === props.id.toString() ?
            <Spinner
              animation="grow"
              variant="secondary"
              style={{position: 'absolute', right: '1rem', zIndex: '1'}}
              size="sm"
            />
            : ''
        }
        <Image src={`${urlBase}/${props.image}`}
               className="mb-3 pp-image vertical-align"/>
      </Col>
      <Col sm={12} className="pp-info">
        <Col sm={12} className="px-0 truncate">
          <p className="pp-title mb-1">{props.category}</p>
        </Col>
        <Col sm={12} className="text-center px-0 truncate">
          <Image src={`${urlImage}/${props.flag.toLowerCase()}.png`} className="pp-item-flag"/>
          <span className="pp-provider ml-2">{props.provider}</span>
        </Col>
      </Col>
    </Row>
  </div>
);

const ServiceItem = (props) => (
  <div className={`${props.basic ? 'ps-item-basic' : 'ps-item'} text-align-center shadow`} id={props.id}
       onClick={props.selectService}>
    <Row>
      <Col sm={12} className="">
        <span className="ps-gets">Receiver gets:</span>
      </Col>
      <Col sm={12} className="">
        <span className="ps-price">{props.price}<span className="ps-currency">USD</span></span>
        <hr className="ps-divider"></hr>
      </Col>
      <Col sm={12} className="">
        <span className="ps-footer">Top-Up Amount {props.topUp} USD</span>
      </Col>
    </Row>
  </div>
);

const PhoneItem = (props) => (
  <Row className="text-left" id={props.id}>
    <Col md={12}>
      <Col md={12} className="text-left phone-card mb-4">
        <Col md={12}>
          <Form.Label
            className="font-weight-bold">{props.isNauta ? `Account: ${props.phone}` : `Phone: +${props.phone}`}</Form.Label>
        </Col>
        <Col md={12}>
          <Form.Label className="font-weight-bold">{`Pay: $${props.amount.toFixed(2)}`}</Form.Label>
        </Col>
        <Col md={12}>
          <Form.Label className="font-weight-bold">{`Receive: $${props.receive.toFixed(2)}`}</Form.Label>
        </Col>
        {
          props.status !== null && props.status === 'Success' ?
            <div className="successful-result">
              <FontAwesomeIcon icon="check"/>
            </div>
            : props.status !== null && props.status !== 'Success' ?
            <div className="failed-result">
              <FontAwesomeIcon icon="times"/>
            </div>
            :
            <Button
              className="btn btn-option btn-circle"
              onClick={() => props.onSubmitDeletePhone(props.index)}
            >
              <FontAwesomeIcon icon="trash"/>
            </Button>
        }
      </Col>
    </Col>
  </Row>
);

const ProviderServices = (props) => {
  const {
    serviceCategory, plans, serviceCategories, onChangeServiceCategory, selectedProvider, selectService, selectedService,
    deselectService, country, paymentMethod, changePaymentMethod, onSubmitPay, currentUser, makingPayment, errorsPayment,
    paymentStatus, showAddPhone, openShowAddPhone, closeShowAddPhone, onSubmitAddPhone,
    phones, onSubmitDeletePhone, totalPrice, totalFee, totalToPay, onResetPayment, simplePaymentResponse, ...rest
  } = props;
  const formId = 'form-submit-payment';

  return (
    <Modal
      {...rest}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-app"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="app-header">
          {selectedProvider.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
          selectedService === null ?
            <div className="px-4">
              {
                !agent.Auth.isLoggedIn() ?
                  <Row>
                    <Col sm={12}>
                      <Alert variant="warning">
                        Must be authenticated to buy a service
                      </Alert>
                    </Col>
                  </Row>
                  : ''
              }
              <Row style={{marginTop: '1rem'}}>
                <Col sm={6} className="p-2 ps-title">
                  <h1 className="app-header">Please select an amount</h1>
                </Col>
                <Col sm={6}>
                  <div className="d-flex flex-row-reverse bd-highlight">
                    <div className="w-50">
                      <Select
                        value={serviceCategory}
                        onChange={onChangeServiceCategory}
                        options={(Array.isArray(serviceCategories) && serviceCategories.length) ? serviceCategories.map(c => ({
                          value: c.id,
                          label: c.description
                        })) : []}
                        styles={selectSortStyles}
                        components={
                          {
                            IndicatorSeparator: () => null
                          }
                        }
                      />
                    </div>
                    <div className="p-2 bd-highlight">
                      <Form.Label className="font-weight-bold">
                        Category:
                      </Form.Label>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="mt-4">
                {
                  plans ? plans.map(p => (
                    <Col sm={6} md={4} key={p.id}>
                      <ServiceItem
                        price={p.minValue !== p.maxValue ? p.minValue + ' - ' + p.maxValue : p.price}
                        topUp={p.maxValue}
                        id={p.id}
                        selectService={selectService}
                        basic={!agent.Auth.isLoggedIn()}
                      />
                    </Col>
                  )) : ''
                }
              </Row>
            </div>
            :
            <div className="px-4">
              <Row style={{marginTop: '1rem'}}>
                <Col sm={4} className="">
                  <Row>
                    <Col sm={12}>
                      <Image src={`${urlBase}/${selectedProvider.imageUrl}`}
                             className="ps-image mb-4 mt-2"
                      />
                    </Col>
                    <Col sm={12}>
                      <h1 className="app-header mb-4"><Image src={`${urlImage}/${country.flag.toLowerCase()}.png`}
                                                             className="mr-2 pp-select-flag"/>{country.label}</h1>
                    </Col>
                    <Col sm={12}>
                      <h1 className="app-header mb-4">Pay Service</h1>
                    </Col>
                    <Col sm={12} className="mb-4">
                      <a className="fp-link" href="/services" onClick={deselectService} style={{fontSize: '15px'}}>
                        <FontAwesomeIcon icon="arrow-left" size="1x" className="fp-link-icon-left"/>
                        Go Back
                      </a>
                    </Col>
                    <Col sm={12} style={{paddingLeft: '.5rem'}}>
                      <ServiceItem
                        price={selectedService.minValue !== selectedService.maxValue ? selectedService.minValue + ' - ' + selectedService.maxValue : selectedService.price}
                        topUp={selectedService.maxValue}
                        id={selectedService.id}
                        basic={true}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col sm={8}>
                  <Formik
                    initialValues={{
                      callingCode: country.callingCode ? country.callingCode : '',
                      phoneNumber: '',
                      callingCodeConfirm: country.callingCode ? country.callingCode : '',
                      phoneNumberConfirm: '',
                      amount: '',
                      email: '',
                      terms: false
                    }}
                    onSubmit={(values) => onSubmitPay(
                      null, //paymentId
                      selectedProvider.currencyIso, //currency
                      totalToPay, //totalAmount
                      selectedService.name, //planName
                      selectedService.planKey, //planKey
                      selectedProvider.operatorCode, //operatorCode
                      country.flag, //countryCode
                      selectedProvider.operatorCode === 'NUCU' ? 'nauta' : 'phone', //type
                      selectedService.topUp ? 'Topup' : 'Pin', //transferType
                      values.email, //notificationEmail
                      paymentMethod, //paymentMethod
                      selectedProvider.operatorCode === 'NUCU', //nauta
                      phones, //recharges
                      currentUser
                    )}
                    validationSchema={Yup.object().shape({
                      email: Yup.string().email('Email not valid'),
                      terms: Yup
                        .boolean()
                        .oneOf([true], 'Must accept Terms and Conditions')
                    })}
                  >
                    {props => {
                      const {
                        values,
                        touched,
                        errors,
                        handleChange,
                        handleBlur,
                        handleSubmit
                      } = props;
                      return (
                        <Form id={formId} onSubmit={handleSubmit} className="ml-3">
                          <Row className="text-left">
                            <Col md={4}>
                              <Form.Group className="mb-4">
                                <Form.Label className="font-weight-bold">Balance</Form.Label>
                                <Form.Control
                                  className="form-input no-editable"
                                  type="text"
                                  id="price"
                                  value={currentUser ? currentUser.balance.toFixed(2) : '0.00'}
                                  onBlur={handleBlur}
                                  disabled
                                />
                              </Form.Group>
                            </Col>
                            <Col md={4}>
                              <Form.Group className="mb-4">
                                <Form.Label className="font-weight-bold">Credit</Form.Label>
                                <Form.Control
                                  className="form-input no-editable"
                                  type="text"
                                  id="fee"
                                  value={currentUser ? currentUser.credit.toFixed(2) : '0.00'}
                                  onBlur={handleBlur}
                                  disabled
                                />
                              </Form.Group>
                            </Col>
                            <Col md={4}>
                              <Form.Group className="mb-4">
                                <Form.Label className="font-weight-bold">Debt</Form.Label>
                                <Form.Control
                                  className="form-input no-editable"
                                  type="text"
                                  id="total"
                                  value={currentUser ? currentUser.debt.toFixed(2) : '0.00'}
                                  onBlur={handleBlur}
                                  disabled
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row className="text-left">
                            <Col md={12}>
                              <Form.Group className="mb-4">
                                <Form.Label className="font-weight-bold">Pay With</Form.Label>
                                <Row>
                                  <Col md={4} className="">
                                    <Button
                                      className={`btn btn-form-app w-100 btn-option ${paymentMethod === paymentMethods.BALANCE ? 'active' : ''}`}
                                      id={paymentMethods.BALANCE}
                                      onClick={changePaymentMethod}
                                    >
                                      <FontAwesomeIcon icon="dollar-sign" size="sm" className="o-btn-icon"/>
                                      Balance
                                    </Button>
                                  </Col>
                                  <Col md={4}>
                                    <Button
                                      className={`btn btn-form-app w-100 btn-option ${paymentMethod === paymentMethods.DEBIT_CREDIT ? 'active' : ''}`}
                                      id={paymentMethods.DEBIT_CREDIT}
                                      onClick={changePaymentMethod}
                                    >
                                      <FontAwesomeIcon icon="credit-card" size="sm" className="o-btn-icon"/>
                                      Credit
                                    </Button>
                                  </Col>
                                  <Col md={4} className="">
                                    <Button
                                      className={`btn btn-form-app w-100 btn-option ${paymentMethod === paymentMethods.PAYPAL ? 'active' : ''}`}
                                      id={paymentMethods.PAYPAL}
                                      onClick={changePaymentMethod}
                                      disabled={true}
                                    >
                                      <FontAwesomeIcon icon={faPaypal} size="sm" className="o-btn-icon"/>
                                      Paypal
                                    </Button>
                                  </Col>
                                </Row>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row className="text-left">
                            <Col md={12}>
                              <Form.Group className="mb-4">
                                <Row>
                                  <Col md={4} className="text-left">
                                    <Button
                                      className={'btn btn-form-app btn-option w-100'}
                                      onClick={openShowAddPhone}
                                      disabled={phones.length === 5}
                                    >
                                      <FontAwesomeIcon icon="plus" size="sm" className="o-btn-icon"/>
                                      {/*selectedProvider.operatorCode === 'NUCU' ? 'Add Account' : 'Add Phone'*/}
                                      Add
                                    </Button>
                                    {/*
                                      submitPaymentChecked && submitPaymentError ?
                                        <div className="error-message">{submitPaymentMessage}</div>
                                      : ''
                                    */}
                                  </Col>
                                  <Col md={8} className="text-left pl-0">
                                    <Form.Label
                                      className="font-weight-bold mt-2">
                                      {`${phones.length} ${selectedProvider.operatorCode === 'NUCU' ? 'account' : 'phone'}(s) added from a total of 5 (avaible ${5 - phones.length})`}
                                    </Form.Label>
                                  </Col>
                                </Row>
                              </Form.Group>
                            </Col>
                          </Row>
                          {
                            phones.map((phone, i) =>
                              <PhoneItem
                                key={i}
                                index={i}
                                phone={phone.item}
                                amount={phone.amount}
                                receive={phone.receive}
                                onSubmitDeletePhone={onSubmitDeletePhone}
                                isNauta={selectedProvider.operatorCode === 'NUCU'}
                                status={simplePaymentResponse !== null ? simplePaymentResponse[i].status : null}
                              />
                            )
                          }
                          <Row className="text-left">
                            <Col md={4}>
                              <Form.Group className="mb-4">
                                <Form.Label className="font-weight-bold">Price</Form.Label>
                                <Form.Control
                                  className="form-input no-editable"
                                  type="text"
                                  id="price"
                                  value={totalPrice ? totalPrice.toFixed(2) : '0.00'}
                                  onBlur={handleBlur}
                                  disabled
                                />
                              </Form.Group>
                            </Col>
                            <Col md={4}>
                              <Form.Group className="mb-4">
                                <Form.Label className="font-weight-bold">Fee</Form.Label>
                                <Form.Control
                                  className="form-input no-editable"
                                  type="text"
                                  id="fee"
                                  value={totalFee ? totalFee.toFixed(2) : '0.00'}
                                  onBlur={handleBlur}
                                  disabled
                                />
                              </Form.Group>
                            </Col>
                            <Col md={4}>
                              <Form.Group className="mb-4">
                                <Form.Label className="font-weight-bold">Total</Form.Label>
                                <Form.Control
                                  className="form-input no-editable"
                                  type="text"
                                  id="total"
                                  value={totalToPay ? totalToPay.toFixed(2) : '0.00'}
                                  onBlur={handleBlur}
                                  disabled
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row className="text-left">
                            <Col md={12}>
                              <Form.Group className="mb-4">
                                <Form.Label className="font-weight-bold">Email to Notify</Form.Label>
                                <Form.Control
                                  className="form-input"
                                  id="email"
                                  type="text"
                                  value={values.email}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {errors.email && touched.email && (
                                  <div className="error-message">{errors.email}</div>
                                )}
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row className="">
                            <Col md={12}>
                              <hr style={{marginTop: '.5rem', marginBottom: '1.5rem'}}></hr>
                              <Form.Group className="mb-3">
                                <Form.Check>
                                  <Form.Check.Label>
                                    <Form.Check.Input
                                      type="checkbox"
                                      id="terms"
                                      value={values.terms}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                    I read and agree to the
                                  </Form.Check.Label>&nbsp;
                                  <Link to="/">Terms and Conditions</Link>
                                </Form.Check>
                                {errors.terms && touched.terms && (
                                  <div className="error-message">{errors.terms}</div>
                                )}
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={12} className="mb-3">
                              <p className="fp-text pass-info mb-3">
                                If you pay with Paypal from outside the USA and are not resident your payment can be
                                delayed by Paypal
                              </p>
                            </Col>
                          </Row>
                        </Form>
                      );
                    }}
                  </Formik>
                </Col>
              </Row>
            </div>
        }
        {
          errorsPayment && errorsPayment !== null ?
            <Message type="error" text={errorsPayment} onClose={onResetPayment}/>
            : paymentStatus === 'Successful' ?
            <Message type="success" text="Petition executed successfully" onClose={onResetPayment}/>
            : ''
        }
        <AddPhone
          show={showAddPhone}
          onHide={closeShowAddPhone}
          selectedProvider={selectedProvider}
          country={country}
          selectedService={selectedService}
          onSubmitAddPhone={onSubmitAddPhone}
        />
      </Modal.Body>
      <Modal.Footer>
        {selectedService && simplePaymentResponse === null ?
          <Button className="btn btn-form-app btn-option" type="submit" form={formId} disabled={makingPayment === true}>
            {
              makingPayment ?
                <Spinner
                  animation="grow"
                  variant="secondary"
                  style={{marginRight: '5px', marginBottom: '4px'}}
                  size="sm"
                />
                : ''
            }
            {
              paymentMethod === paymentMethods.BALANCE ?
                'Pay with Balance'
                : paymentMethod === paymentMethods.DEBIT_CREDIT ?
                'Pay with Credit'
                : paymentMethod === paymentMethods.PAYPAL ?
                  'Pay with Paypal'
                  : 'Pay'
            }
            <FontAwesomeIcon icon="arrow-right" className="icon-right"/>
          </Button> :
          selectedService && simplePaymentResponse !== null ?
            <Button className="btn btn-form-app btn-option" type="button" onClick={deselectService}>
              Continue
              <FontAwesomeIcon icon="arrow-right" className="icon-right"/>
            </Button>
            : ''
        }
        <Button onClick={props.onHide} className="btn btn-outline-dark btn-form-app">Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

const AddPhone = (props) => {
  const {selectedProvider, country, selectedService, onSubmitAddPhone, ...rest} = props;
  const formAddPhoneId = 'form-add-phone';
  return (
    <Modal
      {...rest}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-app"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="app-header">
          {selectedProvider.operatorCode === 'NUCU' ? 'Add Account' : 'Add Phone'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
          selectedService ?
            <Row style={{marginTop: '1rem'}} className="mx-1">
              <Col sm={12}>
                <Formik
                
                  initialValues={{
                    callingCode: country.callingCode ? country.callingCode : '',
                    phoneNumber: '',
                    callingCodeConfirm: country.callingCode ? country.callingCode : '',
                    phoneNumberConfirm: '',
                    amount: selectedService.minValue === selectedService.maxValue ? selectedService.minValue : '',
                    disabled: true,
                    email: '',
                    terms: false
                    }}
                  
                  onSubmit={(values) => onSubmitAddPhone(
                    values.amount,
                    selectedProvider.operatorCode === 'NUCU' ? values.phoneNumber.toString() : values.callingCode.toString() + values.phoneNumber.toString(),
                    selectedService.fee,
                    selectedService.name,
                    selectedProvider.operatorCode,
                    country.flag,
                    values.callingCode.toString() + values.phoneNumber.toString(),
                    selectedService.topUp ? 'Topup' : 'Pin',
                    selectedService.planKey
                  )}
                  validationSchema={Yup.object().shape({
                    callingCode: Yup.string().required('Required'),
                    phoneNumber: Yup
                      .string()
                      .required('Required')
                      .transform(value => selectedProvider.operatorCode === 'NUCU' ? value : country.callingCode + value)
                      .matches(new RegExp(selectedProvider.phoneNumberRegExp), {
                        excludeEmptyString: true,
                        message: 'Invalid for this provider'
                      }),
                    amount: Yup
                      .number()
                      .required('Required')
                      .min(selectedService.price === 0 ? selectedService.minValue : selectedService.price,
                        `Amount must be greater than or equal to ${selectedService.price === 0 ? selectedService.minValue : selectedService.price}`)
                      .max(selectedService.price === 0 ? selectedService.maxValue : selectedService.price,
                        `Amount must be less than or equal to ${selectedService.price === 0 ? selectedService.maxValue : selectedService.price}`)
                      .typeError('Must be a valid number')
                  })}
                >
                  {props => {
                    const {
                      values,
                      touched,
                      errors,
                      handleChange,
                      handleBlur,
                      handleSubmit
                    } = props;
                    return (
                      <Form id={formAddPhoneId} onSubmit={handleSubmit}>
                        <Row className="text-left">
                          <Col md={12}>
                            <Form.Group className="mb-4">
                              <Form.Label className="font-weight-bold">
                                {selectedProvider.operatorCode === 'NUCU' ? 'Nauta Account' : 'Phone Number'}
                              </Form.Label>
                              {
                                selectedProvider.operatorCode === 'NUCU' ?
                                  <Row>
                                    <Col md={12}>
                                      <Form.Control
                                        className="form-input"
                                        type="text"
                                        id="phoneNumber"
                                        value={values.phoneNumber}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                      {errors.phoneNumber && touched.phoneNumber && (
                                        <div className="error-message">{errors.phoneNumber}</div>
                                      )}
                                    </Col>
                                  </Row>
                                  :
                                  <Row>
                                    <Col md={3}>
                                      <Form.Control
                                        className="form-input text-center no-editable"
                                        type="text"
                                        id="callingCode"
                                        value={country.callingCode}
                                        onBlur={handleBlur}
                                        disabled
                                      />
                                    </Col>
                                    <Col md={9}>
                                      <Form.Control
                                        className="form-input"
                                        type="text"
                                        id="phoneNumber"
                                        value={values.phoneNumber}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                      {errors.phoneNumber && touched.phoneNumber && (
                                        <div className="error-message">{errors.phoneNumber}</div>
                                      )}
                                    </Col>
                                  </Row>
                              }
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row className="text-left" style={{}}>
                          <Col md={12}>
                            <Form.Group className="mb-4">
                              <Form.Label className="font-weight-bold">Amount</Form.Label>
                              <Form.Control
                                className={`form-input `}
                                id="amount"
                                type="text"
                                value={selectedService.price !== 0 ? selectedService.price : values.amount}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={selectedService.minValue === selectedService.maxValue}
                              />
                              {errors.amount && touched.amount && (
                                <div className="error-message">{errors.amount}</div>
                              )}
                            </Form.Group>
                          </Col>
                        </Row>
                      </Form>
                    );
                  }}
                </Formik>
              </Col>
            </Row>
            : ''
        }
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn btn-form-app btn-option" type="submit" form={formAddPhoneId}>
          <FontAwesomeIcon icon="check" className="icon"/>
          Add
        </Button>
        <Button onClick={props.onHide} className="btn btn-outline-dark btn-form-app">Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

class PlanPayment extends Component {
  constructor(props) {
    super(props);

    this.changeProvider = (value) => this.props.onChangeProvider(value);
    this.openShowAddPhone = ev => this.props.onToggleShowAddPhone(true);
    this.closeShowAddPhone = ev => this.props.onToggleShowAddPhone(false);
    this.selectProvider = ev => {
      const el = ev.target;
      const parent = el.parentElement.closest(".pp-item");
      return this.props.onSelectProvider(parent !== null ? parent.id : el.id);
    };
    this.selectService = ev => {
      if (agent.Auth.isLoggedIn()) {
        const el = ev.target;
        const parent = el.parentElement.closest(".ps-item");
        return this.props.onSelectService(parent !== null ? parent.id : el.id);
      }
      return false;
    };
    this.deselectService = ev => {
      ev.preventDefault();
      return this.props.onDeselectService();
    };
    this.closeServices = () => this.props.onCloseServices();
    this.changePaymentMethod = ev => {
      const el = ev.target;
      const parent = el.parentElement.closest(".btn-option");
      return this.props.onChangePaymentMethod(parent !== null ? parseInt(parent.id) : parseInt(el.id));
    };
  }

  render() {
    const {
      inProgress, countries, categories, country, category, provider, providers, filteredProviders, currentPage, totalPages,
      showServices, plans, onChangeCountry, onChangeCategory, onPaginate, serviceCategory, serviceCategories, onChangeServiceCategory,
      loadingServices, selectedProvider, selectedService, paymentMethod, onSubmitPay, currentUser, makingPayment, errorsPayment,
      errorsServices, paymentStatus, providerMask, showAddPhone, onSubmitAddPhone, phones, onSubmitDeletePhone,
      totalPrice, totalFee, totalToPay, onResetPayment, home, simplePaymentResponse
    } = this.props;

    return <div className={`bg-white content text-center plan-payment-super-container-parent-${home === true}`}>

      <h1 className={`section-title text-white-${home === true}`}>Plan Payment</h1>
      <h5 className={`section-subtitle mb-lg-5 text-white-${home === true}`}>Pay for Any Plan Offered for Any Provider
        of Any Country...</h5>
      <div className="p-lg-5 search-your-provider-container">
        <Row className="text-left">
          <Col sm={12} md={12} lg={4} className="plan-payment-input-fields">
            <Form.Label className={`font-weight-bold text-white-${home === true}`}>Search for Your Provider</Form.Label>
            <Select
              value={provider !== '' ? provider : null}
              onChange={this.changeProvider}
              options={Array.isArray(providers) && providers.length ? providers.map(item => ({
                value: item.id,
                label: item.name,
                countryId: item.countryId,
                operatorCategoryId: item.operatorCategoryId
              })) : []}
              styles={selectStyles}
              placeholder="Enter Name of Provider"
              isClearable={true}
              components={
                {
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null
                }
              }
            />
          </Col>
          <Col sm={12} md={12} lg={4} className="plan-payment-input-fields">
            <Form.Label className={`font-weight-bold text-white-${home === true}`}>Filter by Country</Form.Label>
            <Select
              value={country}
              onChange={onChangeCountry}
              options={Array.isArray(countries) && countries.length ? countries.map(c => ({
                value: c.id,
                label: c.countryName,
                flag: c.isO2,
                callingCode: c.callingCode
              })) : []}
              styles={selectStyles}
              components={
                {
                  Option: IconOption,
                  SingleValue: IconSingleValue,
                  IndicatorSeparator: () => null
                }
              }
            />
          </Col>
          <Col sm={12} md={12} lg={4} className="filter-by-plan-input-fields">
            <Form.Label className={`font-weight-bold text-white-${home === true}`}>Filter by Plan</Form.Label>
            <Select
              value={category}
              onChange={onChangeCategory}
              options={(Array.isArray(categories) && categories.length) ? categories.map(c => ({
                value: c.id,
                label: c.description
              })) : []}
              styles={selectStyles}
              placeholder="All"
              components={
                {
                  IndicatorSeparator: () => null
                }
              }
            />
          </Col>
        </Row>
      </div>
      <Row className="pl-lg-5 pr-lg-5">
        {filteredProviders ? filteredProviders.map(p => (
          <Col xl={3} lg={4} md={4} sm={6} xs={12} key={p.id}>
            <PlanItem
              image={p.imageUrl}
              category={p.category}
              flag={country.flag}
              provider={p.name}
              id={p.id}
              selectProvider={this.selectProvider}
              providerMask={providerMask}
            />
          </Col>
        )) : ''}
      </Row>
      {
        inProgress ?
          <div>
            <Spinner
              animation="grow"
              variant="secondary"
              style={{marginRight: '5px', marginBottom: '4px'}}
              size="sm"
            />Loading providers...
          </div>
          : totalPages > 0 ?
          totalPages > 1 ?
            <PaginationCircle active={currentPage} nroPages={totalPages} onPaginate={onPaginate}/>
            : ''
          : <p>No data available</p>
      }
      {
        !loadingServices ?
          errorsServices === null || typeof errorsServices === 'undefined' ?
            plans && selectedProvider && selectedProvider !== null ?
              plans.length > 0 ?
                <ProviderServices
                  show={showServices}
                  onHide={this.closeServices}
                  serviceCategory={serviceCategory}
                  plans={plans}
                  onChangeServiceCategory={onChangeServiceCategory}
                  serviceCategories={serviceCategories}
                  selectedProvider={selectedProvider}
                  selectService={this.selectService}
                  selectedService={selectedService}
                  deselectService={this.deselectService}
                  country={country}
                  paymentMethod={paymentMethod}
                  changePaymentMethod={this.changePaymentMethod}
                  onSubmitPay={onSubmitPay}
                  currentUser={currentUser}
                  makingPayment={makingPayment}
                  errorsPayment={errorsPayment}
                  paymentStatus={paymentStatus}
                  showAddPhone={showAddPhone}
                  openShowAddPhone={this.openShowAddPhone}
                  closeShowAddPhone={this.closeShowAddPhone}
                  onSubmitAddPhone={onSubmitAddPhone}
                  phones={phones}
                  onSubmitDeletePhone={onSubmitDeletePhone}
                  totalPrice={totalPrice}
                  totalFee={totalFee}
                  totalToPay={totalToPay}
                  onResetPayment={onResetPayment}
                  simplePaymentResponse={simplePaymentResponse}
                />
                : <Message type="info" text="There are no plans for this provider"/>
              : ''
            : <Message type="error" text={errorsServices}/>
          : ''
      }
    </div>
  }
}

export default PlanPayment;