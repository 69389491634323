import React from 'react';
import {Col, Row, Form, Button, Spinner} from "react-bootstrap";
import moment from 'moment';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as Yup from "yup";
import {Formik} from "formik";

const MyProfile = props => {
  const {currentUser, onSubmitEditProfile, inProgress} = props;
  const formEditProfileId = 'formEditProfileId';
  return (
    <div className="py-0">
      <div className="my-profile-container pl-5-desktop">
        <Col xl={12} lg={12} md={12} sm={12} xs={12} className="my-profile-header-container">
          <h4 className="mb-5">My Profile</h4>
        </Col>
        <div className="my-profile-container-content row">
          <Col xl={8} lg={8} md={8} sm={12} xs={12} className="my-profile-information-container">
            <div className="px-3 row px-3-mobile">
              <Col sm={6} xs={6} lg={12} xl={12} md={12} className="mb-3">
                <Form.Label className="font-weight-bold">Username:</Form.Label>&nbsp;
                <Form.Label>{currentUser ? currentUser.userName : ''}</Form.Label>
              </Col>
              <Col sm={6} xs={6} lg={12} xl={12} md={12} className="mb-3">
                <Form.Label className="font-weight-bold">Balance:</Form.Label>&nbsp;
                <Form.Label>{currentUser ? `${currentUser.balance.toFixed(2)}` : '0.00'}</Form.Label>
              </Col>
              <Col sm={6} xs={6} lg={12} xl={12} md={12} className="mb-3">
                <Form.Label className="font-weight-bold">Credit:</Form.Label>&nbsp;
                <Form.Label>{currentUser ? `${currentUser.credit.toFixed(2)}` : '0.00'}</Form.Label>
              </Col>
              <Col sm={6} xs={6} lg={12} xl={12} md={12} className="mb-3">
                <Form.Label className="font-weight-bold">Debt:</Form.Label>&nbsp;
                <Form.Label>{currentUser ? `${currentUser.debt.toFixed(2)}` : '0.00'}</Form.Label>
              </Col>
              <Col sm={12} xs={12} lg={12} xl={12} md={12} className="mb-3">
                <Form.Label className="font-weight-bold">Member Since:</Form.Label>&nbsp;
                <Form.Label>{currentUser ? moment(currentUser.joinDate).format('YYYY-MM-DD') : ''}</Form.Label>
              </Col>
              <Col sm={12} className="mt-3">
                <hr className="my-4 my-profile-divider-section"/>
                <Formik
                  initialValues={{
                    firstName: currentUser ? currentUser.firstName : '',
                    lastName: currentUser ? currentUser.lastName : '',
                    phoneNumber: currentUser ? currentUser.phoneNumber : '',
                  }}
                  onSubmit={(values) => onSubmitEditProfile(
                    values.firstName,
                    values.lastName,
                    values.phoneNumber,
                    currentUser
                  )}
                  validationSchema={Yup.object().shape({
                    firstName: Yup.string().required('Required'),
                    lastName: Yup.string().required('Required'),
                    phoneNumber: Yup.string().required('Required'),
                  })}
                >
                  {props => {
                    const {
                      values,
                      touched,
                      errors,
                      handleChange,
                      handleBlur,
                      handleSubmit
                    } = props;
                    return (
                      <Form id={formEditProfileId} onSubmit={handleSubmit} className="ml-3">
                        <Row className="text-left">
                          <Col md={12} sm={12} className="px-0">
                            <Form.Group className="mb-4">
                              <Form.Label className="font-weight-bold">First Name</Form.Label>
                              <Form.Control
                                className="form-input border-radius-4"
                                type="text"
                                id="firstName"
                                value={values.firstName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.firstName && touched.firstName && (
                                <div className="error-message">{errors.firstName}</div>
                              )}
                            </Form.Group>
                          </Col>
                          <Col md={12} sm={12} className="px-0">
                            <Form.Group className="mb-4">
                              <Form.Label className="font-weight-bold">Last Name</Form.Label>
                              <Form.Control
                                className="form-input border-radius-4"
                                type="text"
                                id="lastName"
                                value={values.lastName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.lastName && touched.lastName && (
                                <div className="error-message">{errors.lastName}</div>
                              )}
                            </Form.Group>
                          </Col>
                          <Col md={12} sm={12} className="px-0">
                            <Form.Group className="mb-4">
                              <Form.Label className="font-weight-bold">Phone</Form.Label>
                              <Form.Control
                                className="form-input border-radius-4"
                                type="text"
                                id="phoneNumber"
                                value={values.phoneNumber}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.phoneNumber && touched.phoneNumber && (
                                <div className="error-message">{errors.phoneNumber}</div>
                              )}
                            </Form.Group>
                          </Col>
                          <Col md={12} className="px-0">
                            <Button className="btn-app btn-black w-100 mt-2 update-button-profile border-radius-4"
                                    type="submit" disabled={inProgress}>
                              {inProgress ?
                                <Spinner
                                  animation="grow"
                                  variant="secondary"
                                  style={{marginRight: '5px', marginBottom: '4px'}}
                                  size="sm"
                                /> : ''}
                              Update
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    );
                  }}
                </Formik>
              </Col>
            </div>
          </Col>
          <Col xl={4} lg={4} md={4} sm={12} xs={12} className="my-profile-image-container">
            <div className="profile-icon text-center">
              <FontAwesomeIcon icon="user" size="6x" className="color-dark-gray"/>
            </div>
          </Col>
        </div>
      </div>
    </div>
  );
};

export default MyProfile;