import React, {Component} from 'react';
import {Col, Row, Form, Button, Modal, Spinner} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as Yup from "yup";
import {Formik} from "formik";
import ReactTable from "react-table";
import TableInfo from '../TableInfo';

const {paymentMethods} = require('../../utils');

const ResetDebt = (props) => {
  const {debt, inProgress, submitResetDebt, ...rest} = props;
  const formResetDebtId = 'formResetDebtId';
  return (
    <Modal
      {...rest}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-app"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="app-header">
          Reset Debt
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-5">
        {
          debt ?
            <Formik
              initialValues={{
                amount: '',
                firstName: debt ? debt.firstName : '',
                lastName: debt ? debt.lastName : '',
                email: debt ? debt.email : '',
                phoneNumber: debt ? debt.phoneNumber : '',
                debt: debt ? debt.debt.toFixed(2) : '',
                note: ''
              }}
              onSubmit={(values) => submitResetDebt(
                debt.id,
                values.amount,
                values.note,
              )}
              validationSchema={Yup.object().shape({
                amount: Yup
                  .number()
                  .required('Required')
                  .typeError('Invalid number')
                  .min(0.01, 'The maximum value is 0.01')
                  .max(debt.debt, `The maximum value is ${debt.debt}`)
              })}
            >
              {props => {
                const {
                  values,
                  touched,
                  errors,
                  handleChange,
                  handleBlur,
                  handleSubmit
                } = props;
                return (
                  <Form id={formResetDebtId} onSubmit={handleSubmit}>
                    <Row className="text-left">
                      <Col md={6} sm={12}>
                        <Form.Group className="mb-4">
                          <Form.Label className="font-weight-bold">First Name</Form.Label>
                          <Form.Control
                            className="form-input border-radius-4"
                            type="text"
                            id="firstName"
                            value={values.firstName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={true}
                          />
                          {errors.firstName && touched.firstName && (
                            <div className="error-message">{errors.firstName}</div>
                          )}
                        </Form.Group>
                      </Col>
                      <Col md={6} sm={12}>
                        <Form.Group className="mb-4">
                          <Form.Label className="font-weight-bold">Last Name</Form.Label>
                          <Form.Control
                            className="form-input border-radius-4"
                            type="text"
                            id="lastName"
                            value={values.lastName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={true}
                          />
                          {errors.lastName && touched.lastName && (
                            <div className="error-message">{errors.lastName}</div>
                          )}
                        </Form.Group>
                      </Col>
                      <Col md={6} sm={12}>
                        <Form.Group className="mb-4">
                          <Form.Label className="font-weight-bold">Email</Form.Label>
                          <Form.Control
                            className="form-input border-radius-4"
                            type="text"
                            id="email"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={true}
                          />
                          {errors.email && touched.email && (
                            <div className="error-message">{errors.email}</div>
                          )}
                        </Form.Group>
                      </Col>
                      <Col md={6} sm={12}>
                        <Form.Group className="mb-4">
                          <Form.Label className="font-weight-bold">Phone</Form.Label>
                          <Form.Control
                            className="form-input border-radius-4"
                            type="text"
                            id="phoneNumber"
                            value={values.phoneNumber}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={true}
                          />
                          {errors.phoneNumber && touched.phoneNumber && (
                            <div className="error-message">{errors.phoneNumber}</div>
                          )}
                        </Form.Group>
                      </Col>
                      <Col md={6} sm={12}>
                        <Form.Group className="mb-4">
                          <Form.Label className="font-weight-bold">Current Debt</Form.Label>
                          <Form.Control
                            className="form-input border-radius-4"
                            type="text"
                            id="debt"
                            value={values.debt}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={true}
                          />
                          {errors.debt && touched.debt && (
                            <div className="error-message">{errors.debt}</div>
                          )}
                        </Form.Group>
                      </Col>
                      <Col md={6} sm={12}>
                        <Form.Group className="mb-4">
                          <Form.Label className="font-weight-bold">Amount</Form.Label>
                          <Form.Control
                            className="form-input border-radius-4"
                            type="text"
                            id="amount"
                            value={values.amount}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.amount && touched.amount && (
                            <div className="error-message">{errors.amount}</div>
                          )}
                        </Form.Group>
                      </Col>
                      <Col md={12} sm={12}>
                        <Form.Group className="mb-4">
                          <Form.Label className="font-weight-bold">Note</Form.Label>
                          <Form.Control
                            as="textarea"
                            id="note"
                            rows="3"
                            value={values.note}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Form>
                );
              }}
            </Formik>
            : ''
        }
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn btn-form-app btn-option border-radius-4" type="submit" form={formResetDebtId}>
          {
            inProgress ?
              <Spinner
                animation="grow"
                variant="secondary"
                style={{marginRight: '5px', marginBottom: '4px'}}
                size="sm"
              />
              : ''
          }
          Reset Debt
          <FontAwesomeIcon icon="arrow-right" className="icon-right"/>
        </Button>
        <Button onClick={props.onHide} className="btn btn-outline-dark btn-form-app border-radius-4">Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

class Referrals extends Component {
  componentWillMount() {
    this.props.loadReferrals();
  }

  render() {
    const {
      referrals, openResetDebt, showResetDebt, closeResetDebt, debt, submitResetDebt, inProgress,
      submitTransferReferral
    } = this.props;
    const formReferralTransferenceId = 'formReferralTransferenceId';
    return (
      <Row className="referrals-super-container">
        <Col sm={12}>
          <div className="pl-5-desktop-referrals">
            <h4 className="mb-4">Referrals</h4>
            <div className="px-5 py-4 mb-4" style={{border: '1px solid rgba(0,0,0,.1)'}}>
              <Formik
                initialValues={{
                  amount: '',
                  toUserId: '',
                  transferFrom: paymentMethods.BALANCE.toString(),
                  transferTo: paymentMethods.BALANCE.toString()
                }}
                onSubmit={(values, {resetForm}) => {
                  submitTransferReferral(
                    values.amount,
                    values.toUserId,
                    values.transferFrom,
                    values.transferTo,
                  );
                  resetForm();
                }}
                validationSchema={Yup.object().shape({
                  amount: Yup.number().required('Required').typeError('Invalid number'),
                  toUserId: Yup.string().required('Required'),
                  transferFrom: Yup.string().required('Required'),
                  transferTo: Yup.string().required('Required'),
                })}
              >
                {props => {
                  const {
                    values,
                    touched,
                    errors,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  } = props;
                  return (
                    <Form id={formReferralTransferenceId} onSubmit={handleSubmit}>
                      <Row className="text-left">
                        <Col md={6} sm={12}>
                          <Form.Group className="mb-4">
                            <Form.Label className="font-weight-bold">User</Form.Label>
                            <Form.Control
                              as="select"
                              id="toUserId"
                              className="form-input border-radius-4"
                              value={values.toUserId}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <option key={-1} value="">Select a referral</option>
                              {
                                referrals ? referrals.map(item =>
                                    <option key={item.id} value={item.id}>{`${item.firstName} ${item.lastName}`}</option>)
                                  : ''
                              }
                            </Form.Control>
                            {errors.toUserId && touched.toUserId && (
                              <div className="error-message">{errors.toUserId}</div>
                            )}
                          </Form.Group>
                          <Form.Group className="mb-4">
                            <Form.Label className="font-weight-bold mr-3 referral-label-transfers">From</Form.Label>
                            <Form.Check
                              inline
                              type="radio"
                              label="Balance"
                              name="transferFrom"
                              id="transferFromBalance"
                              value={paymentMethods.BALANCE}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              checked={values.transferFrom === paymentMethods.BALANCE.toString()}
                            />
                            <Form.Check
                              inline
                              type="radio"
                              label="Credit"
                              name="transferFrom"
                              id="transferFromCredit"
                              value={paymentMethods.DEBIT_CREDIT}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              checked={values.transferFrom === paymentMethods.DEBIT_CREDIT.toString()}
                            />
                          </Form.Group>
                        </Col>
                        <Col md={6} sm={12}>
                          <Form.Group className="mb-4">
                            <Form.Label className="font-weight-bold">Amount</Form.Label>
                            <Form.Control
                              className="form-input border-radius-4"
                              type="text"
                              id="amount"
                              value={values.amount}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.amount && touched.amount && (
                              <div className="error-message">{errors.amount}</div>
                            )}
                          </Form.Group>
                          <Form.Group className="mb-4">
                            <Form.Label className="font-weight-bold mr-3 referral-label-transfers">Receive
                              As</Form.Label>
                            <Form.Check
                              inline
                              type="radio"
                              label="Balance"
                              name="transferTo"
                              id="transferToBalance"
                              value={paymentMethods.BALANCE}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              checked={values.transferTo === paymentMethods.BALANCE.toString()}
                            />
                            <Form.Check
                              inline
                              type="radio"
                              label="Credit"
                              name="transferTo"
                              id="transferToCredit"
                              value={paymentMethods.DEBIT_CREDIT}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              checked={values.transferTo === paymentMethods.DEBIT_CREDIT.toString()}
                            />
                          </Form.Group>
                        </Col>
                        <Col md={12} className="text-center">
                          <Button
                            className="btn btn-black btn-inline border-radius-4 btn btn-primary"
                            style={{marginTop: '0'}}
                            type="submit"
                            form={formReferralTransferenceId}
                          >
                            {
                              inProgress ?
                                <Spinner
                                  animation="grow"
                                  variant="secondary"
                                  style={{marginRight: '5px', marginBottom: '4px'}}
                                  size="sm"
                                />
                                : ''
                            }
                            Transfer
                            <FontAwesomeIcon icon="arrow-right" className="icon-right"/>
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  );
                }}
              </Formik>
            </div>
            <ReactTable
              columns={[
                {
                  Header: "Full Name",
                  id: "fullName",
                  accessor: d => `${d.firstName} ${d.lastName}`,
                },
                {
                  Header: "Email",
                  accessor: "email",
                },
                {
                  Header: "Balance",
                  id: "balance",
                  accessor: d => d.balance.toFixed(2),
                },
                {
                  Header: "Credit",
                  id: "credit",
                  accessor: d => d.credit.toFixed(2),
                },
                {
                  Header: "Debt",
                  id: "currentDebt",
                  accessor: d => d.debt.toFixed(2),
                },
                {
                  Header: "Reset Debt",
                  accessor: "debt",
                  Cell: ({row}) => (
                    row.debt > 0 ?
                      <Button
                        variant="link" className="btn-row border-radius-4"
                        onClick={() => openResetDebt(row._original)}>
                        <FontAwesomeIcon icon="sticky-note"/>
                      </Button> : ''),
                  sortable: false
                },
              ]}
              defaultSorted={[
                {
                  id: "productName",
                  desc: false
                }
              ]}
              data={referrals}
              className="-highlight"
              defaultPageSize={10}
              pageSizeOptions={[5, 10, 20]}
              minRows={0}
              NoDataComponent={() => null}
            >
              {(state, makeTable) => {
                return (
                  <div>
                    {makeTable()}
                    <TableInfo
                      totalRows={state.sortedData.length}
                      page={state.page}
                      pageSize={state.pageSize}
                    />
                  </div>
                )
              }}
            </ReactTable>
          </div>
        </Col>
        <ResetDebt
          show={showResetDebt}
          onHide={closeResetDebt}
          debt={debt}
          submitResetDebt={submitResetDebt}
          inProgress={inProgress}
        />
      </Row>
    );
  }
}

export default Referrals;