import React, {Component} from 'react';
import ReactTable from "react-table";
import "react-table/react-table.css";
import {Button, Col, Form, Modal, Row, Spinner} from "react-bootstrap";
import {Formik} from "formik";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as Yup from "yup";
import TableInfo from '../TableInfo';

const AddOperatorCategory = (props) => {
  const {
    submitAddOperatorCategory, inProgress, ...rest
  } = props;

  const formAddOperatorCategoryId = 'formAddOperatorCategoryId';

  return (
    <Modal
      {...rest}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-app"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="app-header">
          Add New Category
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <Formik
          initialValues={{
            description: '',
          }}
          onSubmit={(values) => submitAddOperatorCategory(
            values.description
          )}
          validationSchema={Yup.object().shape({
            description: Yup.string().required('Required'),
          })}
        >
          {props => {
            const {
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
            } = props;
            return (
              <Form id={formAddOperatorCategoryId} onSubmit={handleSubmit}>
                <Row className="text-left">
                  <Col md={12} sm={12}>
                    <Form.Group>
                      <Form.Label
                        className="font-weight-bold">Description
                      </Form.Label>
                      <Form.Control
                        className="form-input border-radius-4"
                        type="text"
                        placeholder="Description"
                        id="description"
                        value={values.description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.description && touched.description && (
                        <div className="error-message">{errors.description}</div>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn btn-form-app btn-option border-radius-4" type="submit" form={formAddOperatorCategoryId}
                disabled={inProgress === true}>
          {
            inProgress ?
              <Spinner
                animation="grow"
                variant="secondary"
                style={{marginRight: '5px', marginBottom: '4px'}}
                size="sm"
              />
              : ''
          }
          Accept
          <FontAwesomeIcon icon="arrow-right" className="icon-right"/>
        </Button>
        <Button onClick={props.onHide} className="btn btn-outline-dark btn-form-app border-radius-4">Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

const EditOperatorCategory = (props) => {
  const {
    submitEditOperatorCategory, inProgress, selectedOperatorCategory, ...rest
  } = props;

  const formEditOperatorCategoryId = 'formEditOperatorCategoryId';

  return (
    <Modal
      {...rest}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-app"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="app-header">
          Edit Category
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <Formik
          initialValues={{
            id: selectedOperatorCategory ? selectedOperatorCategory.id : '',
            description: selectedOperatorCategory ? selectedOperatorCategory.description : '',
          }}
          onSubmit={(values) => submitEditOperatorCategory(
            values.id,
            values.description
          )}
          validationSchema={Yup.object().shape({
            description: Yup.string().required('Required'),
          })}
        >
          {props => {
            const {
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
            } = props;

            return (
              <Form id={formEditOperatorCategoryId} onSubmit={handleSubmit}>
                <Row className="text-left">
                  <Col md={12} sm={12}>
                    <Form.Group>
                      <Form.Label
                        className="font-weight-bold">Description
                      </Form.Label>
                      <Form.Control
                        className="form-input border-radius-4"
                        type="text"
                        placeholder="Description"
                        id="description"
                        value={values.description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.description && touched.description && (
                        <div className="error-message">{errors.description}</div>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn btn-form-app btn-option border-radius-4" type="submit" form={formEditOperatorCategoryId}
                disabled={inProgress === true}>
          {
            inProgress ?
              <Spinner
                animation="grow"
                variant="secondary"
                style={{marginRight: '5px', marginBottom: '4px'}}
                size="sm"
              />
              : ''
          }
          Accept
          <FontAwesomeIcon icon="arrow-right" className="icon-right"/>
        </Button>
        <Button onClick={props.onHide} className="btn btn-outline-dark btn-form-app border-radius-4">Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

const DeleteOperatorCategory = (props) => {
  const {
    selectedOperatorCategory, inProgress, submitDeleteOperatorCategory, ...rest
  } = props;
  return (
    <Modal
      {...rest}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-app"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="app-header">
          Delete Category
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Label>
          Sure you want to delete the category&nbsp;
          <span
            className="font-weight-bold">{selectedOperatorCategory ? selectedOperatorCategory.description.toUpperCase() : ''}</span>?
        </Form.Label>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="btn btn-form-app btn-option border-radius-4"
          type="submit"
          onClick={() => submitDeleteOperatorCategory(selectedOperatorCategory.id)}
        >
          {
            inProgress ?
              <Spinner
                animation="grow"
                variant="secondary"
                style={{marginRight: '5px', marginBottom: '4px'}}
                size="sm"
              />
              : ''
          }
          Accept
          <FontAwesomeIcon icon="arrow-right" className="icon-right"/>
        </Button>
        <Button onClick={props.onHide} className="btn btn-outline-dark btn-form-app border-radius-4">Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

class OperatorCategories extends Component {
  render() {
    const {
      inProgress, filteredOperatorCategories, selectedOperatorCategory, submitSearchOperatorCategory, onUpdateFilterOperatorCategory,
      openAddOperatorCategory, showAddOperatorCategory, closeAddOperatorCategory, submitAddOperatorCategory,
      openEditOperatorCategory, showEditOperatorCategory, closeEditOperatorCategory, submitEditOperatorCategory,
      openDeleteOperatorCategory, showDeleteOperatorCategory, closeDeleteOperatorCategory, submitDeleteOperatorCategory,
    } = this.props;
    return (
      <Row className="transferences-super-container">
        <Col sm={12}>
          <div className="pl-5-desktop-transferences">
            <h4 className="mb-4">Categories</h4>
            <Button
              variant="light"
              onClick={openAddOperatorCategory}
              className="header-toolbar-btn border-radius-4"
            >
              <FontAwesomeIcon icon="plus" className="icon"/>
              Add
            </Button>
            <Formik
              initialValues={{description: ''}}
              onSubmit={() => submitSearchOperatorCategory()}
            >
              {props => {
                const {
                  touched,
                  errors,
                  handleSubmit,
                  values,
                  handleChange,
                  handleBlur
                } = props;
                return (
                  <Form className="mt-4" onSubmit={handleSubmit}>
                    <div className="px-4 py-4 mb-4" style={{border: '1px solid rgba(0,0,0,.1)'}}>
                      <Row className="text-left">
                        <Col sm={12} md={4}>
                          <Form.Group className="mb-4">
                            <Form.Label className="font-weight-bold">Category</Form.Label>
                            <Form.Control
                              className="form-input border-radius-4"
                              type="text"
                              id="description"
                              value={values.description}
                              onChange={(ev) => {
                                handleChange(ev);
                                onUpdateFilterOperatorCategory(ev.target.value);
                              }}
                              onBlur={handleBlur}
                            />
                            {errors.description && touched.description && (
                              <div className="error-message">{errors.description}</div>
                            )}
                          </Form.Group>
                        </Col>
                        <Col sm={12} md={6}>
                          <Form.Group className="mb-3">
                            <Button
                              className="btn btn-black btn-inline border-radius-4 search-button-transactions"
                              type="submit"
                              disabled={inProgress}
                            >
                              <FontAwesomeIcon icon="search" size="sm" className="o-btn-icon"/>
                              Search
                            </Button>
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                    <ReactTable
                      ref={(instance) => {
                        this.table = instance;
                      }}
                      columns={[
                        {
                          Header: "Description",
                          accessor: "description",
                        },
                        {
                          Header: "Edit",
                          accessor: "d",
                          Cell: ({row}) => (
                            <Button
                              variant="link" className="btn-row border-radius-4"
                              onClick={() => openEditOperatorCategory(row._original)}
                            >
                              <FontAwesomeIcon icon="pencil-alt"/>
                            </Button>),
                          sortable: false
                        },
                        {
                          Header: "Delete",
                          accessor: "d",
                          Cell: ({row}) => (
                            <Button
                              variant="link" className="btn-row border-radius-4"
                              onClick={() => openDeleteOperatorCategory(row._original)}
                            >
                              <FontAwesomeIcon icon="trash"/>
                            </Button>),
                          sortable: false
                        }
                      ]}
                      defaultSorted={[
                        {
                          id: "description",
                          desc: false
                        }
                      ]}
                      data={filteredOperatorCategories}
                      defaultPageSize={20}
                      pageSizeOptions={[5, 10, 20]}
                      className="-striped -highlight"
                      minRows={0}
                      NoDataComponent={() => null}
                    >
                      {(state, makeTable) => {
                        return (
                          <div>
                            {makeTable()}
                            <TableInfo
                              totalRows={state.sortedData.length}
                              page={state.page}
                              pageSize={state.pageSize}
                            />
                          </div>
                        )
                      }}
                    </ReactTable>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </Col>
        <AddOperatorCategory
          inProgress={inProgress}
          show={showAddOperatorCategory}
          onHide={closeAddOperatorCategory}
          submitAddOperatorCategory={submitAddOperatorCategory}
        />
        <EditOperatorCategory
          inProgress={inProgress}
          show={showEditOperatorCategory}
          onHide={closeEditOperatorCategory}
          submitEditOperatorCategory={submitEditOperatorCategory}
          selectedOperatorCategory={selectedOperatorCategory}
        />
        <DeleteOperatorCategory
          inProgress={inProgress}
          show={showDeleteOperatorCategory}
          onHide={closeDeleteOperatorCategory}
          submitDeleteOperatorCategory={submitDeleteOperatorCategory}
          selectedOperatorCategory={selectedOperatorCategory}
        />
      </Row>
    );
  }
}

export default OperatorCategories;