import {
  LOGIN_PAGE_LOADED,
  LOGIN,
  REGISTER,
  LOGIN_PAGE_UNLOADED,
  REGISTER_PAGE_UNLOADED,
  UPDATE_FIELD_LOGIN,
  FORGOT_PASSWORD,
  ASYNC_START, RESET_ACTION, RESET_PASSWORD,
} from '../constants/actionTypes';

export default (state = {}, action) => {
  switch (action.type) {
    case LOGIN:
      let errors = null;
      if (action.error) {
        errors = 'Error, the action could not be completed';
        if (action.payload) {
          if (action.payload.error_description)
            errors = action.payload.error_description;
          else if (action.payload.message)
            errors = action.payload.message;
        }
      }
      return {
        ...state,
        inProgress: false,
        errors
      };

    case FORGOT_PASSWORD:
      return {
        ...state,
        inProgress: false,
        errors: action.error ? action.payload.message : null,
        showForgotPassword: action.error,
        lastAction: action.error ? null : action.lastAction,
      };

    case REGISTER:
      return {
        ...state,
        inProgress: false,
        errors: action.error ? action.payload ? action.payload.message : 'Error, the action could not be completed' : null,
      };

    case LOGIN_PAGE_LOADED:
      return {
        ...state,
        showForgotPassword: false
      };

    case RESET_PASSWORD:
      return {
        ...state,
        inProgress: false,
        errors: action.error ? action.payload.message : null,
      };

    case LOGIN_PAGE_UNLOADED:
      return {};

    case REGISTER_PAGE_UNLOADED:
      return {};

    case UPDATE_FIELD_LOGIN:
      return {...state, [action.key]: action.value};

    case RESET_ACTION:
      return {
        ...state,
        lastAction: null,
        errors: null,
      };

    case ASYNC_START:
      if (action.subtype === LOGIN || action.subtype === REGISTER || action.subtype === FORGOT_PASSWORD || action.subtype === RESET_PASSWORD) {
        return {...state, inProgress: true, errors: null};
      }
      break;

    default:
      return state;
  }

  return state;
};
