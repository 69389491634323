import React from 'react';
import {Row, Col, Alert} from "react-bootstrap";
import {Link} from 'react-router-dom';

const Confirmation = (props) => {
  if (props.match.params.correct === 'correct') {
    return (
      <Row>
        <Col md={12}>
          <div className="content text-center" style={{maxWidth: '45.5rem', marginLeft: 'auto', marginRight: 'auto'}}>
            <h1 className="section-title text-center">Welcome</h1>
            <p className="fp-text pass-info mb-3 text-center">
              Successfully email confirmation
            </p>
            <Alert variant="info" className="mb-4">
              <p>Your account has been activated. Thanks for confirm your account in OurCellShop!</p>
            </Alert>
            <Link
              to="/login"
              className="btn btn-black btn-inline border-radius-4 search-button-transactions"
              style={{marginTop: '.5rem'}}
            >Continue to Login</Link>
          </div>
        </Col>
      </Row>
    );
  } else {
    return (
      <Row>
        <Col md={12}>
          <div className="content text-center" style={{maxWidth: '45.5rem', marginLeft: 'auto', marginRight: 'auto'}}>
            <h1 className="section-title text-center">Welcome</h1>
            <p className="fp-text pass-info mb-3 text-center">
              Sorry!
            </p>
            <Alert variant="warning" className="mb-4">
              <p>Something was wrong with your email confirmation. Please try to confirm again! </p>
            </Alert>
            <Link
              to="/"
              className="btn btn-black btn-inline border-radius-4 search-button-transactions"
              style={{marginTop: '.5rem'}}
            >Go to Home</Link>
          </div>
        </Col>
      </Row>
    );
  }
};

export default Confirmation;