import React, {Component} from 'react';
import {Col, Row, Form, Button, Modal} from "react-bootstrap";
import moment from 'moment';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Formik} from "formik";
import ReactTable from "react-table";
import TableInfo from '../TableInfo';

const {paymentMethod, rechargeStatus} = require('../../utils');

const BatchPaymentDetail = (props) => {
  const {batchPaymentDetail, ...rest} = props;
  return (
    <Modal
      {...rest}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-app"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="app-header">
          Batch Payment Detail
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
          batchPaymentDetail && batchPaymentDetail !== null && batchPaymentDetail.length > 0 ?
            <ReactTable
              columns={[
                {
                  Header: "Phone",
                  accessor: "phoneNumber",
                },
                {
                  Header: "Amount",
                  id: "amount",
                  accessor: d => d.amount.toFixed(2),
                },
                {
                  Header: "Cellshop Receipt",
                  id: "cellShopReceiptNumber",
                  accessor: d => d.cellShopReceiptNumber !== '' ? d.cellShopReceiptNumber : 'N/A',
                },
                {
                  Header: "External Receipt",
                  id: "externalReceiptNumber",
                  accessor: d => d.externalReceiptNumber !== '' ? d.externalReceiptNumber : 'N/A',
                },
                {
                  Header: "Confirmation Email",
                  id: "confirmationEmail",
                  accessor: d => d.confirmationEmail !== '' ? d.confirmationEmail : 'N/A',
                },
                {
                  Header: "Status",
                  id: "cubacelRechargeStatusId",
                  accessor: d => rechargeStatus(d.cubacelRechargeStatusId),
                },
                {
                  Header: "Payment Method",
                  id: "paymentMethodId",
                  accessor: d => paymentMethod(d.paymentMethodId),
                },
              ]}
              defaultSorted={[
                {
                  id: "phoneNumber",
                  desc: true
                }
              ]}
              data={batchPaymentDetail}
              pageSize={batchPaymentDetail.length}
              className="-striped -highlight"
              showPaginationBottom={false}
            /> : ''
        }
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className="btn btn-outline-dark btn-form-app border-radius-4">Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

class BatchPayments extends Component {
  updateTableData = () => this.table.fireFetchData();

  render() {
    const {
      inProgress, loadBatchPayments, batchPaymentsState, openBatchPaymentDetail, showBatchPaymentDetail,
      closeBatchPaymentDetail, batchPaymentDetail
    } = this.props;
    return (
      <Row className="batch-payments-super-container">
        <Col sm={12}>
          <div className="pl-5-desktop-batch-payments">
            <h4 className="mb-4">Batch Payments</h4>
            <Formik
              onSubmit={() => this.updateTableData()}
            >
              {props => {
                const {
                  handleSubmit,
                } = props;
                return (
                  <Form className="mt-4 mb-3" onSubmit={handleSubmit}>
                    <ReactTable
                      ref={(instance) => {
                        this.table = instance;
                      }}
                      columns={[
                        {
                          Header: "Date",
                          id: "date",
                          accessor: d => moment(d.date).format('YYYY-MM-DD'),
                          sortable: false
                        },
                        {
                          Header: "Name",
                          accessor: "name",
                          sortable: false
                        },
                        {
                          Header: "Amount",
                          id: "totalAmount",
                          accessor: d => d.totalAmount.toFixed(2),
                          sortable: false
                        },
                        {
                          Header: "Synchronized",
                          id: "synchronized",
                          accessor: d => d.synchronized ? 'true' : 'false',
                          sortable: false
                        },
                        {
                          Header: "Executed",
                          id: "executed",
                          accessor: d => d.executed ? 'true' : 'false',
                          sortable: false
                        },
                        {
                          Header: "Detail",
                          accessor: "cubacelRecharges",
                          Cell: ({value}) => (value !== '' ?
                            <Button
                              variant="link" className="btn-row border-radius-4"
                              onClick={() => openBatchPaymentDetail(value)}>
                              <FontAwesomeIcon icon="sticky-note"/>
                            </Button> : ''),
                          sortable: false
                        }
                      ]}
                      manual
                      data={batchPaymentsState.data}
                      pages={batchPaymentsState.pages}
                      loading={inProgress}
                      onFetchData={(state) => loadBatchPayments(state)}
                      defaultPageSize={batchPaymentsState.pageSize}
                      pageSizeOptions={[5, 10, 20]}
                      className="-striped -highlight"
                      minRows={0}
                      NoDataComponent={() => null}
                    >
                      {(state, makeTable) => {
                        return (
                          <div>
                            {makeTable()}
                            <TableInfo
                              totalRows={batchPaymentsState.total}
                              page={state.page}
                              pageSize={state.pageSize}
                            />
                          </div>
                        )
                      }}
                    </ReactTable>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </Col>
        <BatchPaymentDetail
          show={showBatchPaymentDetail}
          onHide={closeBatchPaymentDetail}
          batchPaymentDetail={batchPaymentDetail}
        />
      </Row>
    );
  }
}

export default BatchPayments;