import React from 'react';
import {Button, Col, Form, Modal, Row, Spinner, InputGroup, Nav, Tooltip, OverlayTrigger} from "react-bootstrap";
import {Formik} from "formik";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as Yup from "yup";
import ReactTable from "react-table";
import TableInfo from '../TableInfo';

const {roles} = require('../../utils');

const ResetDebt = (props) => {
  const {
    inProgress, selectedUser, submitResetDebtUser, ...rest
  } = props;
  return (
    <Modal
      {...rest}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-app"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="app-header">
          Reset Debt
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Label>
          Sure you want to reset the debt?
        </Form.Label>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="btn btn-form-app btn-option border-radius-4"
          type="submit"
          onClick={() => submitResetDebtUser(selectedUser.id)}
        >
          {
            inProgress ?
              <Spinner
                animation="grow"
                variant="secondary"
                style={{marginRight: '5px', marginBottom: '4px'}}
                size="sm"
              />
              : ''
          }
          Accept
          <FontAwesomeIcon icon="arrow-right" className="icon-right"/>
        </Button>
        <Button onClick={props.onHide} className="btn btn-outline-dark btn-form-app border-radius-4">Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

const EditUser = (props) => {
  const {
    submitEditUser, inProgress, selectedUser, currentEditUserTab, onChangeEditUserTab, resellerConfigurationFilterPlan,
    isSelectedUserAdmin, isSelectedUserReseller, filteredResellerConfiguration, resellerConfiguration,
    submitSetPinNumber, submitSaveCommissionConfiguration, context,
    showResetDebtUser, openResetDebtUser, closeResetDebtUser, submitResetDebtUser,
    onUpdateFilterResellerConfiguration, onSubmitSearchResellerConfiguration, onUpdateCommissionConfiguration,
    ...rest
  } = props;

  const formEditUserId = 'formEditUserId';
  const formSetPinId = 'formSetPinId';
  const formSetCommissionConfigurationId = 'formSetCommissionConfigurationId';
  let activeForm = formEditUserId;

  const loadContent = () => {
    if (currentEditUserTab === 'data') {
      activeForm = formEditUserId;
      return (
        <Formik
          initialValues={{
            id: selectedUser ? selectedUser.id : '',
            email: selectedUser ? selectedUser.email : '',
            userName: selectedUser ? selectedUser.username : '',
            firstName: selectedUser ? selectedUser.firstname : '',
            lastName: selectedUser ? selectedUser.lastname : '',
            phoneNumber: selectedUser && selectedUser.phonenumber ? selectedUser.phonenumber : '',
            password: '',
            passwordConfirm: '',
            pinNumber: '',
            confirmPinNumber: '',
            resellerPromoCode: selectedUser && selectedUser.resellerpromocode ? selectedUser.resellerpromocode : '',
            isReseller: selectedUser ? selectedUser.isreseller : '',
            rolesToAssign: selectedUser && selectedUser.roles ? selectedUser.roles : [],
            balance: selectedUser ? selectedUser.balance : '',
            unlockBalance: selectedUser ? selectedUser.unlockbalance : '',
            credit: selectedUser ? selectedUser.credit : '',
            debt: selectedUser ? selectedUser.debt : '',
            active: selectedUser ? selectedUser.active : true,
          }}
          onSubmit={(values) => submitEditUser(
            values.userName,
            values.email,
            values.firstName,
            values.lastName,
            values.phoneNumber,
            values.isReseller,
            values.rolesToAssign,
            values.balance,
            values.unlockBalance,
            values.credit,
            values.debt,
            values.active
          )}
          validationSchema={Yup.object().shape({
            email: Yup.string().required('Required').email('Invalid email'),
            userName: Yup.string().required('Required'),
            firstName: Yup.string().required('Required'),
            lastName: Yup.string().required('Required'),
            phoneNumber: Yup.string().required('Required'),
            balance: Yup.number().required('Required').typeError('Invalid number'),
            unlockBalance: Yup.number().required('Required').typeError('Invalid number'),
            credit: Yup.number().required('Required').typeError('Invalid number'),
            debt: Yup.number().required('Required').typeError('Invalid number'),
          })}
        >
          {props => {
            const {
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue
            } = props;
            return (
              <div>
                <Form id={formEditUserId} onSubmit={handleSubmit}>
                  <Row className="text-left">
                    <Col md={6} sm={12}>
                      <Form.Group>
                        <Form.Label
                          className="font-weight-bold">User Name
                        </Form.Label>
                        <Form.Control
                          className="form-input border-radius-4"
                          type="text"
                          placeholder="User Name"
                          id="userName"
                          value={values.userName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={true}
                        />
                        {errors.userName && touched.userName && (
                          <div className="error-message">{errors.userName}</div>
                        )}
                      </Form.Group>
                      <Form.Group>
                        <Form.Label
                          className="font-weight-bold">First Name
                        </Form.Label>
                        <Form.Control
                          className="form-input border-radius-4"
                          type="text"
                          placeholder="First Name"
                          id="firstName"
                          value={values.firstName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.firstName && touched.firstName && (
                          <div className="error-message">{errors.firstName}</div>
                        )}
                      </Form.Group>
                      <Form.Group>
                        <Form.Label
                          className="font-weight-bold">Last Name
                        </Form.Label>
                        <Form.Control
                          className="form-input border-radius-4"
                          type="text"
                          placeholder="Last Name"
                          id="lastName"
                          value={values.lastName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.lastName && touched.lastName && (
                          <div className="error-message">{errors.lastName}</div>
                        )}
                      </Form.Group>
                      <Form.Group>
                        <Form.Label
                          className="font-weight-bold">Email
                        </Form.Label>
                        <Form.Control
                          className="form-input border-radius-4"
                          type="text"
                          placeholder="Email"
                          id="email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.email && touched.email && (
                          <div className="error-message">{errors.email}</div>
                        )}
                      </Form.Group>
                      <Form.Group>
                        <Form.Label
                          className="font-weight-bold">Phone Number
                        </Form.Label>
                        <Form.Control
                          className="form-input border-radius-4"
                          type="text"
                          placeholder="Phone Number"
                          id="phoneNumber"
                          value={values.phoneNumber}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.phoneNumber && touched.phoneNumber && (
                          <div className="error-message">{errors.phoneNumber}</div>
                        )}
                      </Form.Group>
                      <Form.Group>
                        <Form.Label
                          className="font-weight-bold">Reseller Promo Code
                        </Form.Label>
                        <Form.Control
                          className="form-input border-radius-4"
                          type="text"
                          placeholder=""
                          id="resellerPromoCode"
                          value={values.resellerPromoCode}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={true}
                        />
                        {errors.resellerPromoCode && touched.resellerPromoCode && (
                          <div className="error-message">{errors.resellerPromoCode}</div>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                      <Form.Group>
                        <Form.Label
                          className="font-weight-bold">Current Balance
                        </Form.Label>
                        <Form.Control
                          className="form-input border-radius-4"
                          type="text"
                          placeholder="Balance"
                          id="balance"
                          value={values.balance}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.balance && touched.balance && (
                          <div className="error-message">{errors.balance}</div>
                        )}
                      </Form.Group>
                      <Form.Group>
                        <Form.Label
                          className="font-weight-bold">Unlock Balance
                        </Form.Label>
                        <Form.Control
                          className="form-input border-radius-4"
                          type="text"
                          placeholder="Unlock Balance"
                          id="unlockBalance"
                          value={values.unlockBalance}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.unlockBalance && touched.unlockBalance && (
                          <div className="error-message">{errors.unlockBalance}</div>
                        )}
                      </Form.Group>
                      <Form.Group>
                        <Form.Label
                          className="font-weight-bold">Credit
                        </Form.Label>
                        <Form.Control
                          className="form-input border-radius-4"
                          type="text"
                          placeholder="Credit"
                          id="credit"
                          value={values.credit}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.credit && touched.credit && (
                          <div className="error-message">{errors.credit}</div>
                        )}
                      </Form.Group>
                      <Form.Group>
                        <Form.Label
                          className="font-weight-bold">Debt
                        </Form.Label>
                        {selectedUser && selectedUser.debt > 0 ?
                          <InputGroup className="mb-3">
                            <Form.Control
                              className="form-input border-radius-4"
                              type="text"
                              placeholder="Debt"
                              id="debt"
                              value={values.debt}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <InputGroup.Append>
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id={`tooltipResetDebt`}>
                                    Reset Debt
                                  </Tooltip>
                                }
                              >
                                <Button className="btn btn-form-app btn-option border-radius-4"
                                        onClick={() => openResetDebtUser(selectedUser)}
                                >
                                  <FontAwesomeIcon icon="sync"/>
                                </Button>
                              </OverlayTrigger>
                            </InputGroup.Append>
                          </InputGroup>
                          :
                          <Form.Control
                            className="form-input border-radius-4"
                            type="text"
                            placeholder="Debt"
                            id="debt"
                            value={values.debt}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        }
                        {errors.debt && touched.debt && (
                          <div className="error-message">{errors.debt}</div>
                        )}
                      </Form.Group>
                      <Form.Group className="">
                        <Form.Label className="font-weight-bold">Is Reseller?</Form.Label>
                        <Form.Control
                          as="select"
                          id="isReseller"
                          className="form-input border-radius-4"
                          value={values.isReseller}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option key={0} value="false">No</option>
                          <option key={1} value="true">Yes</option>
                        </Form.Control>
                        {errors.isReseller && touched.isReseller && (
                          <div className="error-message">{errors.isReseller}</div>
                        )}
                      </Form.Group>
                      <Form.Group className="">
                        <Form.Label className="font-weight-bold">Is Active?</Form.Label>
                        <Form.Control
                          as="select"
                          id="active"
                          className="form-input border-radius-4"
                          value={values.active}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option key={0} value="false">No</option>
                          <option key={1} value="true">Yes</option>
                        </Form.Control>
                        {errors.active && touched.active && (
                          <div className="error-message">{errors.active}</div>
                        )}
                      </Form.Group>
                      <Form.Group>
                        <Form.Label
                          className="font-weight-bold">Roles
                        </Form.Label>
                        <div className="w-100">
                          {
                            roles.map((item, i) => (
                              <Form.Check
                                key={i}
                                inline label={item}
                                type="checkbox"
                                id={`role${item}`}
                                checked={values.rolesToAssign ? values.rolesToAssign.includes(item) : false}
                                onChange={e => {
                                  if (values.rolesToAssign) {
                                    if (e.target.checked) {
                                      const rolesToAssign = values.rolesToAssign;
                                      rolesToAssign.push(item);
                                      setFieldValue('rolesToAssign', rolesToAssign);
                                      if (item === 'Merchant')
                                        setFieldValue('isReseller', 'true');
                                    } else {
                                      const rolesToAssign = values.rolesToAssign;
                                      const idx = rolesToAssign.indexOf(item);
                                      setFieldValue('rolesToAssign', rolesToAssign.filter((item, i) => i !== idx));
                                    }
                                  }
                                }}
                                className="mb-2"/>
                            ))
                          }
                        </div>
                        {errors.rolesToAssign && (
                          <div className="error-message">{errors.rolesToAssign}</div>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <ResetDebt
                    inProgress={inProgress}
                    selectedUser={selectedUser}
                    show={showResetDebtUser}
                    onHide={closeResetDebtUser}
                    submitResetDebtUser={submitResetDebtUser}
                  />
                </Form>
              </div>
            );
          }}
        </Formik>
      );
    } else if (isSelectedUserAdmin && currentEditUserTab === 'pin') {
      activeForm = formSetPinId;
      return (
        <Formik
          initialValues={{
            id: selectedUser ? selectedUser.id : '',
            pinNumber: '',
            confirmPinNumber: ''
          }}
          onSubmit={(values) => submitSetPinNumber(
            values.id,
            values.pinNumber
          )}
          validationSchema={Yup.object().shape({
            pinNumber: Yup.number().required('Required').typeError('Invalid number'),
            confirmPinNumber: Yup.number().oneOf([Yup.ref('pinNumber'), null], "Pin must match").required('Required'),
          })}
        >
          {props => {
            const {
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
            } = props;
            return (
              <Form id={formSetPinId} onSubmit={handleSubmit} className="text-center mt-5 mb-4">
                <Row className="text-left">
                  <Col md={12} sm={12} style={{maxWidth: '25rem'}} className="mx-auto">
                    <Form.Group>
                      <Form.Label
                        className="font-weight-bold">Pin Number
                      </Form.Label>
                      <Form.Control
                        className="form-input border-radius-4"
                        type="password"
                        placeholder="Pin Number"
                        id="pinNumber"
                        value={values.pinNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.pinNumber && touched.pinNumber && (
                        <div className="error-message">{errors.pinNumber}</div>
                      )}
                    </Form.Group>
                    <Form.Group>
                      <Form.Label
                        className="font-weight-bold">Confirm Pin Number
                      </Form.Label>
                      <Form.Control
                        className="form-input border-radius-4"
                        type="password"
                        placeholder="Confirm Pin Number"
                        id="confirmPinNumber"
                        value={values.confirmPinNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.confirmPinNumber && touched.confirmPinNumber && (
                        <div className="error-message">{errors.confirmPinNumber}</div>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      );
    } else if (isSelectedUserReseller && currentEditUserTab === 'resellerConfiguration') {
      activeForm = formSetCommissionConfigurationId;
      return (
        <Row className="transferences-super-container">
          <Col sm={12}>
            <div className="">
              <Formik
                initialValues={{plan: resellerConfigurationFilterPlan}}
                onSubmit={() => submitSaveCommissionConfiguration(selectedUser.id, resellerConfiguration)}
              >
                {props => {
                  const {
                    touched,
                    errors,
                    handleSubmit,
                    values,
                    handleChange,
                    handleBlur
                  } = props;
                  return (
                    <div>
                      <Form className="">
                        <div className="px-4 py-4 mb-4" style={{border: '1px solid rgba(0,0,0,.1)'}}>
                          <Row className="text-left">
                            <Col sm={12} md={6}>
                              <Form.Group className="mb-0">
                                <Form.Label className="font-weight-bold">Plan</Form.Label>
                                <Form.Control
                                  className="form-input border-radius-4"
                                  type="text"
                                  id="plan"
                                  value={values.plan}
                                  onChange={(ev) => {
                                    handleChange(ev);
                                    onUpdateFilterResellerConfiguration(ev.target.value);
                                  }}
                                  onBlur={handleBlur}
                                />
                                {errors.plan && touched.plan && (
                                  <div className="error-message">{errors.plan}</div>
                                )}
                              </Form.Group>
                            </Col>
                            <Col sm={12} md={6}>
                              <Form.Group className="mb-3">
                                <Button
                                  className="btn btn-black btn-inline border-radius-4 search-button-transactions"
                                  type="submit"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    onSubmitSearchResellerConfiguration();
                                  }}
                                  disabled={inProgress}
                                >
                                  <FontAwesomeIcon icon="search" size="sm" className="o-btn-icon"/>
                                  Search
                                </Button>
                              </Form.Group>
                            </Col>
                          </Row>
                        </div>
                      </Form>
                      <Form id={formSetCommissionConfigurationId} onSubmit={handleSubmit}>
                        <ReactTable
                          columns={[
                            {
                              Header: "Name",
                              accessor: "dingProductName",
                            },
                            {
                              Header: "Min Value",
                              id: "minValue",
                              accessor: d => parseFloat(d.minValue).toFixed(2),
                            },
                            {
                              Header: "Max Value",
                              id: "maxValue",
                              accessor: d => parseFloat(d.maxValue).toFixed(2),
                            },
                            {
                              Header: "Has Commission",
                              id: "commission",
                              accessor: d => d.commission > 0 ? 'Yes' : 'No',
                            },
                            {
                              Header: "Commission",
                              accessor: "d",
                              Cell: ({row}) => (
                                <Form.Control
                                  className="border-radius-4"
                                  type="text"
                                  key={`commission-${row._original.id}`}
                                  id={`commission-${row._original.id}`}
                                  value={row._original.discount}
                                  onChange={(ev) => onUpdateCommissionConfiguration(row._original.id, ev.target.value)}
                                />
                              ),
                              sortable: false
                            },
                          ]}
                          defaultSorted={[
                            {
                              id: "dingProductName",
                              desc: false
                            }
                          ]}
                          data={filteredResellerConfiguration}
                          defaultPageSize={10}
                          pageSizeOptions={[5, 10, 20]}
                          className="-striped -highlight"
                          minRows={0}
                          NoDataComponent={() => null}
                        >
                          {(state, makeTable) => {
                            return (
                              <div>
                                {makeTable()}
                                <TableInfo
                                  totalRows={state.sortedData.length}
                                  page={state.page}
                                  pageSize={state.pageSize}
                                />
                              </div>
                            )
                          }}
                        </ReactTable>
                      </Form>
                    </div>
                  );
                }}
              </Formik>
            </div>
          </Col>
        </Row>
      );
    }
  };

  return (
    <Modal
      {...rest}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-app"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="app-header">
          {`Edit ${context === "resellers" ? 'Reseller' : 'User'} ${selectedUser ? selectedUser.username : ''}`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <Nav variant="tabs" defaultActiveKey="data"
             onSelect={ev => ev !== currentEditUserTab ? onChangeEditUserTab(ev) : null}
        >
          <Nav.Item>
            <Nav.Link eventKey="data" className="nav-link-edit-user">Data</Nav.Link>
          </Nav.Item>
          {isSelectedUserAdmin ?
            <Nav.Item>
              <Nav.Link eventKey="pin" className="nav-link-edit-user">Pin Number</Nav.Link>
            </Nav.Item> : ''}
          {isSelectedUserReseller ?
            <Nav.Item>
              <Nav.Link eventKey="resellerConfiguration" className="nav-link-edit-user">Reseller
                Configuration</Nav.Link>
            </Nav.Item> : ''}
        </Nav>
        <div style={{marginTop: '2rem'}}>
          {loadContent()}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn btn-form-app btn-option border-radius-4" type="submit" form={activeForm}
                disabled={inProgress === true}>
          {
            inProgress ?
              <Spinner
                animation="grow"
                variant="secondary"
                style={{marginRight: '5px', marginBottom: '4px'}}
                size="sm"
              />
              : ''
          }
          Accept
          <FontAwesomeIcon icon="arrow-right" className="icon-right"/>
        </Button>
        <Button onClick={props.onHide} className="btn btn-outline-dark btn-form-app border-radius-4">Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditUser;