import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {connect} from 'react-redux';
import {Formik} from 'formik';
import * as Yup from 'yup';

import loginImage from '../../assets/img/login/login.png';

import Facebook from '../Login/Facebook/Facebook';
import Google from '../Login/Google/Google';

import {
  LOGIN,
  LOGIN_PAGE_UNLOADED,
  REGISTER,
  RESET_ACTION,
  FORGOT_PASSWORD,
  UPDATE_FIELD_LOGIN,
  RESET_PASSWORD,
} from '../../constants/actionTypes';
import agent from '../../agent';

import './LoginUX.css'

import Message from '../Message'

import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';
//import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import emailImage from "../../assets/img/icons/baseline-email-24px.svg";
import lockImage from "../../assets/img/icons/baseline-lock-24px.svg";
import {Col, Form, Modal, Row, Spinner, Alert, Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const theme = createMuiTheme({
  palette: {
    primary: {main: '#000'},
    secondary: {main: '#FFF'},
  },
  typography: {useNextVariants: true}
});

const ForgotPassword = (props) => {
  const {submitForgotPassword, inProgress, ...rest} = props;
  const formForgotPasswordId = 'formForgotPasswordId';
  return (
    <Modal
      {...rest}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-app"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="app-header">
          Forgot your password?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <Formik
          initialValues={{
            email: '',
          }}
          onSubmit={(values) => submitForgotPassword(
            values.email,
          )}
          validationSchema={Yup.object().shape({
            email: Yup.string().required('Required')
          })}
        >
          {props => {
            const {
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              handleSubmit
            } = props;
            return (
              <Form id={formForgotPasswordId} onSubmit={handleSubmit}>
                <Row className="text-left">
                  <Col md={12} sm={12}>
                    <Form.Group className="mb-4">
                      <Form.Label className="font-weight-bold">Enter your email address</Form.Label>
                      <Form.Control
                        className="form-input border-radius-4"
                        type="text"
                        placeholder="Email"
                        id="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.email && touched.email && (
                        <div className="error-message">{errors.email}</div>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Alert variant="info">
                  You will receive an email explaining what to do.
                </Alert>
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn btn-form-app btn-option border-radius-4" type="submit" form={formForgotPasswordId}>
          {
            inProgress ?
              <Spinner
                animation="grow"
                variant="secondary"
                style={{marginRight: '5px', marginBottom: '4px'}}
                size="sm"
              />
              : ''
          }
          Send me instructions
          <FontAwesomeIcon icon="arrow-right" className="icon-right"/>
        </Button>
        <Button onClick={props.onHide} className="btn btn-outline-dark btn-form-app border-radius-4">Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = state => ({...state.auth, ...state.common});

const mapDispatchToProps = dispatch => ({
  onSubmit: (username, password) =>
    dispatch({type: LOGIN, payload: agent.Auth.login(username, password)}),
  onUnload: () =>
    dispatch({type: LOGIN_PAGE_UNLOADED}),
  onResetAuth: () =>
    dispatch({type: RESET_ACTION}),
  onSubmitForgotPassword: (email) => {
    const payload = agent.Profile.forgotPasswordNew({email});
    dispatch({type: FORGOT_PASSWORD, payload, lastAction: FORGOT_PASSWORD})
  },
  onToggleForgotPassword: value =>
    dispatch({type: UPDATE_FIELD_LOGIN, key: 'showForgotPassword', value}),
});

class Login extends Component {
  constructor(props) {
    super(props);

    this.openForgotPassword = ev => {
      ev.preventDefault();
      this.props.onToggleForgotPassword(true)
    };
    this.closeForgotPassword = ev => this.props.onToggleForgotPassword(false);
  }

  componentWillUnmount() {
    this.props.onUnload();
  }

  render() {
    const {inProgress, errors, onSubmit, onResetAuth, onSubmitForgotPassword, showForgotPassword, lastAction} = this.props;
    return (
      <div className="login-ui-super-container">
        <div className="col-12">
          {
            errors && errors !== null ?
              <Message type="error" text={errors}/>
              : lastAction === REGISTER ?
              <Message
                type="success"
                text={'Welcome to Our Cell Shop! Please check your email to activate your account'}
                onClose={onResetAuth}
              />
              : lastAction === FORGOT_PASSWORD ?
                <Message
                  type="success"
                  text={'Email sent'}
                  onClose={onResetAuth}
                />
                : lastAction === RESET_PASSWORD ?
                  <Message
                    type="success"
                    text={'Password reset successfully'}
                    onClose={onResetAuth}
                  />
                  : ''
          }
          <Formik
            initialValues={{username: '', password: ''}}
            onSubmit={(values) => onSubmit(values.username, values.password)}
            validationSchema={Yup.object().shape({
              username: Yup.string().required('Required'),
              password: Yup.string().required('Required'),
            })}
          >
            {props => {
              const {
                values,
                touched,
                errors,
                handleChange,
                handleBlur,
                handleSubmit
              } = props;
              return (
                <div className="row login-ui-super-container-2">
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <img className="new-login-image" src={loginImage} alt=""/>
                  </div>

                  <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div className="col-12 welcome-back-header">
                      <p>Welcome Back!</p>
                    </div>
                    <div className="col-12 login-your-account-header">
                      <p>Login to your account</p>
                    </div>
                    <div>
                      <MuiThemeProvider theme={theme}>
                        <form className="login-ui-form" noValidate autoComplete="off" onSubmit={handleSubmit}>
                          < div className="login-user-password">
                            <div className="login-user-password-fields">
                              <div className="login-input-field col-12">
                                <div className="col-1 icon-div-container">
                                  {/* <i className="material-icons">email</i> */}
                                  <img src={emailImage} alt=""/>
                                </div>
                                <div className="col-11 input-div-container">
                                  <TextField
                                    required
                                    id="username"
                                    label="User or Email"
                                    className="input-email"
                                    margin="normal"
                                    type="text"
                                    value={values.username}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  {errors.username && touched.username && (
                                    <div className="error-message">{errors.username}</div>
                                  )}
                                </div>
                              </div>
                              <div className="login-input-field col-12">
                                <div className="col-1 icon-div-container">
                                  {/* <i className="material-icons">lock</i> */}
                                  <img src={lockImage} alt=""/>
                                </div>
                                <div className="col-11 input-div-container">
                                  <TextField
                                    required
                                    type="password"
                                    id="password"
                                    label="Password"
                                    className="input-password"
                                    margin="normal"
                                    value={values.password}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  {errors.password && touched.password && (
                                    <div className="error-message">{errors.password}</div>
                                  )}
                                </div>
                              </div>
                              <br/>
                            </div>
                            <div className="login-user-password-submit">
                              {/*<Button
                                className="login-button"
                                color="secondary"
                                type="submit"
                              >Login</Button>*/}
                              <Button
                                className="btn btn-form-app btn-black btn-inline border-radius-4 w-100 mt-0"
                                type="submit"
                                disabled={inProgress}>
                                {
                                  inProgress ?
                                    <Spinner
                                      animation="grow"
                                      variant="secondary"
                                      style={{marginRight: '5px', marginBottom: '4px'}}
                                      size="sm"
                                    />
                                    : ''
                                }
                                Login
                              </Button>
                            </div>
                          </div>
                        </form>
                      </MuiThemeProvider>
                    </div>
                    <div className="text-center">
                      <p className="color-dark-gray login-with-your-email-p">Or</p>
                    </div>
                    <div className="facebook-container-in-login-ui">
                      <Facebook title="login"/>
                    </div>
                    <div className="mb-50 google-content-login-ux">
                      <Google title="login"/>
                    </div>
                    <div className="login-input-field col-12">
                      <div className="mb-3 fp-text">By login you confirm that you read and confirm our
                        <Link to="/" className="fp-link font-weight-bold"> Terms and Conditions</Link>
                      </div>
                    </div>
                    <div className="login-input-field col-12">
                      <div className="mb-3 fp-text">
                        <Link to="/" className="fp-link font-weight-bold" onClick={this.openForgotPassword}>I've
                          Forgotten My Password</Link>
                      </div>
                    </div>
                    <div className="login-input-field col-12">
                      <div className="mb-3 fp-text">
                        <Link to="/register" className="fp-link font-weight-bold">Create an Account</Link>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }}
          </Formik>
        </div>
        <ForgotPassword
          submitForgotPassword={onSubmitForgotPassword}
          inProgress={inProgress}
          show={showForgotPassword}
          onHide={this.closeForgotPassword}
        />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);