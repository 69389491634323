const paymentMethods = {BALANCE: 1, DEBIT_CREDIT: 4, PAYPAL: 5};

const roles = ['Admin', 'Merchant', 'SuperUser', 'User'];

const userTypes = {AFFILIATE: 'AFFILIATE', USER: 'USER', SUPERUSER: 'SUPERUSER'};

const urlBase = 'https://cellshopapi.azurewebsites.net';

const urlImage = 'https://cellshopstorage.blob.core.windows.net/cellshop-images';

const rechargeStatus = (id) => {
  let result = 'Undefined';
  if (id === 1)
    result = 'Pending';
  else if (id === 2)
    result = 'Successful';
  else if (id === 3)
    result = 'Failed';
  return result;
};

const paymentMethod = (id) => {
  let result = 'Undefined';
  if (id === 1)
    result = 'Balance';
  else if (id === 4)
    result = 'Credit';
  else if (id === 5)
    result = 'Paypal';
  return result;
};

const identificationType = (id) => {
  let result = 'Undefined';
  if (id === 1)
    result = 'Balance';
  else if (id === 4)
    result = 'Credit';
  else if (id === 5)
    result = 'Paypal';
  return result;
};

const getUserCommissions = (userCommissions, products) => {
  let commissions = [];
  if (userCommissions.length > 0 && products.length > 0) {
    for (let i = 0; i < userCommissions.length; i++) {
      for (let j = 0; j < products.length; j++) {
        if (userCommissions[i].dingProductId === products[j].productId) {
          let obj = products[j];
          obj.commission = userCommissions[i].commission;
          commissions.push(obj);
          break;
        }
      }
    }
  }
  return commissions;
};

module.exports = {
  paymentMethods,
  urlBase,
  urlImage,
  rechargeStatus,
  paymentMethod,
  getUserCommissions,
  userTypes,
  identificationType,
  roles,
};
