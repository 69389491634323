import superagentPromise from 'superagent-promise';
import _superagent from 'superagent';
import config from "./config";

const superagent = superagentPromise(_superagent, global.Promise);

//const API_ROOT = 'http://localhost:12832';

//const API_ROOT = 'https://staging.cellshopapi.com';

const API_ROOT = 'https://cellshopapi.com';


//const API_ROOT = 'https://staging-cellshopapi.azurewebsites.net';

//const encode = encodeURIComponent;
const responseBody = res => ({
  header: res.header,
  body: res.body
});

let token = null;
const tokenPlugin = req => {
  if (token) {
    req.set('authorization', `Bearer ${token}`);
  }
};

const requests = {
  del: url =>
    superagent.del(`${API_ROOT}${url}`).use(tokenPlugin).then(responseBody),
  get: url =>
    superagent.get(`${API_ROOT}${url}`).use(tokenPlugin).then(responseBody),
  put: (url, body) =>
    superagent.put(`${API_ROOT}${url}`, body).use(tokenPlugin).then(responseBody),
  post: (url, body) =>
    superagent.post(`${API_ROOT}${url}`, body).use(tokenPlugin).then(responseBody),
  patch: (url, body) =>
    superagent.patch(`${API_ROOT}${url}`, body).use(tokenPlugin).then(responseBody),
};

const Auth = {
  current: () =>
    requests.get('/api/accounts/user'),
  login: (username, password) =>
    requests.post('/oauth/token', Object.entries({username, password, grant_type: 'password'})
      .map(e => e.join('=')).join('&')),
  isLoggedIn: () =>
    token !== null,
};

/*const Role = {
  getAll: () =>
    requests.get('/api/roles'),
};*/

const User = {
  register: (payload) =>
    requests.post('/api/accounts/register', payload),
  registerWithRoles: (payload) =>
    requests.post('/api/accounts/registerWithRoles', payload),
  checkPinNumber: (user, pin) =>
    requests.post(`/api/accounts/user/${user.id}/checkPinNumber`, {pinNumber: pin}),
  getByUsername: (username) =>
    requests.get(`/api/accounts/user/${username}`),
  update: (user) =>
    requests.put(`/api/accounts/user/update/${user.id}`, user),
  updateWithRoles: (id, payload) =>
    requests.put(`/api/accounts/user/updateWithRoles/${id}`, payload),
  updatePinNumber: (id, payload) =>
    requests.post(`/api/accounts/user/${id}/setPinNumber`, payload),
  resetDebt: (id) =>
    requests.post(`/api/accounts/user/${id}/resetDebt`, {userId: id, amount: 0, note: ''}),
  delete: (id) =>
    requests.del(`/api/accounts/user/${id}`),
  commissionConfiguration: (id) =>
    requests.get(`/api/dingProductRate?appUserId=${id}&all=true`),
  setCommissionConfiguration: (payload) =>
    requests.put(`/api/dingProductRate`, payload),
  /*update: (id, payload) =>
    requests.put(`/api/accounts/user/update/${id}`, payload),*/
  /*rolesInUser: (user) =>
    requests.get(`/api/roles/RolesInUser?id=${user.id}`),*/
  /*createReseller: (user) =>
    requests.get(`/api/accounts/user/${user.id}/createReseller`),*/
  /*setRoles: (user, roles) =>
    requests.put(`/api/accounts/user/${user.id}/roles`, roles),*/
  /*deleteDingProductRate: (id) =>
    requests.del(`/api/dingProductRate/delete/user/${id}`),*/
};

const Country = {
  getAll: () =>
    requests.get('/api/country'),
  add: (payload) =>
    requests.post('/api/country', payload),
  update: (id, payload) =>
    requests.put(`/api/country/${id}`, payload),
  delete: (id) =>
    requests.del(`/api/country/${id}`),
};

const Profile = {
  transferences: (user, page, pageSize, sort, ascending, fromDate, toDate) => {
    let url = `/api/accounts/user/${user.id}/getTransfers?page=${page}&pageSize=${pageSize}&sort=${sort}&ascending=${ascending}`;
    if (fromDate && fromDate !== '')
      url += `&fromDate=${fromDate}`;
    if (toDate && toDate !== '')
      url += `&toDate=${toDate}`;
    return requests.get(url);
  },
  transactions: (user, page, pageSize, sort, ascending, fromDate, toDate, phoneNumber, service) => {
    let url = `/api/transaction?profile=true&page=${page}&pageSize=${pageSize}&userName=${user.userName}&sort=${sort}&ascending=${ascending}`;
    if (fromDate && fromDate !== '')
      url += `&fromDate=${fromDate}`;
    if (toDate && toDate !== '')
      url += `&toDate=${toDate}`;
    if (phoneNumber && phoneNumber !== '')
      url += `&phoneNumber=${phoneNumber}`;
    let serviceType = 'all';
    if (service && service !== '' && (service === 'standard' || service === 'commission')) {
      serviceType = service;
    }
    url += `&service=${serviceType}`;
    return requests.get(url);
  },
  transactionsSummary: (user, page, pageSize, sort, ascending, fromDate, toDate) => {
    let url = `/api/transaction/summary?userLogged=true&page=${page}&pageSize=${pageSize}&superUser=${user.userName}&sort=${sort}&ascending=${ascending}`;
    if (fromDate && fromDate !== '')
      url += `&fromDate=${fromDate}`;
    if (toDate && toDate !== '')
      url += `&toDate=${toDate}`;
    return requests.get(url);
  },
  batchPayments: (user, page, pageSize) =>
    requests.get(`/api/lotPayment?appUserId=${user.id}&page=${page}&pageSize=${pageSize}`),
  paymentNotifications: (user, page, pageSize) =>
    requests.get(`/api/accounts/user/${user.id}?fields=paymentNotifications&page=${page}&pageSize=${pageSize}`),
  resellerInfo: (user) =>
    requests.get(`/api/accounts/user/commission/${user.id}`),
  commissions: (user) =>
    requests.get(`/api/dingProductRate?appUserId=${user.id}&all=true`),
  dingProducts: () =>
    requests.get('/api/dingProductList'),
  referrals: (user) =>
    requests.get(`/api/accounts/user/${user.id}/referrals`),
  resetDebt: (user, payload) =>
    requests.post(`/api/accounts/user/${user.id}/resetDebt`, payload),
  transferReferral: (user, payload) =>
    requests.post(`/api/accounts/user/transferCredit/${user.id}`, payload),
  affiliates: (user, page, pageSize, username) => {
    let url = `/api/accounts/user/${user.id}/affiliates?page=${page}&pageSize=${pageSize}`;
    if (username && username !== '')
      url += `&username=${username}`;
    return requests.get(url);
  },
  sendMessage: (payload) =>
    requests.post('/api/message', payload),
  makeTransference: (user, payload) =>
    requests.post(`/api/accounts/user/${user.id}/balance`, payload),
  getUserById: (id) =>
    requests.get(`/api/accounts/user/${id}`),
  getUserByEmail: (email) =>
    requests.post('/api/accounts/user/email/search', email),
  /*forgotPassword: (email) =>
    requests.post('/api/accounts/GeneratePasswordResetLink', email),*/
  forgotPasswordNew: (email) =>
    requests.post('/api/accounts/GeneratePasswordResetLinkNew', email),
  /*resetPassword: (payload) =>
    requests.post('/api/accounts/PasswordResetPost', payload),*/
  resetPasswordNew: (payload) =>
    requests.post('/api/accounts/PasswordResetPostNew', payload),
};

const Payment = {
  receive: (payload) =>
    requests.post('/api/accounts/user/payment/amount', payload),
  make: (payload) =>
    requests.post('/api/accounts/user/submitMultiplePayment', payload),
};

const PaymentMethod = {
  getAll: () =>
    requests.get('/api/payment'),
  add: (payload) =>
    requests.post('/api/payment', payload),
  delete: (id) =>
    requests.del(`/api/payment/${id}`),
  update: (id, payload) =>
    requests.put(`/api/payment/${id}`, payload),
};

const PaymentApi = {
  getAll: () =>
    requests.get('/api/paymentApi'),
  add: (payload) =>
    requests.post('/api/paymentApi', payload),
  delete: (id) =>
    requests.del(`/api/paymentApi/${id}`),
  update: (id, payload) =>
    requests.put(`/api/paymentApi/${id}`, payload),
};

const OperatorCategory = {
  getAll: () =>
    requests.get('/api/operatorCategory'),
  add: (payload) =>
    requests.post('/api/operatorCategory', payload),
  delete: (id) =>
    requests.del(`/api/operatorCategory/${id}`),
  update: (id, payload) =>
    requests.put(`/api/operatorCategory/${id}`, payload),
};

const Provider = {
  categories: () =>
    requests.get('/api/operatorCategory'),
  getEnabled: () =>
    requests.get('/api/provider?active=true'),
  getAll: () =>
    requests.get('/api/provider'),
  getById: (id) =>
    requests.get(`/api/provider/${id}`),
  add: (payload) =>
    requests.post('/api/provider', payload),
  delete: (id) =>
    requests.del(`/api/provider/${id}`),
  update: (id, payload) =>
    requests.put(`/api/provider/${id}`, payload),
};

const Promotions = {
  getAll: () =>
    requests.get('/api/ding/promotions')
};

const Admin = {
  users: (page, pageSize, sort, ascending, user) => {
    let url = `/api/accounts/users?fields=id,email,emailConfirmed,phoneNumber,userName,firstName,superUserId,isReseller,lastName,joinDate,balance,roleId,totalPayments,unlockBalance,superUserName,credit,resellerPromoCode,debt,roles,active&page=${page}&pageSize=${pageSize}&sort=${sort}&ascending=${ascending}`;
    if (user && user !== '')
      url += `&userName=${user}`;
    return requests.get(url);
  },
  homeBanner: (imageBase64) => {
    let url = `/api/accounts/uploadFile`;
    return requests.post(url, {fileData: imageBase64});
  },
  transactions: (page, pageSize, sort, ascending, service, fromDate, toDate, phoneNumber, userName, superUser, successfulStatus) => {
    let url = `/api/transaction?page=${page}&pageSize=${pageSize}&sort=${sort}&ascending=${ascending}`;
    let serviceType = 'all';
    if (service && service !== '' && (service === 'standard' || service === 'commission')) {
      serviceType = service;
    }
    url += `&service=${serviceType}`;
    if (fromDate && fromDate !== '')
      url += `&fromDate=${fromDate}`;
    if (toDate && toDate !== '')
      url += `&toDate=${toDate}`;
    if (phoneNumber && phoneNumber !== '')
      url += `&phoneNumber=${phoneNumber}`;
    if (userName && userName !== '')
      url += `&userName=${userName}`;
    if (superUser && superUser !== '')
      url += `&superUser=${superUser}`;
    if (successfulStatus && successfulStatus !== '' && successfulStatus !== 'all')
      url += `&successfulStatus=${successfulStatus}`;
    return requests.get(url);
  },
  transferences: (page, pageSize, sort, ascending, fromDate, toDate, fromUserName, toUserName) => {
    let url = `/api/transfer?page=${page}&pageSize=${pageSize}&sort=${sort}&ascending=${ascending}`;
    if (fromDate && fromDate !== '')
      url += `&fromDate=${fromDate}`;
    if (toDate && toDate !== '')
      url += `&toDate=${toDate}`;
    if (fromUserName && fromUserName !== '')
      url += `&fromUserName=${fromUserName}`;
    if (toUserName && toUserName !== '')
      url += `&toUserName=${toUserName}`;
    return requests.get(url);
  },
  paymentNotifications: (page, pageSize, fromDate, toDate, userName) => {
    let url = `/api/paymentNotification?page=${page}&pageSize=${pageSize}`;
    if (fromDate && fromDate !== '')
      url += `&fromDate=${fromDate}`;
    if (toDate && toDate !== '')
      url += `&toDate=${toDate}`;
    if (userName && userName !== '')
      url += `&userName=${userName}`;
    return requests.get(url);
  },
  lotPayments: (page, pageSize, sort, ascending, userName) => {
    let url = `/api/lotPaymentAdmin?page=${page}&pageSize=${pageSize}&sort=${sort}&ascending=${ascending}`;
    if (userName && userName !== '')
      url += `&userName=${userName}`;
    return requests.get(url);
  },
  transactionsSummary: (page, pageSize, sort, ascending, fromDate, toDate, userName, superUser) => {
    let url = `/api/transaction/summary?page=${page}&pageSize=${pageSize}&sort=${sort}&ascending=${ascending}`;
    if (fromDate && fromDate !== '')
      url += `&fromDate=${fromDate}`;
    if (toDate && toDate !== '')
      url += `&toDate=${toDate}`;
    if (userName && userName !== '')
      url += `&userName=${userName}`;
    if (superUser && superUser !== '')
      url += `&superUser=${superUser}`;
    return requests.get(url);
  },
  simActivationsSummary: (page, pageSize, sort, ascending, fromDate, toDate, userName) => {
    let url = `/api/activation/simActivations?page=${page}&pageSize=${pageSize}&sort=${sort}&ascending=${ascending}`;
    if (fromDate && fromDate !== '')
      url += `&fromDate=${fromDate}`;
    if (toDate && toDate !== '')
      url += `&toDate=${toDate}`;
    if (userName && userName !== '')
      url += `&userName=${userName}`;
    return requests.get(url);
  },
  nautaRecharges: (page, pageSize, sort, ascending, userName) => {
    let url = `/api/nautaRecharge?page=${page}&pageSize=${pageSize}&sort=${sort}&ascending=${ascending}`;
    if (userName && userName !== '')
      url += `&userName=${userName}`;
    return requests.get(url);
  },
  resellers: () =>
    requests.get('/api/accounts/user/resellers?reseller=true&fields=id,email,emailConfirmed,phoneNumber,userName,firstName,superUserId,isReseller,lastName,joinDate,balance,roleId,totalPayments,transactions,unlockBalance,superUserName,credit,resellerPromoCode,debt,roles,active'),
  resellerCommission: () =>
    requests.get('/api/resellerCommission'),
  setCommission: (id, payload) =>
    requests.patch(`/api/ResellerCommission/${id}`, payload),
  plans: () =>
    requests.get('/api/dingProductList'),
  addResellerCommission: (payload) =>
    requests.post('/api/resellerCommission', payload),
  deleteResellerCommission: (id) =>
    requests.del(`/api/resellerCommission/${id}`),
  providers: () =>
    requests.get('/api/provider'),
};

export default {
  Auth,
  //Role,
  User,
  Country,
  Profile,
  Provider,
  Payment,
  PaymentMethod,
  PaymentApi,
  OperatorCategory,
  Promotions,
  Admin,
  setToken: _token => {
    token = _token;
  }
};