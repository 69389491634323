import React, {Component} from 'react';
import ReactTable from "react-table";
import "react-table/react-table.css";
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import {Formik} from "formik";
import moment from 'moment';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Datepicker} from "react-formik-ui";
import * as Yup from "yup";
import TableInfo from '../TableInfo';

const {identificationType} = require('../../utils');

const SimActivationSummaryDetail = (props) => {
  const {selectedSimActivationSummary, ...rest} = props;
  return (
    <Modal
      {...rest}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-app"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="app-header">
          Detail
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        {
          selectedSimActivationSummary ?
            <Row>
              <Col sm={12} md={6}>
                <Row>
                  <Col sm={12}>
                    <Form.Label className="font-weight-bold">Username:</Form.Label>&nbsp;
                    <Form.Label>{selectedSimActivationSummary.applicationUserName}</Form.Label>
                  </Col>
                  <Col sm={12}>
                    <Form.Label className="font-weight-bold">Start Date:</Form.Label>&nbsp;
                    <Form.Label>{moment(selectedSimActivationSummary.startedUtc).format('YYYY-MM-DD HH:mm')}</Form.Label>
                  </Col>
                  <Col sm={12}>
                    <Form.Label className="font-weight-bold">End Date:</Form.Label>&nbsp;
                    <Form.Label>{moment(selectedSimActivationSummary.completedUtc).format('YYYY-MM-DD HH:mm')}</Form.Label>
                  </Col>
                  <Col sm={12}>
                    <Form.Label className="font-weight-bold">Identification Type:</Form.Label>&nbsp;
                    <Form.Label>{identificationType(selectedSimActivationSummary.identificationTypeId)}</Form.Label>
                  </Col>
                  <Col sm={12}>
                    <Form.Label className="font-weight-bold">Client Id:</Form.Label>&nbsp;
                    <Form.Label>{selectedSimActivationSummary.clientId}</Form.Label>
                  </Col>
                  <Col sm={12}>
                    <Form.Label className="font-weight-bold">Client Name:</Form.Label>&nbsp;
                    <Form.Label>{selectedSimActivationSummary.clientName}</Form.Label>
                  </Col>
                </Row>
              </Col>
              <Col sm={12} md={6}>
                <Row>
                  <Col sm={12}>
                    <Form.Label className="font-weight-bold">Product Code:</Form.Label>&nbsp;
                    <Form.Label>{selectedSimActivationSummary.skuCode}</Form.Label>
                  </Col>
                  <Col sm={12}>
                    <Form.Label className="font-weight-bold">Value:</Form.Label>&nbsp;
                    <Form.Label>{selectedSimActivationSummary.sendValue.toFixed(2)}</Form.Label>
                  </Col>
                  <Col sm={12}>
                    <Form.Label className="font-weight-bold">Transfer Reference:</Form.Label>&nbsp;
                    <Form.Label>{selectedSimActivationSummary.transferRef}</Form.Label>
                  </Col>
                  <Col sm={12}>
                    <Form.Label className="font-weight-bold">State:</Form.Label>&nbsp;
                    <Form.Label>{selectedSimActivationSummary.processingState}</Form.Label>
                  </Col>
                  <Col sm={12}>
                    <Form.Label className="font-weight-bold">Commercial Office:</Form.Label>&nbsp;
                    <Form.Label>{selectedSimActivationSummary.commercialOfficeName}</Form.Label>
                  </Col>
                  <Col sm={12}>
                    <Form.Label className="font-weight-bold">Province:</Form.Label>&nbsp;
                    <Form.Label>{selectedSimActivationSummary.provinceName}</Form.Label>
                  </Col>
                </Row>
              </Col>
              <Col sm={12} md={12}>
                <Row>
                  <Col sm={12}>
                    <Form.Label className="font-weight-bold">Receipt Text:</Form.Label>&nbsp;
                    <Form.Control
                      as="textarea"
                      style={{fontSize: 'inherit'}}
                      rows="6"
                      disabled={true}
                      value={selectedSimActivationSummary.receiptText}
                    />
                  </Col>
                </Row>
              </Col>
            </Row> : ''
        }
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className="btn btn-outline-dark btn-form-app border-radius-4">Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

class Summary extends Component {
  updateTableData = () => this.table.fireFetchData();

  render() {
    const {
      inProgress, loadTransactionsSummary, transactionsSummaryState, transactionsSummaryTotalColumns,
      //inProgressSecondary, loadSimActivationsSummary, simActivationsSummaryState,
      selectedSimActivationSummary, /*openSimActivationSummaryDetail,*/ showSimActivationSummaryDetail, closeSimActivationSummaryDetail,
    } = this.props;
    return (
      <Row className="transferences-super-container">
        <Col sm={12}>
          <div className="pl-5-desktop-transferences">
            <h4 className="mb-4">Summary</h4>

            <Formik
              initialValues={{
                fromDate: moment().subtract(1, 'month'),
                toDate: moment(),
                userName: '',
                superUser: '',
              }}
              onSubmit={(values) => this.updateTableData(
                values.fromDate,
                values.toDate,
                values.userName,
                values.superUser
              )}
              validationSchema={Yup.object().shape({
                fromDate: Yup.date().typeError('Invalid date'),
                toDate: Yup.date().typeError('Invalid date'),
              })}
            >
              {props => {
                const {
                  touched,
                  errors,
                  handleSubmit,
                  values,
                  handleChange,
                  handleBlur
                } = props;
                return (
                  <Form className="mt-4" onSubmit={handleSubmit}>
                    <div className="px-4 py-4 mb-4" style={{border: '1px solid rgba(0,0,0,.1)'}}>
                      <Row className="text-left">
                        <Col sm={12} md={4}>
                          <Form.Label className="font-weight-bold">From</Form.Label>
                          <Form.Group className="mb-4">
                            <Datepicker
                              className="form-input form-control border-radius-4"
                              name="fromDate"
                              placeholder="From"
                              dateFormat="yyyy-MM-dd"
                            />
                            {errors.fromDate && touched.fromDate && (
                              <div className="error-message">{errors.fromDate}</div>
                            )}
                          </Form.Group>
                        </Col>
                        <Col sm={12} md={4}>
                          <Form.Label className="font-weight-bold">To</Form.Label>
                          <Form.Group className="mb-4">
                            <Datepicker
                              className="form-input form-control border-radius-4"
                              name="toDate"
                              placeholder="To"
                              dateFormat="yyyy-MM-dd"
                            />
                            {errors.toDate && touched.toDate && (
                              <div className="error-message">{errors.toDate}</div>
                            )}
                          </Form.Group>
                        </Col>
                        <Col sm={12} md={4}>
                          <Form.Label className="font-weight-bold">User</Form.Label>
                          <Form.Group className="mb-4">
                            <Form.Control
                              className="form-input border-radius-4"
                              type="text"
                              id="userName"
                              value={values.userName}
                              placeholder="User"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.userName && touched.userName && (
                              <div className="error-message">{errors.userName}</div>
                            )}
                          </Form.Group>
                        </Col>
                        <Col sm={12} md={4}>
                          <Form.Label className="font-weight-bold">Super User</Form.Label>
                          <Form.Group className="mb-4">
                            <Form.Control
                              className="form-input border-radius-4"
                              type="text"
                              id="superUser"
                              value={values.superUser}
                              placeholder="Super User"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.superUser && touched.superUser && (
                              <div className="error-message">{errors.superUser}</div>
                            )}
                          </Form.Group>
                        </Col>
                        <Col sm={12} md={4}>
                          <Form.Group className="mb-3">
                            <Button
                              className="btn btn-black btn-inline border-radius-4 search-button-transactions"
                              type="submit"
                              disabled={inProgress}
                            >
                              <FontAwesomeIcon icon="search" size="sm" className="o-btn-icon"/>
                              Search
                            </Button>
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                    <h4 className="mb-4">Transactions</h4>
                    <ReactTable
                      ref={(instance) => {
                        this.table = instance;
                      }}
                      columns={[
                        {
                          Header: "First Name",
                          accessor: "firstName",
                        },
                        {
                          Header: "Last Name",
                          accessor: "lastName",
                        },
                        {
                          Header: "Username",
                          accessor: "userName",
                          Footer: (<strong>Totals:</strong>)
                        },
                        {
                          Header: "Successfull",
                          accessor: "totalTransactionsSuccessfull",
                          Footer: (
                            <strong>
                              {transactionsSummaryTotalColumns ? transactionsSummaryTotalColumns.allTotalTransactionsSuccessfull : ''}
                            </strong>),
                        },
                        {
                          Header: "Failed",
                          accessor: "totalTransactionsFailed",
                          Footer: (
                            <strong>
                              {transactionsSummaryTotalColumns ? transactionsSummaryTotalColumns.allTotalTransactionsFailed : ''}
                            </strong>),
                        },
                        {
                          Header: "Balance",
                          id: "currentBalance",
                          accessor: d => d.currentBalance.toFixed(2),
                          Footer: (
                            <strong>
                              {transactionsSummaryTotalColumns ? transactionsSummaryTotalColumns.totalCurrentBalance.toFixed(2) : ''}
                            </strong>),
                        },
                        {
                          Header: "Spent Amount",
                          id: "spentAmount",
                          accessor: d => d.spentAmount.toFixed(2),
                          Footer: (
                            <strong>
                              {transactionsSummaryTotalColumns ? transactionsSummaryTotalColumns.totalSpentAmount.toFixed(2) : ''}
                            </strong>),
                        },
                        {
                          Header: "Credit",
                          id: "credit",
                          accessor: d => d.credit.toFixed(2),
                          Footer: (
                            <strong>
                              {transactionsSummaryTotalColumns ? transactionsSummaryTotalColumns.totalCredit.toFixed(2) : ''}
                            </strong>),
                        },
                        {
                          Header: "Debt",
                          id: "debt",
                          accessor: d => d.debt.toFixed(2),
                          Footer: (
                            <strong>
                              {transactionsSummaryTotalColumns ? transactionsSummaryTotalColumns.totalDebt.toFixed(2) : ''}
                            </strong>),
                        },
                        {
                          Header: "Super User",
                          accessor: "superUserUsername",
                        },
                      ]}
                      defaultSorted={[
                        {
                          id: "firstName",
                          desc: true
                        },
                      ]}
                      manual
                      data={transactionsSummaryState.data}
                      pages={transactionsSummaryState.pages}
                      loading={inProgress}
                      onFetchData={(state) => loadTransactionsSummary(state)}
                      defaultPageSize={transactionsSummaryState.pageSize}
                      pageSizeOptions={[5, 10, 20]}
                      className="-striped -highlight"
                      filter={{
                        fromDate: values.fromDate,
                        toDate: values.toDate,
                        userName: values.userName,
                        superUser: values.superUser,
                      }}
                      minRows={0}
                      NoDataComponent={() => null}
                    >
                      {(state, makeTable) => {
                        return (
                          <div>
                            {makeTable()}
                            <TableInfo
                              totalRows={transactionsSummaryState.total}
                              page={state.page}
                              pageSize={state.pageSize}
                            />
                          </div>
                        )
                      }}
                    </ReactTable>

                    {/*<h4 className="mb-4" style={{marginTop: '3rem'}}>Sim Activations</h4>
                    <ReactTable
                      ref={(instance) => {
                        this.table = instance;
                      }}
                      columns={[
                        {
                          Header: "Username",
                          accessor: "applicationUserName",
                        },
                        {
                          Header: "Date",
                          id: "startedUtc",
                          accessor: d => moment(d.startedUtc).format('YYYY-MM-DD'),
                        },
                        {
                          Header: "Sku Code",
                          accessor: "skuCode",
                        },
                        {
                          Header: "Client",
                          accessor: "clientName",
                        },
                        {
                          Header: "Value",
                          id: "sendValue",
                          accessor: d => d.sendValue.toFixed(2),
                        },
                        {
                          Header: "State",
                          accessor: "processingState",
                        },
                        {
                          Header: "Office",
                          accessor: "commercialOfficeName",
                        },
                        {
                          Header: "Province",
                          accessor: "provinceName",
                        },
                        {
                          Header: "Detail",
                          accessor: "detail",
                          Cell: ({row}) => (
                            row.processingState === 'Complete' ?
                              <Button
                                variant="link" className="btn-row border-radius-4"
                                onClick={() => openSimActivationSummaryDetail(row._original)}>
                                <FontAwesomeIcon icon="sticky-note"/>
                              </Button> : ''),
                          sortable: false
                        }
                      ]}
                      defaultSorted={[
                        {
                          id: "applicationUserName",
                          desc: false
                        },
                      ]}
                      manual
                      data={simActivationsSummaryState.data}
                      pages={simActivationsSummaryState.pages}
                      loading={inProgressSecondary}
                      onFetchData={(state) => loadSimActivationsSummary(state)}
                      defaultPageSize={simActivationsSummaryState.pageSize}
                      pageSizeOptions={[5, 10, 20]}
                      className="-striped -highlight"
                      filter={{
                        fromDate: values.fromDate,
                        toDate: values.toDate,
                        userName: values.userName,
                      }}
                      minRows={0}
                      NoDataComponent={() => null}
                    >
                      {(state, makeTable) => {
                        return (
                          <div>
                            {makeTable()}
                            <TableInfo
                              totalRows={simActivationsSummaryState.total}
                              page={state.page}
                              pageSize={state.pageSize}
                            />
                          </div>
                        )
                      }}
                    </ReactTable>*/}
                  </Form>
                );
              }}
            </Formik>
          </div>
        </Col>
        <SimActivationSummaryDetail
          show={showSimActivationSummaryDetail}
          onHide={closeSimActivationSummaryDetail}
          selectedSimActivationSummary={selectedSimActivationSummary}
        />
      </Row>
    );
  }
}

export default Summary;