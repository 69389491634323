import React from 'react';
import { Image } from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import downloadImage from "../../assets/img/common/pay-card/download.png";
import visaImg from "../../assets/img/common/pay-card/visa.png";
import masterCardImg from "../../assets/img/common/pay-card/master-card.png";
import amexImg from "../../assets/img/common/pay-card/amex.png";
import paypalImg from "../../assets/img/common/pay-card/paypal.png";

import './Footer.css';

import {faFacebook, faTwitter, faInstagram, faTumblr, faPinterest} from "@fortawesome/free-brands-svg-icons";

const Footer = () => (
  <footer>
    <div className="bg-gray-2 text-align-left pt-3 pb-3 footer-payment-information-container">
        <div className="row footer-payment-information-container-2">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 footer-container-left">
            <div className="download-image-footer">
              <Image src={downloadImage}/>
            </div>
            <div className="">
              <p className="download-content-text">Download<FontAwesomeIcon icon="download" size="1x" className="ml-2"/></p>
              <p className="cell-shop-express-p">CellShop Express</p>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pt-18 payment-footer-super-container">
            <div className="images-container-payment-method">
              <Image src={visaImg} className="pay-item"/>
              <Image src={masterCardImg} className="pay-item"/>
              <Image src={amexImg} className="pay-item"/>
              <Image src={paypalImg} className="pay-item"/>
            </div>
          </div>
        </div>
    </div>
    <div className="bg-gray-3 text-align-left footer-information-container">
      <div className="pl-5 pr-5">
        <div className="row">
        
          <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 footer-sub-container">
            <h4 className="service-category mb-3">Connect with Us</h4>
            <p className="social-network-p">
              <FontAwesomeIcon icon={faFacebook} size="lg" className="mr-3 social-network-container color-dark-gray"/>
              <FontAwesomeIcon icon={faTwitter} size="lg" className="mr-3 social-network-container color-dark-gray"/>
              <FontAwesomeIcon icon={faInstagram} size="lg" className="mr-3 social-network-container color-dark-gray"/>
              <FontAwesomeIcon icon={faTumblr} size="lg" className="mr-3 social-network-container color-dark-gray"/>
              <FontAwesomeIcon icon={faPinterest} size="lg" className="mr-3 social-network-container color-dark-gray"/>
            </p>
          </div>

          <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 footer-sub-container">
            <h4 className="service-category mb-3">Contact</h4>
            <p><FontAwesomeIcon icon="map-marker-alt" size="1x" className="mr-3 color-dark-gray"/>8339 Bird Rd. Miami, Florida 33155.
              United States</p>
            <p><FontAwesomeIcon icon="envelope" size="1x" className="mr-3 color-dark-gray"/><a href="mailto:support@mygeedee.com"
                                                                               className="email"> support@mygeedee.com</a>
            </p>
            <p><FontAwesomeIcon icon="phone" size="1x" className="mr-3 color-dark-gray"/>1-305-600-2318</p>
          </div>

          <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 footer-sub-container">
            <h4 className="service-category mb-3">Terms & Conditions</h4>
            <p><FontAwesomeIcon icon="scroll" size="1x" className="mr-3 color-dark-gray"/>Terms</p>
            <p><FontAwesomeIcon icon="exchange-alt" size="1x" className="mr-3 color-dark-gray"/>Refund Policy</p>
            <p><FontAwesomeIcon icon="book" size="1x" className="mr-3 color-dark-gray"/>Knowledge Base</p>
          </div>        
        
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;