import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Message extends React.Component {
  showMessage () {
    if (this.props.type === 'info')
      toast.info(this.props.text, {onClose: this.props.onClose});
    else if (this.props.type === 'warning')
      toast.warn(this.props.text, {onClose: this.props.onClose});
    else if (this.props.type === 'success')
      toast.success(this.props.text, {onClose: this.props.onClose});
    else if (this.props.type === 'error')
      toast.error(this.props.text, {onClose: this.props.onClose});
    else
      toast(this.props.text, {onClose: this.props.onClose});
  }

  componentDidMount() {
    this.showMessage();
  }

  render(){
    return (
      <div className="text-left">
        <ToastContainer
          position="bottom-left"
          autoClose={3000}
          //hideProgressBar
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
      </div>
    );
  }
}
export default Message;