import React from 'react';
import {Button, Form, Modal, Spinner} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const DeleteUser = (props) => {
  const {
    selectedUser, inProgress, submitDeleteUser, ...rest
  } = props;
  return (
    <Modal
      {...rest}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-app"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="app-header">
          Delete User
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Label>
          Sure you want to delete the user&nbsp;
          <span className="font-weight-bold">{selectedUser ? selectedUser.username.toUpperCase() : ''}</span>?
        </Form.Label>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="btn btn-form-app btn-option border-radius-4"
          type="submit"
          onClick={() => submitDeleteUser(selectedUser.id)}
        >
          {
            inProgress ?
              <Spinner
                animation="grow"
                variant="secondary"
                style={{marginRight: '5px', marginBottom: '4px'}}
                size="sm"
              />
              : ''
          }
          Accept
          <FontAwesomeIcon icon="arrow-right" className="icon-right"/>
        </Button>
        <Button onClick={props.onHide} className="btn btn-outline-dark btn-form-app border-radius-4">Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteUser;